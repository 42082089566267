import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import Visor from './visor'

const MaimModal = ({ useFetchInit }) => {
    const { dataModal, FetchGetAnuncios, toggle } = useFetchInit
    const { open, titleModal, componentModal } = dataModal

    const { anuncios } = FetchGetAnuncios

    return (
        <>
            <Modal
                id='ANUNCIOS'
                isOpen={anuncios.length > 0 && !open}
                toggle={toggle}
                className='modal-body'
                centered={true}
                size='lg'
                // id='modal-xl'
            >
                <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                {componentModal === 'visor-de-anuncios' && <Visor useFetchInit={useFetchInit} />}
            </Modal>
        </>
    )
}

export default MaimModal
