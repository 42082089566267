import axios from 'axios'

export const getCargas = async (token_user, token_page, solicitud, url_get_cargas) => {
    console.log(`Cargando listado de cargas ${solicitud}`)

    const retorno = await axios
        .get(`${url_get_cargas}?v_solicitud=${solicitud}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            console.log(data)
            return data
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return []
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return []
            } else {
                console.log(e.response.data)
                return []
            }
        })

    return retorno
}
