import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import axios from 'axios'
import DataForm from '../yard-density/DataForm'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'
import BarGraphic from '../yard-density/BarGraphic'

const YardDensityDashboard = props => {
    const token_user = props.match.params.tokenaut

    const [ctipos, setToken] = useState({
        mensaje_carga: 'Ingresar un token válido, para poder operar...',
        carga: false,
        respuesta: false,
    })

    const [showDashboards, showDash] = useState(false)
    console.log(showDashboards)
    const [graphicData, setGraphicData] = useState("")

    let Graficas = []

    const callData = async function (year, weekFrom, weekTo) {
        console.log(`Generando grafica: y: ${year} from: ${weekFrom} to: ${weekTo}`)
        callApi(year, weekFrom, weekTo)
        showDash(true)
    }

    const pad = function (num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    const callApi = async function (year, weekFrom, weekTo) {
        await axios({
            method: 'GET',
            url:
                process.env.REACT_APP_DASHBOARDSCMP_API +
                `/v1/yard-density/weeks/${year}/${weekFrom}/${weekTo}`,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                setGraphicData(res.data.data.map(r => ({
                    year: r.year,
                    week: `WK${pad(r.week, 2)}`,
                    percentage: r.percentage
                })));
            })
            .catch(err => {
                console.log('Error: ' + err)
            })
    }

    const resetGraphic = async function (){
        setGraphicData([]);
    }

    useEffect(() => {
        if (token_user === undefined) {
            setToken({
                ...ctipos,
                carga: false,
                respuesta: false,
                mensaje_carga: 'Token No valido.',
            })
            return
        } else {
            setToken({
                ...ctipos,
                carga: true,
                respuesta: true,
                mensaje_carga: 'Token correcto.',
            })
        }
    }, [])

    return (
        <Fragment>
        <DisclaimerAnuncio {...props} />
        {ctipos.carga ? (
            <div>
                <div className='mt-3'>
                    <DataForm callData={callData} resetGraphic={resetGraphic} pad={pad} token_user={token_user} />
                </div>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <BarGraphic key={0} pad={pad} data={graphicData} />
                    </Col>
                </Row>
            </div>
        ) : (
            <div>
                <h1>token no valido</h1>
            </div>
        )}
    </Fragment>
    )
}

export default YardDensityDashboard
