import { postValidaFecha } from './postValidaFecha'

export const FetchValidaFecha = async ({
    token_user,
    token_page,
    url_valida_fecha,
    solicitud_apex,
    facturar_a,
    fechaRetiro,
    setRespuestaSolicitud,
    respuestaSolicitud
}) => {
    const { v_mensaje, v_resultado, v_estado } = await postValidaFecha(
        token_user,
        token_page,
        url_valida_fecha,
        solicitud_apex,
        facturar_a,
        fechaRetiro
    )
        .then(res => res)
        .catch(err => {
            console.log(err)
            return {
                v_estado: false,
                v_mensaje: 'Error interno del servidor',
                v_resultado: false
            }
        })

    console.log('999999999999999 VALIDA FECHAAAA----------------------> ', v_mensaje, v_resultado, v_estado)
    setRespuestaSolicitud({
        ...respuestaSolicitud,
        valida_fecha: v_mensaje,
        estado_valida_fecha: v_resultado === 'true',
        mostrar_respuestas: true
    })
    const validaFecha = v_estado === true && v_resultado === 'true'
    return { validaFecha }
}
