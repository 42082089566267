import React, { Fragment } from 'react'
import { NombreDe } from './nombreDe'
import { FacturarA } from './facturarA'
import TipoSolicitudes from './tipoSolicitudes'
const FirstStep = ({
    handleInputChange,
    valor_tipo,
    token_user,
    token_page,
    validanit,
    // v_habilita,
    url_ennombre,
    url_tipos,
    url_facturar_a,
    msgForm
    // ctipos,
    // setCtipos,
}) => {
    return (
        <Fragment>
            <div className='col-sm-12 col-xl-12'>
                <div className='card '>
                    <div className='card-header b-b-warning  border-3'>
                        <h5>Ingrese la información principal</h5>
                    </div>

                    <div className='card-body'>
                        <NombreDe
                            handleInputChange={handleInputChange}
                            token_user={token_user}
                            token_page={token_page}
                            url_ennombre={url_ennombre}
                        />

                        <TipoSolicitudes
                            handleInputChange={handleInputChange}
                            p_valor_tipo={valor_tipo}
                            token_user={token_user}
                            token_page={token_page}
                            url_tipos={url_tipos}
                        />

                        <div className='form-group row'>
                            <label className='col-sm-3 col-form-label' htmlFor='inputEmail3'>
                                {'Observaciones'}
                            </label>
                            <div className='col-sm-9'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='form-group mb-0'>
                                            <textarea
                                                name='observacion'
                                                className='form-control'
                                                placeholder='Agregar observación'
                                                onChange={handleInputChange}
                                                rows='5'
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FacturarA
                            handleInputChange={e => handleInputChange(e)}
                            token_user={token_user}
                            token_page={token_page}
                            p_valida_nit={validanit}
                            // p_habilita={v_habilita}
                            url_facturar_a={url_facturar_a}
                        />
                    </div>
                    {msgForm === true && <h6 style={{ color: 'red' }}>Todos los campos son obligatorios*</h6>}
                </div>
            </div>
        </Fragment>
    )
}

export default FirstStep
