import axios from 'axios'

export const GetSolicitudes = async (token_user, token_page, url_solicitud) => {
    const retorno = await axios
        .get(url_solicitud, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            // console.log(data)
            return {
                v_solicitud: data.v_solicitud,
                v_usuario: data.v_usuario,
                v_mensaje: 'success',
                v_estado: true
            }
        })
        .catch(e => {
            console.log(e)
            return {
                v_solicitud: '',
                v_usuario: '',
                v_mensaje: '500',
                v_estado: false
            }

            // if (e.response.status === 500) {
            //     console.log('There was a problem with the server')
            //     return {
            //         v_solicitud: '',
            //         v_usuario: '',
            //         v_mensaje: '500',
            //         v_estado: false,
            //     }
            // } else if (e.response.status === 401) {
            //     console.log(`No hay autorización`)
            //     return {
            //         v_solicitud: '',
            //         v_usuario: '',
            //         v_mensaje: '401',
            //         v_estado: false,
            //     }
            // } else {
            //     console.log(e.response.data)
            //     return {
            //         v_solicitud: '',
            //         v_usuario: '',
            //         v_mensaje: 'error',
            //         v_estado: false,
            //     }
            // }
        })

    // console.log(retorno)
    return retorno
}
