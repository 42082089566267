import { useState, useEffect } from 'react'
import { getListadoDocumentosClientes } from '../../helpers/vinculaciones/getListadoDocumentosClientes'

export const useFetchListDocCliente = (
    url_listado_vinculacion,
    servicio_listado_documentos,
    token_page,
    codigo_vinculacion,
    mostrardocuv
) => {
    const [listdoc, setListDoc] = useState([])

    useEffect(() => {
        getListadoDocumentosClientes(
            url_listado_vinculacion,
            servicio_listado_documentos,
            token_page,
            codigo_vinculacion
        )
            .then(resultado => {
                setListDoc(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `, error)
            })

        return () => {}
    }, [url_listado_vinculacion, servicio_listado_documentos, codigo_vinculacion, token_page, mostrardocuv])

    return [listdoc]
}
