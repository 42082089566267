import axios from 'axios'

export const postEliminarContainer = async (token_user, token_page, url_eliminacion, sol_contenedor) => {
    console.log(sol_contenedor)

    const retorno = await axios
        .post(
            url_eliminacion,
            { v_solicitud_contenedor: sol_contenedor },
            {
                headers: {
                    'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            console.log(data)
            return {
                v_resultado: data.v_resultado,
                v_mensaje: data.v_mensaje,
                v_estado: true
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_resultado: '',
                    v_mensaje: '500',
                    v_estado: false
                }
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_resultado: '',
                    v_mensaje: '401',
                    v_estado: false
                }
            } else {
                console.log(e.response.data)
                return {
                    v_resultado: '',
                    v_mensaje: 'error',
                    v_estado: false
                }
            }
        })

    return retorno
}
