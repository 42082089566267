/**
 * @module hooks/vinculaciones/UseFetchPerfilesUv
 * @description UseFetchPerfilesUv: función para hacer la peticion a la base de datos
 * para la carga de perfiles
 */
import { useState, useEffect } from 'react'
/**
 * @async
 * @function
 * @name module:hooks/vinculaciones/UseFetchPerfilesUv#getPerfiles
 * @description getPerfiles: función para hacer la peticion a la base de datos
 * para la carga de perfiles
 * @see {@link module:helpers/vinculaciones/getPerfiles}
 * @example
 * http://dominio/vinculacion-usuarios
 */
import { getPerfiles } from '../../helpers/vinculaciones/getPerfiles'

export const UseFetchPerfilesUv = (url_listado_vinculacion, servicio_perfiles_usuario, token_page) => {
    const [perfiles, setPerfiles] = useState([]) // tipos

    useEffect(() => {
        getPerfiles(url_listado_vinculacion, servicio_perfiles_usuario, token_page)
            .then(resultado => {
                setPerfiles(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `, error)
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [url_listado_vinculacion, servicio_perfiles_usuario, token_page])

    return [perfiles]
}
