import axios from 'axios'

export const postConfirmaCredenciales = async (
    url_confirmacion,
    servicio_confirma_credenciales,
    token_page,
    codigo_encritado
) => {
    // se cambia a la variable porque lo resibe distinto
    let servicio = ''
    let v_codigo = ''
    servicio = servicio_confirma_credenciales
    v_codigo = codigo_encritado

    const retorno = await axios
        .post(
            url_confirmacion,
            {
                servicio,
                v_codigo
            },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            console.log(data)
            return {
                v_nit: data.v_nit,
                v_cod_vinculacion: data.v_cod_vinculacion,
                v_mensaje: data.v_mensaje,
                v_resultado: data.v_resultado,
                v_error: true
            }
        })
        .catch(e => {
            console.log(`Aquí en el error..........`)
            console.log(e)
            if (e.response?.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_mensaje: '500',
                    v_resultado: false,
                    v_error: false
                }
            } else if (e.response?.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_mensaje: 'Error de autorización',
                    v_resultado: false,
                    v_error: false
                }
            } else {
                console.log(e.response?.data)
                return {
                    v_mensaje: 'ERROR en la comunicación, comunicar a APM Terminals',
                    v_resultado: false,
                    v_error: false
                }
            }
        })

    return retorno
}
