import axios from 'axios'

export const getValidacionNit = async (
    nit_cliente_vinculacion,
    perfil_seleccionado,
    servicios,
    urlPeticiones
) => {
    const token_user = 'nada'
    const { servicio_nit } = servicios
    const { token_page, url_servicios} = urlPeticiones
    const rutaConsulta = `${url_servicios}?servicio=${servicio_nit}&v_nit=${nit_cliente_vinculacion}&v_perfil=${perfil_seleccionado}`
    console.log('RUTA_VALIDAR_NIT ', rutaConsulta)

    const retorno = await axios
        .get(
            rutaConsulta,
            {
                headers: {
                    'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
