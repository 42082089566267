import { useEffect, useState } from 'react'
import { postConfirmaUsuarioEncriptado } from '../../helpers/vinculaciones/postConfirmaUsuarioEncriptado.js'

export const useFetchValidaEncriptacion = ({
    url_confirmacion,
    servicio_confirma_credenciales,
    token_page,
    codigo_encritado
}) => {
    let initialState = {
        respuesta_api: false,
        mensaje_api: '',
        carga: true,
        perfil_carga: false,
        validacion_campos: false,
        user: '',
        password_actual: '',
        password_nuevo: '',
        password_confirmacion: '',
        credenciales: false,
        codigo_vinculacion: '',
        respuesta_credenciales: false
    }

    const [documentacion, setDocumentacion] = useState(initialState)

    useEffect(() => {
        const valida_encriptacion = async () => {
            postConfirmaUsuarioEncriptado({
                url_confirmacion,
                servicio_confirma_credenciales,
                token_page,
                codigo_encritado
            }).then(retorno_credenciales => {
                console.log(retorno_credenciales)
                if (retorno_credenciales.v_resultado) {
                    setDocumentacion({
                        ...documentacion,
                        credenciales: retorno_credenciales.v_resultado,
                        codigo_vinculacion: retorno_credenciales.v_cod_vinculacion,
                        respuesta_credenciales: retorno_credenciales.v_resultado,
                        mensaje_carga: retorno_credenciales.v_mensaje,
                        carga: false
                    })
                } else {
                    if (retorno_credenciales.v_error) {
                        setDocumentacion({
                            ...documentacion,
                            carga: false,
                            respuesta: false,
                            mensaje_carga: retorno_credenciales.v_mensaje,
                            respuesta_credenciales: true,
                            codigo_vinculacion: ''
                        })
                    } else {
                        setDocumentacion({
                            ...documentacion,
                            carga: false,
                            respuesta: false,
                            mensaje_carga: retorno_credenciales.v_mensaje,
                            respuesta_credenciales: false,
                            codigo_vinculacion: ''
                        })
                    }
                }
            })

            // Dismount
            return () => {}

            //invocar nuevo token.
        }

        if (codigo_encritado === undefined || codigo_encritado === '') {
            return
        } else {
            valida_encriptacion()
        }
    }, [codigo_encritado, url_confirmacion, token_page, servicio_confirma_credenciales, setDocumentacion, documentacion])
    // documentacion se agrego a la dependencia

    return [documentacion, setDocumentacion] // {data: [], loading: true}
}
