import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import Loading from '../modal-components/loading'

const AgregarDocumentos = ({
    setMostrardocumentos,
    // mostrardocumentos,
    ctipos,
    token_user,
    token_page,
    url_insertar_documentos
    // url_listado_documentos,
}) => {
    const [file, setFile] = useState('')
    const [filename, setFilename] = useState('Choose File')
    const [enableButton, setEnableButton] = useState(true)
    const [load, setLoad] = useState(false)
    const { solicitud_apex, sub_tipo_solicitud } = ctipos
    const onChange = e => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0])
            setFilename(e.target.files[0].name)
            setEnableButton(false)
            consultar_documentos()
        }
    }

    const consultar_documentos = () => {
        setLoad(true)
        const formData = new FormData()
        formData.append('file', file)
        setMostrardocumentos(true)

        try {
            setMostrardocumentos(true)

            axios
                .post(url_insertar_documentos, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-auth-token': token_user,
                        Authorization: `Basic ${token_page}`,
                        v_solicitud: solicitud_apex
                    }
                })
                .then(response => {
                    console.log(response)
                    setMostrardocumentos(true)
                    toast.success('Documento agregado correctamente!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                    setMostrardocumentos(false)
                    setLoad(false)
                })
                .catch(e => {
                    console.log(e)
                    if (e.response.status === 500) {
                        console.log('There was a problem with the server')
                    } else {
                        console.log(e.response.data)
                    }
                    setMostrardocumentos(false)
                    setLoad(false)
                })
        } catch (err) {
            console.log(`Hay error en el envio...`)
            console.log(err)

            if (err.response.status === 500) {
                console.log('There was a problem with the server')
            } else {
                console.log(err.response.data)
            }
            setMostrardocumentos(false)
        }
    }

    return (
        <Fragment>
            <div className='col-sm-12'>
                <div className='card'>
                    <div className='card-header b-b-warning  border-3 mt-4'>
                        {sub_tipo_solicitud === '142' ||
                        sub_tipo_solicitud === '141' ||
                        sub_tipo_solicitud === '101' ||
                        sub_tipo_solicitud === '461' ? (
                                <h5>Adjunte copia de BL</h5>
                            ) : (
                                <h5>
                                    Adjunte copia de Póliza (DUA/DUT) - SI su Agente Aduanero ha transmitido DUCA_DAT
                                    electrónica, puede consultar la información acá!
                                </h5>
                            )}
                    </div>

                    <div className='card-body'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        {filename}
                                    </label>
                                    <div className='card-body'>
                                        <input
                                            type='file'
                                            className='custom-file-input'
                                            name='foo'
                                            id='customFile'
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {load ? (
                            <Loading />
                        ) : (
                            <Button
                                type='button'
                                disabled={enableButton}
                                className='btn btn-warning'
                                onClick={consultar_documentos}
                            >
                                Adjuntar documento
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AgregarDocumentos
