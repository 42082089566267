/**
 * @module components/VinculacionUsuarios
 * @description componente para manejar la vinculación de usuarios
 */
import React, { Fragment } from 'react'
import { useFetchInitVinculaciones } from '../../hooks/vinculaciones'
import MainVinculacion from './cards'

const MainComponent = (props) => {

    const servicios = {
        servicio_paises: 'paises',
        servicio_ciudades: 'ciudades',
        servicio_tipo_contacto: 'tipo-contacto',
        servicio_monedas: 'monedas',
        servicio_nit: 'nit',
        servicio_perfiles_usuario: 'perfiles-usuario',
        servicio_modalidad: 'modalidad',
        servicio_tipo_persona: 'tipo-persona',
        servicio_terminos: 'terminos'    
    }
    const perfilesContacto = {
        perfil_contacto_cliente: 'CLIENTE',
        perfil_contacto_proveedor: 'PROVEEDOR'      
    }
    const urlPeticiones = {
        token_page: process.env.REACT_APP_TOKEN_PAGE,
        url_servicios: process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/validacion',
        url_confirmacion: process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/confirmacion',
        url_listado_vinculacion: process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/listado'
    } 

    const useFetchInit = useFetchInitVinculaciones({
        servicios,
        urlPeticiones
    })

    console.log(useFetchInit)

    const { perfiles, modalidad, tipopersona, terminos } = useFetchInit

    if (perfiles.length === 0) {
        return <EmptyDataComponent label="Perfiles" />
    }

    if (modalidad.length === 0) {
        return <EmptyDataComponent label="Modalidad" />
    }

    if (tipopersona.length === 0) {
        return <EmptyDataComponent label="Tipo Persona" />
    }

    if (terminos.length === 0) {
        return <EmptyDataComponent label="Terminos" />
    }


    return (
        <Fragment>
            <MainVinculacion
                useFetchInit={useFetchInit}
                servicios={servicios}
                perfilesContacto={perfilesContacto}
                urlPeticiones={urlPeticiones}
                {...props} 
            />
        </Fragment>
    )
}

const EmptyDataComponent = ({ label }) => {
    console.log('vino por aqui')
    return <p>No hay datos disponibles para {label}</p>
}

export default MainComponent
