import React from 'react'
import { Container } from 'reactstrap';
import {Bar} from 'react-chartjs-2'
const BarChartGroup = (props) => {
    const dat = props.datos;
    let sets = [];
    const colors = [
                        'RGBA(252,100,28,1)',
                        'RGBA(8,68,100,1)',
                        'RGBA(240,172,52,1)',
                        'RGBA(100,196,212,1)',
                        'RGBA(116,108,100,1)',
                        'RGBA(232,220,202,1)',
                        'RGBA(96,120,40,1)',
                        'RGBA(108,188,190,1)',
                        'RGBA(52,148,180,1)'
                   ]
    const parseData =()=>{
        let subdata =[];
        let subarray = [[],[],[],[],[],[],[],[],[]];
        for(let i = 0;i<dat.nameColumns.length;i++){
            subdata = dat.data[i]?Object.values(dat.data[i]):[0,0,0,0,0,0,0,0,0]
            for(let j=0;j<subdata.length;j++){
                subarray[j].push(
                    subdata[j]
                )
            }
            sets.push({
                label:dat.nameColumns[i],
                backgroundColor:colors[i],
                data:subarray[i]?subarray[i]:0
            })
        }
    }
    parseData();
    return (
        <Container>
            {dat?(<>
            <div>
                <label>{dat.nameDashboard?dat.nameDashboard:'Nombre no encontrado'}</label>
            </div>
               {/*<div className="textRotation">
                   {dat.aditionalText}
                </div>*/}
               <Bar className="CustomCssBar"
                   data={{
                       labels:dat.mes,
                       datasets:sets
                    }
                   }
                   options={{
                       responsive: false,
                       interaction: {
                           intersect: false,
                       },
                       plugins: {
                           datalabels: {
                            display: true,
                            align: 'center'
                           }
                       },
                   }}
                   height = {465}
                   width = {1000}
               />
               </>):null
        }
        </Container>
    )
}

export default BarChartGroup;