import { getValidaCarga } from './getValidaCarga'
export const FetchValidaCarga = async ({
    token_user,
    token_page,
    url_valida_carga,
    solicitud_apex,
    setRespuestaSolicitud,
    respuestaSolicitud
}) => {
    const { v_mensaje, v_resultado, v_estado } = await getValidaCarga(
        token_user,
        token_page,
        url_valida_carga,
        solicitud_apex
    )
        .then(res => res)
        .catch(err => {
            console.log(err)
            return {
                v_estado: false,
                v_mensaje: 'Error interno del servidor',
                v_resultado: false
            }
        })

    console.log('111111111111 VALIDA CARGA----------------------> ', v_mensaje, v_resultado, v_estado)
    setRespuestaSolicitud({
        ...respuestaSolicitud,
        valida_carga: v_mensaje,
        estado_carga: v_resultado === 'true',
        mostrar_respuestas: true
    })
    const validaCarga = v_estado === true && v_resultado === 'true'
    return { validaCarga }
}
