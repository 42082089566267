import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import Targets from './targets'
import Breadcrumb from '../common/breadcrumb'
import Modal from './modal-components/modal'
import ModalContainerStatus from './modal-components/modal-container-status'
import { headerSiSat } from './headers/header-si-sat.js'
import { headerNoSat } from './headers/header-no-sat.js'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
    DataGrid,
    GridToolbar,
    GridOverlay,
    GridPreferencePanelsValue
} from '@material-ui/data-grid'
import styled from '@emotion/styled'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

const PreArribos = (props) => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    const [sizeTab, setSizeTab] = useState(200)
    const [sat, setSat] = useState('')
    const [modal, setModal] = useState()
    const [componentModal, setComponentModal] = useState('')
    const [titleModal, setTitleModal] = useState('')
    const [showData, setShowData] = useState(false)
    const [dataRequest, setDataRequest] = useState({})
    const [loginMsg, setLoginMsg] = useState('')
    const [stateForm, setStateForm] = useState({ contenedor: '' })
    const [modalContainerStatus, setModalContainerStatus] = useState(false)
    const [dataRequestContainerStatus, setDataRequestContainerStatus] = useState({})
    const awaitData = async () => {
        const resp = await axios
            .get(
                process.env.REACT_APP_EXTERNAL_PANEL +
                    '/v1/apm-services/panel-liberaciones-leyendas',
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`
                    }
                }
            )
            .then((res) => res)
        setData(resp.data.data)
        setSat(resp.data.sat)
        setLoad(false)
        setSizeTab(1000)
    }

    const toggle3 = (_, title, compont, dataRequest) => {
        setModal(!modal)
        if (modal) {
            setShowData(false)
            setLoginMsg('')
        }
        compont && setComponentModal(compont)
        title && setTitleModal(title)
        dataRequest && setDataRequest(dataRequest)
    }
    const toggleContainerStatus = (_, dataRequest) => {
        setModalContainerStatus(!modalContainerStatus)
        dataRequest && setDataRequestContainerStatus(dataRequest)
    }

    useEffect(() => {
        awaitData()
        return () => {
            console.log(`Limpieza en Panel`)
        }
    }, [])

    const sendRequest = async (contenedor) => {
        // setLoginValid(true);
        // setLoginMsg('');
        setLoad(true)
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/panel-liberaciones-filter',
                {
                    data: {
                        contenedor: contenedor
                    }
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`
                    }
                }
            )
            .then((resp) => {
                console.log(resp)
                setData(resp.data.data)
                setSat(resp.data.sat)
                setLoad(false)
            })
            .catch((error) => setLoginMsg(error.response.data.msg))
        // setLoginValid(false);
    }
    const enviarDatosBusqueda = (event) => {
        event.preventDefault()

        setLoad(true)
        if (stateForm.contenedor === '') {
            awaitData()
        } else {
            // const { usuario, password } = !!datos && datos;
            sendRequest(stateForm.contenedor)
        }
    }
    const handleInputChange = (event) => {
        setStateForm({
            ...stateForm,
            [event.target.name]: event.target.value
        })
    }
    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='IMPORTACIÓN - Panel de Liberaciones' parent='Servicios' />
            </Titulo>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <Targets />
                    </div>
                    <div className='col-sm-12'>
                        <form onSubmit={enviarDatosBusqueda}>
                            <div className='form-group row'>
                                <div className='form-group col-sm-12 col-md-8 col-xl-10 row'>
                                    <div className='col-2'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='contenedor-buscar'
                                            name='contenedor'
                                            placeholder='Contenedor'
                                            value={stateForm.contenedor}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <button
                                            type='submit'
                                            className='btn btn-warning col'
                                            id='btn-buscar'
                                        >
                                            <i className='icofont icofont-search' /> Buscar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <Modal
                        toggle={toggle3}
                        modal={modal}
                        title={titleModal}
                        componentModal={componentModal}
                        setDat={setShowData}
                        showData={showData}
                        dataRequest={dataRequest}
                        setLoginMsg={setLoginMsg}
                        loginMsg={loginMsg}
                        enviarDatosBusqueda={enviarDatosBusqueda}
                        load={load}
                    />
                    <ModalContainerStatus
                        toggle={toggleContainerStatus}
                        modal={modalContainerStatus}
                        title={titleModal}
                        dataRequest={dataRequestContainerStatus}
                        toggle3={toggle3}
                    />

                    <div className='col-sm-12'>
                        <div className='card'>
                            <div style={{ height: sizeTab, width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={
                                        sat === 'SI'
                                            ? headerSiSat({ toggle3, toggleContainerStatus })
                                            : headerNoSat({ toggle3 })
                                    }
                                    pageSize={50}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay
                                        // Toolbar: GridToolbar,
                                    }}
                                    loading={load}
                                    state={{
                                        preferencePanel: {
                                            open: false,
                                            openedPanelValue: GridPreferencePanelsValue.filters
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PreArribos
