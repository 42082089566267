import React from 'react';
import bullet_red from '../../../assets/images/estados/bullet-red.png';
import bullet_green from '../../../assets/images/estados/bullet-green.png';
import bullet_yellow from '../../../assets/images/estados/bullet-yellow.png';
import bullet_gray from '../../../assets/images/estados/bullet-gray.png';
import { UncontrolledTooltip } from 'reactstrap';

export function headerNoSat({ toggle3 }) {
    return [
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 350 },
        { field: 'BUQUE', headerName: 'Buque', width: 350 },
        { field: 'EAESCALA', headerName: 'Viaje con APMTQ', width: 300 },
        {
            field: 'NAVIERA',
            headerName: 'Naviera',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'RET_APMTQ',
            headerName: 'APMTQ',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor'
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}. <br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor'
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}. <br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor'
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}. <br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor ml-1'
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}.<br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'XRAY',
            headerName: 'INSPECCIÓN NO INTRUSIVA',
            width: 270,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                className='imgCursor'
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                {params.row.XRAY_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                className='imgCursor'
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Información ATC', 'atc', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.XRAY_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                className='imgCursor'
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                {params.row.XRAY_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                className='imgCursor ml-1'
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                                onClick={() => toggle3('', 'Solicitud Inspección No Intrusiva', 'rayosx', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                {params.row.XRAY_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
    ];
}
