import axios from 'axios'

export const postVincularAutoridad = async (vinculacion, servicio_vinculacion, url_confirmacion, token_page) => {
    // se cambia a la variable porque lo resibe distinto
    let servicio = ''
    servicio = servicio_vinculacion
    const {
        sat_correo_electronico,
        sat_direccion,
        sat_nit,
        sat_nombre_completo,
        sat_perfil,
        sat_puesto,
        sat_telefono,
        sat_telefono2
    } = vinculacion

    const retorno = await axios
        .post(
            url_confirmacion,
            {
                servicio,
                sat_correo_electronico,
                sat_direccion,
                sat_nit,
                sat_nombre_completo,
                sat_perfil,
                sat_puesto,
                sat_telefono,
                sat_telefono2
            },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return {
                v_mensaje: data.v_mensaje,
                v_resultado: data.v_resultado
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response?.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_mensaje: '500',
                    v_resultado: false
                }
            } else if (e.response?.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_mensaje: '401',
                    v_resultado: false
                }
            } else {
                console.log(e.response?.data)
                return {
                    v_mensaje: 'ERROR',
                    v_resultado: false
                }
            }
        })

    return retorno
}
