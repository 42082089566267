import React, { Fragment } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment-timezone';
const ModalModule = props => {
    const { toggle, title, responseData, dataRequest } = props;
    const header = [
        {
            field: 'CONTENEDOR',
            headerName: 'Contenedor',
            width: 200,
        },
        {
            field: 'BUQUE',
            headerName: 'Buque',
            width: 200,
        },
        {
            field: 'VIAJE',
            headerName: 'Viaje',
            width: 200,
        },
        {
            field: 'AUTORIDAD_SOLICITO',
            headerName: 'Autoridad solicitó',
            width: 200,
        },
        {
            field: 'FECHA_ULTIMA_SOLICITUD',
            headerName: 'Fecha última solicitud',
            width: 300,
            renderCell: params => (
                <strong style={{ fontWeight: 'normal' }}>
                    {params.value === null
                        ? ''
                        : moment(params.value).tz('America/Guatemala').format('YYYY/MM/DD')}
                </strong>
            ),
        },
    ];
    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <label className='col-form-label' htmlFor='recipient-name'>
                                <b>Contenedor:</b> {dataRequest.CONTENEDOR}
                            </label>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: 'ENTIDAD_SOLICITANTE_RETENCION',
                                    sort: 'asc',
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
