import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import Targets from './targets'
import Breadcrumb from '../common/breadcrumb'
import { headerSiSat } from './headers/header-si-sat.js'
import { headerNoSat } from './headers/header-no-sat.js'
import LinearProgress from '@material-ui/core/LinearProgress'
import Timer from './timer'
import { DataGrid, GridToolbar, GridOverlay, GridPreferencePanelsValue } from '@material-ui/data-grid'
import styled from '@emotion/styled'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

const MainComponent = props => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    const [sizeTab, setSizeTab] = useState(200)
    const [sat, setSat] = useState('')
    const [timer, setTimer] = useState({ p2: '0', p3: '0', p4: '0' })
    const [timerAwait, setTimerAwait] = useState(true)

    const getTimer = async filtro => {
        setTimerAwait(true)
        const resp = await axios
            .get(`${process.env.REACT_APP_EXTERNAL_PANEL_ES6_VERSION}/v1/apm-services/unaffectedly/timer?entity=${filtro}`, {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                },
            })
            .then(res => res)
        setTimerAwait(false)
        setTimer(resp.data)
    }

    const awaitData = async () => {
        const resp = await axios
            .get(process.env.REACT_APP_EXTERNAL_PANEL_ES6_VERSION + '/v1/apm-services/solicitudes-pendientes-de-programar', {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                },
            })
            .then(res => res)
        setData(resp.data.data)
        setSat(resp.data.sat)
        setLoad(false)
        setSizeTab(1000)
    }

    useEffect(() => {
        awaitData()
        getTimer()
        return () => {
            console.log(`Limpieza`)
        }
    }, [])

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='IMPORTACIÓN - Solicitudes de inspección pendientes de programar' parent='Servicios' />
            </Titulo>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <Targets />
                    </div>

                    <div className='col-sm-12 mb-3' style={{ alignItems: 'center' }}>
                        <div className='row'>
                            <Timer getTimer={getTimer} timer={timer} timerAwait={timerAwait} />
                        </div>
                    </div>

                    <div className='col-sm-12'>
                        <div className='card'>
                            <div style={{ height: sizeTab, width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={sat === 'SI' ? headerSiSat() : headerNoSat()}
                                    pageSize={50}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay,
                                        Toolbar: GridToolbar,
                                    }}
                                    loading={load}
                                    state={{
                                        preferencePanel: {
                                            open: false,
                                            openedPanelValue: GridPreferencePanelsValue.filters,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainComponent
