import React, { Fragment } from 'react'
import { useFetchTipos } from '../../../hooks/solicitudes/useFetchTipos'

const TipoSolicitudes = ({ handleInputChange, p_valor_tipo, token_user, token_page, url_tipos }) => {
    const [tipos] = useFetchTipos(token_user, token_page, url_tipos)

    return (
        <Fragment>
            <div className='form-group row'>
                <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                    {'Tipos de solicitud *'}
                </label>
                <div className='col-sm-9'>
                    <select className='form-control' name='tipo_solicitud' onChange={handleInputChange}>
                        <option value=''>-- Seleccione el tipo de solicitud --</option>
                        {tipos.map(categoria => {
                            return (
                                <option
                                    key={categoria.COD_TIPO_SOLICITUD}
                                    value={categoria.COD_TIPO_SOLICITUD}
                                >
                                    {categoria.TIPO_SOLICITUD}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className='form-group row'>
                <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                    {'Sub tipos de solicitud *'}
                </label>
                <div className='col-sm-9'>
                    <select className='form-control' name='sub_tipo_solicitud' onChange={handleInputChange}>
                        <option value=''>-- Seleccione el sub tipo de solicitud --</option>
                        {p_valor_tipo.map(categoria => {
                            return (
                                <option
                                    key={categoria.COD_SUB_TIPO_SOLICITUD}
                                    value={categoria.COD_SUB_TIPO_SOLICITUD}
                                >
                                    {categoria.SUB_TIPO_SOLICITUD}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </Fragment>
    )
}

export default TipoSolicitudes
