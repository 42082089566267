import React from 'react';
import clsx from 'clsx'; 

export function headerTable({ toggle3 }) {
    return [
        { field: 'HORARIO', headerName: 'Horario', width: 500}, 
        {
            field: 'PORCENTAJE',
            headerName: 'Disponibilidad',
            width: 500,
            align: 'center',
            headerAlign: 'center', 
            cellClassName: (params) => { 
                return clsx('super-app', {
                  low: params.value >= 0 && params.value <= 49,
                  medium: params.value >= 50 && params.value <= 60,
                  high: params.value >= 61 && params.value <= 100,
                });
              },
              renderCell: params => (
                < >
                    {params.value >= 0 && params.value <= 49 ? (
                        <>
                            {'Disponibilidad '+params.value+'%'}
                        </>
                    ):params.value >= 50 && params.value <= 60 ? (
                        <>
                            {'Disponibilidad '+params.value+'%'}
                        </>
                    ):params.value >= 61 && params.value <= 100 ? (
                        <>
                            {'Disponibilidad '+params.value+'%'}
                        </>
                    ):<></>} 
                </>
            ),
        },
       
    ];
}
