import error_red from '../../../assets/images/estados/if_Error_Symbol.png';
import error_yellow from '../../../assets/images/estados/if_Error_Symbol_yellow.png';
import error_gray from '../../../assets/images/estados/if_Error_Symbol_gray.png';

export function headerSiSat() {
    return [
        { field: 'COD_SOLICITUD_USUARIO', headerName: 'Solicitud', width: 180 },
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 200 },
        { field: 'SUB_TIPO_SOLICITUD', headerName: 'Sub tipo solicitud', width: 280 },
        {
            field: 'ADUANA_SAT',
            headerName: 'Aduanas - SAT',
            width: 250,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'rojo' ? (
                        <img alt='img' src={error_red} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'amarillo' ? (
                        <img alt='img' src={error_yellow} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'gris' ? (
                        <img alt='img' src={error_gray} height='20' width='20' data-placement='top'></img>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'DIPAFRONT_MINGOB',
            headerName: 'DIPAFRONT - MINGOB',
            width: 250,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'rojo' ? (
                        <img alt='img' src={error_red} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'amarillo' ? (
                        <img alt='img' src={error_yellow} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'gris' ? (
                        <img alt='img' src={error_gray} height='20' width='20' data-placement='top'></img>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'SGAIA_MINGOB',
            headerName: 'SGAIA - MINGOB',
            width: 250,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'rojo' ? (
                        <img alt='img' src={error_red} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'amarillo' ? (
                        <img alt='img' src={error_yellow} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'gris' ? (
                        <img alt='img' src={error_gray} height='20' width='20' data-placement='top'></img>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'SEPA_OIRSA',
            headerName: 'SEPA - OIRSA',
            width: 250,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'rojo' ? (
                        <img alt='img' src={error_red} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'amarillo' ? (
                        <img alt='img' src={error_yellow} height='20' width='20' data-placement='top'></img>
                    ) : params.value === 'gris' ? (
                        <img alt='img' src={error_gray} height='20' width='20' data-placement='top'></img>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
    ];
}
