import React, { Fragment, useState, useEffect } from 'react'
import { Button, Table } from 'reactstrap'
import { GetContenedores } from '../../../helpers/solicitudes/GetContenedores'
import { postEliminarContainer } from '../../../helpers/solicitudes/postEliminarContainer.js'
import Loading from '../modal-components/loading'
import icon_error from '../../../assets/images/estados/if_Error_Symbol.png'
import icon_amarillo from '../../../assets/images/estados/if_Error_Symbol_yellow.png'
import icon_gris from '../../../assets/images/estados/if_Error_Symbol_gray.png'
import { toast } from 'react-toastify'
import { getValidaCarga } from '../../../helpers/solicitudes/getValidaCarga'

const ListadoContenedores = ({
    flag_mostrar,
    token_user,
    token_page,
    setMostrarcontenedor,
    url_get_contenedores,
    solicitud,
    url_eliminar_contenedor,
    ctipos,
    toggle3,
    setButtonEnable,
    // buttonEnable,
    url_valida_carga
}) => {
    const [state, setstate] = useState([])
    const [load, setLoad] = useState(false)
    const { solicitud_apex, sub_tipo_solicitud } = ctipos

    useEffect(() => {
        setLoad(true)
        GetContenedores(token_user, token_page, solicitud, url_get_contenedores).then(async resultado => {
            let total_contenedores = resultado.length

            if (total_contenedores === 0) {
                setButtonEnable(true)
            } else {
                if (sub_tipo_solicitud === '461' || sub_tipo_solicitud === '101') {
                    const { v_resultado } = await getValidaCarga(
                        token_user,
                        token_page,
                        url_valida_carga,
                        solicitud_apex
                    )
                        .then(res => res)
                        .catch(err => {
                            return {
                                v_estado: false,
                                v_mensaje: 'Error interno del servidor'+err,
                                v_resultado: false
                            }
                        })

                    if (v_resultado === 'true') {
                        setButtonEnable(false)
                    } else {
                        setButtonEnable(true)
                    }

                    // fin validanddo cargas..
                } else {
                    setButtonEnable(false)
                }
            }

            setstate(resultado)
            setMostrarcontenedor(false)
            setLoad(false)
        })
    }, [flag_mostrar, url_get_contenedores, token_user, token_page, solicitud, setMostrarcontenedor])

    const eliminar_contenedor = (solicitud_contenedor) => {
        setLoad(true)
        setMostrarcontenedor(true)
        postEliminarContainer(token_user, token_page, url_eliminar_contenedor, solicitud_contenedor)
            .then(resultado => {
                const { v_resultado, v_mensaje } = resultado
                if (v_resultado === 'true') {
                    toast.success('Contenedor eliminado correctamente!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                } else {
                    toast.warning(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
                setMostrarcontenedor(false)
            })
            .catch(error => {
                console.log(error)
                setMostrarcontenedor(false)
            })
        setLoad(false)
    }

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-12'>
                <div className='card'>
                    <div className='card-header b-b-warning  border-3'>
                        <h5> Listado de contenedores agregados</h5>
                    </div>
                    <div className='card-body'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Eliminar</th>
                                    <th>Contenedor</th>
                                    <th>Aduana SAT</th>
                                    <th>DIPAFRONT - MINGOB</th>
                                    <th>SEPA - OIRSA</th>
                                    <th>SGAIA - MINGOB</th>
                                    <th>SITC - OIRSA</th>
                                    {ctipos?.sub_tipo_solicitud === '461' && <th>Vin/BL</th>}
                                    {ctipos?.sub_tipo_solicitud === '101' && <th>Vin/BL</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {state.map(cont => {
                                    return (
                                        <tr key={cont.COD_SOLICITUD_CONTENEDOR}>
                                            <td>
                                                <Button
                                                    className='btn btn-primary'
                                                    onClick={() => {
                                                        eliminar_contenedor(cont.COD_SOLICITUD_CONTENEDOR, 'REPORTE')
                                                    }}
                                                >
                                                    <i className='fa fa-trash-o'></i>
                                                </Button>
                                            </td>
                                            <td>{cont.CONTENEDOR}</td>
                                            <td>
                                                {cont.ADUANA_SAT === 'error' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_error}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.ADUANA_SAT === 'amarillo' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_amarillo}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.ADUANA_SAT === 'gris' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_gris}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                {cont.DIPAFRONT_MINGOB === 'error' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_error}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.DIPAFRONT_MINGOB === 'amarillo' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_amarillo}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.DIPAFRONT_MINGOB === 'gris' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_gris}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                {cont.SEPA_OIRSA === 'error' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_error}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.SEPA_OIRSA === 'amarillo' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_amarillo}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.SEPA_OIRSA === 'gris' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_gris}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                {cont.SGAIA_MINGOB === 'error' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_error}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.SGAIA_MINGOB === 'amarillo' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_amarillo}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.SGAIA_MINGOB === 'gris' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_gris}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                {cont.SITC_OIRSA === 'error' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_error}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.SITC_OIRSA === 'amarillo' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_amarillo}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : cont.SITC_OIRSA === 'gris' ? (
                                                    <img
                                                        alt='img'
                                                        id='a'
                                                        src={icon_gris}
                                                        height='15'
                                                        width='15'
                                                        data-placement='top'
                                                    ></img>
                                                ) : (
                                                    ''
                                                )}
                                            </td>

                                            <td>
                                                {ctipos?.sub_tipo_solicitud === '461' && (
                                                    <Button
                                                        className='btn btn-primary'
                                                        onClick={() => {
                                                            toggle3(
                                                                '',
                                                                'Datos VL/VIN',
                                                                'INSERTA_CARGA',
                                                                cont.CONTENEDOR
                                                            )
                                                        }}
                                                    >
                                                        Agregar
                                                    </Button>
                                                )}

                                                {ctipos?.sub_tipo_solicitud === '101' && (
                                                    <Button
                                                        className='btn btn-primary'
                                                        onClick={() => {
                                                            toggle3(
                                                                '',
                                                                'Datos VL/VIN',
                                                                'INSERTA_CARGA',
                                                                cont.CONTENEDOR
                                                            )
                                                        }}
                                                    >
                                                        Agregar
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    {load && (
                        <div className='mb-4'>
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default ListadoContenedores
