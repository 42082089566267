import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loading from '../loading';

const ModalModule = ({ modal, toggle, codeBoletin }) => {
    const [message, setMessage] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true);

    const awaitData = async () => {
        setMessage('');
        setLoading(true);
        await axios
            .get(
                `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/boletines/documento?codigo=${codeBoletin}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(async res => {
                setResponseData(res.data);
            })
            .catch(error => setMessage(error.response.data.msg));
        setLoading(false);
    };

    useEffect(() => {
        awaitData();
        return () => {
            console.log(`Desmontando`);
        };
    }, [codeBoletin]);

    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='lg'>
                <ModalHeader toggle={toggle}>{'Visor de boletines'}</ModalHeader>
                <ModalBody>
                    {message}
                    {loading ? (
                        <Loading />
                    ) : (
                        <div>
                            <embed
                                src={
                                    responseData.length > 0 &&
                                    responseData[0].ARCHIVO + '#toolbar=0&navpanes=0&scrollbar=0'
                                }
                                height='600px'
                                width='100%'
                                name='embed_content'
                                type='application/pdf'
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default ModalModule;
