import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { multiStepContext } from './StepContext';
import DisplayData from './DisplayData';
import { Container, Row } from 'react-bootstrap';

export default function FirstStep() {
    const { setStep } = useContext(multiStepContext);
    return (
        <Container className='mt-5'>
            <Row className='justify-content-md-center'>
                <DisplayData />
            </Row>
            <Row className='justify-content-md-center' style={{ marginTop: '50px' }}>
                <Button
                    variant='contained'
                    onClick={() => setStep(2)}
                    style={{ backgroundColor: '#FF6203', color: '#FFFFFF', fontWeight: 'bold' }}
                >
                    Siguiente
                </Button>
            </Row>
        </Container>
    );
}
