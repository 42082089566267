import { postEnvioNaviera } from './postEnvioNaviera'

export const FetchEnvioNaviera = async ({
    token_user,
    token_page,
    url_envio_naviera,
    solicitud_apex,
    sub_tipo_solicitud,
    observacion,
    setRespuestaSolicitud,
    respuestaSolicitud,
    confirmaSolicitud
}) => {
    if (confirmaSolicitud) {
        const { v_mensaje, v_resultado, v_estado } = await postEnvioNaviera(
            token_user,
            token_page,
            url_envio_naviera,
            solicitud_apex,
            sub_tipo_solicitud,
            observacion
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_resultado: false
                }
            })

        console.log(
            '8888888888888888 ENVIO NAVIERA----------------------> ',
            v_mensaje,
            v_resultado,
            v_estado
        )
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            valida_documentos: 'Si tiene agregados los documentos',
            estado_documentos: true,
            confirma_solicitud: 'Solicitud confirmada',
            estado_solicitud: true,
            envia_correo_naviera: v_mensaje,
            estado_correo_naviera: v_resultado === 'true',
            mostrar_respuestas: true
        })
        const envioNaviera = v_estado === true && v_resultado === 'true'
        return { envioNaviera }
    } else {
        return { envioNaviera: false }
    }
}
