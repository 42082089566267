import React from 'react';
import cuadro_exp_verde from '../../../assets/images/estados/cuadro_exp_verde.png';
import cuadro_exp_verde_obs from '../../../assets/images/estados/cuadro_exp_verde_obs.jpg';
import cuadro_exp_rojo from '../../../assets/images/estados/cuadro_exp_rojo.png';
import cuadro_exp_gris from '../../../assets/images/estados/cuadro_exp_gris.png';
import cuadro_exp_amarillo from '../../../assets/images/estados/cuadro_exp_amarillo.png';

import camionVerde from '../../../assets/images/estados_transportista/camion_verde.png';
import camionRojo from '../../../assets/images/estados_transportista/camion_rojo.png';
import camionAmarillo from '../../../assets/images/estados_transportista/camion_amarillo.png';
import camionGris from '../../../assets/images/estados_transportista/camion_gris.png';

import bullet_red from '../../../assets/images/estados/bullet-red.png';
import bullet_green from '../../../assets/images/estados/bullet-green.png';
import bullet_yellow from '../../../assets/images/estados/bullet-yellow.png';
// import bullet_gray from '../../../assets/images/estados/bullet-gray.png';

import { UncontrolledTooltip } from 'reactstrap';

export function headerSiSat({ toggle3 }) {
    return [
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 200 },
        { field: 'BUQUE', headerName: 'Buque', width: 250 },
        { field: 'VIAJE', headerName: 'Viaje con APMTQ', width: 200 },
        { field: 'BOOKING', headerName: 'Booking', width: 180 },
        {
            field: 'SAT',
            headerName: 'SAT',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={cuadro_exp_verde}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>{params.row.SAT_TXT}</b>
                                <br />
                                <br />
                                {params.row.SAT_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'verde obscuro' && (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={cuadro_exp_verde_obs}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>{params.row.SAT_TXT}</b>
                                <br />
                                <br />
                                {params.row.SAT_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={cuadro_exp_rojo}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>{params.row.SAT_TXT}</b>
                                <br />
                                <br />
                                {params.row.SAT_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'gris' && (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={cuadro_exp_gris}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>{params.row.SAT_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'APMTQ',
            headerName: 'APMTQ',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                src={cuadro_exp_verde}
                                height='20'
                                width='20'
                                data-placement='top'
                                className='cursor'
                                onClick={() => toggle3('', 'Solicitud VGM', 'vgm', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>{params.row.APMTQ_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'amarillo' && (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                src={cuadro_exp_amarillo}
                                height='20'
                                width='20'
                                data-placement='top'
                                className='cursor'
                                onClick={() => toggle3('', 'Solicitud VGM', 'vgm', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>{params.row.APMTQ_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                src={cuadro_exp_rojo}
                                height='20'
                                width='20'
                                data-placement='top'
                                className='cursor'
                                onClick={() => toggle3('', 'Facturas Vencidas', 'facturas-vencidas', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>{params.row.APMTQ_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'gris' && (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                src={cuadro_exp_gris}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>{params.row.APMTQ_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'NAVIERA',
            headerName: 'NAVIERA',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                src={camionVerde}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.NAVIERA_TXT === 'Fecha ingreso a Terminal' ? (
                                    <>
                                        {' '}
                                        <b>{params.row.NAVIERA_TXT}</b>
                                        <br />
                                        <br />
                                        {params.row.NAVIERA_LABEL}
                                    </>
                                ) : (
                                    <>
                                        <b>{params.row.NAVIERA_TXT}</b>
                                        <br />
                                        <br />
                                        <b>Fecha Creación Booking:</b> {params.row.NAVIERA_LABEL}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'amarillo' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                src={camionAmarillo}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                <b>{params.row.NAVIERA_TXT}</b>
                                <br />
                                <br />
                                {params.row.NAVIERA_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                src={camionRojo}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                <b>{params.row.NAVIERA_TXT}</b>
                                <br />
                                <br />
                                {params.row.NAVIERA_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'gris' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                src={camionGris}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                <b>{params.row.NAVIERA_TXT}</b>
                                <br />
                                <br />
                                <b>Fecha Creación Booking:</b> {params.row.NAVIERA_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'TRANSPORTE',
            headerName: 'TRANSPORTE',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                src={camionVerde}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>{params.row.TRANSPORTE_TXT}</b>
                                <br />
                                <br />
                                {params.row.TRANSPORTE_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'amarillo' && (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                src={camionAmarillo}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>{params.row.TRANSPORTE_TXT}</b>
                                <br />
                                <br />
                                {params.row.TRANSPORTE_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                src={camionRojo}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>{params.row.TRANSPORTE_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'gris' && (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                src={camionGris}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>{params.row.TRANSPORTE_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'XRAY',
            headerName: 'INSPECCIÓN NO INTRUSIVA',
            width: 280,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'e' + params.row.id}
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
                                <b>{params.row.XRAY_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'e' + params.row.id}
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
                                <b>{params.row.XRAY_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}
                    {params.value === 'amarillo' && (
                        <>
                            <img
                                alt='img'
                                id={'e' + params.row.id}
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
                                <b>{params.row.XRAY_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'EMBARQUE',
            headerName: 'EMBARQUE',
            width: 170,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                src={cuadro_exp_verde}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                <b>Cargado a Buque: </b>
                                <br /> {params.row.EMBARQUE_TXT}
                                <br />
                                <br />
                                <b>Buque finalizó operación: </b>
                                {params.row.EMBARQUE_LABEL}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                src={cuadro_exp_rojo}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                <b>{params.row.EMBARQUE_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'gris' && (
                        <>
                            <img
                                alt='img'
                                id={'f' + params.row.id}
                                src={cuadro_exp_gris}
                                height='20'
                                width='20'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
                                <b>{params.row.EMBARQUE_TXT}</b>
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
    ];
}
