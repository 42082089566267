import React from 'react'
import { Truck } from 'react-feather'

const MainComponent = ({ useFetchInit }) => {
    // const { data, handleInputChange, limpiarFormularioDeComida, agregarSolicitudDeComida, loadingOperation } =
    //     useFetchInit;
    // const { cod_departamento, fecha, tipo_menu, cod_plato } = data;
    return (
        <>
            <div className='card col-sm-4 mt-2'>
                <div className='card-header'>
                    <h5>Previsión de escalas</h5>
                </div>
                <div className='card-body'>
                    <form className='theme-form row'>
                        <div className='form-group col-6 p-r-0'>
                            <label className='col-form-label p-t-0'>Fecha Desde:</label>
                            <input className='form-control' type='date' size='1' />
                        </div>
                        <div className='form-group col-6'>
                            <label className='col-form-label p-t-0'>Fecha Hasta:</label>
                            <input className='form-control' type='date' size='1' />
                        </div>
                        <div className='col-12'>
                            <button className='btn btn-warning btn-block' type='button'>
                                Cargar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default MainComponent
