// import React from 'react'

export const validaCampos = valor => {
    let retorno = false
    if (valor === '' || valor === undefined) {
        retorno = false
    } else {
        retorno = true
    }

    return retorno
}
