import React, { Fragment, useState } from 'react'
import { Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { nanoid } from 'nanoid'
import { useFetchTipoContacto } from '../../../../hooks/vinculaciones/useFetchTipoContacto'

export const ModalContactos = ({
    toggle,
    title,
    url_listado_vinculacion,
    vinculacion,
    perfil_contacto_cliente,
    perfil_contacto_proveedor,
    servicio_tipo_contacto,
    dcontacto,
    setDcontacto,
    infocontacto,
    setInfocontacto,
    token_page
}) => {
    const { perfil_seleccionado } = vinculacion
    const [error, setError] = useState(null)

    let mostrar_perfiles = ''
    if (perfil_seleccionado === '162') {
        mostrar_perfiles = perfil_contacto_proveedor
    } else {
        mostrar_perfiles = perfil_contacto_cliente
    }

    const [tipocontacto] = useFetchTipoContacto(
        url_listado_vinculacion,
        servicio_tipo_contacto,
        mostrar_perfiles,
        perfil_seleccionado,
        token_page
    )

    const handleInputChange = event => {
        let valor_contacto = ''
        if (event.target.name === 'contacto_tipo_contacto') {
            valor_contacto = event.target.options[event.target.selectedIndex].label
            setInfocontacto({
                ...infocontacto,
                [event.target.name]: event.target.value,
                contacto_nombre_tipo: valor_contacto
            })
        } else {
            setInfocontacto({
                ...infocontacto,
                [event.target.name]: event.target.value
            })
        }
    }

    const insertar_contacto = () => {
        const {
            contacto_nombre_completo,
            contacto_posicion,
            contacto_direccion,
            contacto_telefono,
            contacto_email,
            contacto_tipo_contacto
        } = infocontacto

        if (contacto_nombre_completo === '' || contacto_nombre_completo === undefined) {
            setError('Agregar nombre de contacto.')
            return
        }

        if (contacto_posicion === '' || contacto_posicion === undefined) {
            setError('Agregar posicíón.')
            return
        }

        if (contacto_direccion === '' || contacto_direccion === undefined) {
            setError('Agregar dirección.')
            return
        }

        if (contacto_telefono === '' || contacto_telefono === undefined) {
            setError('Agregar teléfono.')
            return
        }

        if (contacto_email === '' || contacto_email === undefined) {
            setError('Agregar correo electrónico.')
            return
        }

        if (contacto_tipo_contacto === '' || contacto_tipo_contacto === undefined) {
            setError('Agregar Tipo de contacto.')
            return
        }

        setDcontacto([
            ...dcontacto,
            {
                contacto_id: nanoid(),
                contacto_nombre_completo: infocontacto.contacto_nombre_completo,
                contacto_posicion: infocontacto.contacto_posicion,
                contacto_direccion: infocontacto.contacto_direccion,
                contacto_telefono: infocontacto.contacto_telefono,
                contacto_email: infocontacto.contacto_email,
                contacto_tipo_contacto: infocontacto.contacto_tipo_contacto,
                contacto_nombre_tipo: infocontacto.contacto_nombre_tipo
            }
        ])

        setInfocontacto({
            contacto_id: '',
            contacto_nombre_completo: '',
            contacto_posicion: '',
            contacto_direccion: '',
            contacto_telefono: '',
            contacto_email: '',
            contacto_tipo_contacto: '',
            contacto_nombre_tipo: ''
        })

        setError(null)
    }

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    {error ? <span className='text-danger'>{error}</span> : null}
                    <Input
                        type='text'
                        name='contacto_nombre_completo'
                        id='contacto_nombre_completo'
                        placeholder='Ingresar Nombre y apellido'
                        onChange={handleInputChange}
                        value={infocontacto.contacto_nombre_completo}
                    />

                    <Input
                        type='text'
                        className='form-control mb-2'
                        placeholder='Ingresar Posición'
                        name='contacto_posicion'
                        id='contacto_posicion'
                        onChange={handleInputChange}
                        value={infocontacto.contacto_posicion}
                    />

                    <Input
                        type='email'
                        className='form-control mb-2'
                        placeholder='Ingresar dirección'
                        name='contacto_direccion'
                        id='contacto_direccion'
                        onChange={handleInputChange}
                        value={infocontacto.contacto_direccion}
                    />

                    <Input
                        type='number'
                        className='form-control mb-2'
                        placeholder='Ingresar telefóno'
                        name='contacto_telefono'
                        id='contacto_telefono'
                        onChange={handleInputChange}
                        value={infocontacto.contacto_telefono}
                    />

                    <Input
                        type='text'
                        className='form-control mb-2'
                        placeholder='Ingresar email'
                        name='contacto_email'
                        id='contacto_email'
                        onChange={handleInputChange}
                        value={infocontacto.contacto_email}
                    />

                    <Input
                        type='select'
                        name='contacto_tipo_contacto'
                        id='contacto_tipo_contacto'
                        onChange={handleInputChange}
                        value={infocontacto.contacto_tipo_contacto}
                    >
                        <option value=''>-- Seleccione tipo contacto --</option>
                        {tipocontacto.map(listado_tipos => {
                            return (
                                <option key={listado_tipos.TIPO} value={listado_tipos.TIPO}>
                                    {listado_tipos.DESCRIPCION}
                                </option>
                            )
                        })}
                    </Input>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='btn btn-primary pull-right'
                    onClick={() => {
                        insertar_contacto()
                    }}
                >
                    Agregar contacto
                </Button>
                <Button color='gray' onClick={toggle} className='btn btn-primary float-left'>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}
