import React from 'react';
import bullet_red from '../../../assets/images/estados/bullet-red.png';
import bullet_green from '../../../assets/images/estados/bullet-green.png';
import bullet_yellow from '../../../assets/images/estados/bullet-yellow.png';
import bullet_gray from '../../../assets/images/estados/bullet-gray.png';
import binocular from '../../../assets/images/servicios-en-linea/icono_21.png';
import { UncontrolledTooltip } from 'reactstrap';

export function headerSiSat({ toggle3, toggleContainerStatus }) {
    console.log("no sat")
    let redir = link => {
        window.open(link);
    };
    return [
        { field: 'verEstados', headerName: 'Estados Contenedor', width: 200,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
            <strong style={{ minWidth: '-webkit-fill-available' }}>
                    <>
                            <img
                                alt='img'
                                id={'h' + params.row.id}
                                className='imgCursor'
                                src={binocular}
                                height='35'
                                width='35'
                                data-placement='top'
                                onClick={() => toggleContainerStatus('', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'h' + params.row.id}>
                                <b>Ver estados del Contenedor </b>
                            </UncontrolledTooltip>
                        </>
            </strong>
        ),
        },
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 250 },
        { field: 'BUQUE', headerName: 'Buque', width: 250 },
        { field: 'EAESCALA', headerName: 'Viaje con APMTQ', width: 200 },
        {
            field: 'NAVIERA',
            headerName: 'Naviera',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'rojo' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.NAVIERA_TXT} <br />
                                {params.row.FECHA_EXPIRACION_ORDEN != null && (
                                    <>
                                        <b>Fecha expiración: </b>
                                        {params.row.FECHA_EXPIRACION_ORDEN}
                                    </>
                                )}
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'SAT_CODIGO_ESTADO',
            headerName: 'SAT',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                {params.row.SAT_CODIGO_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'rojo' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='imgCursor'
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones SAT', 'sat', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                {params.row.SAT_CODIGO_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='imgCursor'
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones SAT', 'sat', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                {params.row.SAT_CODIGO_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        params.row.SAT_CODIGO_ESTADO_LINK != null ? (
                            <>
                                <img
                                    alt='img'
                                    id={'b' + params.row.id}
                                    className='imgCursor ml-1'
                                    src={bullet_gray}
                                    height='21'
                                    width='21'
                                    data-placement='top'
                                    onClick={() => redir(params.row.SAT_CODIGO_ESTADO_LINK)}
                                ></img>
                                <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                    {params.row.SAT_CODIGO_ESTADO_TXT}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <>
                                <img
                                    alt='img'
                                    id={'b' + params.row.id}
                                    className='imgCursor ml-1'
                                    src={bullet_gray}
                                    height='21'
                                    width='21'
                                    data-placement='top'
                                    onClick={() => toggle3('', 'Retenciones SAT', 'sat', params.row)}
                                ></img>
                                <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                    {params.row.SAT_CODIGO_ESTADO_TXT}
                                </UncontrolledTooltip>
                            </>
                        )
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'RET_ESTADO',
            headerName: 'AUTORIDADES',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor'
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones AUTORIDADES', 'autoridades', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.RET_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'rojo' ? (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor'
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones AUTORIDADES', 'autoridades', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.RET_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor'
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones AUTORIDADES', 'autoridades', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.RET_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor ml-1'
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones AUTORIDADES', 'autoridades', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.RET_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'RET_APMTQ',
            headerName: 'APMTQ',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor'
                                src={bullet_green}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}. <br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'rojo' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor'
                                src={bullet_red}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}. <br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor'
                                src={bullet_yellow}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}. <br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'d' + params.row.id}
                                className='imgCursor ml-1'
                                src={bullet_gray}
                                height='21'
                                width='21'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones APMTQ', 'apmtq', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'d' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.RET_APMTQ_TXT}.<br />
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        // {
        //     field: 'ATC_STATUS',
        //     headerName: 'ATC',
        //     width: 150,
        //     align: 'center',
        //     headerAlign: 'center',
        //     renderCell: params => (
        //         <strong style={{ minWidth: '-webkit-fill-available' }}>
        //             {params.value === 'verde' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'e' + params.row.id}
        //                         className='imgCursor'
        //                         src={bullet_green}
        //                         height='32'
        //                         width='32'
        //                         data-placement='top'
        //                         onClick={() => toggle3('', 'Información ATC', 'atc', params.row)}
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
        //                         {params.row.ATC_STATUS_DESCRIPTION}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : params.value === 'rojo' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'e' + params.row.id}
        //                         className='imgCursor'
        //                         src={bullet_red}
        //                         height='32'
        //                         width='32'
        //                         data-placement='top'
        //                         onClick={() => toggle3('', 'Información ATC', 'atc', params.row)}
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
        //                         {params.row.ATC_STATUS_DESCRIPTION}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : params.value === 'amarillo' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'e' + params.row.id}
        //                         className='imgCursor'
        //                         src={bullet_yellow}
        //                         height='32'
        //                         width='32'
        //                         data-placement='top'
        //                         onClick={() => toggle3('', 'Información ATC', 'atc', params.row)}
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
        //                         {params.row.ATC_STATUS_DESCRIPTION}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : params.value === 'gris' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'e' + params.row.id}
        //                         className='imgCursor ml-1'
        //                         src={bullet_gray}
        //                         height='21'
        //                         width='21'
        //                         data-placement='top'
        //                         onClick={() => toggle3('', 'Información ATC', 'informacionATC', params.row)}
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'e' + params.row.id}>
        //                         {params.row.ATC_STATUS_DESCRIPTION}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : (
        //                 <></>
        //             )}
        //         </strong>
        //     ),
        // },
        // {
        //     field: 'XRAY',
        //     headerName: 'INSPECCIÓN NO INTRUSIVA',
        //     width: 270,
        //     align: 'center',
        //     headerAlign: 'center',
        //     renderCell: params => (
        //         <strong style={{ minWidth: '-webkit-fill-available' }}>
        //             {params.value === 'verde' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'f' + params.row.id}
        //                         className='imgCursor'
        //                         src={bullet_green}
        //                         height='32'
        //                         width='32'
        //                         data-placement='top'
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
        //                         {params.row.XRAY_TXT}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : params.value === 'rojo' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'f' + params.row.id}
        //                         className='imgCursor'
        //                         src={bullet_red}
        //                         height='32'
        //                         width='32'
        //                         data-placement='top'
        //                         onClick={() => toggle3('', 'Información ATC', 'atc', params.row)}
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
        //                         <b>Estado: </b>
        //                         {params.row.XRAY_TXT}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : params.value === 'amarillo' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'f' + params.row.id}
        //                         className='imgCursor'
        //                         src={bullet_yellow}
        //                         height='32'
        //                         width='32'
        //                         data-placement='top'
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
        //                         {params.row.XRAY_TXT}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : params.value === 'gris' ? (
        //                 <>
        //                     <img
        //                         alt='img'
        //                         id={'f' + params.row.id}
        //                         className='imgCursor ml-1'
        //                         src={bullet_gray}
        //                         height='21'
        //                         width='21'
        //                         data-placement='top'
        //                         onClick={() => toggle3('', 'Solicitud Inspección No Intrusiva', 'rayosx', params.row)}
        //                     ></img>
        //                     <UncontrolledTooltip placement='top' target={'f' + params.row.id}>
        //                         {params.row.XRAY_TXT}
        //                     </UncontrolledTooltip>
        //                 </>
        //             ) : (
        //                 <></>
        //             )}
        //         </strong>
        //     ),
        // },
    ];
}
