import React from 'react'
import PasosVinculacionesCliente from './pasos/pasosVinculacionesClientes'
import { PasosVinculacionesProveedores } from './pasos/pasosVinculacionesProveedores'
import { PasosVinculacionesSat } from './pasos/pasosVinculacionesSat'

export const EvaluandoPerfil = ({
    useFetchInit,
    servicios,
    urlPeticiones  
}) => {
    const {
        vinculacion
    } = useFetchInit
    console.log('EVALUANDO_PERFIL ',vinculacion)
    const { perfil_seleccionado } = vinculacion

    switch (perfil_seleccionado) {
    case '162':
        return (
            <PasosVinculacionesProveedores
                useFetchInit={useFetchInit}
                servicios={servicios}
                urlPeticiones={urlPeticiones}
            />
        )
    case '201':
        return (
            <PasosVinculacionesSat
                useFetchInit={useFetchInit}
            />
        )
    default:
        return (
            <PasosVinculacionesCliente
                useFetchInit={useFetchInit}
                servicios={servicios}
                urlPeticiones={urlPeticiones}                    
            />
        )
    }
}
