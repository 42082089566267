/**
 * @module helpers/vinculaciones/getPerfiles
 * @description getPerfiles: función para hacer la peticion a la base de datos
 * para la carga de perfiles
 */
import axios from 'axios'

export const getPerfiles = async (url_listado_vinculacion, servicio_perfiles_usuario, token_page) => {
    console.log(`.. get perfiles ...`)
    const token_user = 'nada'
    // ----
    const urlRuta = `${url_listado_vinculacion}?servicio=${servicio_perfiles_usuario}`
    console.log('urlRuta', urlRuta)

    const retorno = await axios
        .get(urlRuta, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            console.log(data)
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
