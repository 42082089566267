import React, { Fragment, useState, useEffect } from 'react'
import GraficaGrupoBarras from '../graficas/GraficaGrupoBarras'
import FormularioDatos from '../dashboard-users/FormularioDatos'
import Cardsdata from '../dashboard-users/Cards'
// import IconLink from '../servicios-en-linea/cards/icon-link'
// import LogoTimeLine from '../../assets/images/timeLineImage.png'
import { Row, Col } from 'reactstrap'
import axios from 'axios'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const InputExcelDashbord = props => {
    const token_user = props.match.params.tokenaut
    //const token_user = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiR1RNLTU0NDI1MTYiLCJwc3dkIjoiU3NhbWJvcm8yMDE5LiIsImlhdCI6MTYzNDc0OTM0MCwiZXhwIjoxNjM0NzUyOTQwfQ.cahw0g9zYP7poDwiNfmSSDnnxpap2ELZeq2r-ZO7Vp4'
    const [ctipos, setToken] = useState({
        mensaje_carga: 'Ingresar un token válido, para poder operar...',
        carga: false,
        respuesta: false,
    })
    const [showDashboards, showDash] = useState(false)
    const [dashboards, dataFillingDash] = useState([])
    const [cards, dataFillingCards] = useState([])
    let ComplementoGrafica = []
    const callData = async function (nit, fecha1, fecha2) {
        callApi(nit, fecha1, fecha2)
        showDash(true)
    }
    const callApi = async function (nit, fecha1, fecha2) {
        const resp = await axios({
            method: 'GET',
            url:
                process.env.REACT_APP_DASHBOARDSCMP_API +
                `/v1/cmp/users/dashboard/${nit}?fechainicio=${fecha2}&fechafin=${fecha1}`,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                dataFillingDash(res.data.dashboard)
                dataFillingCards(res.data.cards)
            })
            .catch(err => {
                console.log('Error: ' + err)
            })
    }
    const callChilds = () => {
        for (var i = 0; i < dashboards.length; i++) {
            if (dashboards[i].nameDashboard == 'Tiempo de liberacion por actores involucrados') {
                ComplementoGrafica.push(<GraficaGrupoBarras key={i} datos={dashboards[i]} />)
            } else {
                console.log('No hay datos')
            }
        }
    }
    callChilds()
    useEffect(() => {
        if (token_user === undefined) {
            setToken({
                ...ctipos,
                carga: false,
                respuesta: false,
                mensaje_carga: 'Token No valido.',
            })
            return
        } else {
            setToken({
                ...ctipos,
                carga: true,
                respuesta: true,
                mensaje_carga: 'Token correcto.',
            })
        }
    }, [])
    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            {ctipos.carga ? (
                <div>
                    <div className='mt-3'>
                        <FormularioDatos callData={callData} token_user={token_user} />
                    </div>
                    {ComplementoGrafica ? (
                        <div>
                            <Row xs='1' sm='6' md='6' lg='6' className='cssentreComponentes'>
                                <Col xs='12' sm='12' md='12' lg='8' id='padingTemp'>
                                    <Cardsdata cardsData={cards} />
                                </Col>
                            </Row>
                            <Row xs='12' sm='12' md='12' lg='12' className='lastBar'>
                                {ComplementoGrafica}
                            </Row>
                            {/*<Row className="justify-content-center">
                            <IconLink
                                key={1}
                                id={15}
                                img={LogoTimeLine}
                                link={"https://www.services.apmterminalsquetzal.com/ords/f?p=103:113::::101:P101_LINK:113"}
                                tittle={"Time Line"}
                                tooltipTittle={"view container timeline"}
                                tooltipDescription={"view container timeline"}
                                text='apm'
                            />
                    </Row>*/}
                        </div>
                    ) : (
                        <div>
                            <h1>token no valido</h1>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <h1>token no valido</h1>
                </div>
            )}
        </Fragment>
    )
}
export default InputExcelDashbord
