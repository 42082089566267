import React, { useContext, useState, useEffect, Fragment } from 'react'
import FirstStep from './steps/FirstStep'
import SecondStep from './steps/SecondStep'
import ThirdStep from './steps/ThirdStep'
import { Stepper, StepLabel, Step } from '@material-ui/core'
import { multiStepContext } from './steps/StepContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale'
import loadVisanetScript from './scripts/visanet.js'
import { Container, Row, Col } from 'react-bootstrap'
import StepConnector from '@material-ui/core/StepConnector'

const Billing = props => {
    const { currentStep, invoiceDetail, setInvoiceDetail, userData, setUserData } = useContext(multiStepContext)
    const [loadedVisanet, setLoadedVisanet] = useState(false)
    const [load, setLoad] = useState(true)
    const [post, setPosts] = useState([])

    const theme = createMuiTheme(
        {
            palette: {
                primary: { main: '#FF6203' },
            },
        },
        esES
    )

    const QontoConnector = withStyles({
        alternativeLabel: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        active: {
            '& $line': {
                borderColor: '#FF6203',
            },
        },
        completed: {
            '& $line': {
                borderColor: '#FF6203',
            },
        },
        line: {
            borderColor: '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    })(StepConnector)

    const awaitData = async () => {
        setLoadedVisanet(false)
        Swal.fire({
            title: 'Espere un Momento...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading()
            },
        }).then(
            () => {},
            dismiss => {}
        )

        const resp = await axios({
            method: 'POST',
            url: process.env.REACT_APP_VISANET_API + '/service/invoice',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                token: props.idInvoicing,
            },
        })
            .then(res => {
                if (res.data.result === 'SUCCESS') {
                    let merchantId = res.data.description.merchantId
                    let orgId = res.data.description.organizationId
                    let sessionId = res.data.description.idPago
                    loadVisanetScript(merchantId, orgId, sessionId, () => {
                        setUserData({
                            ...userData,
                            idInvoice: res.data.description.idPago,
                            reference: res.data.description.reference,
                            total: res.data.description.total,
                            idSession: res.data.description.idPago,
                            monto: res.data.description.monto,
                            merchantId: merchantId,
                            organizationId: orgId,
                            recaptchaKey: res.data.description.recaptchaKey,
                        })
                        //console.log('RespuestaApi: '+JSON.stringify(res.data));
                        setLoadedVisanet(true)
                        setPosts(res.data)
                        setInvoiceDetail(invoiceDetail => [...invoiceDetail, res.data])
                        setLoad(false)
                        Swal.close()
                    })
                } else {
                    Swal.close()
                    Swal.fire({
                        icon: 'error',
                        title: 'Ha ocurrido un Error..',
                        text: `(${res.data.description.errorCode}) ${res.data.description.detail}`,
                        confirmButtonColor: '#FF6203',
                    })
                    setLoadedVisanet(false)
                }
            })
            .catch(err => {
                //console.log('Error: '+err);
                Swal.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un Error..',
                    text: 'Recarga la página nuevamente',
                    confirmButtonColor: '#FF6203',
                })
                setLoadedVisanet(false)
            })
    }

    function showStep(step) {
        switch (step) {
            case 1:
                return <FirstStep />
            case 2:
                return <SecondStep />
            case 3:
                return <ThirdStep />
        }
    }

    useEffect(() => {
        awaitData()
        //console.log('API VISANET --> ok');
    }, [])

    if (load == true) {
        return ''
    }

    return (
        <Fragment>
            {loadedVisanet && (
                <Container>
                    <Row className='justify-content-md-center'>
                        <iframe
                            style={{
                                width: '100px',
                                height: '100px',
                                border: '0',
                                position: 'absolute',
                                top: '-5000px',
                            }}
                            src={
                                'https://h.online-metrix.net/fp/tags?org_id=' +
                                userData['organizationId'] +
                                '&session_id=' +
                                userData['merchantId'] +
                                userData['idSession']
                            }
                        ></iframe>
                        <ThemeProvider theme={theme}>
                            <Col>
                                <Stepper
                                    activeStep={currentStep - 1}
                                    orientation='horizontal'
                                    style={{ backgroundColor: 'transparent' }}
                                    connector={<QontoConnector />}
                                >
                                    <Step alternativeLabel>
                                        <StepLabel>Revisa el Detalle de tu Factura</StepLabel>
                                    </Step>
                                    <Step alternativeLabel>
                                        <StepLabel>Ingresa tus datos para el Pago</StepLabel>
                                    </Step>
                                    <Step alternativeLabel>
                                        <StepLabel>Confirma la Transacción</StepLabel>
                                    </Step>
                                </Stepper>
                            </Col>
                            {showStep(currentStep)}
                        </ThemeProvider>
                    </Row>
                </Container>
            )}
        </Fragment>
    )
}
export default Billing
