import React from 'react';
import { Fragment } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styled from '@emotion/styled';

const Tittle = styled.h5`
    padding: 24px 32px;
    background-color: #ff6219;
    font-size: 24px;
    border-radius: 4px;
    color: #ffffff;
`;

const Breadcrumb = props => {
    return (
        <Fragment>
            <Container fluid className='mt-3'>
                <Row>
                    <Col sm={12}>
                        <Tittle>{props.title}</Tittle>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Breadcrumb;
