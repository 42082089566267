import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { StepsVinculaciones } from './stepsVinculaciones'
import { postConfirmarVinculacionCliente } from '../../../helpers/vinculaciones/postConfirmarVinculacionCliente'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}))

function getSteps(vinculacion) {
    const { nombre_perfil_seleccionado, nit_cliente_vinculacion } = vinculacion
    let titulo_step_one = ''
    titulo_step_one = `${nombre_perfil_seleccionado} - ${nit_cliente_vinculacion} `
    return [titulo_step_one, 'Ingreso de información', 'Confirmando Vinculación']
}

export default function StepsTabsVinculacion({
    useFetchInit,
    urlPeticiones,
    servicios
}) {

    const {
        vinculacion,
        setVinculacion,
        btndisableuv,
        setBtndisableuv
    } = useFetchInit

    const classes = useStyles()
    const { perfil_seleccionado, nit_cliente_vinculacion } = vinculacion
    const [activeStep, setActiveStep] = useState(0)

    // const [estadovinculacion, setEstadovinculacion] = useState(false)
    const [mensajevinculacion, setMensajevinculacion] = useState({
        v_mensaje: '',
        v_nuevo: false,
        v_resultado: false,
        v_vinculacion: false
    })

    const steps = getSteps(vinculacion)

    const handleNext = () => {
        if (activeStep === 0 && perfil_seleccionado !== '' && nit_cliente_vinculacion !== '') {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
            setBtndisableuv(true)
        }

        if (activeStep === 1 && perfil_seleccionado !== '' && nit_cliente_vinculacion !== '') {
            setActiveStep(prevActiveStep => prevActiveStep + 1)

            // setEstadovinculacion(false)
            setMensajevinculacion({
                v_nuevo: false,
                v_mensaje: '',
                v_resultado: false,
                v_vinculacion: false
            })
        }

        if (activeStep === 2 && perfil_seleccionado !== '' && nit_cliente_vinculacion !== '') {
            // setEstadovinculacion(false)
            setMensajevinculacion({
                v_nuevo: false,
                v_mensaje: '',
                v_resultado: false,
                v_vinculacion: false
            })
            // inicia post --------------------------------------
            postConfirmarVinculacionCliente(
                useFetchInit,
                urlPeticiones
            )
                .then(resultado => {
                    const { v_mensaje, v_nuevo, v_resultado, v_vinculacion } = resultado

                    setMensajevinculacion({
                        v_nuevo: v_nuevo,
                        v_mensaje: v_mensaje,
                        v_resultado: v_resultado,
                        v_vinculacion: v_vinculacion
                    })
                    // setEstadovinculacion(true)

                    if (v_resultado) {
                        setActiveStep(0)
                        toast.success(v_mensaje, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })

                        setVinculacion({
                            nit_cliente_vinculacion: '',
                            perfil_seleccionado: ''
                        })
                    }
                })
                .catch(error => {
                    console.log(`Existe un error  `, error)
                })

            // finaliza post --------------------------------------
        }
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>Todos los pasos fueron completados</Typography>
                        <Button onClick={handleReset}>Comenzar de nuevo</Button>
                    </div>
                ) : (
                    <div>
                        <Typography className={classes.instructions}>
                            {StepsVinculaciones({
                                useFetchInit,
                                activeStep,
                                mensajevinculacion,
                                servicios,
                                urlPeticiones
                            })}
                        </Typography>
                        <div>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                                Anterior
                            </Button>
                            <Button variant='contained' color='primary' disabled={btndisableuv} onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Confirmar vinculación' : 'Siguiente'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
