import React, {useContext, useState} from 'react';
import {TableContainer, TableHead, TableBody, TableRow, Table, TableCell} from '@material-ui/core';
import {multiStepContext} from '../steps/StepContext';
import {Col, Container} from 'react-bootstrap';


export default function DisplayConfirmation(){
    const {invoiceDetail, userData} = useContext(multiStepContext);
    const data=invoiceDetail[0].description;
    let cardId=userData.accountNumber;
    let lastNumbers;

    try{
        lastNumbers='************'+cardId.substr(cardId.length - 4);
    } catch(ex){
        lastNumbers='';
    }
    
    return(
        <Container>
            <Col md={{ span: 6, offset: 3 }}>
            <TableContainer >
                <Table border="1" size="small">
                    <TableHead>  
                        <TableRow style={{backgroundColor: '#004165', color: '#FFFFFF', fontWeight: 'bold'}}>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>Campo</TableCell>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>Detalle</TableCell>
                        </TableRow>
                    </TableHead>     
                    <TableBody>
                            <TableRow key={'1'}>
                                <TableCell>{'Nombre'}</TableCell>
                                <TableCell>{userData.firstName}</TableCell>
                            </TableRow>
                            <TableRow key={'2'}>
                                <TableCell>{'Apellido'}</TableCell>
                                <TableCell>{userData.lastName}</TableCell>
                            </TableRow>
                            <TableRow key={'3'}>
                                <TableCell>{'Dirección'}</TableCell>
                                <TableCell>{userData.adress}</TableCell>
                            </TableRow>
                            <TableRow key={'4'}>
                                <TableCell>{'Email'}</TableCell>
                                <TableCell>{userData.email}</TableCell>
                            </TableRow>
                            <TableRow key={'5'}>
                                <TableCell>{'Tarjeta'}</TableCell>
                                <TableCell>{lastNumbers}</TableCell>
                            </TableRow>

                            <TableRow key={'7'}>
                                <TableCell>{'Id Factura'}</TableCell>
                                <TableCell>{data.idPago}</TableCell>
                            </TableRow>
                            <TableRow key={'8'}>
                                <TableCell>{'Referencia'}</TableCell>
                                <TableCell>{data.reference}</TableCell>
                            </TableRow>
                            <TableRow key={'9'}>
                                <TableCell>{'Total'}</TableCell>
                                <TableCell>{data.total}</TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Col>
        </Container>
    )
}