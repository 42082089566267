import { useState, useEffect } from 'react'
import { getTerminos } from '../../helpers/vinculaciones/getTerminos'

export const useFetchTerminos = (url_listado_vinculacion, servicio_terminos, token_page) => {
    const [terminos, setTerminos] = useState([]) // tipos

    useEffect(() => {
        getTerminos(url_listado_vinculacion, servicio_terminos, token_page)
            .then(resultado => {
                setTerminos(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [url_listado_vinculacion, servicio_terminos, token_page])

    return [terminos]
}
