import React, {useState } from 'react';
import Billing from '../Billing';
import Swal from 'sweetalert2';
import axios from 'axios';
import InvoiceToPrint from '../invoiceToPrint';


export const multiStepContext = React.createContext();

const StepContext=(props) =>{
    const [currentStep, setStep] = useState(1);
    const [userData, setUserData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [invoiceDetail, setInvoiceDetail] = useState([]);
    const [finalState, setFinalState] = useState(false);
    const [transData,setTransData] = useState([]);


    async function submitData(){
        Swal.fire({
            title: 'Estás seguro de Realizar el Pago?',
            text: "Este paso NO es Reversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FF6203',
            cancelButtonColor: '#004165',
            confirmButtonText: 'Si',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
               Swal.fire({
                    title: 'Espere un Momento...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    onOpen: () => {
                      Swal.showLoading();
                    }
                }).then(() => {},(dismiss) => {}
                );

                const resp = await axios(
                    {
                      method: 'POST',
                      url: process.env.REACT_APP_VISANET_API+'/service/payment',
                      headers:{
                        'Content-Type': 'application/json'
                      },
                      data: {
                            "invoice":{
                                "id": userData.idInvoice,
                                "reference": userData.reference,
                                "total": userData.monto
                            },
                            "card":{
                                "accountNumber": userData.accountNumber,
                                "expirationMonth": userData.expirationMonth,
                                "expirationYear": userData.expirationYear,
                                "cvNumber": userData.cvNumber
                            },
                            "client":{
                                "firstName": userData.firstName,
                                "lastName": userData.lastName,
                                "adress": userData.adress,
                                "email": userData.email
                            },
                            "device":{
                                "id": userData.idSession
                            }
                      },
                    }              
                  ).then(res =>{
                    Swal.close();
                    //console.log('RespuestaApi: '+res.data);
                    
                    if(res.data.result==="SUCCESS"){
                        setTransData(res.data.description);
                        Swal.fire ({
                            icon: 'success',
                            title: 'Transacción Exitosa..',
                            text: 'Presione para continuar!',
                            confirmButtonColor: '#FF6203'
                        });
                        setUserData('');
                        setStep(1);
                        setFinalState(true);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Ha ocurrido un Error..',
                            text: `(${res.data.description.errorCode}) ${res.data.description.detail}`,
                            confirmButtonColor: '#FF6203'
                        }) 
                        setStep(1);
                        setFinalState(false);
                    }
                  })
                  .catch(err=>{
                    //console.log('Error: '+err);
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Ha ocurrido un Error..',
                        text: 'Recarga la página nuevamente',
                        confirmButtonColor: '#FF6203'
                    }) 
                    setStep(1);
                    setFinalState(false);
                  });
            }
        });

       // setFinalData(finalData=>[...finalData, userData]);

    }
/*
<div>
                        <InvoiceToPrint ref={componentRef} />
                        <button onClick={handlePrint}>Print this out!</button>
                    </div>
*/
    return (
            <multiStepContext.Provider value = {{currentStep, setStep, userData, setUserData, finalData, setFinalData, submitData, invoiceDetail,setInvoiceDetail, finalState, setFinalState}}>
                { finalState ? 
                    <div>
                        <br/>
                        <InvoiceToPrint trasData={transData}/>
                    </div>
                : <Billing idInvoicing={props.idInvoicing}/>
                }
            </multiStepContext.Provider>            
    )
}

export default StepContext;