import React, { Fragment } from 'react';
import styled from '@emotion/styled';

const CajaContenedora = styled.div`
    padding: 30px 32px 24px 32px;
    margin-top: 100px;
    margin-bottom: 30px;
    background-color: #ff6219;
    font-size: 24px;
    border-radius: 4px;
    color: #ffffff;
`;

const Titulo = styled.h5`
    background-color: #ff6219;
    font-size: 24px;
    border-radius: 4px;
    color: #ffffff;
`;

const SubTitulo = styled.h4`
    background-color: #ff6219;
    padding-top: 5px;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
`;

export const TituloRecuperacion = () => {
    return (
        <Fragment>
            <CajaContenedora>
                <Titulo>Recuperación de Contraseña</Titulo>
                <SubTitulo>Ingrese la información requerida para recuperar su contraseña</SubTitulo>
            </CajaContenedora>
        </Fragment>
    );
};
