import { Editors } from "react-data-grid-addons";
import axios from 'axios';

const catalog = async (_transporte, token_user, estadoBooking, editable)=>{
    let response=[]
    let params = _transporte ? `?codigoTransporte=${_transporte}` :'';
    let pilotos=[];
    let camiones=[];

    try{
        console.log(`Consultado Catalogo Transporte: ${params}`)
        const { DropDownEditor } = Editors;

        const resp = await axios(
           {
               method: 'POST',
               url: process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/trans/catalog'+params,
               headers: {
                    'authorization': `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    'x-auth-token': token_user
               }
           } 
        ).then(res=>{

            if(res.data.data.length >0){

                pilotos=res.data.data.filter(row => row.TIPO === 'PILOTO').map(row=>{
                    return {id:row.CODIGO, value:row.NOMBRE}
                })
                camiones=res.data.data.filter(row => row.TIPO === 'TRANS').map(row=>{
                    return {id:row.CODIGO, value:row.MATRICULA}
                })


                const pilotosEditor = <DropDownEditor options={pilotos} />;
                const camionesEditor = <DropDownEditor options={camiones} />;

                let columns;
                
                if(estadoBooking==='PENDI'){
                    columns = 
                    [
                        { key: "placa", name: "Placa", editable: true, editor: camionesEditor },
                        { key: "piloto", name: "Piloto", editable: true, editor: pilotosEditor }
                    ];
                }else{

                    if(editable==='TRUE'){
                        columns = 
                        [
                            { key: "placa", name: "Placa", editable: true, editor: camionesEditor },
                            { key: "piloto", name: "Piloto", editable: true, editor: pilotosEditor  },
                            { key: "pin", name: "Pin", editable: false  },
                            { key: "iso", name: "Iso", editable: false },
                        ];
                    }else{
                        columns = 
                        [
                            { key: "placa", name: "Placa", editable: false  },
                            { key: "piloto", name: "Piloto", editable: false },
                            { key: "pin", name: "Pin", editable: false  },
                            { key: "iso", name: "Iso", editable: false },
                        ];
                    }
                }
                response = columns;
            }
        })
        .catch(err=>{
            console.log(`Error Catalogo Axios: ${err}`);
        })

        return response;
    }catch(e){
        console.log(`Error Catalog: ${e}`)
        return response
    }
}

export default catalog;