import React, { Fragment, useEffect, useState } from 'react'
import Breadcrumb from '../common/breadcrumb'
import JobFilter from './filters'
import axios from 'axios'
import styled from '@emotion/styled'
import Target from './target'
import Modal from './modal-components/modal'
import Loading from './loading'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const CardView = props => {
    const [data, setData] = useState([])
    const [categoria, setCategoria] = useState([])
    const [subcategoria, setSubcategoria] = useState([])
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState()
    const [codeBoletin, setCodeBoletin] = useState('')
    const [filtros, setFiltros] = useState({
        busqueda: '',
        categoria: '',
        subcategoria: '',
    })

    const awaitData = async () => {
        setLoading(true)
        const resp = await axios
            .get(
                `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/boletines/consulta-boletines?grupo=${filtros.subcategoria}&busqueda=${filtros.busqueda}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => res)
        setData(resp.data)
        setLoading(false)
    }

    const awaitDataCategoria = async () => {
        const resp = await axios
            .get(`${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/boletines/clasificacion`, {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                },
            })
            .then(res => res)
        setCategoria(resp.data)
    }

    const awaitDataSubcategoria = async () => {
        const resp = await axios
            .get(
                `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/boletines/grupo?clasificacion=${filtros.categoria}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => res)
        setSubcategoria(resp.data)
    }

    const toggle3 = (_, _codeBoletin) => {
        setModal(!modal)
        !modal && setCodeBoletin(_codeBoletin)
    }

    useEffect(() => {
        awaitDataCategoria()
        awaitData()
        return () => {
            console.log(`Limpieza`)
        }
    }, [filtros.busqueda, filtros.subcategoria])

    useEffect(() => {
        awaitDataSubcategoria()
        return () => {
            console.log(`Limpieza`)
        }
    }, [filtros.categoria])

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Boletines APM Terminals Quetzal' parent='Servicios' />
            </Titulo>
            <Modal toggle={toggle3} modal={modal} codeBoletin={codeBoletin} />
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <JobFilter
                        categoria={categoria}
                        subcategoria={subcategoria}
                        setFiltros={setFiltros}
                        filtros={filtros}
                        setData={setData}
                        setSubcategoria={setSubcategoria}
                    />
                    <div className='col-sm-12 col-md-8 col-xl-9'>
                        <div className='row'>
                            {loading && (
                                <div className='col-sm-12 mt-5 mb-5'>
                                    <Loading />
                                </div>
                            )}
                            {data.map((item, index) => {
                                return <Target item={item} key={index} toggle3={toggle3} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CardView
