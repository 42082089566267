import React, { Fragment } from 'react';

const Loading = ({ color }) => {
    return (
        <Fragment>
            <div className='container-fluid bd-example-row'>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <div className='loader'>
                            <div className={`line bg-${color ? color : 'warning'}`}></div>
                            <div className={`line bg-${color ? color : 'warning'}`}></div>
                            <div className={`line bg-${color ? color : 'warning'}`}></div>
                            <div className={`line bg-${color ? color : 'warning'}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Loading;
