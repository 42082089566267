import React, { Fragment, useState } from 'react'
import { Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { nanoid } from 'nanoid'

export const ModalSocios = ({ toggle, title, infosocios, setInfosocios, dsocios, setDsocios }) => {
    const [error, setError] = useState(null)

    const handleInputChange = event => {
        setInfosocios({
            ...infosocios,
            [event.target.name]: event.target.value
        })
    }

    const insertar_socios = () => {
        const { socios_nombre_socio, socios_participacion } = infosocios

        if (socios_nombre_socio === '' || socios_nombre_socio === undefined) {
            setError('Agregar nombre de socio.')
            return
        }

        if (socios_participacion === '' || socios_participacion === undefined) {
            setError('Agregar participación del socio.')
            return
        }

        setDsocios([
            ...dsocios,
            {
                socios_id: nanoid(),
                socios_nombre_socio: infosocios.socios_nombre_socio,
                socios_participacion: infosocios.socios_participacion
            }
        ])

        setInfosocios({
            socios_id: '',
            socios_nombre_socio: '',
            socios_participacion: ''
        })

        setError(null)
    }

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    {error ? <span className='text-danger'>{error}</span> : null}
                    <Input
                        type='text'
                        name='socios_nombre_socio'
                        id='socios_nombre_socio'
                        placeholder='Nombre socio'
                        onChange={handleInputChange}
                        value={infosocios.socios_nombre_socio}
                    />

                    <Input
                        type='text'
                        className='form-control mb-2'
                        placeholder='Participación'
                        name='socios_participacion'
                        id='socios_participacion'
                        onChange={handleInputChange}
                        value={infosocios.socios_participacion}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='btn btn-primary pull-right'
                    onClick={() => {
                        insertar_socios()
                    }}
                >
                    Agregar cuentas
                </Button>
                <Button color='gray' onClick={toggle} className='btn btn-primary float-left'>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}
