import React, { Fragment } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import AdjuntarDocumento from './adjuntar-dodumento';
import EditarDocumento from './editar-documento';

const ModalModule = ({
    toggle,
    modal,
    dataRequest,
    title,
    componentModal,
    documentacion,
    url_confirmacion,
    servicio_subida_documento,
    token_page,
    servicio_eliminar_documento,
    setLoad,
    load,
    setMostrardocuv,
    docclientes,
    url_save_files,
    setShowtipodocsuv,
}) => {
    return (
        <Fragment>
            <Modal isOpen={modal} className='modal-body' centered={true} size='lg'>
                <ModalHeader>{title}</ModalHeader>
                {componentModal === 'adjuntar-documento' && (
                    <AdjuntarDocumento
                        toggle={toggle}
                        docclientes={docclientes}
                        documentacion={documentacion}
                        url_confirmacion={url_confirmacion}
                        servicio_subida_documento={servicio_subida_documento}
                        token_page={token_page}
                        url_save_files={url_save_files}
                        setShowtipodocsuv={setShowtipodocsuv}
                        setMostrardocuv={setMostrardocuv}
                    />
                )}
                {componentModal === 'editar-documento' && (
                    <EditarDocumento
                        toggle={toggle}
                        dataRequest={dataRequest}
                        servicio_eliminar_documento={servicio_eliminar_documento}
                        token_page={token_page}
                        url_confirmacion={url_confirmacion}
                        setMostrardocuv={setMostrardocuv}
                        setShowtipodocsuv={setShowtipodocsuv}
                    />
                )}
            </Modal>
        </Fragment>
    );
};

export default ModalModule;
