import { useState, useEffect } from 'react'
import { GetSolicitudes } from '../../helpers/solicitudes/GetSolicitudes'

export const useFetchSolicitud = (token_user, token_page, url_solicitud) => {
    const [ctipos, setCtipos] = useState({
        tipo_solicitud: '',
        sub_tipo_solicitud: '',
        observacion: '',
        en_nombre_de: '',
        facturar_a: '',
        usuario_apex: '',
        solicitud_apex: '',
        cod_gestor: '',
        nombre_gestor: '',
        mensaje_carga: 'Ingresar un token válido, para poder operar...',
        carga: false,
        respuesta: false
    })

    useEffect(() => {
        if (token_user === undefined) {
            /*  
                Esto si no viene la variable para el token
                console.log(`can not read token`) 
            */
            return
        }

        GetSolicitudes(token_user, token_page, url_solicitud).then(sol_user => {
            // console.log(sol_user)
            // console.log(typeof sol_user.v_estado)

            if (sol_user.v_estado) {
                setCtipos({
                    ...ctipos,
                    usuario_apex: sol_user.v_usuario,
                    solicitud_apex: sol_user.v_solicitud,
                    carga: true,
                    respuesta: true,
                    mensaje_carga: 'Token correcto.'
                })
            } else {
                console.log(`Renovar token o no tiene autorización`)
                setCtipos({
                    ...ctipos,
                    carga: false,
                    respuesta: false,
                    mensaje_carga: 'Renovar token para tener autorización.'
                })
            }

            console.log(`fin de la invocacion del del helper.`)
        })

        return () => {
            console.log(`Esta es la limpieza 01`)
        }

        //invocar nuevo token.
    }, [token_user, url_solicitud, token_page])

    return [ctipos, setCtipos] // {data: [], loading: true}
}
