import React, {useContext, useRef, useState} from 'react'
import {Button} from '@material-ui/core';
import {multiStepContext} from './StepContext';
import DisplayConfirmation from '../steps/DisplayConfirmation';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import {Row, Container } from 'react-bootstrap';

export default function ThirdStep() {
    const {setStep, userData, submitData} = useContext(multiStepContext);
    const [validCaptcha, setValidCaptcha] = useState(null);
    const [validUser, setValidUser] = useState(false);
    const captcha = useRef(null);
    //console.log('Key: '+userData.recaptchaKey);

    const onChangeCaptcha = () =>{
        if (captcha.current.getValue()){
            //console.log('El usuario no es un robot.');
            setValidCaptcha(true);
        }else{
            //console.log('El usuario es un robot');
            setValidCaptcha(false);
        }
    }

    const sendData = ()=>{
        if(captcha.current.getValue()){
            //console.log('El usuario no es un robot.');
            setValidUser(true);
            setValidCaptcha(true);
            submitData();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Por Favor..',
                text: 'Confirma que no eres un Robot',
                confirmButtonColor: '#FF6203'
            })          
            setValidUser(false);
            setValidCaptcha(false);
        }
    }  

    return (
        <Container className="mt-5">
            <DisplayConfirmation/>

            <div  style={{display: 'flex', justifyContent: 'center', marginTop:'10px'}}>
            <ReCAPTCHA 
                    ref = {captcha}
                    sitekey={userData.recaptchaKey}
                    onChange={onChangeCaptcha}
                />
            </div>
            <Row className="justify-content-md-center" style={{marginTop:'20px'}}>
                <Button className="mr-5" variant="contained" onClick={()=>setStep(2)} style={{backgroundColor: '#004165', color: '#FFFFFF', fontWeight: 'bold'}}>Atrás</Button> <span></span>
                <Button variant="contained" onClick={sendData} style={{backgroundColor: '#FF6203', color: '#FFFFFF', fontWeight: 'bold'}}>Confirmar</Button>
            </Row>
        </Container>
    )
}
