import { useState, useEffect } from 'react'
import { getValidacionNit } from '../../helpers/vinculaciones/getValidacionNit'

export const useFetchValidacionNit = (
    nit_cliente_vinculacion,
    perfil_seleccionado,
    servicios,
    urlPeticiones
) => {
    const [validacionnit, setValidacionnit] = useState([]) // tipos
    const [cargaperfil, setCargaperfil] = useState(true)

    useEffect(() => {
        getValidacionNit(nit_cliente_vinculacion, perfil_seleccionado, servicios, urlPeticiones)
            .then(resultado => {
                setValidacionnit(resultado)
                setCargaperfil(false)
            })
            .catch(error => {
                console.log(error)
                setCargaperfil(true)
            })

        return () => {
            console.log(`Limpieza en tipos - validación nit`)
        }
    }, [nit_cliente_vinculacion, perfil_seleccionado, servicios, urlPeticiones])

    return [validacionnit, cargaperfil]
}
