import React, { Fragment } from 'react';
import Vertical from '../../../assets/images/iap/vertical-line.png';
import Inventory from '../../../assets/images/iap/contenedores.png';
import CamionContenedor from '../../../assets/images/iap/camion-contenedor.png';
import ContenedorVacio from '../../../assets/images/iap/contenedor-abierto.png';
import Abandono from '../../../assets/images/iap/forgot.png';

const FirstStep = props => {
    const { contenedoresAbandono, contenedoresTransbordo, contenedoresVacios, inventario } = !!props && props;

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Total inventario</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Inventory} alt='Smiley face' height='62' width='62'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{inventario || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Total contenedores Transbordo</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={CamionContenedor} alt='Smiley face' height='62' width='62'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedoresTransbordo || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Total contenedores vacíos</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={ContenedorVacio} alt='Smiley face' height='32' width='62'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedoresVacios || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Total contenedores en abandono</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Abandono} alt='Smiley face' height='52' width='52'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedoresAbandono || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FirstStep;
