import React from 'react'
import Breadcrumb from '../../common/breadcrumb'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import { Tag, Box, MessageSquare, Users, Truck } from 'react-feather'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const MainComponent = () => {
    const history = useHistory()
    return (
        <>
            <Titulo>
                <Breadcrumb title='Menú TAS' parent='Servicios' />
            </Titulo>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <div className='card'>
                            <div className='card-header border-warning border-2'>
                                <div className='h3'>Opciones </div>
                            </div>
                            <div className='card-body row'>
                                <div className='col-sm-6 col-xl-4 col-lg-6'>
                                    <div
                                        className='card o-hidden cursor'
                                        onClick={() => history.push('/vinculacion-empresa-transporte')}
                                    >
                                        <div className='bg-warning b-r-4 card-body'>
                                            <div className='media static-top-widget'>
                                                <div className='align-self-center text-center'>
                                                    <Truck />
                                                </div>
                                                <div className='media-body'>
                                                    <h4 className='mb-0 counter'>
                                                        Vinculación de Empresa de Transporte
                                                    </h4>
                                                    <Truck className='icon-bg' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-xl-4 col-lg-6'>
                                    <div
                                        className='card o-hidden cursor'
                                        onClick={() => history.push('/asignacion-placas-de-camiones')}
                                    >
                                        <div className='bg-warning b-r-4 card-body'>
                                            <div className='media static-top-widget'>
                                                <div className='align-self-center text-center'>
                                                    <Tag />
                                                </div>
                                                <div className='media-body'>
                                                    <h4 className='mb-0 counter'>
                                                        Asignación de Placas de Camiones a EMT
                                                    </h4>
                                                    <Tag className='icon-bg' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-xl-4 col-lg-6'>
                                    <div
                                        className='card o-hidden cursor'
                                        onClick={() => history.push('/asignacion-dpi-pilotos')}
                                    >
                                        <div className='bg-warning b-r-4 card-body'>
                                            <div className='media static-top-widget'>
                                                <div className='align-self-center text-center'>
                                                    <Users />
                                                </div>
                                                <div className='media-body'>
                                                    <h4 className='mb-0 counter'>Asignación de DPI de Pilotos a EMT</h4>
                                                    <Users className='icon-bg' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainComponent
