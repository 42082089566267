import React, { Fragment } from 'react'

const Breadcrumb = props => {
    const breadcrumb = props
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='page-header'>
                    <div className='row'>
                        <div className='col'>
                            <div className='page-header-left'>
                                <h3>{breadcrumb.title}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Breadcrumb
