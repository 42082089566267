import axios from 'axios'

export const postCambioCredenciales = async (cambio, servicio_cambio, url_confirmacion, token_page) => {
    // se cambia a la variable porque lo resibe distinto
    let servicio = ''
    servicio = servicio_cambio
    const { codigo_vinculacion, user, password_actual, password_nuevo, password_confirmacion } = cambio

    const retorno = await axios
        .post(
            url_confirmacion,
            {
                servicio,
                codigo_vinculacion,
                user,
                password_actual,
                password_nuevo,
                password_confirmacion
            },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return {
                v_mensaje: data.v_mensaje,
                v_resultado: data.v_resultado
            }
        })
        .catch(e => {
            if (e.response?.status === 500) {
                return {
                    v_mensaje: 'Error 500, Comunicarse con APM Terminals.',
                    v_resultado: false
                }
            } else if (e.response?.status === 401) {
                return {
                    v_mensaje: 'Erro 401, Comunicarse con APM Terminals.',
                    v_resultado: false
                }
            } else {
                return {
                    v_mensaje: 'Error, Comunicarse con APM Terminals.',
                    v_resultado: false
                }
            }
        })

    return retorno
}
