import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@material-ui/data-grid';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalModule = props => {
    const { toggle, title, responseData, dataRequest } = props;
    const { CONTENEDOR, VIAJE, BUQUE } = dataRequest;

    const header = [
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 180 },
        { field: 'BUQUE', headerName: 'Buque', width: 150 },
        { field: 'VIAJE', headerName: 'Viaje', width: 150 },
        { field: 'MANIFIESTO', headerName: 'Manifiesto', width: 200 },
        {
            field: 'FECHA_CREO',
            headerName: 'Fecha creo',
            width: 200,
            valueGetter: params => moment(params.value).tz('America/Guatemala').format('DD/MM/YYYY hh:mm'),
        },
    ];

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <label className='col-form-label' htmlFor='recipient-name'>
                                <b>Contenedor:</b> {CONTENEDOR}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Buque:</b> {BUQUE}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Viaje:</b> {VIAJE}
                            </label>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: 'CONTENEDOR',
                                    sort: 'asc',
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
