import React, { Fragment, useState, useEffect } from 'react'
import GraficaBarras from '../graficas/GraficaBarras'
import GraficaGrupoBarras from '../graficas/GraficaGrupoBarras'
import Cardsdata from '../dashboard-naviera/Cards'
import FormularioDatos from '../dashboard-naviera/FormularioDatos'
import { Row, Col } from 'reactstrap'
// import logo from '../../assets/images/endless-logo.png'
import axios from 'axios'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const InputExcelDashbord = props => {
    const token_user = props.match.params.tokenaut
    //const token_user = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiR1RNLUFQTVRRQ0YiLCJwc3dkIjoiMTIzIiwiaWF0IjoxNjMyODcxNDg0LCJleHAiOjE2MzI4NzUwODR9.FAL70T_N64fK2MeK-T1jnCFeiB8gyZK0O0SaSWQnxSQ'
    const [ctipos, setToken] = useState({
        mensaje_carga: 'Ingresar un token válido, para poder operar...',
        carga: false,
        respuesta: false,
    })
    const [showDashboards, showDash] = useState(false)
    const [dashboards, dataFillingDash] = useState([])
    const [cards, dataFillingCards] = useState([])
    let Graficas = []
    let PrimeraGrafica = []
    let ComplementoGrafica = []
    const callData = async function (buque, fecha1, fecha2) {
        callApi(buque, fecha1, fecha2)
        showDash(true)
    }
    const callApi = async function (buque, fecha1, fecha2) {
        const resp = await axios({
            method: 'GET',
            url:
                process.env.REACT_APP_DASHBOARDSCMP_API +
                `/v1/cmp/dashboard/buque/${buque}?fechainicio=${fecha2}&fechafin=${fecha1}`,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                dataFillingDash(res.data.dashboard)
                dataFillingCards(res.data.cards)
            })
            .catch(err => {
                console.log('Error: ' + err)
            })
    }
    const callChilds = () => {
        for (var i = 0; i < dashboards.length; i++) {
            if (dashboards[i].nameDashboard == 'Container moves') {
                PrimeraGrafica.push(
                    <Col xs='12' sm='12' md='12' lg='4'>
                        <GraficaBarras key={i} datos={dashboards[i]} />
                    </Col>
                )
            } else if (dashboards[i].nameDashboard == 'Release time by stakeholders') {
                ComplementoGrafica.push(<GraficaGrupoBarras key={i} datos={dashboards[i]} />)
            } else {
                Graficas.push(
                    <Col xs='12' sm='12' md='12' lg='6'>
                        <GraficaBarras key={i} datos={dashboards[i]} />
                    </Col>
                )
            }
        }
    }
    callChilds()
    useEffect(() => {
        if (token_user === undefined) {
            setToken({
                ...ctipos,
                carga: false,
                respuesta: false,
                mensaje_carga: 'Token No valido.',
            })
            return
        } else {
            setToken({
                ...ctipos,
                carga: true,
                respuesta: true,
                mensaje_carga: 'Token correcto.',
            })
        }
    }, [])
    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            {ctipos.carga ? (
                <div>
                    <div className='mt-3'>
                        <FormularioDatos callData={callData} token_user={token_user} />
                    </div>
                    <Row xs='1' sm='6' md='6' lg='6' className='cssentreComponentes'>
                        {PrimeraGrafica}
                        <Col xs='12' sm='12' md='12' lg='8' id='padingTemp'>
                            <Cardsdata cardsData={cards} />
                        </Col>
                    </Row>
                    <Row xs='1' sm='1' md='2' lg='2' className='cssentreComponentes'>
                        {Graficas}
                    </Row>
                    <Row xs='12' sm='12' md='12' lg='12' className='lastBar'>
                        {ComplementoGrafica}
                    </Row>
                </div>
            ) : (
                <div>
                    <h1>token no valido</h1>
                </div>
            )}
        </Fragment>
    )
}
export default InputExcelDashbord
