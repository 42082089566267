import { postBloqueoToken } from './postBloqueoToken'

export const FetchBloqueoToken = async ({
    token_user,
    token_page,
    url_update_bloqueo_token,
    solicitud_apex,
    setRespuestaSolicitud,
    respuestaSolicitud,
    woMarchamo
}) => {
    if (woMarchamo) {
        const { v_mensaje, v_resultado, v_estado } = await postBloqueoToken(
            token_user,
            token_page,
            url_update_bloqueo_token,
            solicitud_apex
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_resultado: false
                }
            })

        console.log(
            '77777777777777777777 TOKEEEEN----------------------> ',
            v_mensaje,
            v_resultado,
            v_estado
        )
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            boqueo_token: v_mensaje,
            estado_bloqueo_token: v_resultado === 'true',
            mostrar_respuestas: true
        })
        const bloqueoToken = v_estado === true && v_resultado === 'true'
        return { bloqueoToken }
    } else {
        return { bloqueoToken: false }
    }
}
