import React from 'react'
import { Truck, HelpCircle, Mic, Zap, UserCheck } from 'react-feather'

const MainComponent = ({ useFetchInit }) => {
    // const { data, handleInputChange, limpiarFormularioDeComida, agregarSolicitudDeComida, loadingOperation } =
    //     useFetchInit;
    // const { cod_departamento, fecha, tipo_menu, cod_plato } = data;
    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='row'>
                        <UserCheck className='m-r-20 ml-2' />
                        <div className='h5'>Datos del transportista</div>
                    </div>
                </div>

                <div className='card-body'>
                    <form>
                        <div className='form-group row'>
                            <div className='form-group col-sm-12 col-md-8 col-xl-10 row'>
                                <div className='col'>
                                    <label for='codigo-empresa'>Código de la empresa</label>
                                </div>
                                <div className='col-8'>
                                    <input type='email' className='form-control' id='codigo-empresa' />
                                </div>
                            </div>
                            <div className='form-group col-sm-12 col-md-4 col-xl-2'>
                                <button type='button' className='btn btn-warning col' id='inputPassword4'>
                                    <i className='icofont icofont-search' /> Buscar
                                </button>
                            </div>
                        </div>

                        <div className='card-body b-t-warning mt-4 b-b-warning mb-4'>
                            <div className='upcoming-event'>
                                <div className='upcoming-innner media'>
                                    <div className='bg-warning left m-r-20'>
                                        <Truck />
                                    </div>
                                    <div className='media-body pull-right'>
                                        <p className='mb-0 pull-right'>
                                            <b>NIT:</b> 63283441
                                        </p>
                                        <h6 className='f-w-600'>
                                            TRANSBRAS, TRANSPORTE Y LOGISTICA BRAS, SOCIEDAD ANONIMA
                                        </h6>
                                        <p className='mb-0'>
                                            23 CALLE 14-58 BODEGA A5,ZONA 4 DE MIXCO, COMPLEJODE NEGOCIOS CRECE, CONDADO
                                            EL NARANJO, GUATEMALA
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  */}

                        <div className='form-group row'>
                            <div className='form-group col-sm-12 col-md-6 col-xl-6 row mt-3'>
                                <div className='col'>
                                    <label for='codigo-empresa'>Nombre</label>
                                </div>
                                <div className='col-8'>
                                    <input type='email' className='form-control' id='codigo-empresa' />
                                </div>
                            </div>

                            <div className='form-group col-sm-12 col-md-6 col-xl-6 row mt-3'>
                                <div className='col'>
                                    <label for='codigo-empresa'>Número de DPI</label>
                                </div>
                                <div className='col-8'>
                                    <input type='email' className='form-control' id='codigo-empresa' />
                                </div>
                            </div>

                            {/* <div className='form-group col-sm-12 row'>
                                <div className='form-group col-sm-12 col-md-6 col-xl-6 row'>
                                    <div className='col-sm-4'>
                                        <label for='codigo-empresa'>Nombre</label>
                                    </div>
                                    <div className='col-sm-12'>
                                        <input type='email' className='form-control col-sm-12' id='codigo-empresa' />
                                    </div>
                                </div>
                                <div className='form-group col-sm-12 col-md-6 col-xl-6 row'>
                                    <div className='col-sm-4'>
                                        <label for='codigo-empresa'>Número de DPI</label>
                                    </div>
                                    <div className='col-sm-8'>
                                        <input type='email' className='form-control' id='codigo-empresa' />
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-sm-12 mt-3'>
                                <button type='button' className='btn btn-warning col' id='inputPassword4'>
                                    <i className='icofont icofont-plus' /> Asignar
                                </button>
                            </div>
                        </div>
                        {/* <div className='form-row'>
                            <div className='form-group col-md-6'>
                                <label for='nombre-empresa'>Nombre de la empresa</label>
                                <input type='text' className='form-control' id='nombre-empresa' />
                            </div>
                            <div className='form-group col-md-6'>
                                <label for='inputEmail4'>Email</label>
                                <input type='text' className='form-control' id='inputEmail4' />
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='form-group col-md-6'>
                                <label for='inputEmail4'>Dirección</label>
                                <input type='text' className='form-control' id='inputDirection' />
                            </div>
                            <div className='form-group col-md-3'>
                                <label for='inputCity'>Provincia</label>
                                <input type='text' className='form-control' id='inputProvince' />
                            </div>
                            <div className='form-group col-md-3'>
                                <label for='inputCity'>Ciudad</label>
                                <input type='text' className='form-control' id='inputCity' />
                            </div>
                        </div> */}

                        {/* <div className='row'>
                            <div className='col-sm-6 form-group mt-3'>
                                <button
                                    className='btn btn-warning col-sm-12'
                                    type='submit'
                                    // disabled={loadingOperation}
                                >
                                    <i className='icofont icofont-plus' /> Agregar
                                </button>
                            </div>
                            <div className='col-sm-6 form-group mt-3'>
                                <button
                                    className='btn col-sm-12'
                                    type='button'
                                    // onClick={limpiarFormularioDeComida}
                                >
                                    <i className='icofont icofont-ui-delete' /> Limpiar
                                </button>
                            </div>
                        </div> */}
                    </form>
                </div>
            </div>
        </>
    )
}

export default MainComponent
