import { getValidaDocumentos } from './getValidaDocumentos'

export const FetchValidaDocumentos = async ({
    token_user,
    token_page,
    url_validar_documentos,
    sub_tipo_solicitud,
    solicitud_apex,
    // setRespuestaSolicitud,
    // respuestaSolicitud,
    validaCarga
}) => {
    if (validaCarga === false) {
        return { documentacionCorrecta: false }
    }
    const { v_mensaje, v_resultado, v_estado } = await getValidaDocumentos(
        token_user,
        token_page,
        url_validar_documentos,
        sub_tipo_solicitud,
        solicitud_apex
    )
        .then(res => res)
        .catch(err => {
            console.log(err)
            return {
                v_estado: false,
                v_mensaje: 'Error interno del servidor',
                v_resultado: false
            }
        })
    console.log('Response VALIDA DOCUMENTO', v_mensaje)
    // setRespuestaSolicitud({
    //     ...respuestaSolicitud,
    //     valida_documentos: v_mensaje,
    //     estado_documentos: v_resultado,
    //     mostrar_respuestas: true,
    // })
    const documentacionCorrecta = v_estado === true && v_resultado === true
    return { documentacionCorrecta }
}
