import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import IconLink from './icon-link'
import Loaging from '../loading'
import { toast } from 'react-toastify'
import { HTML5_FMT } from 'moment'

const CardComponent = ({ data, loading, error }) => {
    const { importacion } = data
    // const [data, setData] = useState([])
    // const [loading, setLoading] = useState(true)

    // const awaitData = async () => {
    //     const res = await axios
    //         .get(
    //             `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/servicios-en-linea/importacion`,
    //             {
    //                 headers: {
    //                     Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
    //                 },
    //             }
    //         )
    //         .then(res => res)
    //     setData(res.data)
    //     setLoading(false)
    // }

    // useEffect(() => {
    //     awaitData()
    //     return () => {
    //         console.log(`Desmonte de componente`)
    //     }
    // }, [])

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-6'>
                <div className='card b-b-warning b-l-warning b-r-warning b-t-warning'>
                    <div className='card-header border-3 text-center'>
                        <h5 className='text-warning'>{'Importación'}</h5>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            {loading && <Loaging />}
                            {error && (
                                <div className='col-12 text-center'>
                                    <h5 className='text-danger'>¡Error al obtener los datos!</h5>
                                </div>
                            )}
                            {importacion.map((item, index) => {
                                const { COD_DIRECTORIO_LINK, CARD_LINK, CARD_TITLE, DESCRIPCION, IMAGEN, SUBTITULO } =
                                    item && item
                                return (
                                    <IconLink
                                        key={index}
                                        id={COD_DIRECTORIO_LINK}
                                        img={IMAGEN}
                                        link={CARD_LINK}
                                        tittle={CARD_TITLE}
                                        tooltipTittle={DESCRIPCION}
                                        tooltipDescription={SUBTITULO}
                                        text='apm'
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CardComponent
