import { postEnvioAlmacen } from './postEnvioCorreoAlmacen'

export const FetchEnvioCorreoAlmacen = async ({
    token_user,
    token_page,
    url_envio_correo_aop,
    solicitud_apex,
    tipo_solicitud,
    sub_tipo_solicitud,
    setRespuestaSolicitud,
    respuestaSolicitud,
    confirmaSolicitud,
    envioNaviera
}) => {
    if (confirmaSolicitud) {
        const { v_mensaje, v_resultado, v_estado } = await postEnvioAlmacen(
            token_user,
            token_page,
            url_envio_correo_aop,
            solicitud_apex,
            tipo_solicitud,
            sub_tipo_solicitud
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_resultado: false
                }
            })

        console.log(
            '8888888888888888 ENVIO CORREO ALMACEN----------------------> ',
            v_mensaje,
            v_resultado,
            v_estado
        )
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            valida_documentos: 'Si tiene agregados los documentos',
            estado_documentos: true,
            confirma_solicitud: 'Solicitud confirmada',
            estado_solicitud: true,
            envia_correo_naviera: envioNaviera ? ' Correo enviado correctamente' : 'Correo no enviado',
            estado_correo_naviera: envioNaviera,
            envia_correo_almacen: v_mensaje,
            estado_correo_almacen: v_resultado === 'true',
            mostrar_respuestas: true
        })
        const envioCorreoAlmacen = v_estado === true && v_resultado === 'true'
        return { envioCorreoAlmacen }
    } else {
        return { envioCorreoAlmacen: false }
    }
}
