import React from 'react'
import moment from 'moment-timezone'
import bandera_roja from '../../../assets/images/estados_sat/bandera_rojo.png'
import bandera_verde from '../../../assets/images/estados_sat/bandera_verde.png'
import bandera_gris from '../../../assets/images/estados_sat/bandera_gris.png'
import bandera_amarilla from '../../../assets/images/estados_sat/bandera_amarillo.png'
import { UncontrolledTooltip } from 'reactstrap'

export function headerSiSat({ toggle3 }) {
    let redir = link => {
        window.open(link)
    }

    return [
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 220 },
        { field: 'BUQUE', headerName: 'Buque', width: 200 },
        { field: 'VIAJE', headerName: 'Viaje con APMTQ', width: 200 },
        {
            field: 'LISTA_DESCARGA',
            headerName: 'Contenedor en lista de descarga',
            width: 300,
            align: 'center',
            headerAlign: 'center',
            resizable: true,
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bandera_verde}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.LISTA_DESCARGA_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'rojo' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bandera_roja}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.LISTA_DESCARGA_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bandera_amarilla}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.LISTA_DESCARGA_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                src={bandera_gris}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.LISTA_DESCARGA_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'MANIFIESTO_IMPORT',
            headerName: 'Contenedor en Manifiesto Importación',
            width: 320,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_verde}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Historial de manifiesto', 'manifiesto', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.MANIFIESTO_IMPORT_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'rojo' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_roja}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Historial de manifiesto', 'manifiesto', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.MANIFIESTO_IMPORT_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'amarillo' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_amarilla}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Historial de manifiesto', 'manifiesto', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.MANIFIESTO_IMPORT_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : params.value === 'gris' ? (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_gris}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Historial de manifiesto', 'manifiesto', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.MANIFIESTO_IMPORT_TXT}
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        <></>
                    )}
                </strong>
            ),
        },
        {
            field: 'SAT_CODIGO_ESTADO',
            headerName: 'Estado DUA',
            width: 250,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_verde}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones SAT', 'retenciones-sat', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.SAT_CODIGO_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_roja}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones SAT', 'retenciones-sat', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.SAT_CODIGO_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'amarillo' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='imgCursor ml-1'
                                src={bandera_amarilla}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Retenciones SAT', 'retenciones-sat', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                <b>Estado: </b>
                                {params.row.SAT_CODIGO_ESTADO_TXT}
                            </UncontrolledTooltip>
                        </>
                    )}

                    {params.value === 'gris' &&
                        (params.row.SAT_CODIGO_ESTADO_LINK != null ? (
                            <>
                                <img
                                    alt='img'
                                    id={'c' + params.row.id}
                                    className='imgCursor ml-1'
                                    src={bandera_gris}
                                    height='32'
                                    width='32'
                                    data-placement='top'
                                    onClick={() => redir(params.row.SAT_CODIGO_ESTADO_LINK)}
                                ></img>
                                <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                    <b>Estado: </b>
                                    {params.row.SAT_CODIGO_ESTADO_TXT}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <>
                                <img
                                    alt='img'
                                    id={'c' + params.row.id}
                                    className='imgCursor ml-1'
                                    src={bandera_gris}
                                    height='32'
                                    width='32'
                                    data-placement='top'
                                    onClick={() => toggle3('', 'Retenciones SAT', 'retenciones-sat', params.row)}
                                ></img>
                                <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                    <b>Estado: </b>
                                    {params.row.SAT_CODIGO_ESTADO_TXT}
                                </UncontrolledTooltip>
                            </>
                        ))}
                </strong>
            ),
        },
        //KENNY TOTI PIDIO QUE SE OCULTARA ESTA COLUMNA
        // {
        //     field: 'ETA',
        //     headerName: 'Fecha Estimada Arribo',
        //     width: 250,
        //     // valueGetter: params => moment(params.value).format('DD/MM/YYYY'),
        // },
    ]
}
