import React, { Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import styled from '@emotion/styled'
import ReglamentoGeneralPDF from './REGLAMENTO_APMTQ.pdf'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const ReglamentoComponent = props => {
    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Reglamento General APMTQ S.A.' parent='Servicios' />
            </Titulo>
            <div className='container-fluid mt-3'>
                <div className='card'>
                    <div className='card-body'>
                        <embed
                            src={ReglamentoGeneralPDF}
                            height='900px'
                            width='100%'
                            name='embed_content'
                            type='application/pdf'
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ReglamentoComponent
