import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

export const TabsProveedores = ({ activeTab, toggle }) => {
    return (
        <Nav tabs className={`header-tab-vinculacion`}>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                        toggle('1')
                    }}
                >
                    Datos Empresariales
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                        toggle('2')
                    }}
                >
                    Datos Legales
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                        toggle('3')
                    }}
                >
                    Referencias Comerciales
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                        toggle('4')
                    }}
                >
                    Informacion Bancaria
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => {
                        toggle('5')
                    }}
                >
                    Informacion Socios
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => {
                        toggle('6')
                    }}
                >
                    Contactos
                </NavLink>
            </NavItem>
        </Nav>
    )
}
