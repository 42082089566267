import React, { Fragment } from 'react'
import Table from 'react-bootstrap/Table'
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import bullet_red from '../../../assets/images/estados/bullet-red.png'
import bullet_green from '../../../assets/images/estados/bullet-green.png'
import bullet_yellow from '../../../assets/images/estados/bullet-yellow.png'
import bullet_gray from '../../../assets/images/estados/bullet-gray.png'

let redir = (link) => {
    window.open(link)
}

// NOBL se cambió por Booking
// MANIFIESTO se cambió por sat_cushman

const ModalModule = (props) => {
    const { toggle, dataRequest, toggle3 } = props
    return (
        <Fragment>
            <ModalHeader toggle={toggle}>Importación - Estado del contenedor</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <div className='col-md-12 mb-1'>
                            <b>Buque:</b> {dataRequest.BUQUE}
                        </div>
                        <div className='col-md-12 mb-1'>
                            <b>Contenedor:</b> {dataRequest.CONTENEDOR}
                        </div>
                        <div className='col-md-12 mb-1'>
                            <b>Viaje:</b> {dataRequest.EAESCALA}
                        </div>
                        <div className='col-md-12 mb-1'>
                            <b>Manifiesto:</b> {dataRequest.SAT_CUSHMAN}
                        </div>
                        <div className='col-md-12 mb-1'>
                            <b>NOBL:</b> {dataRequest.BOOKING}
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='row'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Autoridad</th>
                                    <th>Estado</th>
                                    <th>Información</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {dataRequest.NAVIERA === 'verde' ? (
                                        <>
                                            <td>
                                                <b>Naviera </b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'a' + dataRequest.id}
                                                    src={bullet_green}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.NAVIERA_TXT} <br />
                                                {dataRequest.FECHA_EXPIRACION_ORDEN != null && (
                                                    <>
                                                        Fecha expiración:
                                                        {dataRequest.FECHA_EXPIRACION_ORDEN}
                                                    </>
                                                )}
                                            </td>
                                        </>
                                    ) : dataRequest.NAVIERA === 'rojo' ? (
                                        <>
                                            <td>
                                                <b>Naviera </b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'a' + dataRequest.id}
                                                    src={bullet_red}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.NAVIERA_TXT} <br />
                                                {dataRequest.FECHA_EXPIRACION_ORDEN != null && (
                                                    <>
                                                        Fecha expiración:
                                                        {dataRequest.FECHA_EXPIRACION_ORDEN}
                                                    </>
                                                )}
                                            </td>
                                        </>
                                    ) : dataRequest.NAVIERA === 'amarillo' ? (
                                        <>
                                            <td>
                                                <b>Naviera </b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'a' + dataRequest.id}
                                                    src={bullet_yellow}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.NAVIERA_TXT} <br />
                                                {dataRequest.FECHA_EXPIRACION_ORDEN != null && (
                                                    <>
                                                        Fecha expiración:
                                                        {dataRequest.FECHA_EXPIRACION_ORDEN}
                                                    </>
                                                )}
                                            </td>
                                        </>
                                    ) : dataRequest.NAVIERA === 'gris' ? (
                                        <>
                                            <td>
                                                <b>Naviera </b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'a' + dataRequest.id}
                                                    src={bullet_gray}
                                                    height='21'
                                                    width='21'
                                                    data-placement='top'
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.NAVIERA_TXT} <br />
                                                {dataRequest.FECHA_EXPIRACION_ORDEN != null && (
                                                    <>
                                                        Fecha expiración:
                                                        {dataRequest.FECHA_EXPIRACION_ORDEN}
                                                    </>
                                                )}
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>
                                                <b>Naviera </b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'a' + dataRequest.id}
                                                    src={bullet_gray}
                                                    height='21'
                                                    width='21'
                                                    data-placement='top'
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.NAVIERA_TXT} <br />
                                                {dataRequest.FECHA_EXPIRACION_ORDEN != null && (
                                                    <>
                                                        Fecha expiración:
                                                        {dataRequest.FECHA_EXPIRACION_ORDEN}
                                                    </>
                                                )}
                                            </td>
                                        </>
                                    )}
                                </tr>
                                <tr>
                                    {dataRequest.SAT_CODIGO_ESTADO === 'verde' ? (
                                        <>
                                            <td>
                                                <b>SAT</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'b' + dataRequest.id}
                                                    src={bullet_green}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                ></img>
                                            </td>
                                            <td>{dataRequest.SAT_CODIGO_ESTADO_TXT}</td>
                                        </>
                                    ) : dataRequest.SAT_CODIGO_ESTADO === 'rojo' ? (
                                        <>
                                            <td>
                                                <b>SAT</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'b' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_red}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones SAT',
                                                            'sat',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>{dataRequest.SAT_CODIGO_ESTADO_TXT}</td>
                                        </>
                                    ) : dataRequest.SAT_CODIGO_ESTADO === 'amarillo' ? (
                                        <>
                                            <td>
                                                <b>SAT</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'b' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_yellow}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones SAT',
                                                            'sat',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>{dataRequest.SAT_CODIGO_ESTADO_TXT}</td>
                                        </>
                                    ) : dataRequest.SAT_CODIGO_ESTADO === 'gris' ? (
                                        dataRequest.SAT_CODIGO_ESTADO_LINK != null ? (
                                            <>
                                                <td>
                                                    <b>SAT</b>
                                                </td>
                                                <td>
                                                    <img
                                                        alt='img'
                                                        id={'b' + dataRequest.id}
                                                        className='imgCursor ml-1'
                                                        src={bullet_gray}
                                                        height='21'
                                                        width='21'
                                                        data-placement='top'
                                                        onClick={() =>
                                                            redir(
                                                                dataRequest.SAT_CODIGO_ESTADO_LINK
                                                            )
                                                        }
                                                    ></img>
                                                </td>
                                                <td>{dataRequest.SAT_CODIGO_ESTADO_TXT}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>
                                                    <b>SAT</b>
                                                </td>
                                                <td>
                                                    <img
                                                        alt='img'
                                                        id={'b' + dataRequest.id}
                                                        className='imgCursor ml-1'
                                                        src={bullet_gray}
                                                        height='21'
                                                        width='21'
                                                        data-placement='top'
                                                        onClick={() =>
                                                            toggle3(
                                                                '',
                                                                'Retenciones SAT',
                                                                'sat',
                                                                dataRequest
                                                            )
                                                        }
                                                    ></img>
                                                </td>
                                                <td>{dataRequest.SAT_CODIGO_ESTADO_TXT}</td>
                                            </>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                                <tr>
                                    {dataRequest.RET_ESTADO === 'verde' ? (
                                        <>
                                            <td>
                                                <b>Autoridades</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'c' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_green}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones AUTORIDADES',
                                                            'autoridades',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>{dataRequest.RET_ESTADO_TXT}</td>
                                        </>
                                    ) : dataRequest.RET_ESTADO === 'rojo' ? (
                                        <>
                                            <td>
                                                <b>Autoridades</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'c' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_red}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones AUTORIDADES',
                                                            'autoridades',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>{dataRequest.RET_ESTADO_TXT}</td>
                                        </>
                                    ) : dataRequest.RET_ESTADO === 'amarillo' ? (
                                        <>
                                            <td>
                                                <b>Autoridades</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'c' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_yellow}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones AUTORIDADES',
                                                            'autoridades',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>{dataRequest.RET_ESTADO_TXT}</td>
                                        </>
                                    ) : dataRequest.RET_ESTADO === 'gris' ? (
                                        <>
                                            <td>
                                                <b>Autoridades</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'c' + dataRequest.id}
                                                    className='imgCursor ml-1'
                                                    src={bullet_gray}
                                                    height='21'
                                                    width='21'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones AUTORIDADES',
                                                            'autoridades',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>{dataRequest.RET_ESTADO_TXT}</td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                                <tr>
                                    {dataRequest.RET_APMTQ === 'verde' ? (
                                        <>
                                            <td>
                                                <b>Apmtq</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'d' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_green}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones APMTQ',
                                                            'apmtq',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.RET_APMTQ_TXT}. <br />
                                                {dataRequest.APMTQ_FECHA}
                                            </td>
                                        </>
                                    ) : dataRequest.RET_APMTQ === 'rojo' ? (
                                        <>
                                            <td>
                                                <b>Apmtq</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'d' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_red}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones APMTQ',
                                                            'apmtq',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.RET_APMTQ_TXT}. <br />
                                                {dataRequest.APMTQ_FECHA}
                                            </td>
                                        </>
                                    ) : dataRequest.RET_APMTQ === 'amarillo' ? (
                                        <>
                                            <td>
                                                <b>Apmtq</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'d' + dataRequest.id}
                                                    className='imgCursor'
                                                    src={bullet_yellow}
                                                    height='32'
                                                    width='32'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones APMTQ',
                                                            'apmtq',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.RET_APMTQ_TXT}. <br />
                                                {dataRequest.APMTQ_FECHA}
                                            </td>
                                        </>
                                    ) : dataRequest.RET_APMTQ === 'gris' ? (
                                        <>
                                            <td>
                                                <b>Apmtq</b>
                                            </td>
                                            <td>
                                                <img
                                                    alt='img'
                                                    id={'d' + dataRequest.id}
                                                    className='imgCursor ml-1'
                                                    src={bullet_gray}
                                                    height='21'
                                                    width='21'
                                                    data-placement='top'
                                                    onClick={() =>
                                                        toggle3(
                                                            '',
                                                            'Retenciones APMTQ',
                                                            'apmtq',
                                                            dataRequest
                                                        )
                                                    }
                                                ></img>
                                            </td>
                                            <td>
                                                {dataRequest.RET_APMTQ_TXT}.<br />
                                                {dataRequest.APMTQ_FECHA}
                                            </td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}

export default ModalModule
