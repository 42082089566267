import axios from 'axios'

export const getDocumentos = async (token_user, token_page, solicitud, url_documentos) => {
    const retorno = await axios
        .get(`${url_documentos}?v_solicitud=${solicitud}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
