import { useState } from 'react'
import { useFetchGetAnuncios } from './useEffects'

export const useFetchInitDisclaimerAnuncios = ({ access }) => {
    const [dataModal, setDataModal] = useState({
        open: false,
        titleModal: 'Visor de anuncios',
        componentModal: 'visor-de-anuncios',
        dataRequest: {},
    })

    const toggle = (_, title, compont, dataRequest) => {
        setDataModal({
            open: !dataModal.open,
            titleModal: title,
            componentModal: compont,
            dataRequest: dataRequest,
        })
    }

    const FetchGetAnuncios = useFetchGetAnuncios({ access, dataModal })

    return {
        dataModal,
        toggle,
        FetchGetAnuncios,
    }
}
