import React, { Fragment } from 'react'
import { Modal } from 'reactstrap'
import { ModalCiudades } from './modales/modalCiudades'
import { ModalPaises } from './modales/modalPaises'
import { ModalContactos } from './modales/modalContactos'
import { ModalSocios } from './modales/modalSocios'
import { ModalInfoBancaria } from './modales/modalInfoBancaria'
import { ModalReferenciasComerciales } from './modales/modalReferenciasComerciales'
// props
const ModalModule = ({
    useFetchInit,
    urlPeticiones,
    servicios,
    perfilesContacto    
}) => {
    
    const {
        toggle3,
        modal,
        title,
        // dataRequest,
        componentModal,
        setVinculacion,
        vinculacion,
        // url_listado_vinculacion,
        // servicio_paises,
        // servicio_ciudades,
        // perfil_contacto_cliente,
        // perfil_contacto_proveedor,
        // servicio_tipo_contacto,
        dcontacto,
        setDcontacto,
        infocontacto,
        setInfocontacto,
        // servicio_monedas,
        inforeferencias,
        setInforeferencias,
        dreferencias,
        setDreferencias,
        infobancaria,
        setInfobancaria,
        dinfobancaria,
        setDinfobancaria,
        infosocios,
        setInfosocios,
        dsocios,
        setDsocios
        // btndisableuv,
        // setBtndisableuv,
        // token_page,
    } = useFetchInit

    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle3} className='modal-body' centered={true} size={'lg'}>
                {componentModal === 'OPEN_PAISES' ? (
                    <ModalPaises
                        toggle={toggle3}
                        // modal={modal}
                        title={title}
                        // dataRequest={dataRequest}
                        url_listado_vinculacion={urlPeticiones.url_listado_vinculacion}
                        servicio_paises={servicios.servicio_paises}
                        setVinculacion={setVinculacion}
                        vinculacion={vinculacion}
                        token_page={urlPeticiones.token_page}
                    />
                ) : componentModal === 'OPEN_CIUDADES' ? (
                    <ModalCiudades
                        toggle={toggle3}
                        // modal={modal}
                        title={title}
                        // dataRequest={dataRequest}
                        url_listado_vinculacion={urlPeticiones.url_listado_vinculacion}
                        servicio_ciudades={servicios.servicio_ciudades}
                        setVinculacion={setVinculacion}
                        vinculacion={vinculacion}
                        token_page={urlPeticiones.token_page}
                    />
                ) : componentModal === 'OPEN_CONTACTOS' ? (
                    <ModalContactos
                        toggle={toggle3}
                        title={title}
                        url_listado_vinculacion={urlPeticiones.url_listado_vinculacion}
                        vinculacion={vinculacion}
                        perfil_contacto_cliente={perfilesContacto.perfil_contacto_cliente}
                        perfil_contacto_proveedor={perfilesContacto.perfil_contacto_proveedor}
                        servicio_tipo_contacto={servicios.servicio_tipo_contacto}
                        dcontacto={dcontacto}
                        setDcontacto={setDcontacto}
                        infocontacto={infocontacto}
                        setInfocontacto={setInfocontacto}
                        token_page={urlPeticiones.token_page}
                    />
                ) : componentModal === 'OPEN_REFERENCIAS_COMERCIALES' ? (
                    <ModalReferenciasComerciales
                        toggle={toggle3}
                        title={title}
                        inforeferencias={inforeferencias}
                        setInforeferencias={setInforeferencias}
                        dreferencias={dreferencias}
                        setDreferencias={setDreferencias}
                    />
                ) : componentModal === 'OPEN_INFORMACION_BANCARIA' ? (
                    <ModalInfoBancaria
                        toggle={toggle3}
                        title={title}
                        url_listado_vinculacion={urlPeticiones.url_listado_vinculacion}
                        servicio_monedas={servicios.servicio_monedas}
                        infobancaria={infobancaria}
                        setInfobancaria={setInfobancaria}
                        dinfobancaria={dinfobancaria}
                        setDinfobancaria={setDinfobancaria}
                        token_page={urlPeticiones.token_page}
                    />
                ) : componentModal === 'OPEN_INFORMACION_SOCIOS' ? (
                    <ModalSocios
                        toggle={toggle3}
                        title={title}
                        infosocios={infosocios}
                        setInfosocios={setInfosocios}
                        dsocios={dsocios}
                        setDsocios={setDsocios}
                    />
                ) : (
                    ''
                )}
            </Modal>
        </Fragment>
    )
}

export default ModalModule
