import React, { Fragment } from 'react';
import logo from '../../assets/images/apm-logo.jpg';

const CardView = ({ item, toggle3 }) => {
    const { COD_BOLETIN_APMT, CARD_TITLE, CARD_TEXT, CARD_SUBTEXT } = item;
    return (
        <Fragment>
            <div className='col-sm-12 col-md-6 col-xl-4 '>
                <div className='card cursor' onClick={() => toggle3('', COD_BOLETIN_APMT)}>
                    <div className='job-search'>
                        <div className='card-body target-boletin mb-4'>
                            <div className='media'>
                                <img className='img-60 img-fluid m-r-20' src={logo} alt='' />
                                <div className='media-body'>
                                    <h6 className='f-w-500'>
                                        <h6 className='text-apm'>{CARD_TITLE}</h6>
                                    </h6>
                                    <p>{CARD_SUBTEXT}</p>
                                </div>
                            </div>
                            <p>{CARD_TEXT}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CardView;
