import React, { Fragment, useState } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loading from './loading-user';
// import axios from 'axios';

const ModalModule = props => {
    const {
        toggle,
        title,
        vgmTipo,
        vgmSubipo,
        setVgmSubipo,
        vgmANombreDe,
        dataRequest,
        solicitud,
        setSolicitud,
        datos,
    } = props;
    const [loading, setLoading] = useState(false);

    const sendOrder = event => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    };

    const onChangeEvent = e => {
        console.log(e.target.value);
        setSolicitud({ ...solicitud, cod_tipo: e.target.value });
    };

    return (
        <Fragment>
            <form className='theme-form' onSubmit={sendOrder}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className='container-fluid bd-example-row'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label className='col-form-label' htmlFor='recipient-name'>
                                        <b>Contenedor:</b> {dataRequest.CONTENEDOR}
                                    </label>
                                </div>
                            </div>
                            <hr />

                            <div className='card-body'>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{'Tipo solicitud'}</label>
                                    <div className='col-sm-9'>
                                        <select
                                            required
                                            className='form-control'
                                            name='tipo_solicitud'
                                            onChange={onChangeEvent}
                                        >
                                            <option value=''>-- Seleccione el tipo --</option>
                                            {vgmTipo.map(item => {
                                                return (
                                                    <option value={item.COD_TIPO_SOLICITUD}>
                                                        {item.TIPO_SOLICITUD}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{'Subtipo solicitud'}</label>
                                    <div className='col-sm-9'>
                                        <select required className='form-control' name='sub_tipo_solicitud'>
                                            <option value=''>-- Seleccione el subtipo --</option>
                                            {vgmSubipo.map(item => {
                                                return (
                                                    <option value={item.COD_SUB_TIPO_SOLICITUD}>
                                                        {item.SUB_TIPO_SOLICITUD}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{'Observaciones'}</label>
                                    <div className='col-sm-9'>
                                        <textarea
                                            name='observaciones'
                                            className='form-control'
                                            rows='5'
                                            cols='5'
                                            placeholder='Observaciones'
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{'Factura a nombre de'}</label>
                                    <div className='col-sm-9'>
                                        <select required className='form-control' name='a_nombre_de'>
                                            <option value=''>-- Seleccione el nombre de la persona --</option>
                                            {vgmANombreDe.map(item => {
                                                return (
                                                    <option value={item.COD_VINCULACION_USUARIO}>
                                                        {item.SOLICITADO_POR}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color='gray' onClick={toggle}>
                        Cerrar
                    </Button>
                    <button className='btn btn-outline-warning' type='submit'>
                        {'Enviar Solicitud'}
                    </button>
                </ModalFooter>
            </form>
        </Fragment>
    );
};

export default ModalModule;
