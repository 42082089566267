import { useState, useEffect } from 'react'
import { getPerfilesSat } from '../../helpers/vinculaciones/getPerfilesSat'

export const useFetchPerfilesSat = (url_listado_vinculacion, servicio_perfiles_usuario, token_page) => {
    const [perfiles, setPerfiles] = useState([]) // tipos

    useEffect(() => {
        getPerfilesSat(url_listado_vinculacion, servicio_perfiles_usuario, token_page)
            .then(resultado => {
                setPerfiles(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `, error)
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [url_listado_vinculacion, servicio_perfiles_usuario, token_page])

    return [perfiles]
}
