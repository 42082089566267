import React, { Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import { RegistroAutoridades } from './modal-components/registroAutoridades'
import { useFetchIniSat } from '../../hooks/vinculaciones/useFetchInitSat'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'
/************************ Cargando Hook principal ********************************/
/*********************************************************************************/

const VinculacionAutoridades = props => {
    const url_listado_vinculacion = process.env.REACT_APP_URL_LISTADO_VINCULACION
    const url_confirmacion = process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/confirmacion'
    const servicio_perfiles_usuario = 'perfiles-sat'
    const servicio_vinculacion = 'registro-autoridad'
    const token_page = process.env.REACT_APP_TOKEN_PAGE
    const [vinculacion, setVinculacion, capturandovalores, perfiles, load, error] = useFetchIniSat({
        url_listado_vinculacion,
        servicio_perfiles_usuario,
        url_confirmacion,
        servicio_vinculacion,
        token_page,
    })

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Breadcrumb title='Registro Funcionarios Aduana y Autoridades' parent='Servicios' />
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <form onSubmit={capturandovalores}>
                            <RegistroAutoridades
                                setVinculacion={setVinculacion}
                                vinculacion={vinculacion}
                                perfiles={perfiles}
                                load={load}
                                error={error}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VinculacionAutoridades
