import React, { Fragment } from "react";

const Loader = () => {
    return (
        <Fragment>
            <div className="loader">
                <div className="line bg-warning"></div>
                <div className="line bg-warning"></div>
                <div className="line bg-warning"></div>
                <div className="line bg-warning"></div>
            </div>
        </Fragment>
    );
};

export default Loader;
