import React from 'react'
import { Container} from 'reactstrap';
import {Bar} from 'react-chartjs-2'
const BarChart = (props) => {
    const dat = props.datos
    return (
        <Container className="tituloGraficaBarras">
            {dat?(<>
            <div>
                <label>{dat.nameDashboard?dat.nameDashboard:'Nombre no encontrado'}</label>
            </div>
               <Bar className="CustomCssBar"
                   data={{
                       labels: dat.mes?dat.mes:['-'],
                       datasets: [{
                           label: dat.nameColumn1?dat.nameColumn1:'Nombre no encontrado',
                           data: dat.dataStack1?dat.dataStack1:[0],
                           backgroundColor: 'rgba(252,100,28,1)',
                           stack: 'Stack 0',
                       },
                       {
                           label: dat.nameColumn2?dat.nameColumn2:'',
                           data: dat.dataStack2?dat.dataStack2:[0],
                           backgroundColor: dat.nameColumn2?'rgba(8,68,100,1)':'rgba(0,0,0,0)',
                           stack: dat.nameColumn2=='Target'?'Stack 1':'Stack 0',
                       }]
                   }}
                   options={{
                       responsive: false,
                       maintainAspectRatio : false,
                       interaction: {
                           intersect: false,
                       },
                       plugins: {
                           datalabels: {
                           display: true,
                           align: 'center',
                           anchor: 'center'
                           }
                       },
                       scales: {
                           x: {
                            stacked: true,
                           },
                           y: {
                            stacked: true
                           }
                       }
                   }}
                   height = {350}
                   width = {500}
               />
               </>):null
        }
        </Container>
    )
}

export default BarChart;