import React from 'react'
import Breadcrumb from '../../common/breadcrumb'
import Formulario from './formulario'
import Table from './table'
import styled from '@emotion/styled'
import Errors from '../../errors/errors-api'
// import { useFetchInitSolicitudDeComida } from '../../hooks/solicitud-de-comida/useFetchInitSolicitudDeComida';

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const MainComponent = () => {
    const useFetchInit = null
    // const { errors } = useFetchInit;

    return (
        <>
            <Titulo>
                <Breadcrumb title='Asignación de DPI de pilotos a EMT' />
            </Titulo>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        {/* <Errors errors={errors} /> */}
                        <Formulario useFetchInit={useFetchInit} />
                        <Table useFetchInit={useFetchInit} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainComponent
