import React, { Fragment, useState } from 'react'
import axios from 'axios'

import Breadcrumb from './breadcrumb'
import Carousel from './cards/carousel'

import Importacion from './cards/importacion'
import Exportacion from './cards/exportacion'
import RegistroAyuda from './cards/registro-ayuda'
import LineasNavieras from './cards/lineas-navieras'
import AduanasAutoridades from './cards/aduanas-autoridades'
// import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const MainComponent = props => {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState('')
    const [data, setData] = React.useState({
        importacion: [],
        exportacion: [],
        registro_ayuda: [],
        lineas_navieras: [],
        aduanas_autoridades: [],
        carrousel: [],
    })

    React.useEffect(() => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_SERVICE_PANEL}/v2/servicios-en-linea/query`, {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    service: 'all-services',
                },
            })
            .then(({ data }) => {
                const { error } = data || {}
                if (error) {
                    setError(error)
                } else {
                    setData(data)
                }
                setLoading(false)
            })
            .catch(({ response }) => {
                setError(response)
                setLoading(false)
            })
        return () => {
            console.log(`Desmonte de componente`)
        }
    }, [])

    return (
        <Fragment>
            {/* <DisclaimerAnuncio {...props} /> */}
            <Breadcrumb
                title='Servicios en Línea, página transaccional - apm terminals quetzal'
                parent='Advance'
                center
            />
            <div className='container-fluid'>
                <div className='row'>
                    <RegistroAyuda data={data} loading={loading} error={error} />
                    <Importacion data={data} loading={loading} error={error} />
                    <Exportacion data={data} loading={loading} error={error} />
                    <LineasNavieras data={data} loading={loading} error={error} />
                    <AduanasAutoridades data={data} loading={loading} error={error} />
                    <Carousel data={data} loading={loading} error={error} />
                </div>
            </div>
        </Fragment>
    )
}

export default MainComponent
