import React, { Fragment } from 'react'
// import Breadcrumb from '../common/breadcrumb';
import { FormularioRecuperacion } from './modal-components/formularioRecuperacion'
import { useFetchIniRecuperacion } from '../../hooks/vinculaciones/useFetchInitRecuperacion'
import { TituloRecuperacion } from './modal-components/tituloRecuperacion'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'
/************************ Cargando Hook principal ********************************/
/*********************************************************************************/

const RecuperacionCredenciales = props => {
    const url_login_external = process.env.REACT_APP_URL_LOGIN_EXTERNAL

    const url_confirmacion = process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/confirmacion'
    const servicion_nuevas_credenciales = 'nuevas-credenciales'
    const token_page = process.env.REACT_APP_TOKEN_PAGE

    const [vinculacion, setVinculacion, capturandovalores, load, error] = useFetchIniRecuperacion({
        url_confirmacion,
        servicion_nuevas_credenciales,
        token_page,
    })

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            {/* <Breadcrumb title='Registro Funcionarios Aduana y Autoridades' parent='Servicios' /> */}
            <div className='container-fluid'>
                <TituloRecuperacion />
                <div className='row'>
                    <div className='col-sm-12'>
                        <form onSubmit={capturandovalores}>
                            <FormularioRecuperacion
                                setVinculacion={setVinculacion}
                                vinculacion={vinculacion}
                                load={load}
                                error={error}
                                url_login_external={url_login_external}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RecuperacionCredenciales
