import axios from 'axios'

export const getCiudades = async (
    url_listado_vinculacion,
    servicio_ciudades,
    p_pagina,
    p_registros,
    p_buscador,
    codigo_pais,
    token_page
) => {
    const token_user = 'nada'

    const retorno = await axios
        .get(
            `${url_listado_vinculacion}?servicio=${servicio_ciudades}&v_pagina=${p_pagina}&v_registros=${p_registros}&v_buscador=${p_buscador}&v_cod_pais=${codigo_pais}`,
            {
                headers: {
                    'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
