import axios from 'axios';
import Swal from 'sweetalert2';
import catalog from './catalog.js'

const gridCitas =   async (booking, token_user) => {
  let response=[];
  try{
      let rows;
      let columns;
      
      Swal.fire({
        title: 'Espere un Momento...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      }).then(
         () => {},
        (dismiss) => {
        }
      );

      const resp = await axios(
        {
            method: 'POST',
            url: process.env.REACT_APP_EXTERNAL_PANEL +'/v1/apm-services/citas-masivas',
            headers: {
                'authorization': `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                'x-auth-token': token_user
            },
            data:{
                booking: booking
            }
        }
        ).then(async (res) => {
            Swal.close();
            
            if(res.data.data.length >0){
              if(res.data.data[0].AUTH==='SI'){
                rows = res.data.data.map(row=>{
                  return {placa:row.PLACA, piloto:row.NOMBRE_PILOTO,  pin: row.PINCODE, iso: row.ISO, code: row.CODE_CITA}
                })
                
                 columns = await catalog(res.data.data[0].CODIGO_TRANSPORTE, token_user, res.data.data[0].ESTADO_BOOKING, res.data.data[0].EDITABLE)
  
                if(columns.length > 0){
                  response = {
                    transaction:{
                      codigo_transporte: res.data.data[0].CODIGO_TRANSPORTE, 
                      booking: booking, 
                      fecha_inicio: res.data.data[0].FECHA_INICIO, 
                      cantidad_x_camion: res.data.data[0].CANTIDAD_X_CAMION,
                      cantidad_citas: res.data.data[0].CANTIDAD_CITAS,
                      estado_booking: res.data.data[0].ESTADO_BOOKING,
                      iso: res.data.data[0].ISO,
                      fecha_fin: res.data.data[0].FECHA_FIN,
                      editable: res.data.data[0].EDITABLE
                    }, 
                    columns: columns, 
                    rows: rows
                  }
                }
              }
            }

        })
        .catch(err=>{
            console.log(`Error transporte Axios: ${err}`);
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un Error..',
                text: `Recarge la página Nuevamente`,
                confirmButtonColor: '#FF6203'
            });
        });
        return response;
  }
  catch(e){
    console.log('Error Grid: '+e)
    return response;
  }
}

export default gridCitas;