import React, { Fragment, useState } from 'react'
import { InputsProveedores } from '../tabs/inputsProveedores'
import { TabsProveedores } from '../tabs/tabsProveedores'
import { useFetchValidacionNit } from '../../../../hooks/vinculaciones/useFetchValidacionNit'
import { MensajeProveedor } from './mensajeProveedor'
import Loading from '../loading'

export const PasosVinculacionesProveedores = ({
    useFetchInit,
    servicios,
    urlPeticiones
}) => {
    const { vinculacion } = useFetchInit
    const {
        nit_cliente_vinculacion,
        perfil_seleccionado
    } = vinculacion
    const [activeTab, setActiveTab] = useState('1')

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab)
    }

    const [validacionnit, cargaperfil] = useFetchValidacionNit(
        nit_cliente_vinculacion,
        perfil_seleccionado,
        servicios,
        urlPeticiones
    )

    const { v_informacion, v_mensaje, v_vincular } = validacionnit
    console.log(' validacionnit', validacionnit)
    console.log(' cargaperfil', cargaperfil)

    return (
        <Fragment>
            {cargaperfil ? <Loading /> : ''}
            {v_vincular && v_informacion === false ? (
                <>
                    <TabsProveedores activeTab={activeTab} toggle={toggle} />
                    <InputsProveedores
                        activeTab={activeTab}
                        useFetchInit={useFetchInit}
                    />
                </>
            ) : (
                <MensajeProveedor mensaje={v_mensaje} />
            )}
        </Fragment>
    )
}
