import React, { Fragment } from 'react'
import { Button } from 'reactstrap'

const AddContainer = ({
    valor_boton,
    valor_x,
    v_habilita
    // isValidated,
    // jumpToStep,
    // step1
}) => {
    return (
        <Fragment>
            <div className='col-sm-12'>
                <div className='card'>
                    {v_habilita && (
                        <Button
                            type='button'
                            className='btn btn-warning'
                            onClick={() => {
                                valor_boton('', 'Ingreso de contenedores', 'OPEN_ADDCONTAINER', valor_x)
                            }}
                        >
                            <i className='fa fa-plus-circle'></i> &nbsp Agregar contenedores
                        </Button>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default AddContainer
