import { useState } from 'react'
import { postCambioCredenciales } from '../../helpers/vinculaciones/postCambioCredenciales'
import { validaCampos } from '../../helpers/vinculaciones/validaCampos'
import { useFetchValidaCredenciales } from './useFetchValidaCredenciales'
import { toast } from 'react-toastify'

export const useFetchInitCambioCredenciales = ({
    url_confirmacion,
    servicio_confirma_credenciales,
    token_page,
    codigo_encritado,
    servicio_cambio
}) => {
    /******* boton de pasos *****/

    const [load, setLoad] = useState(false)
    const [error, setError] = useState({
        estado: false,
        mensaje: '',
        input: ''
    })

    /***********************************************************/
    /***********************************************************************************************/
    let initialState = {
        respuesta_api: false,
        mensaje_api: '',
        carga: true,
        perfil_carga: false,
        validacion_campos: false,
        user: '',
        password_actual: '',
        password_nuevo: '',
        password_confirmacion: '',
        credenciales: false,
        codigo_vinculacion: '',
        nit_cliente: '',
        respuesta_credenciales: false
    }

    const [cambio, setCambio] = useState(initialState)

    // eslint-disable-next-line
    const {} = useFetchValidaCredenciales(
        url_confirmacion,
        servicio_confirma_credenciales,
        token_page,
        codigo_encritado,
        cambio,
        setCambio
    )

    const handleChange = event => {
        setCambio({
            ...cambio,
            [event.target.name]: event.target.value
        })
    }

    const capturandovalores = e => {
        setLoad(true)
        e.preventDefault()
        setCambio({ ...cambio, respuesta_api: '' })

        const { user, password_actual, password_nuevo, password_confirmacion } = cambio

        if (!validaCampos(user)) {
            setError({
                estado: true,
                mensaje: 'Agregar nombre de usuario.',
                input: 'user'
            })
            setLoad(false)
            setCambio({
                ...cambio,
                mensaje_api: '',
                respuesta_api: false,
                validacion_campos: false
            })
            return
        }

        if (!validaCampos(password_actual)) {
            setError({
                estado: true,
                mensaje: 'Agregar password actual.',
                input: 'password_actual'
            })
            setLoad(false)
            setCambio({
                ...cambio,
                mensaje_api: '',
                respuesta_api: false,
                validacion_campos: false
            })
            return
        }

        if (!validaCampos(password_nuevo)) {
            setError({
                estado: true,
                mensaje: 'Agregar password nuevo.',
                input: 'password_nuevo'
            })
            setLoad(false)
            setCambio({
                ...cambio,
                mensaje_api: '',
                respuesta_api: false,
                validacion_campos: false
            })
            return
        }

        if (!validaCampos(password_confirmacion)) {
            setError({
                estado: true,
                mensaje: 'Agregar password confirmacion',
                input: 'password_confirmacion'
            })
            setLoad(false)
            setCambio({
                ...cambio,
                mensaje_api: '',
                respuesta_api: false,
                validacion_campos: false
            })
            return
        }

        postCambioCredenciales(cambio, servicio_cambio, url_confirmacion, token_page)
            .then(({ v_mensaje, v_resultado }) => {
                if (v_resultado) {
                    setError('')
                    setLoad(false)
                    setCambio({
                        ...cambio,
                        mensaje_api: v_mensaje,
                        respuesta_api: false,
                        user: '',
                        password_actual: '',
                        password_nuevo: '',
                        password_confirmacion: ''
                    })

                    toast.success(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                } else {
                    setError('')
                    setLoad(false)
                    setCambio({
                        ...cambio,
                        mensaje_api: v_mensaje,
                        respuesta_api: true
                    })
                }
            })
            .catch(error => {
                console.log(error)
                setLoad(false)
            })
    }

    return [cambio, capturandovalores, load, error, handleChange]
}
