import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { Info } from 'react-feather';

const Targets = () => {
    return (
        <Fragment>
            <div className='card'>
                <div className='card-body'>
                    <div className='media'>
                        <Info className='m-r-20' style={{ alignSelf: 'center', color: 'dodgerblue' }} />
                        <div className='media-body'>
                            <h6 className='f-w-600'>{'Términos y Condiciones'}</h6>
                            <p>
                                {
                                    'El uso, transmisión o reproducción por cualquier medio, de la información publicada en esta página y el uso inadecuado de la misma con fines ilícitos o difamatorios, se penalizará conforme a la ley, APMTQ dispone de mecanismos de control de trazabilidad de todas las operaciones realizadas en el portal, las cuales a requerimiento de Autoridades Competentes podrán ser remitidas sin consentimiento previo del Usuario.'
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div> 
        </Fragment>
    );
};

export default Targets;
