import { postEnvioEmailSolicitud } from './postEnvioEmailSolicitud'

export const FetchEnviaCorreos = async ({
    token_user,
    token_page,
    url_enviar_correo,
    solicitud_apex,
    sub_tipo_solicitud,
    setRespuestaSolicitud,
    respuestaSolicitud,
    confirmaSolicitud,
    woMarchamo,
    bloqueoToken
}) => {
    if (confirmaSolicitud || woMarchamo || bloqueoToken) {
        const { v_mensaje, v_resultado, v_estado } = await postEnvioEmailSolicitud(
            token_user,
            token_page,
            url_enviar_correo,
            solicitud_apex,
            sub_tipo_solicitud
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_resultado: false
                }
            })
        console.log('ENVIA CORREOS------------------>', v_resultado, v_mensaje, v_estado)
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            valida_documentos: bloqueoToken ? '' : 'Si tiene agregados los documentos',
            estado_documentos: true,
            confirma_solicitud: 'Se confirmó en la solicitud',
            estado_solicitud: true,
            envio_wo: woMarchamo ? 'Se envio la Work Order' : '',
            estado_wo: woMarchamo ? true : false,
            boqueo_token: bloqueoToken ? 'Bloqueo actualizado' : '',
            estado_bloqueo_token: bloqueoToken ? true : false,
            envia_correo: v_mensaje,
            estado_correo: v_resultado === 'ENVIADO',
            mostrar_respuestas: true
        })
        const enviaCorreo = v_estado === true && v_resultado === 'ENVIADO'
        return { enviaCorreo }
    } else {
        return { enviaCorreo: false }
    }
}
