import React, { Fragment } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalModule = props => {
    const { toggle, tittle, responseData, dataRequest } = props;
    const { CONTENEDOR, VIAJE, BUQUE, CITA_EMPRESA_TRANS } = dataRequest;

    const header = [{ field: 'NOMBRE_EMPRESA', headerName: 'Empresa de transporte', width: 700 }];

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{tittle}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <label className='col-form-label' htmlFor='recipient-name'>
                                <b>Transporte:</b> {CITA_EMPRESA_TRANS}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Contenedor:</b> {CONTENEDOR}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Buque:</b> {BUQUE}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Viaje:</b> {VIAJE}
                            </label>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: 'NOMBRE_EMPRESA',
                                    sort: 'asc',
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
