import React from 'react';
import { Truck } from 'react-feather';

const MainComponent = ({ useFetchInit }) => {
    // const { data, handleInputChange, limpiarFormularioDeComida, agregarSolicitudDeComida, loadingOperation } =
    //     useFetchInit;
    // const { cod_departamento, fecha, tipo_menu, cod_plato } = data;
    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <div className='row'>
                        <Truck className='m-r-20 ml-2' />
                        <div className='h5'>Datos de la empresa</div>
                    </div>
                </div>

                <div className='card-body'>
                    <form>
                        <div className='form-row'>
                            <div className='col'>
                                <label for='codigo-empresa'>Código empresa</label>
                                <input type='email' className='form-control' id='codigo-empresa' />
                            </div>
                            <div className='col'>
                                <label for='inputPassword4'>C.I.F</label>
                                <input type='password' className='form-control' id='inputPassword4' />
                            </div>
                            <div className='col-sm-3'>
                                <label for='menu'>Activo WEB</label>
                                <div className='text-center'>
                                    <div className='checkbox checkbox-solid-warning'>
                                        <input id='solid' type='checkbox' />
                                        <label htmlFor='solid'>(X-Activo)</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='form-group col-md-5'>
                                <label for='codigo-empresa'>Código empresa</label>
                                <input type='email' className='form-control' id='codigo-empresa' />
                            </div>
                            <div className='form-group col-md-5'>
                                <label for='inputPassword4'>C.I.F</label>
                                <input type='password' className='form-control' id='inputPassword4' />
                            </div>
                            <div className='form-group col-md-2'>
                                <label for='menu'>Activo WEB</label>
                                <div className='text-center'>
                                    <div className='checkbox checkbox-solid-warning'>
                                        <input id='solid' type='checkbox' />
                                        <label htmlFor='solid'>(X-Activo)</label>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className='form-row'>
                            <div className='form-group col-md-6'>
                                <label for='nombre-empresa'>Nombre de la empresa</label>
                                <input type='text' className='form-control' id='nombre-empresa' />
                            </div>
                            <div className='form-group col-md-6'>
                                <label for='inputEmail4'>Email</label>
                                <input type='text' className='form-control' id='inputEmail4' />
                            </div>
                        </div>

                        {/*  */}
                        <div className='form-row'>
                            <div className='form-group col-md-6'>
                                <label for='inputEmail4'>Dirección</label>
                                <input type='text' className='form-control' id='inputDirection' />
                            </div>
                            <div className='form-group col-md-3'>
                                <label for='inputCity'>Provincia</label>
                                <input type='text' className='form-control' id='inputProvince' />
                            </div>
                            <div className='form-group col-md-3'>
                                <label for='inputCity'>Ciudad</label>
                                <input type='text' className='form-control' id='inputCity' />
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-sm-6 form-group'>
                                <button
                                    className='btn btn-warning col-sm-12'
                                    type='submit'
                                    // disabled={loadingOperation}
                                >
                                    <i className='icofont icofont-plus' /> Agregar
                                </button>
                            </div>
                            <div className='col-sm-6'>
                                <button
                                    className='btn col-sm-12'
                                    type='button'
                                    // onClick={limpiarFormularioDeComida}
                                >
                                    <i className='icofont icofont-ui-delete' /> Limpiar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default MainComponent;
