import { postWoAlmacen } from './postWoAlmacenaje'

export const FetchWoAlmacenaje = async ({
    token_user,
    token_page,
    url_wo_almacenaje,
    solicitud_apex,
    facturar_a,
    setRespuestaSolicitud,
    respuestaSolicitud,
    confirmaSolicitud
}) => {
    if (confirmaSolicitud) {
        const { v_mensaje, v_resultado, v_estado } = await postWoAlmacen(
            token_user,
            token_page,
            url_wo_almacenaje,
            solicitud_apex,
            facturar_a
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_resultado: false
                }
            })

        console.log(
            '100000000000000000 WO ALMACENAJE----------------------> ',
            v_mensaje,
            v_resultado,
            v_estado
        )
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            valida_fecha: '[ Fecha estimada ingresada:  ]',
            estado_valida_fecha: true,
            confirma_solicitud: 'Se confirmó en la solicitud',
            estado_solicitud: true,
            wo_almacenaje: 'WO enviada',
            estado_wo_almacenaje: true,
            mostrar_respuestas: true
        })
        const envioCorreoAlmacen = v_estado === true && v_resultado === 'true'
        return { envioCorreoAlmacen }
    } else {
        return { envioCorreoAlmacen: false }
    }
}
