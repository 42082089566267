import axios from 'axios'

export const postInsertarCarga = async (
    token_user,
    token_page,
    url_insertar_carga,
    v_solicitud,
    v_contenedor,
    v_tipo,
    v_identificador
) => {
    console.log(`.. post insertar carga`, v_solicitud, v_contenedor, v_tipo, v_identificador)

    const retorno = await axios
        .post(
            url_insertar_carga,
            {
                v_solicitud,
                v_contenedor,
                v_tipo,
                v_identificador
            },
            {
                headers: {
                    'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            console.log(data)
            return {
                v_resultado: data.v_resultado,
                v_mensaje: data.v_mensaje,
                v_estado: true
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_resultado: '',
                    v_mensaje: '500',
                    v_estado: false
                }
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_resultado: '',
                    v_mensaje: '401',
                    v_estado: false
                }
            } else {
                console.log(e.response.data)
                return {
                    v_resultado: '',
                    v_mensaje: 'error',
                    v_estado: false
                }
            }
        })

    return retorno
}
