import axios from 'axios'

export const postEliminarDocUv = async (token_page, servicio_eliminar_documento, cod_documento, url_confirmacion) => {
    // const token_user = 'nada'

    const retorno = await axios
        .post(
            url_confirmacion,
            { servicio: servicio_eliminar_documento, cod_documento_uv: cod_documento },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            console.log(data)
            return {
                v_resultado: data.v_resultado,
                v_mensaje: data.v_mensaje,
                v_estado: true
            }
        })
        .catch(e => {
            console.log(`desde el errro..... `)
            console.log(e)
            console.log(`--------------------------`)
            if (e.response?.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_resultado: false,
                    v_mensaje: 'Error 500, Comunicarse con APM Terminals.',
                    v_estado: false
                }
            } else if (e.response?.status === 401) {
                return {
                    v_resultado: false,
                    v_mensaje: 'Error 401, Comunicarse con APM Terminals.',
                    v_estado: false
                }
            } else {
                return {
                    v_resultado: '',
                    v_mensaje: 'Error, Comunicarse con APM Terminals.',
                    v_estado: false
                }
            }
        })

    return retorno
}
