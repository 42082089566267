import { useState } from 'react'

export const useFetchModalContainer = () => {
    const [obtenercontenedor, setObtenercontenedor] = useState({
        contenedor: ''
    })

    const [cargando, setCargando] = useState(false)
    const [listadoc, setListadoc] = useState([])
    const [respuestad, setRespuestad] = useState([])
    const [estado, setEstado] = useState(false)

    const [obtenerduca, setObtenerDuca] = useState({
        p_resultado: '',
        p_mensaje: '',
        p_duca: ''
    })

    return [
        obtenercontenedor,
        setObtenercontenedor,
        cargando,
        setCargando,
        listadoc,
        setListadoc,
        respuestad,
        setRespuestad,
        estado,
        setEstado,
        obtenerduca,
        setObtenerDuca
    ]
}
