import React, { Fragment, useState, useEffect } from 'react'
import { Button, Table } from 'reactstrap'
import { getDocumentos } from '../../../helpers/solicitudes/getDocumentos'
import { getValidaDocumentos } from '../../../helpers/solicitudes/getValidaDocumentos'
import { postEliminarDocumento } from '../../../helpers/solicitudes/postEliminarDocumento'
import Loading from '../modal-components/loading'

const ListadoDocumentos = ({
    setMostrardocumentos,
    mostrardocumentos,
    token_user,
    token_page,
    url_listado_documentos,
    // url_solicitud,
    solicitud,
    url_eliminar_documentos,
    setButtonEnable,
    // buttonEnable,
    url_validar_documentos,
    ctipos
}) => {
    const [state, setstate] = useState([])
    const [load, setLoad] = useState(false)

    const { solicitud_apex, sub_tipo_solicitud } = ctipos

    useEffect(() => {
        setLoad(true)
        getDocumentos(token_user, token_page, solicitud, url_listado_documentos).then(async resultado => {
            setstate(resultado)
            let total_documentos = resultado.length
            if (total_documentos === 0) {
                ////////// fin validación del subtipo para ver si requiere documentacio´n
                const { v_resultado } = await getValidaDocumentos(
                    token_user,
                    token_page,
                    url_validar_documentos,
                    sub_tipo_solicitud,
                    solicitud_apex
                )
                    .then(res => res)
                    .catch(err => {
                        console.log(err)
                        return {
                            v_estado: false,
                            v_mensaje: 'Error interno del servidor',
                            v_resultado: false
                        }
                    })

                //////// fin validación del subtipo para ver si requiere documentacio´n

                if (v_resultado) {
                    setButtonEnable(false)
                } else {
                    setButtonEnable(true)
                }
            } else {
                setButtonEnable(false)
            }
            setMostrardocumentos(false)
            setLoad(false)
        })
    }, [mostrardocumentos, token_user, token_page, url_listado_documentos, url_eliminar_documentos, solicitud])

    const eliminar_documento = (cod_documento, b) => {
        setMostrardocumentos(true)
        setLoad(true)
        console.log(cod_documento)
        console.log(b)

        postEliminarDocumento(token_user, token_page, url_eliminar_documentos, cod_documento)
            .then(resultado => {
                console.log(resultado)
                console.log(resultado.data)

                // const { v_mensaje, v_resultado } = resultado.data
                setMostrardocumentos(false)
                setLoad(false)
            })
            .catch(error => {
                console.log(error)
                setMostrardocumentos(false)
            })
    }

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-12'>
                <div className='card'>
                    <div className='card-header b-b-warning  border-3'>
                        <h5> Listado de documentos</h5>
                    </div>
                    <div className='card-body'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Accion</th>
                                    <th>Nombre archivo</th>
                                    <th>Mimetype</th>
                                    <th>Fecha creo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.map(cont => {
                                    return (
                                        <tr key={cont.COD_DOCUMENTO_SOLICITUD}>
                                            <td>
                                                <Button
                                                    className='btn btn-warning'
                                                    onClick={() => {
                                                        eliminar_documento(cont.COD_DOCUMENTO_SOLICITUD, 'REPORTE')
                                                    }}
                                                >
                                                    <i className='fa fa-trash-o'></i>
                                                </Button>
                                            </td>
                                            <td>{cont.NOMBRE_ARCHIVO}</td>
                                            <td>{cont.MIMETYPE}</td>
                                            <td>{cont.FECHA_CREO}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    {load && (
                        <div className='mb-4'>
                            <Loading />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default ListadoDocumentos
