import React, { Fragment } from 'react'
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'

export const DatosLegales = ({
    useFetchInit
}) => {
    const {
        setVinculacion,
        vinculacion,
        setBtndisableuv,
        dreferencias,
        dinfobancaria,
        dsocios,
        dcontacto
    } = useFetchInit
    const {
        nombre_comercial,
        input_modalidad,
        razon_social,
        nombre_cliente,
        codigo_pais,
        codigo_ciudad,
        telefono_uno,
        correo_electronico,
        nit_cliente_vinculacion,
        tipo_persona,
        direccion,
        termino_pago,
        representante_legal,
        puesto_legal,
        tipo_documento,
        perfil_seleccionado
    } = vinculacion

    const handleChange = event => {
        setBtndisableuv(true)

        setVinculacion({
            ...vinculacion,
            [event.target.name]: event.target.value
        })

        validar_campos()
    }

    const validar_campos = () => {
        if (perfil_seleccionado === '162') {
            // ------------------ validar proveedores ------------
            if (
                nombre_comercial.trim() !== '' &&
                input_modalidad.trim() !== '' &&
                razon_social.trim() !== '' &&
                nombre_cliente.trim() !== '' &&
                correo_electronico.trim() !== '' &&
                codigo_pais > 0 &&
                codigo_ciudad > 0 &&
                telefono_uno.trim() !== '' &&
                nit_cliente_vinculacion.trim() !== '' &&
                tipo_persona.trim() !== '' &&
                direccion.trim() !== '' &&
                termino_pago.trim() !== '' &&
                representante_legal.trim() !== '' &&
                puesto_legal.trim() !== '' &&
                tipo_documento.trim() !== '' &&
                dreferencias.length > 0 &&
                dinfobancaria.length > 0 &&
                dsocios.length > 0 &&
                dcontacto.length > 0
            ) {
                setBtndisableuv(false)
            } else {
                setBtndisableuv(true)
            }
        } else {
            // ------------------ validar clientes ------------
            if (
                nombre_comercial.trim() !== '' &&
                input_modalidad.trim() !== '' &&
                razon_social.trim() !== '' &&
                nombre_cliente.trim() !== '' &&
                correo_electronico.trim() !== '' &&
                codigo_pais > 0 &&
                codigo_ciudad > 0 &&
                telefono_uno.trim() !== '' &&
                nit_cliente_vinculacion.trim() !== '' &&
                tipo_persona.trim() !== '' &&
                direccion.trim() !== '' &&
                representante_legal.trim() !== '' &&
                puesto_legal.trim() !== '' &&
                tipo_documento.trim() !== '' &&
                dcontacto.length > 0
            ) {
                setBtndisableuv(false)
            } else {
                setBtndisableuv(true)
            }
        }
    }

    validar_campos()

    return (
        <Fragment>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <Label for='representante_legal'>Representante Legal</Label>
                        <Input
                            type='text'
                            name='representante_legal'
                            id='representante_legal'
                            placeholder='Ingresar Representante Legal'
                            onChange={handleChange}
                            value={representante_legal}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='puesto_legal'>Puesto Legal</Label>
                        <Input
                            type='text'
                            name='puesto_legal'
                            id='puesto_legal'
                            placeholder='Puesto Legal'
                            onChange={handleChange}
                            value={puesto_legal}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='tipo_documento'>Indentificación DPI/Pasaporte (Sin Guiones Ni Espacios)</Label>
                        <Input
                            type='text'
                            name='tipo_documento'
                            id='tipo_documento'
                            placeholder='DPI/Pasaporte (Sin Guiones Ni Espacios)'
                            onChange={handleChange}
                            value={tipo_documento}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    )
}
