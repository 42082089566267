import React, { Fragment } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalModule = (props) => {
    const { toggle, title, responseData, dataRequest } = props;
    const header = [
        { field: "SAT_CUSHMAN", headerName: "Manifiesto", width: 180 },
        { field: "SAT_CUSHIMEX", headerName: "Tipo", width: 150 },
        {
            field: "SAT_NODECLARACION",
            headerName: "No. declaración",
            width: 250,
        },
        { field: "SAT_CODIGOESTADO", headerName: "Código", width: 100 },
        { field: "SAT_NOMBREESTADO", headerName: "Estado", width: 150 },
        { field: "SAT_FECHA_ESTADO", headerName: "Fecha", width: 200 },
        { field: "SAT_MENSAJEESTADO", headerName: "Mensaje", width: 900 },
    ];
    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className="container-fluid bd-example-row">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <label
                                className="col-form-label"
                                htmlFor="recipient-name"
                            >
                                <b>Contenedor:</b> {dataRequest.CONTENEDOR}
                            </label>
                        </div>
                    </div>
                    <div style={{ height: 300, width: "100%" }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: "SAT_CUSHMAN",
                                    sort: "asc",
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="gray" onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
