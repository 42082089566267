/**
 * @module hooks/vinculaciones
 * @description useFetchInitVinculaciones: Hook principal
 * para el manejo de las vinculaciones de usuario
 * 
 */
import { useState } from 'react'
import { UseFetchPerfilesUv } from './useFetchPerfilesUv'
import { useFetchModalidad } from './useFetchModalidad'
import { useFetchTipoPersona } from './useFetchTipoPersona'
import { useFetchTerminos } from './useFetchTerminos'

export const useFetchInitVinculaciones = ({
    servicios,
    urlPeticiones
}) => {
    // url_listado_vinculacion,
    // servicio_perfiles_usuario,
    // servicio_modalidad,
    // servicio_tipo_persona,
    // servicio_terminos,
    // token_page,
    const { token_page, url_listado_vinculacion}  = urlPeticiones
    const {
        servicio_perfiles_usuario,
        servicio_modalidad,
        servicio_tipo_persona,
        servicio_terminos
    }  = servicios

    /**
     * @async
     * @function
     * @name module:hooks/vinculaciones#UseFetchPerfilesUv
     * @description UseFetchPerfilesUv: Carga de perfiles.
     * AA, AI, CF, ET etc.
     * @see {@link module:hooks/vinculaciones}
     * @example
     * http://dominio/vinculacion-usuarios
     */
    const [perfiles] = UseFetchPerfilesUv(
        url_listado_vinculacion,
        servicio_perfiles_usuario,
        token_page
    )
    /**
     * @async
     * @function
     * @name module:hooks/vinculaciones#useFetchModalidad
     * @description UseFetchPerfilesUv: Carga de modalidad
     * NAC / EXT: Nacional o extrangero.
     * @see {@link module:hooks/vinculaciones}
     * @example
     * http://dominio/vinculacion-usuarios
     */
    const [modalidad] = useFetchModalidad(
        url_listado_vinculacion,
        servicio_modalidad, 
        token_page
    )
    /**
     * @async
     * @function
     * @name module:hooks/vinculaciones#useFetchTipoPersona
     * @description UseFetchPerfilesUv: Carga tipo de persona
     * IND / JUR: Individual o Juridica
     * @see {@link module:hooks/vinculaciones}
     * @example
     * http://dominio/vinculacion-usuarios
     */
    const [tipopersona] = useFetchTipoPersona(
        url_listado_vinculacion,
        servicio_tipo_persona,
        token_page
    )
    /**
     * @async
     * @function
     * @name module:hooks/vinculaciones#useFetchTerminos
     * @description UseFetchPerfilesUv: Carga de listado
     * de terminos de pago, 00, 5, 8, 10 ... días.
     * @see {@link module:hooks/vinculaciones}
     * @example
     * http://dominio/vinculacion-usuarios
     */
    const [terminos] = useFetchTerminos(
        url_listado_vinculacion,
        servicio_terminos,
        token_page
    )

    // console.log('perfiles ',perfiles);
    // console.log('modalidad ',modalidad);
    // console.log('tipopersona ',tipopersona);
    // console.log('terminos ',terminos);

    /***********************************************************************************************/

    /***************************************** Modal ******************************************************/
    const [modal, setModal] = useState()
    const [componentModal, setComponentModal] = useState('')
    const [titleModal, setTitleModal] = useState('')
    const [dataRequest, setDataRequest] = useState({})

    // estados referencias comerciales
    const [inforeferencias, setInforeferencias] = useState([
        {
            referencia_id: '',
            referencia_empresa: '',
            referencia_telefono: ''
        }
    ])

    const [dreferencias, setDreferencias] = useState([])
    // ---------------------------------------------------------------
    // Inforamación bancaria
    const [infobancaria, setInfobancaria] = useState([
        {
            bancaria_id: '',
            bancaria_nombre_banco: '',
            bancaria_moneda: '',
            bancaria_nombre_moneda: '',
            bancaria_nombre_cuenta: '',
            bancaria_cuenta: ''
        }
    ])

    const [dinfobancaria, setDinfobancaria] = useState([])
    // ---------------------------------------------------------------
    // Información socios
    const [infosocios, setInfosocios] = useState([
        {
            socios_id: '',
            socios_nombre_socio: '',
            socios_participacion: ''
        }
    ])

    const [dsocios, setDsocios] = useState([])
    // ---------------------------------------------------------------
    // estados contactos
    const [infocontacto, setInfocontacto] = useState([
        {
            contacto_id: '',
            contacto_nombre_completo: '',
            contacto_posicion: '',
            contacto_direccion: '',
            contacto_telefono: '',
            contacto_email: '',
            contacto_tipo_contacto: '',
            contacto_nombre_tipo: ''
        }
    ])
    const [dcontacto, setDcontacto] = useState([])
    /***********************************************************************************************/

    /******* boton de pasos *****/
    const [btndisableuv, setBtndisableuv] = useState(true)
    /***********************************************************/

    const [tabscompletos, setTabscompletos] = useState({
        datos_empresariales: false,
        datos_legales: false,
        referencias_comerciales: false,
        informacion_bancaria: false,
        informacion_socios: false,
        contactos: false
    })
    /***********************************************************************************************/
    const [vinculacion, setVinculacion] = useState({
        tipo_solicitud: '',
        sub_tipo_solicitud: '',
        observacion: '',
        en_nombre_de: '',
        facturar_a: '',
        usuario_apex: '',
        solicitud_apex: '',
        mensaje_carga: 'Ingresar un token válido, para poder operar...',
        carga: false,
        respuesta: false,
        perfil_carga: false,
        perfil_seleccionado: '',
        nombre_perfil_seleccionado: '',
        nombre_comercial: '',
        input_modalidad: '',
        razon_social: '',
        nombre_cliente: '',
        codigo_pais: '',
        nombre_pais: '',
        codigo_ciudad: '',
        nombre_ciudad: '',
        telefono_uno: '',
        telefono_dos: '',
        correo_electronico: '',
        nit: '',
        nit_cliente_vinculacion: '',
        sitio_web: '',
        tipo_persona: '',
        direccion: '',
        codigo_postal: '',
        termino_pago: '',
        representante_legal: '',
        puesto_legal: '',
        tipo_documento: '',
        sat_nombre_completo: '',
        sat_nit: '',
        sat_telefono: '',
        sat_telefono2: '',
        sat_correo_electronico: '',
        sat_perfil: '',
        sat_direccion: '',
        sat_puesto: ''
    })

    const handleInputChange = event => {
        console.log('EVENT ', event.target.value)
        if (vinculacion.nit_cliente_vinculacion !== '') {
            setBtndisableuv(false)
        }

        setVinculacion({
            ...vinculacion,
            [event.target.name]: event.target.id,
            nombre_perfil_seleccionado: event.target.value
        })
    }

    const toggle3 = (_, title, compont, dataRequest) => {
        setModal(!modal)
        if (modal) {
            setComponentModal('')
        }
        compont && setComponentModal(compont)
        title && setTitleModal(title)
        dataRequest && setDataRequest(dataRequest)
    }

    const capturandovalores = e => {
        e.preventDefault()
    }

    return {
        perfiles,
        handleInputChange,
        vinculacion,
        setVinculacion,
        modalidad,
        tipopersona,
        modal,
        titleModal,
        toggle3,
        componentModal,
        dataRequest,
        terminos,
        capturandovalores,
        dcontacto,
        setDcontacto,
        infocontacto,
        setInfocontacto,
        inforeferencias,
        setInforeferencias,
        dreferencias,
        setDreferencias,
        infobancaria,
        setInfobancaria,
        dinfobancaria,
        setDinfobancaria,
        infosocios,
        setInfosocios,
        dsocios,
        setDsocios,
        btndisableuv,
        setBtndisableuv,
        tabscompletos,
        setTabscompletos
    }
}
