import React, { Fragment } from 'react';
import { Modal} from 'reactstrap';
import ContainerStatus from './container-status';

const ModalModule = props => {
    const { modal, toggle } = props;
    console.log(props)
    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size={'lg'}>
                <ContainerStatus {...props} />
            </Modal>
        </Fragment>
    );
};

export default ModalModule;
