import React, { Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';

const JobFilter = ({ filtros, setFiltros, setData, categoria, subcategoria, setSubcategoria }) => {
    const [isFilter, setIsFilter] = useState(true);

    const buscarFun = e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setData([]);
            setFiltros({ ...filtros, busqueda: e.target.value });
        }
    };

    const onChangeCategoria = e => {
        setSubcategoria([]);
        setFiltros({ ...filtros, categoria: e.target.value });
    };

    const onChangeSubcategoria = e => {
        setData([]);
        setFiltros({ ...filtros, subcategoria: e.target.value });
    };

    return (
        <Fragment>
            <div className='col-sm-12 col-md-4 col-xl-3'>
                <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h5 className='mb-0'>
                                        <button
                                            className='btn btn-link pl-0'
                                            data-toggle='collapse'
                                            onClick={() => setIsFilter(!isFilter)}
                                            data-target='#collapseicon'
                                            aria-expanded={isFilter}
                                            aria-controls='collapseicon'
                                        >
                                            {'Filtros'}
                                        </button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isFilter}>
                                    <div className='card-body filter-cards-view animate-chk'>
                                        <div className='job-filter'>
                                            <div className='faq-form'>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    placeholder='Buscar boletín'
                                                    onKeyPress={buscarFun}
                                                />
                                                <Search className='search-icon' />
                                            </div>
                                        </div>
                                        <div className='job-filter'>
                                            <div className='faq-form'>
                                                <label className='d-block' htmlFor='chk-ani5'>
                                                    {'Categorías'}
                                                </label>
                                                <select
                                                    className='form-control'
                                                    name='tipo_solicitud'
                                                    onChange={onChangeCategoria}
                                                >
                                                    <option value=''>-- Todos --</option>
                                                    {categoria.map(item => {
                                                        return (
                                                            <option value={item.COD_CLASIFICACION_GRUPO_BOLETI}>
                                                                {item.NOMBRE_CLASIFICACION}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='job-filter'>
                                            <div className='faq-form mt-3'>
                                                <label className='d-block' htmlFor='chk-ani5'>
                                                    {'Sub-categorías'}
                                                </label>
                                                <select
                                                    className='form-control'
                                                    name='tipo_solicitud'
                                                    onChange={onChangeSubcategoria}
                                                >
                                                    <option value=''>-- Ninguna --</option>
                                                    {subcategoria.map(item => {
                                                        return (
                                                            <option value={item.COD_GRUPO_BOLETIN}>
                                                                {item.NOMBRE_GRUPO_BOLETIN}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default JobFilter;
