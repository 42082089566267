import React from 'react'
import styled from '@emotion/styled'

const TituloRed = styled.h5`
    padding: 24px 32px
    background-color: #ff6219
    font-size: 24px
    border-radius: 4px
    color: #ffffff
`

const TituloGreen = styled.h5`
    padding: 24px 32px
    background-color: #00d600
    font-size: 24px
    border-radius: 4px
    color: #ffffff
`

export const MensajeCarga = ({ ctipos }) => {
    console.log('IMPRIMIENDO', ctipos)

    return (
        <div className='container-fluid mt-2 mt-3'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className='card-header'>
                            {ctipos.respuesta ? (
                                <TituloGreen>{ctipos.mensaje_carga}</TituloGreen>
                            ) : (
                                <TituloRed>{ctipos.mensaje_carga}</TituloRed>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
