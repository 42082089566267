import axios from 'axios'

export const getTipoDocumentosClientes = async (
    url_listado_vinculacion,
    servicio_tipo_documento,
    token_page,
    codigo_vinculacion
) => {
    console.log(`.. get tipo documentos cliente ...`)
    const token_user = 'nada'

    const retorno = await axios
        .get(
            `${url_listado_vinculacion}?servicio=${servicio_tipo_documento}&codigo_vinculacion=${codigo_vinculacion}`,
            {
                headers: {
                    'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            console.log(data)
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
