import React, { Fragment } from 'react'
import Instructions from './cards/instructions'
import TituloSolicitudes from './cards/tituloSolicitudes'
import Modal from './modal-components/modal'
import { MensajeCarga } from './cards/mensajeCarga'
import { useFetchInitSolicitudes } from '../../hooks/solicitudes/useFetchInitSolicitudes'
// import Breadcrumb from '../common/breadcrumb'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { Button } from 'reactstrap'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { getStepContent } from './steps/steps'
import ResputestaSolicitud from './cards/respuesta-solicitud'

const IngresoSolicitudes = props => {
    const token_user = props.match.params.tokenaut
    const token_page = process.env.REACT_APP_TOKEN_PAGE
    console.log(props)
    console.log(token_user)

    const url_solicitud = process.env.REACT_APP_URL_SOLICITUD
    const url_ennombre = process.env.REACT_APP_URL_ENNOMBRE
    const url_tipos = process.env.REACT_APP_URL_TIPOS
    const url_subtipos = process.env.REACT_APP_URL_SUBTIPOS
    const url_facturar_a = process.env.REACT_APP_URL_FACTURAR_A
    const url_tipo_carga = process.env.REACT_APP_URL_TIPO_CARGA
    const url_get_contenedores = process.env.REACT_APP_URL_GET_CONTENEDORES

    const url_ducas_asociadas = process.env.REACT_APP_URL_DUCAS_ASOCIADAS
    const url_contenedores_asociados = process.env.REACT_APP_URL_CONTENEDORES_ASOCIADOS
    const url_valida_tipos = process.env.REACT_APP_URL_VALIDA_TIPOS
    const url_estados_d = process.env.REACT_APP_URL_ESTADOS_D
    const url_estados_c = process.env.REACT_APP_URL_ESTADOS_C
    const url_gestores = process.env.REACT_APP_URL_GESTORES
    const url_eliminar_contenedor = process.env.REACT_APP_URL_ELIMINAR_CONTENEDOR
    const url_insert_contenedores_marchamo = process.env.REACT_APP_URL_INSERT_CONTENEDORES_MARCHAMO
    const url_listado_documentos = process.env.REACT_APP_URL_LISTADO_DOCUMENTOS
    const url_insertar_documentos = process.env.REACT_APP_URL_INSERTAR_DOCUMENTOS
    const url_eliminar_documentos = process.env.REACT_APP_URL_ELIMINAR_DOCUMENTOS
    const url_valida_gestor = process.env.REACT_APP_URL_VALIDA_GESTOR
    const url_inserta_contenedores_reintegros = process.env.REACT_APP_URL_INSERTA_CONTENEDORES_REINTEGROS
    const url_update_bloqueo_token = process.env.REACT_APP_URL_UPDATE_BLOQUEO_TOKEN
    const url_inserta_contenedores_aop = process.env.REACT_APP_URL_INSERTA_CONTENEDORES_AOP

    /***************** confirmar solicitudes *********/
    const url_valida_fecha = process.env.REACT_APP_URL_VALIDA_FECHA
    const url_wo_almacenaje = process.env.REACT_APP_URL_WO_ALMACENAJE
    const url_envio_naviera = process.env.REACT_APP_URL_ENVIO_NAVIERA
    const url_envio_correo_aop = process.env.REACT_APP_URL_ENVIO_CORREO_AOP
    const url_validar_documentos = process.env.REACT_APP_URL_VALIDAR_DOCUMENTOS
    const url_confirmar_solicitudes = process.env.REACT_APP_URL_CONFIRMAR_SOLICITUDES
    const url_enviar_correo = process.env.REACT_APP_URL_ENVIAR_CORREO
    const url_enviar_wo = process.env.REACT_APP_URL_ENVIAR_WO

    const url_valida_carga = process.env.REACT_APP_URL_VALIDA_CARGA
    const url_insertar_carga = process.env.REACT_APP_URL_INSERTAR_CARGA
    const url_eliminar_carga = process.env.REACT_APP_URL_ELIMINAR_CARGA
    const url_listar_carga = process.env.REACT_APP_URL_LISTAR_CARGA

    // const url_valida_contenedores = process.env.REACT_APP_URL_VALIDA_CONTENEDORES

    /****** Cargando mi hooks *********/

    const [
        mostrarcontenedor,
        setMostrarcontenedor,
        subtipos,
        // setSubTipos,
        validanit,
        // setValidanit,
        habilitar,
        // setHabilitar,
        handleInputChange,
        ctipos,
        setCtipos,
        modal,
        // setModal,
        titleModal,
        // setTitleModal,
        toggle3,
        capturandovalores,
        componentModal,
        // setComponentModal,
        dataRequest,
        // setDataRequest,
        buttonEnable,
        setButtonEnable,
        msgForm,
        // setMsgForm,
        mostrardocumentos,
        setMostrardocumentos,
        handleNext,
        handleBack,
        handleReset,
        classes,
        activeStep,
        steps,
        sub_tipo_solicitud,
        respuestaSolicitud,
        load
    ] = useFetchInitSolicitudes({
        token_user,
        token_page,
        url_subtipos,
        url_solicitud,
        url_validar_documentos,
        url_confirmar_solicitudes,
        url_enviar_correo,
        url_enviar_wo,
        url_update_bloqueo_token,
        url_envio_naviera,
        url_envio_correo_aop,
        url_valida_fecha,
        url_wo_almacenaje,
        url_valida_carga
    })

    return ctipos.carga ? (
        <Fragment>
            <div className='container-fluid mt-3'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <TituloSolicitudes />
                        <Instructions />
                    </div>

                    <Modal
                        toggle={toggle3}
                        modal={modal}
                        title={titleModal}
                        dataRequest={dataRequest}
                        componentModal={componentModal}
                        statemain={ctipos}
                        v_sol={ctipos.solicitud_apex}
                        token_user={token_user}
                        token_page={token_page}
                        v_show={setMostrarcontenedor}
                        url_get_contenedores={url_get_contenedores}
                        url_ducas_asociadas={url_ducas_asociadas}
                        url_contenedores_asociados={url_contenedores_asociados}
                        url_valida_tipos={url_valida_tipos}
                        url_estados_d={url_estados_d}
                        url_estados_c={url_estados_c}
                        setCtipos={setCtipos}
                        url_gestores={url_gestores}
                        url_valida_gestor={url_valida_gestor}
                        setButtonEnable={setButtonEnable}
                        url_inserta_contenedores_reintegros={url_inserta_contenedores_reintegros}
                        url_inserta_contenedores_aop={url_inserta_contenedores_aop}
                        url_tipo_carga={url_tipo_carga}
                        url_insertar_carga={url_insertar_carga}
                        url_eliminar_carga={url_eliminar_carga}
                        url_listar_carga={url_listar_carga}
                        url_validar_documentos={url_validar_documentos}
                    />

                    <div className='col-sm-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{'Procedimiento para la creación de solicitudes'}</h5>
                            </div>
                            <div className='card-body'>
                                <form onSubmit={capturandovalores}>
                                    <div className={classes.root}>
                                        <Stepper activeStep={activeStep} orientation='vertical'>
                                            {steps.map((label, index) => (
                                                <Step key={label}>
                                                    <StepLabel>{label}</StepLabel>
                                                    <StepContent>
                                                        <Typography>
                                                            {getStepContent({
                                                                index,
                                                                mostrarcontenedor,
                                                                setMostrarcontenedor,
                                                                subtipos,
                                                                // setSubTipos,
                                                                validanit,
                                                                // setValidanit,
                                                                habilitar,
                                                                // setHabilitar,
                                                                handleInputChange,
                                                                ctipos,
                                                                setCtipos,
                                                                // modal,
                                                                // setModal,
                                                                // titleModal,
                                                                // setTitleModal,
                                                                toggle3,
                                                                // capturandovalores,
                                                                // componentModal,
                                                                // setComponentModal,
                                                                // dataRequest,
                                                                // setDataRequest,
                                                                // buttonEnable,
                                                                setButtonEnable,
                                                                msgForm,
                                                                // setMsgForm,
                                                                mostrardocumentos,
                                                                setMostrardocumentos,
                                                                url_eliminar_contenedor,
                                                                token_user,
                                                                token_page,
                                                                url_insertar_documentos,
                                                                url_listado_documentos,
                                                                url_eliminar_documentos,
                                                                // url_solicitud,
                                                                url_get_contenedores,
                                                                // url_valida_gestor,
                                                                url_ennombre,
                                                                url_tipos,
                                                                url_facturar_a,
                                                                sub_tipo_solicitud,
                                                                url_insert_contenedores_marchamo,
                                                                respuestaSolicitud,
                                                                load,
                                                                url_validar_documentos,
                                                                url_valida_carga
                                                            })}
                                                        </Typography>
                                                        <div className={classes.actionsContainer}>
                                                            <div className='col-sm-12 row'>
                                                                <Button
                                                                    outline
                                                                    className='btn mr-3 ml-4'
                                                                    color='warning'
                                                                    disabled={activeStep === 0}
                                                                    onClick={handleBack}
                                                                >
                                                                    Anterior
                                                                </Button>
                                                                <Button
                                                                    disabled={buttonEnable}
                                                                    onClick={handleNext}
                                                                    color='warning'
                                                                >
                                                                    {activeStep === steps.length - 1
                                                                        ? 'Confirmar solcitud'
                                                                        : 'Siguiente'}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </StepContent>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {activeStep === steps.length && (
                                            <Fragment>
                                                <ResputestaSolicitud respuestaSolicitud={respuestaSolicitud} />
                                                <Paper square elevation={0} className={classes.resetContainer}>
                                                    <h6>
                                                        <span className='badge badge-success digits'>
                                                            <i className='fa fa-check-square-o'></i>
                                                            {' No. de solicitud creada: '}
                                                        </span>
                                                        &nbsp&nbsp&nbsp
                                                        {ctipos.solicitud_apex}
                                                    </h6>
                                                    <p>
                                                        Gracias por enviar la solicitud, Puede darle seguimiento a su
                                                        solicitud dentro del menu Consultas - Solicitudes Vigentes al
                                                        concluir el proceso se le estara notificando para que recibira
                                                        un aviso que el proceso a concluido
                                                    </p>
                                                    <Button onClick={handleReset} outline color='warning'>
                                                        ENVIAR OTRA SOLICITUD
                                                    </Button>
                                                </Paper>
                                            </Fragment>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    ) : (
        <MensajeCarga ctipos={ctipos} />
    )
}

export default IngresoSolicitudes
