import React, { Fragment, useState } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { postEliminarDocUv } from '../../../helpers/vinculaciones/postEliminarDocUv';
import { toast } from 'react-toastify';
import Loading from '../loading';

const ModalModule = ({
    toggle,
    dataRequest,
    servicio_eliminar_documento,
    token_page,
    url_confirmacion,
    setMostrardocuv,
    setShowtipodocsuv,
}) => {
    const [loagind, setLoading] = useState(false);
    // const [datos, setDatos] = useState({
    //     sss: '',
    //     sss: '',
    // });

    // const handleInputChange = event => {
    //     setDatos({
    //         ...datos,
    //         [event.target.name]: event.target.value,
    //     });
    // };

    const eliminar_documento = (cod_documento, b) => {
        setMostrardocuv(false);
        setShowtipodocsuv(false);
        console.log(`enviar `, token_page, ' - ', servicio_eliminar_documento, ' - ', cod_documento);
        console.log(b);
        setLoading(true);

        postEliminarDocUv(token_page, servicio_eliminar_documento, cod_documento, url_confirmacion)
            .then(resultado => {
                console.log(resultado);

                const { v_mensaje, v_resultado } = resultado;
                setLoading(false);

                if (v_resultado) {
                    toast.success(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    toggle();
                } else {
                    toast.warning(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    toggle();
                }

                setMostrardocuv(true);
                setShowtipodocsuv(true);
            })
            .catch(error => {
                console.log(error);
                toast.warning('Existe un error, al eliminar el documento.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                toggle();
                setMostrardocuv(true);
                setShowtipodocsuv(true);
            });
    };

    const { COD_DOCUMENTO_UV, NOMBRE_ARCHIVO } = dataRequest.row;

    return (
        <Fragment>
            <form
            // onSubmit={enviarDatos}
            >
                {loagind ? (
                    <div className='mt-3 mb-3'>
                        <Loading />
                    </div>
                ) : (
                    <Fragment>
                        <ModalBody>
                            <Fragment>
                                <div className='container-fluid bd-example-row'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='form-group row'>
                                                <label className='col-sm-12 col-form-label'>
                                                    {'Nombre del archivo:'}
                                                </label>
                                                <div className='col-sm-12'>
                                                    <b>{NOMBRE_ARCHIVO}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col'>
                                            <div className='form-group row'>
                                                <label className='col-sm-12 col-form-label'>{'Archivo:'}</label>
                                                <div className='col-sm-12'>
                                                    <input required className='form-control' type='file' />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </Fragment>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='gray' onClick={toggle}>
                                Cancelar
                            </Button>
                            <Button
                                className='btn btn-warning'
                                onClick={() => {
                                    eliminar_documento(COD_DOCUMENTO_UV, 'REPORTE');
                                }}
                            >
                                <i className='fa fa-trash-o'></i> Eliminar
                            </Button>
                        </ModalFooter>
                    </Fragment>
                )}
            </form>
        </Fragment>
    );
};

export default ModalModule;
