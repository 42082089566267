import { useState, useEffect } from 'react'
import { getTipoDocumentosClientes } from '../../helpers/vinculaciones/getTipoDocumentosClientes'

export const useFetchDocCliente = (
    url_listado_vinculacion,
    servicio_tipo_documento,
    token_page,
    codigo_vinculacion,
    showtipodocsuv
) => {
    const [docclientes, setDocclientes] = useState([]) // tipos

    useEffect(() => {
        getTipoDocumentosClientes(url_listado_vinculacion, servicio_tipo_documento, token_page, codigo_vinculacion)
            .then(resultado => {
                setDocclientes(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {}
    }, [url_listado_vinculacion, servicio_tipo_documento, codigo_vinculacion, token_page, showtipodocsuv])

    return [docclientes]
}
