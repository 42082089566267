import { FetchValidaDocumentos } from '../../helpers/solicitudes/FetchValidaDocumentos'
import { FetchConfirmacionSolicitud } from './../../helpers/solicitudes/FetchConfirmacionSolicitud'
import { FetchEnviaCorreos } from './../../helpers/solicitudes/FetchEnviaCorreos'
import { FetchWoMarchamo } from './../../helpers/solicitudes/FetchWoMarchamo'
import { FetchBloqueoToken } from './../../helpers/solicitudes/FetchBloqueoToken'
import { FetchEnvioNaviera } from './../../helpers/solicitudes/FetchEnvioNaviera'
import { FetchEnvioCorreoAlmacen } from '../../helpers/solicitudes/FetchEnvioCorreoAlmacen'
import { FetchValidaFecha } from '../../helpers/solicitudes/FetchValidaFecha'
import { FetchWoAlmacenaje } from './../../helpers/solicitudes/FetchWoAlmacenaje'
import { FetchValidaCarga } from './../../helpers/solicitudes/FetchValidaCarga'

export const PasosSubTiposSolicitudes = ({
    token_user,
    token_page,
    activeStep,
    ctipos,
    setActiveStep,
    setLoad,
    // buttonEnable,
    setButtonEnable,
    url_validar_documentos,
    url_confirmar_solicitudes,
    url_enviar_correo,
    url_enviar_wo,
    url_update_bloqueo_token,
    setMostrarcontenedor,
    validanit,
    setMostrardocumentos,
    setMsgForm,
    setRespuestaSolicitud,
    respuestaSolicitud,
    url_envio_naviera,
    url_envio_correo_aop,
    url_valida_fecha,
    url_wo_almacenaje,
    url_valida_carga
}) => {
    const {
        en_nombre_de,
        facturar_a,
        solicitud_apex,
        sub_tipo_solicitud,
        tipo_solicitud,
        cod_gestor,
        observacion,
        fechaRetiro
    } = ctipos

    const getSteps = () => {
        let stepsArray = []
        if (sub_tipo_solicitud === '281') {
            stepsArray = [
                'Ingrese la información principal',
                'Agregar Contenedores',
                'Adjunte copia de Póliza',
                'Términos y condiciones',
                'Envíe su solicitud'
            ]
        } else if (sub_tipo_solicitud === '341') {
            stepsArray = [
                'Ingrese la información principal',
                'Agregar Contenedores',
                'Términos y condiciones',
                'Envíe su solicitud'
            ]
        } else if (
            // sub_tipo_solicitud === '101' ||
            sub_tipo_solicitud === '142' ||
            sub_tipo_solicitud === '141'
        ) {
            stepsArray = [
                'Ingrese la información principal',
                'Agregar gestor',
                'Agregar Contenedores',
                'Adjunte copia de BL',
                'Términos y condiciones',
                'Envíe su solicitud'
            ]
        } else if (sub_tipo_solicitud === '61') {
            stepsArray = [
                'Ingrese la información principal',
                'Indique fecha estimada de retiro',
                'Agregar Contenedores',
                'Términos y condiciones',
                'Envíe su solicitud'
            ]
        } else if (sub_tipo_solicitud === '461' || sub_tipo_solicitud === '101') {
            stepsArray = [
                'Ingrese la información principal',
                'Agregar gestor',
                'Agregar Contenedores + No vin/BL',
                'Adjunte copia de BL',
                'Términos y condiciones',
                'Envíe su solicitud'
            ]
        } else {
            stepsArray = [
                'Ingrese la información principal',
                'Agregar gestor',
                'Agregar Contenedores',
                'Adjunte copia de Póliza (DUA/DUT)',
                'Términos y condiciones',
                'Envíe su solicitud'
            ]
        }
        return stepsArray
    }

    const SubtipoNO281 = async () => {
        console.log(`now here`)
        if (
            activeStep === 0 &&
            validanit.t_flag === true &&
            en_nombre_de !== '' &&
            facturar_a !== '' &&
            solicitud_apex !== '' &&
            sub_tipo_solicitud !== '' &&
            tipo_solicitud !== ''
        ) {
            console.log(`Init steps`)
            setButtonEnable(true)
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (cod_gestor !== '' && activeStep === 1) {
            console.log(`que paso es?`)
            setButtonEnable(true)
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 2) {
            console.log(`paso 2`)
            console.log(ctipos)
            setButtonEnable(true)
            setMostrardocumentos(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
            console.log(ctipos)
        } else if (activeStep === 3) {
            console.log(`paso 3`)
            setButtonEnable(true)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 4) {
            console.log(`paso 4`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else {
            console.log(`Others steps`)
            setMsgForm(true)
            setTimeout(() => {
                setMsgForm(false)
            }, 3000)
        }

        if (activeStep === 5) {
            setLoad(true)
            setButtonEnable(true)
            console.log(`diferente a 281`)

            const { documentacionCorrecta } = await FetchValidaDocumentos({
                token_user,
                token_page,
                url_validar_documentos,
                sub_tipo_solicitud,
                solicitud_apex
                // setRespuestaSolicitud,
                // respuestaSolicitud
            })

            const { confirmaSolicitud } = await FetchConfirmacionSolicitud({
                token_user,
                token_page,
                url_confirmar_solicitudes,
                solicitud_apex,
                en_nombre_de,
                tipo_solicitud,
                sub_tipo_solicitud,
                facturar_a,
                cod_gestor,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                documentacionCorrecta
            })

            const { enviaCorreo } = await FetchEnviaCorreos({
                token_user,
                token_page,
                url_enviar_correo,
                solicitud_apex,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            setLoad(false)
            setButtonEnable(false)
            if (enviaCorreo) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
            console.log('SIGUIENTE PASO', enviaCorreo)
            /*********  finaliza confirmar de solicitudes *****************/
        }
    }

    const Subtipo281 = async () => {
        if (
            activeStep === 0 &&
            validanit.t_flag === true &&
            en_nombre_de !== '' &&
            facturar_a !== '' &&
            solicitud_apex !== '' &&
            sub_tipo_solicitud !== '' &&
            tipo_solicitud !== ''
        ) {
            console.log(`Init steps`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
            setMostrarcontenedor(false)
        } else if (activeStep === 1) {
            console.log(`paso 1`)
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 2) {
            console.log(`paso 2`)
            setMostrardocumentos(false)
            setButtonEnable(true)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 3) {
            console.log(`paso 3`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else {
            setMsgForm(true)
            setTimeout(() => {
                setMsgForm(false)
            }, 3000)
        }

        if (activeStep === 4) {
            setLoad(true)
            /*********** inicia confirmación de solicitudes de tipo 281****************/
            const { documentacionCorrecta } = await FetchValidaDocumentos({
                token_user,
                token_page,
                url_validar_documentos,
                sub_tipo_solicitud,
                solicitud_apex
                // setRespuestaSolicitud,
                // respuestaSolicitud
            })

            const { confirmaSolicitud } = await FetchConfirmacionSolicitud({
                token_user,
                token_page,
                url_confirmar_solicitudes,
                solicitud_apex,
                en_nombre_de,
                tipo_solicitud,
                sub_tipo_solicitud,
                facturar_a,
                cod_gestor,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                documentacionCorrecta
            })

            const { woMarchamo } = await FetchWoMarchamo({
                token_user,
                token_page,
                url_enviar_wo,
                solicitud_apex,
                facturar_a,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            const { enviaCorreo } = await FetchEnviaCorreos({
                token_user,
                token_page,
                url_enviar_correo,
                solicitud_apex,
                setRespuestaSolicitud,
                respuestaSolicitud,
                woMarchamo
            })

            setLoad(false)
            setButtonEnable(false)

            if (enviaCorreo) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
            console.log('SIGUIENTE PASO', enviaCorreo)
            /*********  finaliza confirmar de solicitudes de tipo 281  *****************/
        }
    }

    const Subtipo341 = async () => {
        if (
            activeStep === 0 &&
            validanit.t_flag === true &&
            en_nombre_de !== '' &&
            facturar_a !== '' &&
            solicitud_apex !== '' &&
            sub_tipo_solicitud !== '' &&
            tipo_solicitud !== ''
        ) {
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 1) {
            console.log(`paso 2`)
            setButtonEnable(true)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 2) {
            console.log(`paso 3`)
            setMostrardocumentos(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else {
            setMsgForm(true)
            setTimeout(() => {
                setMsgForm(false)
            }, 3000)
        }

        if (activeStep === 3) {
            setLoad(true)
            /*********** inicia confirmación de solicitudes de tipo 281****************/
            const documentacionCorrecta = true //PASO SIGUENTE
            const { confirmaSolicitud } = await FetchConfirmacionSolicitud({
                token_user,
                token_page,
                url_confirmar_solicitudes,
                solicitud_apex,
                en_nombre_de,
                tipo_solicitud,
                sub_tipo_solicitud,
                facturar_a,
                cod_gestor,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                documentacionCorrecta
            })

            const { woMarchamo } = await FetchWoMarchamo({
                token_user,
                token_page,
                url_enviar_wo,
                solicitud_apex,
                // sub_tipo_solicitud,
                facturar_a,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            const { bloqueoToken } = await FetchBloqueoToken({
                token_user,
                token_page,
                url_update_bloqueo_token,
                solicitud_apex,
                setRespuestaSolicitud,
                respuestaSolicitud,
                woMarchamo
            })

            const { enviaCorreo } = await FetchEnviaCorreos({
                token_user,
                token_page,
                url_enviar_correo,
                solicitud_apex,
                setRespuestaSolicitud,
                respuestaSolicitud,
                woMarchamo,
                bloqueoToken
            })

            setLoad(false)
            setButtonEnable(false)

            if (bloqueoToken) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
            console.log('SIGUIENTE PASO', enviaCorreo)
            /*********  finaliza confirmar de solicitudes de tipo 281  *****************/
        }
    }

    const SubtipoAOP = async () => {
        if (
            activeStep === 0 &&
            validanit.t_flag === true &&
            en_nombre_de !== '' &&
            facturar_a !== '' &&
            solicitud_apex !== '' &&
            sub_tipo_solicitud !== '' &&
            tipo_solicitud !== ''
        ) {
            setButtonEnable(true)
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 1) {
            console.log(`paso 2`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 2) {
            console.log(`paso 3`)
            setMostrardocumentos(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 3) {
            console.log(`paso 4`)
            setButtonEnable(true)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 4) {
            console.log(`paso 5`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else {
            setMsgForm(true)
            setTimeout(() => {
                setMsgForm(false)
            }, 3000)
        }
        console.log(`--->>>>>>>>>>>>>>>>>>>> PASO: `, activeStep)
        if (activeStep === 5) {
            setLoad(true)
            /*********** inicia confirmación de solicitudes de tipo 281****************/
            // const documentacionCorrecta = true //PASO SIGUENTE

            const { documentacionCorrecta } = await FetchValidaDocumentos({
                token_user,
                token_page,
                url_validar_documentos,
                sub_tipo_solicitud,
                solicitud_apex
                // setRespuestaSolicitud,
                // respuestaSolicitud
            })

            const { confirmaSolicitud } = await FetchConfirmacionSolicitud({
                token_user,
                token_page,
                url_confirmar_solicitudes,
                solicitud_apex,
                en_nombre_de,
                tipo_solicitud,
                sub_tipo_solicitud,
                facturar_a,
                cod_gestor,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                documentacionCorrecta
            })

            const { envioNaviera } = await FetchEnvioNaviera({
                token_user,
                token_page,
                url_envio_naviera,
                solicitud_apex,
                sub_tipo_solicitud,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            await FetchEnvioCorreoAlmacen({
                token_user,
                token_page,
                url_envio_correo_aop,
                solicitud_apex,
                tipo_solicitud,
                sub_tipo_solicitud,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud,
                envioNaviera
            })

            setLoad(false)
            setButtonEnable(false)

            if (confirmaSolicitud) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
            // console.log('SIGUIENTE PASO', enviaCorreo)
            /*********  finaliza confirmar de solicitudes de tipo 281  *****************/
        }
    }

    const Subtipo61 = async () => {
        if (
            activeStep === 0 &&
            validanit.t_flag === true &&
            en_nombre_de !== '' &&
            facturar_a !== '' &&
            solicitud_apex !== '' &&
            sub_tipo_solicitud !== '' &&
            tipo_solicitud !== ''
        ) {
            setButtonEnable(true)
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 1 && fechaRetiro !== '') {
            console.log(`paso 2`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 2) {
            console.log(`paso 3`)
            setButtonEnable(true)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 3) {
            console.log(`paso 4`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else {
            setMsgForm(true)
            setTimeout(() => {
                setMsgForm(false)
            }, 3000)
        }
        console.log(`--->>>>>>>>>>>>>>>>>>>> PASO: `, activeStep)
        if (activeStep === 4) {
            setLoad(true)
            /*********** inicia confirmación de solicitudes de tipo 281****************/
            // const documentacionCorrecta = true //PASO SIGUENTE

            const { validaFecha } = await FetchValidaFecha({
                token_user,
                token_page,
                url_valida_fecha,
                solicitud_apex,
                facturar_a,
                fechaRetiro,
                setRespuestaSolicitud,
                respuestaSolicitud
            })

            console.log('SIGUIENTE PASO FetchValidaFecha', validaFecha)

            const { confirmaSolicitud } = await FetchConfirmacionSolicitud({
                token_user,
                token_page,
                url_confirmar_solicitudes,
                solicitud_apex,
                en_nombre_de,
                tipo_solicitud,
                sub_tipo_solicitud,
                facturar_a,
                cod_gestor,
                observacion,
                fechaRetiro,
                setRespuestaSolicitud,
                respuestaSolicitud,
                validaFecha
            })

            await FetchEnviaCorreos({
                token_user,
                token_page,
                url_enviar_correo,
                solicitud_apex,
                sub_tipo_solicitud,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            await FetchWoAlmacenaje({
                token_user,
                token_page,
                url_wo_almacenaje,
                solicitud_apex,
                facturar_a,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            // const { envioNaviera } = await FetchEnvioNaviera({
            //     token_user,
            //     token_page,
            //     url_envio_naviera,
            //     solicitud_apex,
            //     sub_tipo_solicitud,
            //     observacion,
            //     setRespuestaSolicitud,
            //     respuestaSolicitud,
            //     confirmaSolicitud,
            // })

            // await FetchEnvioCorreoAlmacen({
            //     token_user,
            //     token_page,
            //     url_envio_correo_aop,
            //     solicitud_apex,
            //     tipo_solicitud,
            //     sub_tipo_solicitud,
            //     setRespuestaSolicitud,
            //     respuestaSolicitud,
            //     confirmaSolicitud,
            //     envioNaviera,
            // })

            setLoad(false)
            setButtonEnable(false)

            if (confirmaSolicitud) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
            // console.log('SIGUIENTE PASO', enviaCorreo)
            /*********  finaliza confirmar de solicitudes de tipo 281  *****************/
        }
    }

    const Subtipo461y101 = async () => {
        if (
            activeStep === 0 &&
            validanit.t_flag === true &&
            en_nombre_de !== '' &&
            facturar_a !== '' &&
            solicitud_apex !== '' &&
            sub_tipo_solicitud !== '' &&
            tipo_solicitud !== ''
        ) {
            setButtonEnable(true)
            setMostrarcontenedor(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 1) {
            console.log(`paso 2`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 2) {
            console.log(`paso 3`)
            setMostrardocumentos(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 3) {
            console.log(`paso 4`)
            setButtonEnable(true)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else if (activeStep === 4) {
            console.log(`paso 5`)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        } else {
            setMsgForm(true)
            setTimeout(() => {
                setMsgForm(false)
            }, 3000)
        }
        console.log(`--->>>>>>>>>>>>>>>>>>>> PASO: `, activeStep)
        if (activeStep === 5) {
            setLoad(true)
            const { validaCarga } = await FetchValidaCarga({
                token_user,
                token_page,
                url_valida_carga,
                solicitud_apex,
                setRespuestaSolicitud,
                respuestaSolicitud
            })

            const { documentacionCorrecta } = await FetchValidaDocumentos({
                token_user,
                token_page,
                url_validar_documentos,
                sub_tipo_solicitud,
                solicitud_apex,
                // setRespuestaSolicitud,
                // respuestaSolicitud,
                validaCarga
            })

            const { confirmaSolicitud } = await FetchConfirmacionSolicitud({
                token_user,
                token_page,
                url_confirmar_solicitudes,
                solicitud_apex,
                en_nombre_de,
                tipo_solicitud,
                sub_tipo_solicitud,
                facturar_a,
                cod_gestor,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                documentacionCorrecta
            })

            const { envioNaviera } = await FetchEnvioNaviera({
                token_user,
                token_page,
                url_envio_naviera,
                solicitud_apex,
                sub_tipo_solicitud,
                observacion,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud
            })

            await FetchEnvioCorreoAlmacen({
                token_user,
                token_page,
                url_envio_correo_aop,
                solicitud_apex,
                tipo_solicitud,
                sub_tipo_solicitud,
                setRespuestaSolicitud,
                respuestaSolicitud,
                confirmaSolicitud,
                envioNaviera
            })

            setLoad(false)
            setButtonEnable(false)

            if (confirmaSolicitud) {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
            }
            // console.log('SIGUIENTE PASO', enviaCorreo)
            /*********  finaliza confirmar de solicitudes de tipo 281  *****************/
        }
    }

    return { getSteps, Subtipo341, Subtipo281, SubtipoNO281, SubtipoAOP, Subtipo61, Subtipo461y101 }
}
