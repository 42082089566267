import React, { Fragment } from "react";
import moment from "moment-timezone";
import binoculares from "../../../assets/images/expediente.png";
// COLOR_FONT
export function headerSiSat({ toggle3 }) {
    return [
        {
            field: "SEGUIMIENTO",
            headerName: "Seguimiento",
            width: 150,
            renderCell: (params) => (
                <strong style={{ marginInline: "auto", fontWeight: "normal" }}>
                    {params.value === "seguimiento" && (
                        <img
                            alt="img"
                            className="imgCursor ml-1"
                            src={binoculares}
                            height="32"
                            width="32"
                            data-placement="top"
                            onClick={() =>
                                toggle3("", "Seguimiento", params.row)
                            }
                        ></img>
                    )}
                </strong>
            ),
        },
        {
            field: "CONTENEDOR",
            headerName: "Contenedor",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "TIPO",
            headerName: "Tipo",
            width: 150,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "NAVIERA",
            headerName: "Naviera",
            width: 300,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "PIES",
            headerName: "Tamaño",
            width: 150,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "TIPO_DE_DESPACHO",
            headerName: "Tipo de Despacho",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "BUQUE_IMPORTACION",
            headerName: "Buque Importación",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "MARCHAMOS",
            headerName: "Marchamos",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "DIAS_DE_PERMANENCIA",
            headerName: "Dias de Permanencia",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "DOCUMENTO_TRANSPORTE",
            headerName: "Documento Tranporte",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "BUQUE_EXPORTACION",
            headerName: "Buque Exportación",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "MANIFIESTO",
            headerName: "Manifiesto",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "IMPORTADOR",
            headerName: "Importador",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "EXPORTADOR",
            headerName: "Exportador",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "EMPRESA_DE_TRANSPORTE",
            headerName: "Empresa de Transporte",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "NUMERO_DECLARACION",
            headerName: "Número de declaración",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "FECHA_TRANSMISION",
            headerName: "Fehca Transmisión",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "REGIMEN",
            headerName: "Régimen",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "MODALIDAD",
            headerName: "Modalidad",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "CLASE",
            headerName: "Clase",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "FECHA_DESCARGA",
            headerName: "Fecha Descarga/Entrada",
            width: 300,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : (
                        <Fragment>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "FECHA_ENTRADA",
            headerName: "Fecha Entrada",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : (
                        <Fragment>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "FECHA_IMPRESION",
            headerName: "Fecha de consulta",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : (
                        <Fragment>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "SITUACION_ADUANERA",
            headerName: "Estado Abandono",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "EMISION_TOKEN",
            headerName: "Emision Token",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : (
                        <Fragment>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "FECHA_CITA_RETIRO",
            headerName: "Fecha Cita Retiro",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </p>
                    ) : (
                        <Fragment>
                            {params.value === null
                                ? ""
                                : moment(params.value)
                                      .tz("America/Guatemala")
                                      .format("YYYY/MM/DD")}
                        </Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "CODIGO_AGENTE",
            headerName: "Código Agente",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "DESCRIPCION_MERCANCIA",
            headerName: "Descripción Mercancia",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "MATRICULA_PREVISTA_LLEGADA",
            headerName: "Matrícula Prevista Llegada",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "ESTADO_DEL_CONTENEDOR",
            headerName: "Estado Del Contenedor",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "SAT_CODIGOESTADO",
            headerName: "SAT Código-estado",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "SAT_NOMBREESTADO",
            headerName: "SAT Nombre-estado",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
        {
            field: "SAT_MENSAJEESTADO",
            headerName: "SAT Mensaje-estado",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.row.COLOR_FONT === "AZUL" ? (
                        <p style={{ color: "blue" }}>{params.value}</p>
                    ) : params.row.COLOR_FONT === "ROJO" ? (
                        <p style={{ color: "red" }}>{params.value}</p>
                    ) : (
                        <Fragment>{params.value}</Fragment>
                    )}
                </strong>
            ),
        },
    ];
}
