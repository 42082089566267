import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { postSaveFile } from '../../../helpers/vinculaciones/postSaveFile';
import Loading from '../loading';

const ModalModule = ({
    toggle,
    docclientes,
    documentacion,
    url_confirmacion,
    servicio_subida_documento,
    token_page,
    url_save_files,
    setShowtipodocsuv,
    setMostrardocuv,
}) => {
    const { codigo_vinculacion } = documentacion;

    const [loagind, setLoading] = useState(false);

    const [datos, setDatos] = useState({
        tipo_documento: '',
        sss: '',
    });

    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('Choose File');

    const onChange = e => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setFilename(e.target.files[0].name);
        }
    };

    const handleInputChange = event => {
        console.log(`desde el handle input change`);
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };

    const { tipo_documento } = datos;
    // console.log(datos);

    const adjuntar_documentos_cliente = (id, b) => {
        console.log(`Desde adjuntar documentos...`);
        console.log(id);
        console.log(b);
        // const arrayFiltrado = dreferencias.filter(item => {
        //     return item.referencia_id !== id;
        // });

        // setDreferencias(arrayFiltrado);

        // validar_campos();
    };

    const enviarDatos = e => {
        e.preventDefault();
        console.log(`Desde enviar datos ...`);
        setShowtipodocsuv(false);
        setMostrardocuv(false);
        // event.preventDefault();

        if (file === '' || file === undefined) {
            toast.warning('Por favor, adjuntar documento.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }

        if (tipo_documento === '' || tipo_documento === undefined) {
            toast.warning('Por favor, elegir tipo documento.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }

        const formData = new FormData();

        console.log(file);
        formData.append('file', file);
        formData.append('servicio', servicio_subida_documento);
        formData.append('cod_vinculacion', codigo_vinculacion);
        formData.append('tipo_documento', tipo_documento);

        // ------------------- SUBIDA DE ARCHIVOS

        console.log(`Aqui la subida de archivos`);

        postSaveFile(
            formData,
            url_save_files,
            token_page,
            servicio_subida_documento,
            codigo_vinculacion,
            tipo_documento
        )
            .then(resultado => {
                console.log(`Aqui el resultado`);
                console.log(resultado);

                const { v_mensaje } = resultado;
                setShowtipodocsuv(true);
                setMostrardocuv(true);
                toast.success(v_mensaje, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                toggle();
            })
            .catch(error => {
                console.log(`Existe un error`);
                console.log(error);
                setShowtipodocsuv(true);
                setMostrardocuv(true);
                toast.warning('Existe un error, al adjuntar el documento.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                toggle();
            });

        // ------------------- FIN SUBIDA DE ARCHIVOS
    };

    console.log(documentacion);
    console.log(docclientes);

    return (
        <Fragment>
            <form onSubmit={enviarDatos}>
                {loagind ? (
                    <div className='mt-3 mb-3'>
                        <Loading />
                    </div>
                ) : (
                    <Fragment>
                        <ModalBody>
                            <Fragment>
                                <div className='container-fluid bd-example-row'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='form-group row'>
                                                <label className='col-sm-12 col-form-label'>
                                                    {'Tipo de documento:'}
                                                </label>
                                                <div className='col-sm-12'>
                                                    <select
                                                        // required
                                                        className='custom-select form-control'
                                                        name='tipo_documento'
                                                        id='tipo_documento'
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value=''>-- Seleccionar tipo documento --</option>
                                                        {docclientes.map(valor => {
                                                            return (
                                                                <option
                                                                    key={valor.COD_TIPO_DOCUMENTO}
                                                                    value={valor.COD_TIPO_DOCUMENTO}
                                                                >
                                                                    {valor.NOMBRE_DOCUMENTO}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='form-group row'>
                                                <label className='col-sm-12 col-form-label'>{'Archivo:'}</label>
                                                <div className='col-sm-12'>
                                                    <input
                                                        // required
                                                        className='form-control'
                                                        type='file'
                                                        name='foo2'
                                                        id='customFile2'
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='gray' onClick={toggle}>
                                Cancelar
                            </Button>
                            <Button className='btn btn-warning' type='submit'>
                                Crear
                            </Button>
                        </ModalFooter>
                    </Fragment>
                )}
            </form>
        </Fragment>
    );
};

export default ModalModule;
