import axios from 'axios'

export const getValidaCarga = async (token_user, token_page, url_valida_carga, v_solicitud) => {
    const retorno = await axios
        .get(`${url_valida_carga}?v_solicitud=${v_solicitud}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            console.log(data)
            return {
                v_resultado: data.v_resultado,
                v_mensaje: data.v_mensaje,
                v_estado: true
            }
        })
        .catch(e => {
            console.log(e)
            return {
                v_resultado: '',
                v_mensaje: 'There was a problem with the server',
                v_estado: false
            }
            // if (e.response.status === 500) {
            //     console.log('There was a problem with the server')
            //     return {
            //         v_resultado: '',
            //         v_mensaje: '',
            //         v_estado: false,
            //     }
            // } else if (e.response.status === 401) {
            //     console.log(`No hay autorización`)
            //     return {
            //         v_resultado: '',
            //         v_mensaje: '',
            //         v_estado: false,
            //     }
            // } else {
            //     console.log(e.response.data)
            //     return {
            //         v_resultado: '',
            //         v_mensaje: '',
            //         v_estado: false,
            //     }
            // }
        })

    console.log(retorno)
    return retorno
}
