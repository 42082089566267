import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Load from './loading';
const ModalModule = _props => {
    const { modal, toggle, title, dataRequest, props, setMsg, msg } = _props;
    const [color, setColor] = useState('verde');
    const [load, setLoad] = useState(false);
    const { CONTENEDOR } = dataRequest;

    const sendRequest = async () => {
        setLoad(true);
        await axios
            .post(
                process.env.REACT_APP_IAP_SEGUIMIENTO,
                {
                    v_contenedor: CONTENEDOR,
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                        'x-auth-token': props.match.params.token,
                    },
                }
            )
            .then(res => {
                setColor('verde');
                setMsg('Seguimiento agregado con exito');
            })
            .catch(error => {
                setMsg(error.response.data.msg);
                setColor('red');
            });
        setLoad(false);
    };

    const enviarDatos = event => {
        event.preventDefault();
        sendRequest();
    };

    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true}>
                <form onSubmit={enviarDatos}>
                    <ModalHeader toggle={toggle}>{title}</ModalHeader>
                    <ModalBody>
                        <div className='container-fluid bd-example-row'>
                            <div className='row'>
                                <div className='col-md-12 mb-4'>
                                    <label className='col-form-label' htmlFor='recipient-name'>
                                        <b>Contenedor a notificar: {CONTENEDOR}</b>
                                    </label>
                                </div>
                                <div className='col-md-12 mb-4'>
                                    <label
                                        className='col-form-label'
                                        htmlFor='recipient-name'
                                        style={{ color: `${color}` }}
                                    >
                                        {load ? <Load /> : msg}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='gray' onClick={toggle}>
                            Cancelar
                        </Button>
                        <Button className='btn btn-warning' type='submit'>
                            Agregar Seguimiento
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </Fragment>
    );
};

export default ModalModule;
