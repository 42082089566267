import { useState, useEffect } from 'react'
import { getTipoPersona } from '../../helpers/vinculaciones/getTipoPersona'

export const useFetchTipoPersona = (url_listado_vinculacion, servicio_tipo_persona, token_page) => {
    const [tipopersona, setTipopersona] = useState([]) // tipos

    useEffect(() => {
        getTipoPersona(url_listado_vinculacion, servicio_tipo_persona, token_page)
            .then(resultado => {
                setTipopersona(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `, error)
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [servicio_tipo_persona, url_listado_vinculacion, token_page])

    return [tipopersona]
}
