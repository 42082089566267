import React, { Fragment } from 'react'
import { Modal } from 'reactstrap'
import ModalAddContainer from './modaladdcontainer'
import ModalGestor from './modalgestor'
import ModalCarga from './modalCarga'

const ModalModule = props => {
    const {
        toggle,
        modal,
        title,
        dataRequest,
        componentModal,
        statemain,
        // v_sol,
        token_user,
        token_page,
        v_show,
        // url_get_contenedores,
        url_ducas_asociadas,
        url_contenedores_asociados,
        url_valida_tipos,
        url_estados_d,
        url_estados_c,
        setCtipos,
        url_gestores,
        url_valida_gestor,
        setButtonEnable,
        url_inserta_contenedores_reintegros,
        url_inserta_contenedores_aop,
        url_tipo_carga,
        url_insertar_carga,
        url_eliminar_carga,
        url_listar_carga
        // url_validar_documentos,
    } = props

    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size={'lg'}>
                {componentModal === 'OPEN_GESTOR' ? (
                    <ModalGestor
                        token_user={token_user}
                        token_page={token_page}
                        toggle={toggle}
                        modal={modal}
                        title={title}
                        dataRequest={dataRequest}
                        url_gestores={url_gestores}
                        ctipos={statemain}
                        setCtipos={setCtipos}
                        url_valida_gestor={url_valida_gestor}
                        setButtonEnable={setButtonEnable}
                    />
                ) : componentModal === 'INSERTA_CARGA' ? (
                    <ModalCarga
                        token_user={token_user}
                        token_page={token_page}
                        toggle={toggle}
                        modal={modal}
                        title={title}
                        dataRequest={dataRequest}
                        url_gestores={url_gestores}
                        ctipos={statemain}
                        setCtipos={setCtipos}
                        url_valida_gestor={url_valida_gestor}
                        setButtonEnable={setButtonEnable}
                        url_tipo_carga={url_tipo_carga}
                        url_insertar_carga={url_insertar_carga}
                        url_eliminar_carga={url_eliminar_carga}
                        url_listar_carga={url_listar_carga}
                        v_show={v_show}
                    />
                ) : (
                    componentModal === 'OPEN_ADDCONTAINER' && (
                        <ModalAddContainer
                            token_user={token_user}
                            token_page={token_page}
                            toggle={toggle}
                            // modal={modal}
                            title={title}
                            // dataRequest={dataRequest}
                            statemain={statemain}
                            // v_sol={v_sol}
                            v_show={v_show}
                            // url_get_contenedores={url_get_contenedores}
                            url_ducas_asociadas={url_ducas_asociadas}
                            url_contenedores_asociados={url_contenedores_asociados}
                            url_valida_tipos={url_valida_tipos}
                            url_estados_d={url_estados_d}
                            url_estados_c={url_estados_c}
                            url_inserta_contenedores_reintegros={url_inserta_contenedores_reintegros}
                            url_inserta_contenedores_aop={url_inserta_contenedores_aop}
                        />
                    )
                )}
            </Modal>
        </Fragment>
    )
}

export default ModalModule
