import React, { Fragment } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment-timezone";
const ModalModule = (props) => {
    const { toggle, title, responseData, dataRequest } = props;
    const header = [
        {
            field: "ENTIDAD_SOLICITANTE_RETENCION",
            headerName: "Entidad solicitante retención",
            width: 200,
        },
        {
            field: "FECHA_RETENCION",
            headerName: "Fecha retención",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.value === null
                        ? ""
                        : moment(params.value)
                              .tz("America/Guatemala")
                              .format("YYYY/MM/DD")}
                </strong>
            ),
        },
        {
            field: "FECHA_LIBERACION",
            headerName: "Fecha liberación",
            width: 200,
            renderCell: (params) => (
                <strong style={{ fontWeight: "normal" }}>
                    {params.value === null
                        ? ""
                        : moment(params.value)
                              .tz("America/Guatemala")
                              .format("YYYY/MM/DD")}
                </strong>
            ),
        },
        {
            field: "ESTADO_RETENCION",
            headerName: "Estado retención",
            width: 200,
        },
        { field: "CONTENEDOR", headerName: "Contenedor", width: 200 },
    ];
    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className="container-fluid bd-example-row">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <label
                                className="col-form-label"
                                htmlFor="recipient-name"
                            >
                                <b>Contenedor:</b> {dataRequest.CONTENEDOR}
                            </label>
                        </div>
                    </div>
                    <div style={{ height: 300, width: "100%" }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: "ENTIDAD_SOLICITANTE_RETENCION",
                                    sort: "asc",
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="gray" onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
