import React from 'react'
import { ConfirmandoVinculacion } from './confirmandoVinculacion'
import { EvaluandoPerfil } from './evaluandoPerfil'
import { IniciarVinculacion } from './iniciarVinculacion'

export const StepsVinculaciones = ({
    useFetchInit,
    activeStep: stepIndex,
    mensajevinculacion,
    servicios,
    urlPeticiones
}) => {
    switch (stepIndex) {
    case 0:
        return (
            <IniciarVinculacion useFetchInit = {useFetchInit} 
            />
        )
    case 1:
        return EvaluandoPerfil({
            useFetchInit,
            servicios,
            urlPeticiones                
        })
    case 2:
        return (
            <ConfirmandoVinculacion
                mensajevinculacion={mensajevinculacion}
            />
        )
    default:
        return 'Unknown stepIndex'
    }
}
