import React from 'react'
import { Container} from 'reactstrap'
import { Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import { registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);

const BarGraphic = (props) => {
    const { pad } = props
    const dat = props.data
    return (
        <Container className="tituloGraficaBarras">
            {dat?(<>
            <div>
                <h3>{`Yard Density - ${dat[0]?.year || '' }`}</h3>
            </div>
               <Bar className="CustomCssBar"
                   data={{
                       labels: dat.map((r) => r.week),
                       datasets: [{
                           label: 'Density Percentage',
                           data: dat.map((r) => r.percentage),
                           backgroundColor: 'rgba(252,100,28,1)',
                           stack: 'Stack 0',
                       }
                    ]
                   }}
                   plugins={[ChartDataLabels]}
                   options={{
                    responsive: false,
                    interaction: {
                        intersect: false,
                    },
                    plugins: {
                        datalabels: {
                         display: true,
                         align: 'center',
                         font: {
                                weight: 'bold', 
                                size: 13
                         },
                         color: 'white',
                         formatter: function(value, context) {
                            console.log('c', context)
                            return `${pad(context.dataset.data[context.dataIndex],2)}%`
                         }
                        },
                        tooltip: {
                            callbacks: {
                              label: function(value) {
                                return `${value.dataset.label}: ${pad(value.formattedValue,2)}%`
                            }
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true,
                                callback: function(value) {
                                    return pad(value) + "%"
                                }
                            },
                            max: 100,
                            scaleLabel: {
                                display: true,
                                labelString: "Percentage"
                            }
                        }
                    }
                   }
                }
                   height = {550}
                   width = {1100}
               />
               </>):null
        }
        </Container>
    )
}

export default BarGraphic;