import React, {useContext} from 'react';
import {TableContainer, TableHead, TableBody, TableRow, Table, TableCell} from '@material-ui/core';
import {multiStepContext} from '../steps/StepContext';
import {Col, Container} from 'react-bootstrap';


export default function DisplayData() {
    const {finalData, invoiceDetail} = useContext(multiStepContext);
    const data=invoiceDetail[0].description;
    const detail  =data.detail;

    return (
        <Container>
            <TableContainer >
                <Table border="1" size="small">
                    <TableHead>  
                        <TableRow style={{backgroundColor: '#004165', color: '#FFFFFF', fontWeight: 'bold'}}>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>ID Factura</TableCell>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>Referencia</TableCell>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>Contenedor</TableCell>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>Descripcion</TableCell>
                            <TableCell style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>Monto</TableCell>
                        </TableRow>
                    </TableHead>     
                    <TableBody>
                        {detail.map((dataDetail)=>(
                            <TableRow key={dataDetail.id}>
                                <TableCell>{data.idPago}</TableCell>
                                <TableCell>{data.reference}</TableCell>
                                <TableCell>{dataDetail.container}</TableCell>
                                <TableCell>{dataDetail.description}</TableCell>
                                <TableCell>--</TableCell>
                            </TableRow>
                        ))}
                        <TableRow key={data.reference}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{textAlign:'right', fontWeight: 'bold'}}>Total:</TableCell>
                            <TableCell style={{fontWeight: 'bold'}}>{data.total}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}