import axios from 'axios'

export const GetValidaNit = async (p_user, p_token, p_vinculacion) => {
    if (p_user.trim() === '') {
        console.log(`No hacer nada`)
    } else {
        const url = process.env.REACT_APP_URL_VALIDA_NIT
        const { data } = await axios.get(url, {
            headers: {
                'x-auth-token': p_user,
                Authorization: `Basic ${p_token}`,
                cod_vinculacion: p_vinculacion
            }
        })
        return data
    }
}
