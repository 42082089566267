import axios from 'axios'

export const getMoneda = async (url_listado_vinculacion, servicio_moneda, token_page) => {
    const token_user = 'nada'

    const retorno = await axios
        .get(`${url_listado_vinculacion}?servicio=${servicio_moneda}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
