import { useState } from 'react'
import { useFetchPerfilesSat } from './useFetchPerfilesSat'
import { postVincularAutoridad } from '../../helpers/vinculaciones/postVincularAutoridad'

export const useFetchIniSat = ({
    url_listado_vinculacion,
    servicio_perfiles_usuario,
    url_confirmacion,
    servicio_vinculacion,
    token_page
}) => {
    const [perfiles] = useFetchPerfilesSat(url_listado_vinculacion, servicio_perfiles_usuario, token_page)

    /******* boton de pasos *****/

    const [load, setLoad] = useState(false)
    const [error, setError] = useState(null)

    /***********************************************************/
    /***********************************************************************************************/
    const [vinculacion, setVinculacion] = useState({
        respuesta_api: '',
        carga: false,
        respuesta: false,
        perfil_carga: false,
        sat_nombre_completo: '',
        sat_nit: '',
        sat_telefono: '',
        sat_telefono2: '',
        sat_correo_electronico: '',
        sat_perfil: '',
        sat_direccion: '',
        sat_puesto: ''
    })

    const capturandovalores = e => {
        setLoad(true)
        setVinculacion({ ...vinculacion, respuesta_api: '' })
        e.preventDefault()

        const {
            sat_correo_electronico,
            sat_direccion,
            sat_nit,
            sat_nombre_completo,
            sat_perfil,
            sat_puesto,
            sat_telefono
            // sat_telefono2,
        } = vinculacion

        if (sat_nombre_completo === '' || sat_nombre_completo === undefined) {
            setError('Agregar Nombre.')
            setLoad(false)
            return
        }

        if (sat_nit === '' || sat_nit === undefined) {
            setError('Agregar Nit.')
            setLoad(false)
            return
        }

        if (sat_telefono === '' || sat_telefono === undefined) {
            setError('Agregar número de teléfono.')
            setLoad(false)
            return
        }

        if (sat_correo_electronico === '' || sat_correo_electronico === undefined) {
            setError('Agregar correo electrónico.')
            setLoad(false)
            return
        }

        if (sat_perfil === '' || sat_perfil === undefined) {
            setError('Seleccionar perfil de usuario.')
            setLoad(false)
            return
        }

        if (sat_direccion === '' || sat_direccion === undefined) {
            setError('Agregar dirección.')
            setLoad(false)
            return
        }

        if (sat_puesto === '' || sat_puesto === undefined) {
            setError('Agregar puesto.')
            setLoad(false)
            return
        }

        //----- confirmar solicitud
        postVincularAutoridad(vinculacion, servicio_vinculacion, url_confirmacion, token_page)
            .then(({ v_mensaje, v_resultado }) => {
                if (v_resultado === 'true') {
                    console.log(` Dio true...`)
                    setError('')
                    setLoad(false)
                    setVinculacion({
                        ...vinculacion,
                        respuesta_api: v_mensaje,
                        sat_correo_electronico: '',
                        sat_direccion: '',
                        sat_nit: '',
                        sat_nombre_completo: '',
                        sat_perfil: '',
                        sat_puesto: '',
                        sat_telefono: '',
                        sat_telefono2: ''
                    })
                } else {
                    console.log(` dio error false`)
                    setError('')
                    setLoad(false)
                    setVinculacion({ ...vinculacion, respuesta_api: v_mensaje })
                }
            })
            .catch(error => {
                console.log(`Existe un error `,error)
                setLoad(false)
            })

        // finaliza post --------------------------------------
    }

    return [vinculacion, setVinculacion, capturandovalores, perfiles, load, error]
}
