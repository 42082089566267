import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Info } from 'react-feather';

const Targets = () => {
    return (
        <Fragment>
            <div className='card'>
                <div className='card-body'>
                    <div className='media'>
                        <Info className='m-r-20' style={{ alignSelf: 'center', color: 'dodgerblue' }} />
                        <div className='media-body'>
                            <h6 className='f-w-600'>{'Información'}</h6>
                            <p>
                                {
                                    'Para seguir con el proceso de vinculación en APMTQ, debe de subir los documentos requeridos (Carta, RTU y DPI) del representante legal o persona individual el campo "Agregar documento" y luego presione en "Finalizar Documentación"'
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Targets;
