import React from 'react'
import LoadingTable from './loading-line'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { Trash2, Edit3 } from 'react-feather'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css'
const TableComponent = ({ useFetchInit }) => {
    // const { loadingOperation } = useFetchInit;
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='outer'>
                        <div className='ag-theme-alpine' style={{ height: 350, width: '100%' }}>
                            {/* {loadingOperation && <LoadingTable />} */}

                            <AgGridReact
                                paginationPageSize={5}
                                modules={AllModules}
                                defaultColDef={{
                                    editable: false,
                                    sortable: true,
                                    resizable: true,
                                }}
                                suppressRowClickSelection={true}
                                rowSelection={'multiple'}
                                enableRangeSelection={true}
                                pagination={true}
                                rowData={[
                                    {
                                        NIT_ASISTENTE: '9592326',
                                        NOMBRE_EMPRESA: 'EMPRESA RIOS DEL MONTE',
                                        DIRECCION: 'Calle solis, 12, Avenida 5',
                                        FECHA: '10/20/22',
                                    },
                                ]}
                                cacheBlockSize={100}
                                maxBlocksInCache={10}
                                blockLoadDebounceMillis={1000}
                                animateRows={true}
                            >
                                <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='ETA'
                                    field='ETA'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                                <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='BUQUE'
                                    field='DIRECCION'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                                <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='MOVIMIENTOS'
                                    field='FECHA'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                                <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='OBSERVACIONES'
                                    field='FECHA'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableComponent
