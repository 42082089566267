import React, { Fragment } from 'react'
import Loader from './loader'
const Select = ({ getTimer, timer, timerAwait }) => {
    return (
        <Fragment>
            <div className='col-sm-12 col-md-3 col-xl-2'>
                <div className='form-group row mb-0'>
                    <div className='col-sm-12'>
                        <select className='custom-select form-control' onChange={e => getTimer(e.target.value)}>
                            <option value=''>{'[TODOS]'}</option>
                            <option value='DIPAFRONT'>{'DIPAFRONT'}</option>
                            <option value='MAGA'>{'MAGA'}</option>
                            <option value='ROJO'>{'SELECTIVO ROJO'}</option>
                            <option value='SGAIA'>{'SGAIA'}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='col-sm-8 col-md-6  col-xl-8 text-right'>
                <p className='h1 txt-warning'>
                    <small>{'Tiempo estimado de Espera Pegado a Rampa'}</small>
                </p>
            </div>
            <div className='col-sm-4 col-md-3 col-xl-2 text-left'>
                {!timerAwait ? (
                    <p className='h1 txt-warning'>
                        <small>{`${timer.p2}d ${timer.p3}h ${timer.p4}m`}</small>
                    </p>
                ) : (
                    <Loader />
                )}
            </div>
        </Fragment>
    )
}

export default Select
