import { useState } from 'react'
// import { postCambioCredenciales } from '../../helpers/vinculaciones/postCambioCredenciales'
// import { validaCampos } from '../../helpers/vinculaciones/validaCampos'
import { useFetchValidaEncriptacion } from './useFetchValidaEncriptacion'
// import { toast } from 'react-toastify'
import { useFetchDocCliente } from './useFetchDocCliente'
import { useFetchListDocCliente } from './useFetchListDocCliente'

export const useFetchInitDocumentosVinculacion = ({
    url_confirmacion,
    servicio_confirma_credenciales,
    token_page,
    codigo_encritado,
    url_listado_vinculacion,
    // servicio_subida_documento,
    // servicio_editar_documento,
    servicio_tipo_documento,
    servicio_listado_documentos
}) => {
    /******* boton de pasos *****/

    const [mostrardocuv, setMostrardocuv] = useState(false)
    const [showtipodocsuv, setShowtipodocsuv] = useState(false)

    const [load, setLoad] = useState(false)
    const [error, setError] = useState({
        estado: false,
        mensaje: '',
        input: ''
    })

    /***********************************************************/
    /***********************************************************************************************/

    const [documentacion, setDocumentacion] = useFetchValidaEncriptacion({
        url_confirmacion,
        servicio_confirma_credenciales,
        token_page,
        codigo_encritado
    })

    const { codigo_vinculacion } = documentacion
    // console.log(codigo_vinculacion)

    // Mostrar la información en un select list con los registros pendientes del usuario.
    const [docclientes] = useFetchDocCliente(
        url_listado_vinculacion,
        servicio_tipo_documento,
        token_page,
        codigo_vinculacion,
        showtipodocsuv
    )

    // Para mostrar reporte de Listado de documentos agregados por el cliente.
    const [listdoc] = useFetchListDocCliente(
        url_listado_vinculacion,
        servicio_listado_documentos,
        token_page,
        codigo_vinculacion,
        mostrardocuv
    )

    //////////////////////////////////////////////////////////////

    const handleChange = event => {
        setDocumentacion({
            ...documentacion,
            [event.target.name]: event.target.value
        })
    }

    const capturandovalores = e => {
        console.log(`Dentro de capturando valores ...`)
        // setLoad(true)
        e.preventDefault()
    }

    return [
        documentacion,
        setDocumentacion,
        capturandovalores,
        load,
        error,
        handleChange,
        listdoc,
        docclientes,
        setLoad,
        mostrardocuv,
        setMostrardocuv,
        showtipodocsuv,
        setShowtipodocsuv,
        setError
    ]
}
