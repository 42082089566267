import React, { Fragment, useState } from 'react'
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Table } from 'reactstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useFetchModalContainer } from '../../../hooks/solicitudes/useFetchModalContainer'
import { useFetchInsertEstadosD } from '../../../hooks/solicitudes/useFetchInsertEstadosD'
import { postInsertaContenedoresReintegro } from './../../../helpers/solicitudes/postInsertaContenedoresReintegro'
import { postInsertaContenedoresAOP } from './../../../helpers/solicitudes/postInsertaContenedoresAOP'
import Loading from './loading'

const ModalAddContainer = props => {
    const {
        token_user,
        token_page,
        toggle,
        // modal,
        title,
        // dataRequest,
        statemain,
        // v_sol,
        v_show,
        // url_get_contenedores,
        url_ducas_asociadas,
        url_contenedores_asociados,
        url_valida_tipos,
        url_estados_d,
        url_estados_c,
        url_inserta_contenedores_reintegros,
        url_inserta_contenedores_aop
    } = props

    const { tipo_solicitud, sub_tipo_solicitud, solicitud_apex, usuario_apex } = statemain

    const [
        obtenercontenedor,
        setObtenercontenedor,
        // cargando,
        setCargando,
        listadoc,
        setListadoc,
        respuestad,
        setRespuestad,
        estado,
        setEstado,
        obtenerduca,
        setObtenerDuca
    ] = useFetchModalContainer()

    const [insertar_estados_d] = useFetchInsertEstadosD(
        token_user,
        token_page,
        setEstado,
        setRespuestad,
        v_show,
        url_estados_d
    )
    const [load, setLoad] = useState(false)

    const consultar_contenedor = () => {
        setLoad(true)
        setEstado(false)
        setCargando(true)
        setObtenerDuca({
            p_resultado: '',
            p_mensaje: '',
            p_duca: ''
        })

        const consultando_ducas = async () => {
            const url_ducas = `${url_ducas_asociadas}?v_contenedor=${encodeURI(obtenercontenedor.contenedor)}`
            const { data } = await axios.get(url_ducas)

            if (data) {
                setObtenerDuca({
                    p_resultado: data.p2,
                    p_mensaje: data.p3,
                    p_duca: data.p4
                })

                if (data.p3 === 'SI') {
                    let p_duca = data.p4

                    const url_l_contenedores = `${url_contenedores_asociados}?v_duca=${encodeURI(p_duca)}`
                    const listado = await axios.get(url_l_contenedores)

                    setListadoc(listado.data)
                    setEstado(false)
                } else if (data.p3 === 'NO') {
                    let valores_insertar = ''
                    valores_insertar = {
                        v_tipos: tipo_solicitud,
                        v_subtipos: sub_tipo_solicitud,
                        v_solicitud: solicitud_apex,
                        v_contenedor: obtenercontenedor.contenedor,
                        v_usuario: usuario_apex
                    }

                    insertar_estados_cero(valores_insertar)
                }
                setLoad(false)
            }

            setCargando(false)
        }

        consultando_ducas()
    }

    const valor_contenedor = e => {
        setObtenercontenedor({
            ...obtenercontenedor,
            [e.target.name]: e.target.value
        })
    }

    const insertar_contenedor = (v_contenedor_retorno) => {
        setEstado(false)

        fn_validando_tipos(statemain, v_contenedor_retorno)
    }

    const fn_validando_tipos = (p_data, p_contenedor) => {
        setLoad(true)

        let v_tipo = ''
        v_tipo = p_data.tipo_solicitud
        let v_subtipo = ''
        v_subtipo = p_data.sub_tipo_solicitud

        axios
            .post(url_valida_tipos, {
                v_tipos: v_tipo,
                v_subtipos: v_subtipo,
                v_solicitud: solicitud_apex,
                v_contenedor: p_contenedor,
                v_usuario: token_user
            })
            .then(response => {
                let valores_solicitud = ''
                valores_solicitud = {
                    v_tipos: v_tipo,
                    v_subtipos: v_subtipo,
                    v_solicitud: solicitud_apex,
                    v_contenedor: p_contenedor,
                    v_usuario: usuario_apex
                }

                if (response.data.p8 === '325') {
                    insertar_estados_d(valores_solicitud)
                } else if (response.data.p8 === '150') {
                    insertar_estados_cero(valores_solicitud)
                } else {
                    console.log(`No hacer nada por el momento`)
                }
            })
            .catch(e => {
                console.log(e)
            })
        setLoad(false)
    }

    const insertar_estados_cero = campos => {
        axios
            .post(url_estados_c, {
                v_tipos: campos.v_tipos,
                v_subtipos: campos.v_subtipos,
                v_solicitud: campos.v_solicitud,
                v_contenedor: campos.v_contenedor,
                v_usuario: campos.v_usuario
            })
            .then(response => {
                if (response.data.p6 !== 'false') {
                    setEstado(true)

                    setRespuestad(response.data)
                    v_show(true)
                    toggle()
                    toast.success('Contenedor agregado correctamente!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                } else {
                    setRespuestad(response.data)
                    toast.warning(`${response.data.p8}!!`, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    // ------------------------------------------------- CONTENEDORES PARA REINTEGRO--------------------------------------///

    const ingreso_contendores_reintegro = () => {
        setLoad(true)
        postInsertaContenedoresReintegro(
            token_user,
            token_page,
            url_inserta_contenedores_reintegros,
            statemain.solicitud_apex,
            obtenercontenedor.contenedor
        )
            .then(resultado => {
                const { v_resultado, v_mensaje } = resultado
                if (v_resultado === 'true') {
                    toggle()
                    v_show(true)
                    toast.success('Contenedor agregado correctamente!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                } else {
                    toast.warning(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
                setLoad(false)
                setCargando(false)
            })
            .catch(error => {
                console.log(error)
                setLoad(false)
                setCargando(false)
            })
    }

    // ------------------------------------------------- CONTENEDORES PARA REINTEGRO--------------------------------------///

    // ------------------------------------------------- CONTENEDORES PARA AOP--------------------------------------///
    const ingresoDeContenedoresAOP = () => {
        setLoad(true)
        postInsertaContenedoresAOP(
            token_user,
            token_page,
            url_inserta_contenedores_aop,
            statemain.solicitud_apex,
            obtenercontenedor.contenedor,
            sub_tipo_solicitud
        )
            .then(resultado => {
                const { v_resultado, v_mensaje } = resultado
                if (v_resultado === 'true') {
                    toggle()
                    v_show(true)
                    toast.success('Contenedor agregado correctamente!', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                } else {
                    toast.warning(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
                setLoad(false)
                setCargando(false)
            })
            .catch(error => {
                console.log(error)
                setLoad(false)
                setCargando(false)
            })
    }
    // ------------------------------------------------- CONTENEDORES PARA AOP--------------------------------------///

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            {load === true ? (
                <ModalBody>
                    <div className='container-fluid bd-example-row'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Loading />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            ) : (
                <>
                    <ModalBody>
                        <div className='container-fluid bd-example-row'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label className='col-form-label' htmlFor='recipient-name'>
                                        Agregar contenedor:{' '}
                                    </label>
                                    {/* {cargando && <p>Cargando..</p>} */}
                                    {estado && <p>{respuestad.p8}</p>}
                                    <input
                                        className='form-control'
                                        type='text'
                                        placeholder='Agregar contenedor'
                                        name='contenedor'
                                        onChange={valor_contenedor}
                                        value={obtenercontenedor.contenedor}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='gray' onClick={toggle}>
                            Cerrar
                        </Button>
                        <Button
                            className='btn btn-warning'
                            onClick={
                                sub_tipo_solicitud === '341'
                                    ? ingreso_contendores_reintegro
                                    : sub_tipo_solicitud === '101' ||
                                      sub_tipo_solicitud === '141' ||
                                      sub_tipo_solicitud === '142'
                                        ? ingresoDeContenedoresAOP
                                        : consultar_contenedor
                            }
                        >
                            Ingresar
                        </Button>
                        {obtenerduca.p_mensaje === 'SI' && (
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Número Duca</th>
                                        <th>Número Orden</th>
                                        <th>Contenedor</th>
                                        <th>Agregar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listadoc.map(contenedores => {
                                        return (
                                            <tr key={contenedores.CONTENEDOR}>
                                                <td>{contenedores.NUMERO_DUCA}</td>
                                                <td>{contenedores.NUMERO_ORDEN}</td>
                                                <td>{contenedores.CONTENEDOR}</td>
                                                <td>
                                                    <Button
                                                        className='btn btn-primary'
                                                        onClick={() => {
                                                            insertar_contenedor(contenedores.CONTENEDOR)
                                                        }}
                                                    >
                                                        +
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </ModalFooter>
                </>
            )}
        </Fragment>
    )
}

export default ModalAddContainer
