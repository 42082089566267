import React, { Fragment } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
// import ImgDefault from '../../../assets/images/1.jpg';
import ImgDefaultAPM from '../../../assets/images/apm-logo.jpg';

const CardComponent = ({ img, link, tittle, tooltipTittle, tooltipDescription, id, text, sm, xl }) => {
    let redir = () => {
        window.open(link, '_blank');
    };

    return (
        <Fragment>
            <div className={`col-sm-${sm ? sm : 3} col-xl-${xl ? xl : 3}`}>
                <div
                    className='card cursor'
                    style={{ boxShadow: '1px 1px 5px gray', height: '134px', placeContent: 'center' }}
                    onClick={redir}
                >
                    <div className='text-center avatar-showcase'>
                        <div className='avatars mt-1'>
                            <div className='avatar'>
                                <img
                                    className='img-80 rounded-circle mb-1'
                                    src={img ? img : ImgDefaultAPM}
                                    alt='#'
                                    id={`target${id}`}
                                />
                                <h6 className={`text-${text}`}>{tittle}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UncontrolledTooltip placement='right' target={`target${id}`}>
                <b>{tooltipTittle}</b>
                <br />
                <br />
                {tooltipDescription}
            </UncontrolledTooltip>
        </Fragment>
    );
};

export default CardComponent;
