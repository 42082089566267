import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GetSubTipos } from '../../helpers/solicitudes/GetSubTipos'
import { GetValidaNit } from '../../helpers/solicitudes/GetValidaNit'
import { useFetchSolicitud } from './useFetchSolicitud'
import { PasosSubTiposSolicitudes } from './pasosSubtipos'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(3)
    }
}))

export const useFetchInitSolicitudes = ({
    token_user,
    token_page,
    url_subtipos,
    url_solicitud,
    url_validar_documentos,
    url_confirmar_solicitudes,
    url_enviar_correo,
    url_enviar_wo,
    url_update_bloqueo_token,
    url_envio_naviera,
    url_envio_correo_aop,
    url_valida_fecha,
    url_wo_almacenaje,
    url_valida_carga
    // url_valida_contenedores
}) => {
    const [ctipos, setCtipos] = useFetchSolicitud(token_user, token_page, url_solicitud)

    const [mostrarcontenedor, setMostrarcontenedor] = useState(false)
    const [mostrardocumentos, setMostrardocumentos] = useState(false)
    const [subtipos, setSubTipos] = useState([]) // subtipo
    const [validanit, setValidanit] = useState({
        t_delegado: '',
        t_link: '',
        t_respuesta: '',
        t_flag: false
    })

    const [habilitar, setHabilitar] = useState(true)

    const [modal, setModal] = useState()
    const [componentModal, setComponentModal] = useState('')
    const [titleModal, setTitleModal] = useState('')
    const [dataRequest, setDataRequest] = useState({})

    const [buttonEnable, setButtonEnable] = useState(true)
    const [msgForm, setMsgForm] = useState(false)

    // States para los steps
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)

    const [respuestaSolicitud, setRespuestaSolicitud] = useState({
        valida_documentos: '',
        estado_documentos: false,
        confirma_solicitud: '',
        estado_solicitud: false,
        envio_wo: '',
        estado_wo: false,
        boqueo_token: '',
        estado_bloqueo_token: false,
        envia_correo: '',
        estado_correo: false,
        envia_correo_almacen: '',
        estado_correo_almacen: false,
        envia_correo_naviera: '',
        estado_correo_naviera: false,
        valida_fecha: '',
        estado_valida_fecha: false,
        wo_almacenaje: '',
        estado_wo_almacenaje: false,
        valida_carga: '',
        estado_carga: false,
        mostrar_respuestas: false
    })

    const [load, setLoad] = useState(false)

    const handleInputChange = event => {
        //console.log(ctipos)

        console.log('ANTES DE ', ctipos)
        setCtipos({
            ...ctipos,
            [event.target.name]: event.target.value
        })
        console.log('DESPYES DE ', ctipos)
        //console.log(event.target.name)

        if (event.target.name === 'tipo_solicitud') {
            const obtenerSubTipos = async () => {
                GetSubTipos(token_user, token_page, url_subtipos, event.target.value)
                    .then(resultado => {
                        setSubTipos(resultado)
                        setCtipos({
                            ...ctipos,
                            [event.target.name]: event.target.value
                        })
                    })
                    .catch(error => {
                        console.log(`Existe un error `,error)
                    })
            }

            obtenerSubTipos()
        }

        if (event.target.name === 'facturar_a') {
            setButtonEnable(true)
            setValidanit({
                t_delegado: '',
                t_link: '',
                t_respuesta: '',
                t_flag: false
            })

            //console.log(`Aqui hacer algo para validar si se puede o no....`)
            let v_factura = ''
            v_factura = event.target.value
            //console.log(v_factura)

            if (v_factura.trim() === '') {
                console.log(`Mostrar error y no dejarlo hacer nada...`)
            } else {
                console.log(`Validar si debe o no....`)
                console.log(`ERRRRORRRRRRRRRRRRRRRR!...`)
                GetValidaNit(token_user, token_page, v_factura).then(validacion => {
                    if (validacion.t_respuesta === '' || validacion.t_respuesta === null) {
                        console.log(`ERRRRORRRRRRRRRRRRRRRR22222222222!...`, validacion)
                        setButtonEnable(false)
                        setHabilitar(true)
                    } else {
                        console.log(`ERRRRORRRRRRRRRRRRRRRR3333333333333!...`, validacion)
                        setHabilitar(false)
                    }

                    setValidanit({
                        t_delegado: validacion.t_delegado,
                        t_link: validacion.t_link,
                        t_respuesta: validacion.t_respuesta,
                        t_flag: true
                    })
                })
            }
        }
    }

    const toggle3 = (_, title, compont, dataRequest) => {
        setModal(!modal)
        if (modal) {
            setComponentModal('')
        }
        compont && setComponentModal(compont)
        title && setTitleModal(title)
        dataRequest && setDataRequest(dataRequest)
    }

    const capturandovalores = e => {
        e.preventDefault()
        console.log(ctipos)
    }

    /***** Steps *******/
    const { sub_tipo_solicitud } = ctipos

    const handleNext = async () => {
        switch (sub_tipo_solicitud) {
        case '281':
            Subtipo281()
            break
        case '341':
            Subtipo341()
            break
        // case '101':
        case '141':
        case '142':
            SubtipoAOP()
            break
        case '61':
            Subtipo61()
            break
        case '461':
        case '101':
            Subtipo461y101()
            break
        default:
            SubtipoNO281()
            break
        }
    }

    const { getSteps, Subtipo341, Subtipo281, SubtipoNO281, SubtipoAOP, Subtipo61, Subtipo461y101 } =
        PasosSubTiposSolicitudes({
            token_user,
            token_page,
            activeStep,
            ctipos,
            setActiveStep,
            setLoad,
            // buttonEnable,
            setButtonEnable,
            url_validar_documentos,
            url_confirmar_solicitudes,
            url_enviar_correo,
            url_enviar_wo,
            url_update_bloqueo_token,
            setMostrarcontenedor,
            validanit,
            setMostrardocumentos,
            setMsgForm,
            setRespuestaSolicitud,
            respuestaSolicitud,
            url_envio_naviera,
            url_envio_correo_aop,
            url_valida_fecha,
            url_wo_almacenaje,
            url_valida_carga
        })

    const handleBack = () => {
        setButtonEnable(false)
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    const steps = getSteps()

    return [
        mostrarcontenedor,
        setMostrarcontenedor,
        subtipos,
        setSubTipos,
        validanit,
        setValidanit,
        habilitar,
        setHabilitar,
        handleInputChange,
        ctipos,
        setCtipos,
        modal,
        setModal,
        titleModal,
        setTitleModal,
        toggle3,
        capturandovalores,
        componentModal,
        setComponentModal,
        dataRequest,
        setDataRequest,
        buttonEnable,
        setButtonEnable,
        msgForm,
        setMsgForm,
        mostrardocumentos,
        setMostrardocumentos,
        handleNext,
        handleBack,
        handleReset,
        classes,
        activeStep,
        steps,
        sub_tipo_solicitud,
        respuestaSolicitud,
        load
    ]
}
