import React from 'react'
import { TabContent, TabPane, Row, Col } from 'reactstrap'
import { DatosEmpresariales } from '../formularios/datosEmpresariales'
import { DatosLegales } from '../formularios/datosLegales'
import { ReferenciasComerciales } from '../formularios/referenciasComerciales'
import { InformacionBancaria } from '../formularios/informacionBancaria'
import { InformacionSocios } from '../formularios/informacionSocios'
import { Contactos } from '../formularios/contactos'

export const InputsProveedores = ({
    activeTab,
    useFetchInit
}) => {
    return (
        <TabContent activeTab={activeTab} className={`contenido-tabs`}>
            <TabPane tabId='1'>
                <Row>
                    <Col sm='12'>
                        <DatosEmpresariales
                            useFetchInit={useFetchInit}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId='2'>
                <Row>
                    <Col sm='12'>
                        <DatosLegales
                            useFetchInit={useFetchInit}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId='3'>
                <Row>
                    <Col sm='12'>
                        <ReferenciasComerciales
                            useFetchInit={useFetchInit}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId='4'>
                <Row>
                    <Col sm='12'>
                        <InformacionBancaria
                            useFetchInit={useFetchInit}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId='5'>
                <Row>
                    <Col sm='12'>
                        <InformacionSocios
                            useFetchInit={useFetchInit}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId='6'>
                <Row>
                    <Col sm='12'>
                        <Contactos
                            useFetchInit={useFetchInit}
                        />
                    </Col>
                </Row>
            </TabPane>
        </TabContent>
    )
}
