import { useState, useEffect } from 'react'
import { GetEnNombre } from '../../helpers/solicitudes/GetEnNombre'

export const useFetchEnNombre = (token_user, token_page, url_ennombre) => {
    const [vnombre, setVnombre] = useState([]) // tipos

    useEffect(() => {
        console.log(`Efect en nombre de: `)

        GetEnNombre(token_user, token_page, url_ennombre)
            .then(resultado => {
                console.log(resultado)
                setVnombre(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {
            console.log(`Limpieza en nombre de.`)
        }
    }, [token_user, token_page, url_ennombre])

    return [vnombre] //  [vnombre,setVnombre]   {data: [], loading: true}
}
