import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import IconLink from './icon-link'
import Loaging from '../loading'

const CardComponent = ({ data, loading, error }) => {
    const { registro_ayuda } = data
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(true);

    // const awaitData = async () => {
    //     const resp = await axios
    //         .get(
    //             `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/servicios-en-linea/registro-ayuda`,
    //             {
    //                 headers: {
    //                     Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
    //                 },
    //             }
    //         )
    //         .then(res => res);
    //     setData(resp.data);
    //     setLoading(false);
    // };

    // useEffect(() => {
    //     awaitData();
    //     return () => {
    //         console.log(`Desmonte de componente`);
    //     };
    // }, []);

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-12'>
                <div className='card b-b-info b-l-info b-r-info b-t-info'>
                    <div className='card-header border-3 text-center'>
                        <h5 className='text-info'>{'Registro - Ayuda'}</h5>
                    </div>
                    <div className='card-body'>
                        <div className='row d-flex justify-content-around'>
                            {loading && <Loaging color='info' />}
                            {error && (
                                <div className='col-12 text-center'>
                                    <h5 className='text-danger'>¡Error al obtener los datos!</h5>
                                </div>
                            )}
                            {registro_ayuda.map((item, index) => {
                                const { COD_DIRECTORIO_LINK, CARD_LINK, CARD_TITLE, DESCRIPCION, IMAGEN, SUBTITULO } =
                                    item && item
                                return (
                                    <IconLink
                                        key={index}
                                        id={COD_DIRECTORIO_LINK}
                                        img={IMAGEN}
                                        link={CARD_LINK}
                                        tittle={CARD_TITLE}
                                        tooltipTittle={DESCRIPCION}
                                        tooltipDescription={SUBTITULO}
                                        text='info'
                                        sm={2}
                                        xl={2}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CardComponent
