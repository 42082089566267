import axios from 'axios'

export const postConfirmaDocumentacion = async ({
    token_page,
    servicio_confirmacion,
    codigo_vinculacion,
    url_confirmacion
}) => {
    // const token_user = 'nada'

    const retorno = await axios
        .post(
            url_confirmacion,
            { servicio: servicio_confirmacion, codigo_vinculacion: codigo_vinculacion },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return {
                v_mensaje: data.v_mensaje,
                v_resultado: data.v_resultado,
                v_estado: true
            }
        })
        .catch(e => {
            if (e.response?.status === 500) {
                return {
                    v_mensaje: 'Error 500, Comunicarse con APM Terminals.',
                    v_resultado: false,
                    v_estado: false
                }
            } else if (e.response?.status === 401) {
                return {
                    v_mensaje: 'Error 401, Comunicarse con APM Terminals.',
                    v_resultado: false,
                    v_estado: false
                }
            } else {
                return {
                    v_mensaje: 'Error, Comunicarse con APM Terminals.',
                    v_resultado: false,
                    v_estado: false
                }
            }
        })

    return retorno
}
