import { useState, useEffect } from 'react'
import { GetTipos } from '../../helpers/solicitudes/GetTipos'

export const useFetchTipos = (token_user, token_page, url_ennombre) => {
    const [tipos, setTipos] = useState([]) // tipos

    useEffect(() => {
        GetTipos(token_user, token_page, url_ennombre)
            .then(resultado => {
                setTipos(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [token_user, token_page, url_ennombre])

    return [tipos, token_page, url_ennombre, token_user] //  [vnombre,setVnombre]   {data: [], loading: true}
}
