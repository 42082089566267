import React, { Fragment } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalModule = props => {
    const { toggle, title, responseData, dataRequest } = props;
    const header = [
        { field: 'NO_SOLICITUD', headerName: 'Solicitud', width: 180 },
        { field: 'TIPO_SOLICITUD', headerName: 'Tipo', width: 150 },
        {
            field: 'REFERENCIA',
            headerName: 'No. referencia',
            width: 250,
        },
        { field: 'MONTO', headerName: 'Monto', width: 150 },
        { field: 'IMPORTE_BRUTO_MEXTRANJ', headerName: 'Importe Bruto Mextranj', width: 250 },
        { field: 'IMPORTE_BRUTO_FACT', headerName: 'Importe Bruto Fact', width: 250 },
        { field: 'FECHA_EMISION_FACTURA', headerName: 'Fecha Emisión Factura', width: 250 },
        { field: 'FECHA_VENCIMIENTO_FACTURA', headerName: 'Fecha Vencimiento Factura', width: 250 },
        { field: 'NOMBRE_CLIENTE', headerName: 'Nombre Cliente', width: 250 },
        { field: 'NIT', headerName: 'Nit', width: 150 },
    ];
    const redirect = () => {
        window.open('https://www.services.apmterminalsquetzal.com/ords/f?p=103:101::::101:P101_LINK:35');
    };

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <button className='btn btn-outline-warning' type='button' onClick={redirect}>
                                Ir a Estado de Cuenta
                            </button>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: 'NO_SOLICITUD',
                                    sort: 'asc',
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
