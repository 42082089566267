import React, { Fragment, useState, useEffect } from 'react'
import { UncontrolledCarousel } from 'reactstrap'
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap'
// import axios from 'axios'
// import IconLink from './icon-link'
import Loading from '../loading'

// const items = [
//     {
//         src: Img1,
//         altText: 'Slide 1',
//         caption: 'Slide 1',
//         header: 'Slide 1 Header',
//     },

// ];

const CarouselTwo = ({ data, loading, error }) => {
    const { carrousel } = data
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(true);

    const redirect = link => {
        window.open(link, '_blank')
    }

    // const awaitData = async () => {
    //     const resp = await axios
    //         .get(`${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/servicios-en-linea/carrousel`, {
    //             headers: {
    //                 Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
    //             },
    //         })
    //         .then(res => res);
    //     setData(resp.data);
    //     setLoading(false);
    // };

    const [activeIndex, setActiveIndex] = useState(0)
    const [animating, setAnimating] = useState(false)

    const next = () => {
        if (animating) return
        const nextIndex = activeIndex === carrousel.length - 1 ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    const previous = () => {
        if (animating) return
        const nextIndex = activeIndex === 0 ? carrousel.length - 1 : activeIndex - 1
        setActiveIndex(nextIndex)
    }

    const goToIndex = newIndex => {
        setActiveIndex(newIndex)
        if (animating) return
    }

    // useEffect(() => {
    //     awaitData();
    //     return () => {
    //         console.log(`Desmonte de componente`);
    //     };
    // }, []);

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-12'>
                <div className='card b-b-info b-l-info b-r-info b-t-info'>
                    <div className='card-body'>
                        {loading && <Loading color='primary' />}
                        {error && (
                            <div className='col-12 text-center'>
                                <h5 className='text-danger'>¡Error al obtener los datos!</h5>
                            </div>
                        )}
                        <div className='col-sm-12 col-xl-12'>
                            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                                <CarouselIndicators
                                    items={carrousel}
                                    activeIndex={activeIndex}
                                    onClickHandler={goToIndex}
                                />
                                {carrousel.map(item => {
                                    return (
                                        <CarouselItem
                                            onExiting={() => setAnimating(true)}
                                            onExited={() => setAnimating(false)}
                                            key={item.id}
                                        >
                                            <div className='col-sm-12 col-xl-12 row'>
                                                <div className='col-sm-12 col-xl-7'>
                                                    <img
                                                        src={item.src}
                                                        alt={item.altText}
                                                        className='cursor carrouselWidth'
                                                        onClick={() => redirect(item.LINK)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-xl-5 v-a-center  text-center'>
                                                    <CarouselCaption
                                                        className='text-info blod'
                                                        captionText={item.caption}
                                                        captionHeader={item.header}
                                                    />
                                                </div>
                                            </div>
                                        </CarouselItem>
                                    )
                                })}
                                <CarouselControl direction='prev' directionText='Previous' onClickHandler={previous} />
                                <CarouselControl direction='next' directionText='Next' onClickHandler={next} />
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CarouselTwo

// import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';

// const items = [
//     {
//         id: 1,
//         src: require('../../../assets/images/c1.jpg'),
//         altText: 'Slide 1',
//         caption: 'Slide 1',
//     },
//     {
//         id: 2,
//         src: require('../../../assets/images/c2.jpg'),
//         altText: 'Slide 2',
//         caption: 'Slide 2',
//     },
//     {
//         id: 3,
//         src: require('../../../assets/images/c3.jpg'),
//         altText: 'Slide 3',
//         caption: 'Slide 3',
//     },
// ];

// export const Carsouselthree = props => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const awaitData = async () => {
//         const resp = await axios
//             .get(`${process.env.REACT_APP_PANEL_LINKS}carrousel`, {
//                 headers: {
//                     Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
//                 },
//             })
//             .then(res => res);
//         setData(resp.data);
//         setLoading(false);
//     };

// const [activeIndex, setActiveIndex] = useState(0);
// const [animating, setAnimating] = useState(false);

// const next = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
//     setActiveIndex(nextIndex);
// };

// const previous = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
//     setActiveIndex(nextIndex);
// };

// const goToIndex = newIndex => {
//     setActiveIndex(newIndex);
//     if (animating) return;
// };

//     useEffect(() => {
//         awaitData();
//         return () => {
//             console.log(`Desmonte de componente`);
//         };
//     }, []);

//     return (
//         <div className='col-sm-12 col-xl-12'>
//             <div className='card'>
//                 <Carousel activeIndex={activeIndex} next={next} previous={previous}>
//                     <CarouselIndicators items={data} activeIndex={activeIndex} onClickHandler={goToIndex} />
//                     {data.map(item => {
//                         return (
//                             <CarouselItem
//                                 onExiting={() => setAnimating(true)}
//                                 onExited={() => setAnimating(false)}
//                                 key={item.id}
//                             >
//                                 <img src={item.src} alt={item.altText} />
//                                 <CarouselCaption
//                                     className='text-danger'
//                                     captionText={item.caption}
//                                     captionHeader={item.caption}
//                                 />
//                             </CarouselItem>
//                         );
//                     })}
//                     <CarouselControl direction='prev' directionText='Previous' onClickHandler={previous} />
//                     <CarouselControl direction='next' directionText='Next' onClickHandler={next} />
//                 </Carousel>
//                 <hr />
//                 <div className='btn-showcase' role='group' aria-label='Carousel toggle controls'></div>
//             </div>
//         </div>
//     );
// };
// export default Carsouselthree;
