import React, { Fragment } from 'react'
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'
import { Ciudades } from './ciudades'
import { Paises } from './paises'
// const email_regex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$')

export const DatosEmpresariales = ({
    useFetchInit
}) => {
    const {
        setVinculacion,
        vinculacion,
        modalidad,
        tipopersona,
        toggle3,
        terminos,
        setBtndisableuv,
        dreferencias,
        dinfobancaria,
        dsocios,
        dcontacto
    } = useFetchInit
    const {
        nombre_comercial,
        input_modalidad,
        razon_social,
        nombre_cliente,
        codigo_pais,
        codigo_ciudad,
        telefono_uno,
        telefono_dos,
        correo_electronico,
        nit_cliente_vinculacion,
        tipo_persona,
        direccion,
        termino_pago,
        representante_legal,
        puesto_legal,
        tipo_documento,
        sitio_web,
        codigo_postal,
        perfil_seleccionado
    } = vinculacion

    // const [valiemail, setValiemail] = useState(false)

    const handleChange = event => {
        setVinculacion({
            ...vinculacion,
            [event.target.name]: event.target.value
        })

        // if (event.target.name === 'correo_electronico') {
        // }

        validar_campos()
    }

    const validar_campos = () => {
        if (perfil_seleccionado === '162') {
            // ------------------ validar proveedores ------------
            if (
                nombre_comercial.trim() !== '' &&
                input_modalidad.trim() !== '' &&
                razon_social.trim() !== '' &&
                nombre_cliente.trim() !== '' &&
                correo_electronico.trim() !== '' &&
                codigo_pais > 0 &&
                codigo_ciudad > 0 &&
                telefono_uno.trim() !== '' &&
                nit_cliente_vinculacion.trim() !== '' &&
                tipo_persona.trim() !== '' &&
                direccion.trim() !== '' &&
                termino_pago.trim() !== '' &&
                representante_legal.trim() !== '' &&
                puesto_legal.trim() !== '' &&
                tipo_documento.trim() !== '' &&
                dreferencias.length > 0 &&
                dinfobancaria.length > 0 &&
                dsocios.length > 0 &&
                dcontacto.length > 0
            ) {
                setBtndisableuv(false)
            } else {
                setBtndisableuv(true)
            }
        } else {
            // ------------------ validar clientes ------------
            if (
                nombre_comercial.trim() !== '' &&
                input_modalidad.trim() !== '' &&
                razon_social.trim() !== '' &&
                nombre_cliente.trim() !== '' &&
                correo_electronico.trim() !== '' &&
                codigo_pais > 0 &&
                codigo_ciudad > 0 &&
                telefono_uno.trim() !== '' &&
                nit_cliente_vinculacion.trim() !== '' &&
                tipo_persona.trim() !== '' &&
                direccion.trim() !== '' &&
                representante_legal.trim() !== '' &&
                puesto_legal.trim() !== '' &&
                tipo_documento.trim() !== '' &&
                dcontacto.length > 0
            ) {
                setBtndisableuv(false)
            } else {
                setBtndisableuv(true)
            }
            // fin if para validación de clientes...
        }
    }

    validar_campos()

    return (
        <Fragment>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <Label for='nombre_comercial'>Nombre Comercial</Label>
                        <Input
                            type='text'
                            name='nombre_comercial'
                            id='nombre_comercial'
                            placeholder='Ingresar Nombre comercial'
                            onChange={handleChange}
                            value={nombre_comercial}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='input_modalidad'>Modalidad</Label>
                        <Input
                            type='select'
                            name='input_modalidad'
                            id='input_modalidad'
                            onChange={handleChange}
                            value={input_modalidad}
                        >
                            <option value=''>-- Seleccione Modalidad --</option>
                            {modalidad.map(valor => {
                                return (
                                    <option key={valor.MODALIDAD} value={valor.MODALIDAD}>
                                        {valor.DESCRIPCION}
                                    </option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='razon_social'>Razón social</Label>
                        <Input
                            type='text'
                            name='razon_social'
                            id='razon_social'
                            placeholder='Razón social'
                            onChange={handleChange}
                            value={razon_social}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='nombre_cliente'>Nombre cliente</Label>
                        <Input
                            type='text'
                            name='nombre_cliente'
                            id='nombre_cliente'
                            placeholder='Nombre cliente'
                            onChange={handleChange}
                            value={nombre_cliente}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='correo_electronico'>
                            Email (Llegará confirmación de su usuario a esta dirección)
                        </Label>
                        {/* <p>{valiemail ? 'verdaedor' : 'no valido'} - afas</p> */}
                        <Input
                            type='email'
                            name='correo_electronico'
                            id='correo_electronico'
                            placeholder='Correo Electrónico'
                            onChange={handleChange}
                            value={correo_electronico}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Paises toggle3={toggle3} vinculacion={vinculacion} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Ciudades toggle3={toggle3} vinculacion={vinculacion} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='telefono_uno'>Teléfono</Label>
                        <Input
                            type='number'
                            name='telefono_uno'
                            id='telefono_uno'
                            placeholder='Teléfono'
                            onChange={handleChange}
                            value={telefono_uno}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='telefono_dos'>Teléfono 2</Label>
                        <Input
                            type='number'
                            name='telefono_dos'
                            id='telefono_dos'
                            placeholder='Teléfono 2'
                            onChange={handleChange}
                            value={telefono_dos}
                        />
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label>Nit:</Label>
                        <p></p>
                        <Label>{nit_cliente_vinculacion}</Label>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sitio_web'>Sitio Web</Label>
                        <Input
                            type='text'
                            name='sitio_web'
                            id='sitio_web'
                            placeholder='Sitio web'
                            onChange={handleChange}
                            value={sitio_web}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='tipo_persona'>Tipo persona</Label>
                        <Input
                            type='select'
                            name='tipo_persona'
                            id='tipo_persona'
                            onChange={handleChange}
                            value={tipo_persona}
                        >
                            <option value=''>-- Seleccione Tipo de Persona --</option>
                            {tipopersona.map(valor => {
                                return (
                                    <option key={valor.PERSONA} value={valor.PERSONA}>
                                        {valor.DESCRIPCION}
                                    </option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='direccion'>Dirección (Aségurese que la dirección fiscal coincida con el RTU)</Label>
                        <Input
                            type='text'
                            name='direccion'
                            id='direccion'
                            placeholder='Dirección'
                            onChange={handleChange}
                            value={direccion}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='codigo_postal'>Código postal</Label>
                        <Input
                            type='text'
                            name='codigo_postal'
                            id='codigo_postal'
                            onChange={handleChange}
                            value={codigo_postal}
                        />
                    </FormGroup>
                </Col>
                {perfil_seleccionado === '162' ? (
                    <Col md={6}>
                        <FormGroup>
                            <Label for='termino_pago'>Término de Pago</Label>
                            <Input
                                type='select'
                                name='termino_pago'
                                id='termino_pago'
                                onChange={handleChange}
                                value={termino_pago}
                            >
                                <option value=''>-- Seleccione Término de pago --</option>
                                {terminos.map(lista_termino => {
                                    return (
                                        <option key={lista_termino.TERMINO} value={lista_termino.TERMINO}>
                                            {lista_termino.DESCRIPCION}
                                        </option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                ) : (
                    ''
                )}
            </Row>
        </Fragment>
    )
}
