import React, { Fragment, useState } from 'react'
import { Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { nanoid } from 'nanoid'

export const ModalReferenciasComerciales = ({
    toggle,
    title,
    inforeferencias,
    setInforeferencias,
    dreferencias,
    setDreferencias
}) => {
    const [error, setError] = useState(null)

    const handleInputChange = event => {
        setInforeferencias({
            ...inforeferencias,
            [event.target.name]: event.target.value
        })
    }

    const insertar_referencias = () => {
        const { referencia_empresa, referencia_telefono } = inforeferencias

        if (referencia_empresa === '' || referencia_empresa === undefined) {
            setError('Agregar nombre de empresa.')
            return
        }

        if (referencia_telefono === '' || referencia_telefono === undefined) {
            setError('Agregar número de teléfono.')
            return
        }

        setDreferencias([
            ...dreferencias,
            {
                referencia_id: nanoid(),
                referencia_empresa: inforeferencias.referencia_empresa,
                referencia_telefono: inforeferencias.referencia_telefono
            }
        ])

        setInforeferencias({
            referencia_id: '',
            referencia_empresa: '',
            referencia_telefono: ''
        })

        setError(null)
    }

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    {error ? <span className='text-danger'>{error}</span> : null}
                    <Input
                        type='text'
                        name='referencia_empresa'
                        id='referencia_empresa'
                        placeholder='Empresa'
                        onChange={handleInputChange}
                        value={inforeferencias.referencia_empresa}
                        required
                    />

                    <Input
                        type='number'
                        className='form-control mb-2'
                        placeholder='Teléfono'
                        name='referencia_telefono'
                        id='referencia_telefono'
                        onChange={handleInputChange}
                        value={inforeferencias.referencia_telefono}
                        required
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='btn btn-primary pull-right'
                    onClick={() => {
                        insertar_referencias()
                    }}
                >
                    Agregar cuentas
                </Button>
                <Button color='gray' onClick={toggle} className='btn btn-primary float-left'>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}
