import React, { Fragment } from 'react';
import { Info } from 'react-feather';
import styled from '@emotion/styled';
import Loading from './loading';

const CajaMensaje = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    background-color: #ff6219;
    font-size: 15px;
    border-radius: 4px;
    color: #ffffff;
    width: 98%;
`;

const CajaInfoUsuario = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    font-size: 15px;
    border-radius: 4px;
    font-weight: 600;
    color: #004265;
    width: 98%;
`;

const CajaTerminos = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    font-size: 15px;
    border-radius: 4px;
    font-weight: 600;
    width: 98%;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 20%) inset, 0 1px 2px rgb(0 0 0 / 10%);
`;

const FormularioCambios = ({ cambio, load, error, handleChange }) => {
    const { nit_cliente, user, password_actual, password_nuevo, password_confirmacion, mensaje_api, respuesta_api } =
        cambio;

    return (
        <Fragment>
            {' '}
            <div className='page-wrapper'>
                <div className='card mt-8'>
                    <div className='card-body'>
                        {' '}
                        <div className='form-group m-form__group'>
                            <label className='col-form-label pt-0'>Usuario</label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>
                                        <i className='fa fa-user icofont-pencil-alt-5'></i>
                                    </span>
                                </div>
                                <input
                                    name='user'
                                    id='user'
                                    className='form-control'
                                    type='text'
                                    placeholder=''
                                    onChange={handleChange}
                                    value={user}
                                />
                            </div>

                            <span className='h6 txt-danger'>
                                {error.estado ? (
                                    error.input === 'user' ? (
                                        <CajaMensaje>{error.mensaje}</CajaMensaje>
                                    ) : null
                                ) : null}
                            </span>
                        </div>
                        <div className='form-group m-form__group'>
                            <label className='col-form-label pt-0'>Contraseña actual</label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>
                                        <i className='fa fa-unlock-alt icofont-pencil-alt-5'></i>
                                    </span>
                                </div>
                                <input
                                    name='password_actual'
                                    id='password_actual'
                                    className='form-control'
                                    type='password'
                                    placeholder=''
                                    onChange={handleChange}
                                    value={password_actual}
                                />
                            </div>
                            <span className='h6 txt-danger'>
                                {error.estado ? (
                                    error.input === 'password_actual' ? (
                                        <CajaMensaje>{error.mensaje}</CajaMensaje>
                                    ) : null
                                ) : null}
                            </span>
                        </div>
                        <div className='form-group m-form__group'>
                            <label className='col-form-label pt-0'>Nueva contraseña</label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>
                                        <i className='fa fa-unlock-alt icofont-pencil-alt-5'></i>
                                    </span>
                                </div>
                                <input
                                    name='password_nuevo'
                                    id='password_nuevo'
                                    className='form-control'
                                    type='password'
                                    placeholder=''
                                    onChange={handleChange}
                                    value={password_nuevo}
                                />
                            </div>
                            <span className='h6 txt-danger'>
                                {error.estado ? (
                                    error.input === 'password_nuevo' ? (
                                        <CajaMensaje>{error.mensaje}</CajaMensaje>
                                    ) : null
                                ) : null}
                            </span>
                        </div>
                        <div className='form-group m-form__group'>
                            <label className='col-form-label pt-0'>Confirma nueva contraseña</label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>
                                        <i className='fa fa-unlock-alt icofont-pencil-alt-5'></i>
                                    </span>
                                </div>
                                <input
                                    // ref={register({ required: true })}
                                    name='password_confirmacion'
                                    id='password_confirmacion'
                                    className='form-control'
                                    type='password'
                                    placeholder=''
                                    onChange={handleChange}
                                    value={password_confirmacion}
                                />
                            </div>
                            <span className='h6 txt-danger'>
                                {error.estado ? (
                                    error.input === 'password_confirmacion' ? (
                                        <CajaMensaje>{error.mensaje}</CajaMensaje>
                                    ) : null
                                ) : null}
                            </span>
                        </div>
                        <div className='form-group form-row mt-3 mb-0'>
                            <CajaInfoUsuario>
                                <p>
                                    {nit_cliente} - Ingresa tus credenciales para cambiar tu contraseña e ingresar al
                                    sistema Ingresa tu contraseña actual y luego confirma la nueva contraseña.
                                </p>
                            </CajaInfoUsuario>
                        </div>
                        {load ? <Loading /> : respuesta_api ? <CajaMensaje>{mensaje_api}</CajaMensaje> : ''}
                        {/* aquí los términos */}
                        <CajaTerminos>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='media'>
                                        <Info className='m-r-20' style={{ alignSelf: 'center', color: 'dodgerblue' }} />
                                        <div className='media-body'>
                                            <h6 className='f-w-600'>{'Términos y Condiciones'}</h6>
                                            <p>
                                                {
                                                    'Acepto los términos y condiciones en cuanto al uso del USUARIO Y CONTRASEÑA brindado por APMTQ, S.A. Me hago responsable por la utilización de la nueva contraseña y las responsabilidades implicadas.'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CajaTerminos>
                        {/* aquí los términos */}
                        {/* Inicio botón */}
                        <div className='form-group form-row mt-3 mb-0'>
                            <button className='btn btn-warning btn-block p-3' type='submit'>
                                Aceptar y Confirmar
                            </button>
                        </div>
                        {/* Fin botón */}
                        {/* fin inputs ................ */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormularioCambios;
