import React, { Fragment } from 'react';
import Vertical from '../../../assets/images/iap/vertical-line.png';
import Calendario from '../../../assets/images/iap/calendario.png';
import Cheque from '../../../assets/images/iap/cheque.png';
import Exportacion from '../../../assets/images/iap/barco-de-carga.png';

const FirstStep = props => {
    const { contenedorCitaExport, contenedorTokenExport, contenedoresLlenosExport } = !!props && props;

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-4'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Total contenedores llenos de Exportación</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Exportacion} alt='Smiley face' height='40' width='57'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedoresLlenosExport || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-4'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Contenedores con token</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Cheque} alt='Smiley face' height='42' width='42'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedorTokenExport || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-12 col-xl-4'>
                <div className='card '>
                    <div className='card-header b-t-warning border-4'>
                        <h6>Contenedores con cita</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Calendario} alt='Smiley face' height='42' width='42'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedorCitaExport || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FirstStep;
