import React, { Fragment, useState } from 'react'
import axios from 'axios'
import Fromulario from './form'
import Breadcrumb from '../common/breadcrumb'
import styled from '@emotion/styled'
import Swal from 'sweetalert2'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #ff6219;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const MainComponent = props => {
    const [loading, setLoagind] = useState(false)
    const [datos, setDatos] = useState({
        nombres: '',
        apellidos: '',
        email: '',
        fecha_nacimiento: '',
        telefono: '',
        direccion: '',
    })

    const handleInputChange = event => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        })
    }

    const SendOrder = async event => {
        event.preventDefault()
        setLoagind(true)
        await axios(
                {
                    method:'post',
                    url: `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/solicitud-empleo`,
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`
                    },
                    data: datos
                }
            ).then(res => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res.data.mensaje,
                    showConfirmButton: false,
                    timer: 4000,
                })
                event.target.reset()
            })
            .catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error.response.data.error.msg,
                    showConfirmButton: false,
                    timer: 2500,
                })
            })
        setLoagind(false)
    }

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Solicitud de empleo' parent='Servicios' />
            </Titulo>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 mt-3'>
                        <form className='theme-form' onSubmit={SendOrder}>
                            <Fromulario handleInputChange={handleInputChange} loading={loading} />
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainComponent
