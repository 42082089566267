const loadVisanetScript = (merchantId, organizationId,sessionId,callback) => {
    //console.log('Cargando script visanet');
    const existingScript = document.getElementById('visanetScript');
    if (!existingScript) {
        //console.log('Creando nuevo script Visanet para: '+merchantId + ' - '+organizationId +' - '+ sessionId);
        const script = document.createElement('script');
        script.src = 'https://h.online-metrix.net/fp/tags.js?org_id='+organizationId+'&session_id='+merchantId+sessionId;
        script.id = 'visanetScript';
        document.body.appendChild(script);
        script.onload = () => { 
            if (callback) callback();
        };
    }/*else{
        console.log('script visanet ya existe para: '+merchantId + ' - '+organizationId+' - '+ sessionId);
    }*/
    if (existingScript && callback) callback();
};
export default loadVisanetScript;