import React, { Fragment, useState, useEffect } from 'react'

import axios from 'axios'
import Targets from './targets'
import Breadcrumb from '../common/breadcrumb'
import Modal from './modal-components/modal'
import { headerTable } from './headers/header-table-rows.js'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DataGrid, GridToolbar, GridOverlay, GridPreferencePanelsValue } from '@material-ui/data-grid'
import styled from '@emotion/styled'
// import DisclaimerAnuncio from '../common/disclaimer-anuncios'
import Box from '@mui/material/Box'
import { TextField, Grid, MenuItem } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/es'
import { toast } from 'react-toastify'

import moment from 'moment'
import { Button } from 'reactstrap'
import { confTableRowsStatus, listadoEstado, listadoImportExport, initialState, useStyles } from './globalConfigurations/globalConfigs'
const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`
function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

const PanelDisponibilidadCitas = props => {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)
    const [sizeTab, setSizeTab] = useState(200)
    // const [sat, setSat] = useState('')
    const [modal, setModal] = useState()
    const [componentModal, setComponentModal] = useState('')
    const [titleModal, setTitleModal] = useState('')
    const [showData, setShowData] = useState(false)
    const [dataRequest, setDataRequest] = useState({})
    const [loginMsg, setLoginMsg] = useState('')
    const [valuesFields, setValuesFields] = React.useState(initialState)
    const [selectedDate, setSelectedDate] = React.useState(new Date())

    const awaitData = async () => {
        const resp = await axios
            // .get(process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/disponibilidad-citas-transporte', {${filtros.subcategoria}
            .get(
                `${process.env.REACT_APP_EXTERNAL_PANEL}/v1/apm-services/unaffectedly/disponibilidad-citas-transporte?_State=${valuesFields.statusContainers}&_ImpExp=${valuesFields.importExport}&_fCita=${valuesFields.fecha}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => res)
        // console.log("xxxx",resp.data.data)
        setData(resp.data.data)
        // setSat(resp.data.sat)
        setLoad(false)
        setSizeTab(1000)
    }

    const toggle3 = (_, title, compont, dataRequest) => {
        setModal(!modal)
        modal && setShowData(false)
        compont && setComponentModal(compont)
        title && setTitleModal(title)
        dataRequest && setDataRequest(dataRequest)
    }
    const handleChange = name => event => {
        setValuesFields({ ...valuesFields, [name]: event.target.value })
    }

    function handleDateChangeFecha(date) {
        setSelectedDate(date)
        setValuesFields({ ...valuesFields, fecha: moment(date).format('DD/MM/YYYY') })
    }

    useEffect(() => {
        setValuesFields({ ...valuesFields, fecha: moment(selectedDate).format('DD/MM/YYYY') })
        // awaitData()
        // return () => {
        //     console.log(`Limpieza`)
        // }
    }, [])

    const searchData = () => {
        if (moment(moment(selectedDate).format('YYYY/MM/DD')).isSameOrAfter(moment().format('YYYY/MM/DD')) === true && valuesFields.importExport !== '' && valuesFields.statusContainers !== '') {
            // console.log("buscar");
            setLoad(true)
            awaitData()
            return () => {
                console.log(`Limpieza`)
            }
        } else {
            // console.log("no buscar");
            toast.warning('Revise los filtros para la búsqueda!', {
                position: toast.POSITION.BOTTOM_RIGHT,
            })
            setData([])
        }
    }
    return (
        <Fragment>
            {/* <DisclaimerAnuncio {...props} /> */}
            <Titulo>
                <Breadcrumb title='Disponibilidad Citas Transporte' parent='Servicios' />
            </Titulo>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <Targets />
                    </div>

                    <Modal
                        toggle={toggle3}
                        modal={modal}
                        tittle={titleModal}
                        componentModal={componentModal}
                        setDat={setShowData}
                        showData={showData}
                        dataRequest={dataRequest}
                        setLoginMsg={setLoginMsg}
                        loginMsg={loginMsg}
                    />

                    <div className='col-sm-12'>
                        <div className='card'>
                            <Grid container className='mt-4' spacing={2} style={{ textAlign: 'center' }}>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        id='txtField1'
                                        select
                                        label='Estado'
                                        className={classes.textField}
                                        value={valuesFields.statusContainers}
                                        onChange={handleChange('statusContainers')}
                                        helperText='Seleccione estado'
                                        margin='normal'
                                    >
                                        {listadoEstado.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        id='txtField1'
                                        select
                                        label='Import/Export'
                                        className={classes.textField}
                                        value={valuesFields.importExport}
                                        onChange={handleChange('importExport')}
                                        helperText='Seleccione el tipo'
                                        margin='normal'
                                    >
                                        {listadoImportExport.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin='normal'
                                            className={classes.textField}
                                            id='dtpicker1'
                                            cancelLabel='Cancelar'
                                            okLabel='Aceptar'
                                            invalidDateMessage='Fecha inválida'
                                            minDateMessage='Fecha inválida, no puede ser anterior a la fecha actual'
                                            openTo='year'
                                            views={['year', 'month', 'date']}
                                            label='Fecha Cita'
                                            format='yyyy-MM-dd'
                                            minDate={moment().toDate()}
                                            value={selectedDate}
                                            onChange={handleDateChangeFecha}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            variant='filled'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type='button'
                                        className='btn btn-warning pull-center'
                                        onClick={() => {
                                            searchData()
                                        }}
                                    >
                                        <i className='fa fa-search' aria-hidden='true'></i> &nbsp; Consultar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className='col-sm-12'>
                        <div className='card'>
                            {/* <div>  */}
                            <Box style={{ height: sizeTab, width: '100%' }} sx={confTableRowsStatus}>
                                <DataGrid
                                    rows={data}
                                    columns={headerTable({ toggle3 })}
                                    pageSize={50}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay,
                                        Toolbar: GridToolbar,
                                    }}
                                    loading={load}
                                    state={{
                                        preferencePanel: {
                                            open: false,
                                            openedPanelValue: GridPreferencePanelsValue.filters,
                                        },
                                    }}
                                />
                            </Box>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PanelDisponibilidadCitas
