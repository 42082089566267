import React, { Fragment } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from './loading';
import styled from '@emotion/styled';

const CajaMensaje = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    background-color: #ff6219;
    font-size: 15px;
    border-radius: 4px;
    color: #ffffff;
    width: 98%;
`;

const CajaMensajeExito = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    background-color: #28a745;
    font-size: 15px;
    border-radius: 4px;
    color: #ffffff;
    width: 98%;
`;

export const FormularioRecuperacion = ({ setVinculacion, vinculacion, load, error, url_login_external }) => {
    const handleChange = event => {
        setVinculacion({
            ...vinculacion,
            [event.target.name]: event.target.value,
        });
    };

    const { mensaje_api, respuesta_api, validacion_campos } = vinculacion;

    return (
        <Fragment>
            <Row>
                {load ? (
                    <Loading />
                ) : respuesta_api ? (
                    <CajaMensajeExito> {mensaje_api}</CajaMensajeExito>
                ) : validacion_campos ? (
                    <CajaMensaje>{mensaje_api}</CajaMensaje>
                ) : (
                    ''
                )}
                {error ? <CajaMensaje>{error}</CajaMensaje> : null}
                <Col md={12} mt={5}>
                    <FormGroup>
                        <Label for='usuario_recuperar'>Usuario</Label>
                        <Input
                            type='text'
                            name='usuario_recuperar'
                            id='usuario_recuperar'
                            placeholder='Ingresar Nombre de usuario'
                            onChange={handleChange}
                            value={vinculacion.usuario_recuperar}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label for='usuario_correo_electronico'>Correo electrónico</Label>
                        <Input
                            type='text'
                            name='usuario_correo_electronico'
                            id='usuario_correo_electronico'
                            placeholder='Ingresar correo electrónico'
                            onChange={handleChange}
                            value={vinculacion.usuario_correo_electronico}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <Button color='primary' href={url_login_external}>
                        <i className='fa fa-undo' aria-hidden='true'></i> Regresar a auntenticación
                    </Button>
                </Col>
                <Col md={6}>
                    <Button color='warning' type='submit'>
                        <i className='fa fa-key' aria-hidden='true'></i> Recuperar credenciales
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};
