import { useEffect } from 'react'
import { postConfirmaCredenciales } from '../../helpers/vinculaciones/postConfirmaCredenciales'

export const useFetchValidaCredenciales = (
    url_confirmacion,
    servicio_confirma_credenciales,
    token_page,
    codigo_encritado,
    cambio,
    setCambio
) => {
    useEffect(() => {
        if (codigo_encritado === undefined || codigo_encritado === '') {
            /*  
                Esto si no viene la variable para el token
                console.log(`can not read token`) 
            */
            return
        }

        postConfirmaCredenciales(url_confirmacion, servicio_confirma_credenciales, token_page, codigo_encritado).then(
            retorno_credenciales => {
                console.log(retorno_credenciales)
                if (retorno_credenciales.v_resultado) {
                    setCambio({
                        ...cambio,
                        credenciales: retorno_credenciales.v_resultado,
                        codigo_vinculacion: retorno_credenciales.v_cod_vinculacion,
                        respuesta_credenciales: retorno_credenciales.v_resultado,
                        nit_cliente: retorno_credenciales.v_nit,
                        mensaje_carga: retorno_credenciales.v_mensaje,
                        carga: false
                    })
                } else {
                    if (retorno_credenciales.v_error) {
                        setCambio({
                            ...cambio,
                            carga: false,
                            respuesta: false,
                            mensaje_carga: retorno_credenciales.v_mensaje,
                            respuesta_credenciales: true,
                            codigo_vinculacion: '',
                            nit_cliente: ''
                        })
                    } else {
                        setCambio({
                            ...cambio,
                            carga: false,
                            respuesta: false,
                            mensaje_carga: retorno_credenciales.v_mensaje,
                            respuesta_credenciales: false,
                            codigo_vinculacion: '',
                            nit_cliente: ''
                        })
                    }
                }
            }
        )

        // Dismount
        return () => {}

        //invocar nuevo token.
    }, [codigo_encritado, url_confirmacion, token_page, servicio_confirma_credenciales, setCambio])

    return [cambio, setCambio] // {data: [], loading: true}
}
