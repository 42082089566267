import React, { Fragment, useState } from 'react'
import DatePicker from 'react-datepicker'
const AddFechaRetiro = ({ ctipos, setCtipos, setButtonEnable }) => {
    const [startDate, setstartDate] = useState()
    const handleChange = date => {
        const fechaRetiro = date.toLocaleDateString()
        setCtipos({ ...ctipos, fechaRetiro })
        setButtonEnable(false)
        setstartDate(new Date(date))
    }

    return (
        <Fragment>
            <div className='col-sm-12'>
                <div className='card'>
                    <div className='card-body col-sm-12 '>
                        <div className='col-sm-12 '>
                            <div className='form-group form-row'>
                                <div className='col-xl-12 col-sm-12'>
                                    <DatePicker
                                        placeholderText='Fecha estimada retiro'
                                        className='form-control digits'
                                        todayButton='Seleccione Fecha'
                                        selected={startDate}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddFechaRetiro
