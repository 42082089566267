import React, { Fragment, useEffect, Suspense } from 'react'
import ReactDOM from 'react-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import './index.scss'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ScrollContext } from 'react-router-scroll-4'
import * as serviceWorker from './serviceWorker'
import Loader from './components/common/loader'

// ** Import custom components for redux**
import { Provider } from 'react-redux'
import store from './store/index'
import App from './components/app'

import ServiciosEnLinea from './components/servicios-en-linea/index'

import ConsultaInteligenciaAduaneraIAP from './components/consulta-iap/index'
import PanelLiberaciones from './components/panel-liberaciones/index'
import PanelPrearribosImport from './components/panel-pre-arribos-import/index'
import IngresoSolicitudes from './components/ingreso-solicitudes/index'
import SolicitudesInspeccionesPendientes from './components/solicitudes-inspecciones-pendientes/index'
import SolicitudesProgramadas from './components/solicitudes-programadas/index'

import PanelTransportista from './components/panel-transportista/index'
import CodigoEtica from './components/codigo-etica/index'
import TarifasAPMTQ from './components/tarifas/index'
import Empleo from './components/empleo/index'
// import Login from './components/login/index';
import BillingVisanet from './components/billing-visanet/index'
// Vinculaciónes
import VinculacionUsuarios from './components/vinculacion-usuarios'
import VinculacionAutoridades from './components/registro-funcionarios-y-autoridades'
import RecuperacionCredenciales from './components/recuperacion-de-credenciales'
import CambioDeCredenciales from './components/cambio-de-credenciales'
import inputExceldashbord from './components/dashboard-naviera/index'
import inputDashboardUsers from './components/dashboard-users/index'
import consultaAlmacenaje from './components/consulta-almacenaje-contenedor/index'

import SolicitudesInspeccionesPendientesExportacion from './components/solicitudes-inspecciones-pendientes-export/index'
import SolicitudesProgramadasExportacion from './components/solicitudes-programadas-export/index'
import PanelLiberacionesExport from './components/panel-liberaciones-export/index'
import Boletines from './components/boletines/index'
import AdjuntarDocumentosObligatorios from './components/adjuntar-documentos-obligatorios'

import ReglamentoGeneral from './components/reglamento-general'
import TerminosYCondiciones from './components/terminos-y-condiciones'

import CitasMasivas from './components/citas-masivas/index'

import MenuTas from './components/TAS/menu'
import VinculacionEmpresaDeTransporte from './components/TAS/vinculacion-empresa-de-transporte'
import AsignacionPlacasDeCamionesAEmt from './components/TAS/asignacion-placas-de-camiones-a-emt'
import AsignacionDPIPilotosAEmt from './components/TAS/asignacion-dpi-pilotos-a-emt'
import PrevisionDeEscalas from './components/TAS/prevision-de-escalas'

import DisponibilidadCitas from './components/diponibilidad-citas/index'
import YardDensityDashboard from './components/yard-density/index'

import PanelLiberacionesLeyendas from './components/panel-liberaciones-leyendas/index'

import GateStatus from './components/gate-status/index'

function Root() {
    const abortController = new AbortController()
    useEffect(() => {
        const layout = localStorage.getItem('layout_version')
        const color = localStorage.getItem('color')
        document.body.classList.add(layout)
        document.getElementById('color').setAttribute('href', `${process.env.PUBLIC_URL}/assets/css/${color}.css`)
        return function cleanup() {
            abortController.abort()
        }
    })

    return (
        <div className='App'>
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {/* PAGINAS SIN HEADER NI FOOTER DE APMTQ */}
                                {/* <Route path={`${process.env.PUBLIC_URL}/login/:token?`} component={Login} /> */}
                                <Route
                                    path={`${process.env.PUBLIC_URL}/consulta-inteligencia-aduanera-portuaria-iap/:tokenaut?`}
                                    component={ConsultaInteligenciaAduaneraIAP}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/ingreso-solicitudes/:tokenaut?`}
                                    component={IngresoSolicitudes}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/dashboard-naviera/:tokenaut`}
                                    component={inputExceldashbord}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/dashboard-usuario/:tokenaut`}
                                    component={inputDashboardUsers}
                                />
                                {/* <AppAuth> */}
                                <Route
                                    path={`${process.env.PUBLIC_URL}/reglamento-general/:tokenauth`}
                                    component={ReglamentoGeneral}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/terminos-y-condiciones/:tokenauth`}
                                    component={TerminosYCondiciones}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/citas-masivas/:tokenaut`}
                                    component={CitasMasivas}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL}/yard-density/:tokenaut`}
                                    component={YardDensityDashboard}
                                />
                                {/* </AppAuth> */}
                                <Fragment>
                                    <App>
                                        {/* PAGINAS CON HEADER Y FOOTER DE APMTQ */}
                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/`}
                                            render={() => {
                                                return <Redirect to={`${process.env.PUBLIC_URL}/servicios-en-linea`} />
                                            }}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/adjuntar-documentos-obligatorios/:codigouser`}
                                            component={AdjuntarDocumentosObligatorios}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/vinculacion-usuarios`}
                                            component={VinculacionUsuarios}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/registro-funcionarios-y-autoridades`}
                                            component={VinculacionAutoridades}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/recuperacion-de-credenciales`}
                                            component={RecuperacionCredenciales}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/cambio-de-credenciales/:codigouser`}
                                            component={CambioDeCredenciales}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/panel-liberaciones`}
                                            component={PanelLiberaciones}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/panel-liberaciones-export`}
                                            component={PanelLiberacionesExport}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/panel-transportista`}
                                            component={PanelTransportista}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/panel-pre-arribos-import`}
                                            component={PanelPrearribosImport}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/solicitudes-inspecciones-pendientes`}
                                            component={SolicitudesInspeccionesPendientes}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/solicitudes-inspecciones-pendientes-export`}
                                            component={SolicitudesInspeccionesPendientesExportacion}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/solicitudes-programadas-inspeccion`}
                                            component={SolicitudesProgramadas}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/solicitudes-programadas-inspeccion-export`}
                                            component={SolicitudesProgramadasExportacion}
                                        />

                                        {/* <Route
                                            path={`${process.env.PUBLIC_URL}/ords/billing/visanet/:id?`}
                                            component={BillingVisanet}
                                        /> */}

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/billing/visanet/:id?`}
                                            component={BillingVisanet}
                                        />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/servicios-en-linea`}
                                            component={ServiciosEnLinea}
                                        />

                                        <Route path={`${process.env.PUBLIC_URL}/boletines`} component={Boletines} />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/codigo-etica`}
                                            component={CodigoEtica}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/tarifas-apmtq`}
                                            component={TarifasAPMTQ}
                                        />
                                        <Route path={`${process.env.PUBLIC_URL}/empleo-apmtq`} component={Empleo} />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/dashboard-naviera/:tokenaut`}
                                            component={inputExceldashbord}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/consulta-almacenaje-contenedor`}
                                            component={consultaAlmacenaje}
                                        />

                                        <Route path={`${process.env.PUBLIC_URL}/menu-tas`} component={MenuTas} />
                                        <Route
                                            path={`/vinculacion-empresa-transporte`}
                                            component={VinculacionEmpresaDeTransporte}
                                        />
                                        <Route
                                            path={`/asignacion-placas-de-camiones`}
                                            component={AsignacionPlacasDeCamionesAEmt}
                                        />
                                        <Route path={'/asignacion-dpi-pilotos'} component={AsignacionDPIPilotosAEmt} />

                                        <Route path={'/prevision-de-escalas'} component={PrevisionDeEscalas} />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/disponibilidad-citas`}
                                            component={DisponibilidadCitas}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/yard-density/:tokenaut`}
                                            component={YardDensityDashboard}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/panel-liberaciones-react`}
                                            component={PanelLiberacionesLeyendas}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/estado-gate`}
                                            component={GateStatus}
                                        />
                                    </App>
                                </Fragment>
                            </Switch>
                        </Suspense>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorker.unregister()
