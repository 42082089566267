import React, { Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import styled from '@emotion/styled'
import EticaPDF from './Code_of_Conduct_Spa_FINAL.pdf'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const CodigoDeEtica = props => {
    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Código de ética' parent='Servicios' />
            </Titulo>
            <div className='container-fluid mt-3'>
                <div className='card'>
                    <div className='card-body'>
                        <embed src={EticaPDF} height='900px' width='100%' name='embed_content' type='application/pdf' />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CodigoDeEtica
