import React, { Fragment, useState } from 'react'
import Breadcrumb from '../common/breadcrumb'
import styled from '@emotion/styled'
import CartaParaVinculacionPDF from './carta_vinculacion_usuario.pdf'
import Targets from './targets'
import Table from './table'
import Modal from './modal-components/modal'
import { useFetchInitDocumentosVinculacion } from '../../hooks/vinculaciones/useFetchInitDocumentosVinculacion'
import Loading from './loading'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const CajaMensaje = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    background-color: #ff6219;
    font-size: 15px;
    border-radius: 4px;
    color: #ffffff;
    width: 98%;
`

const AdjuntarDocumentosComponent = props => {
    const codigo_encritado = props.match.params.codigouser
    const url_confirmacion = process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/confirmacion'
    const url_listado_vinculacion = process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/listado'
    const url_save_files = process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/files'

    const servicio_confirma_credenciales = 'usuario-encriptado'
    const servicio_tipo_documento = 'tipo-documentos-cliente'
    const servicio_listado_documentos = 'documentos-cliente'
    const servicio_subida_documento = 'add-documentos'
    const servicio_editar_documento = 'editar-documento'
    const servicio_eliminar_documento = 'eliminar-doc-uv'
    const servicio_confirmacion = 'documentacion'
    const token_page = process.env.REACT_APP_TOKEN_PAGE

    const [modal, setModal] = useState()
    const [dataRequest, setDataRequest] = useState({})
    const [titleModal, setTitleModal] = useState('')
    const [componentModal, setComponentModal] = useState('')

    const [
        documentacion,
        setDocumentacion,
        capturandovalores,
        load,
        error,
        handleChange,
        listdoc,
        docclientes,
        setLoad,
        mostrardocuv,
        setMostrardocuv,
        showtipodocsuv,
        setShowtipodocsuv,
    ] = useFetchInitDocumentosVinculacion({
        url_confirmacion,
        servicio_confirma_credenciales,
        token_page,
        codigo_encritado,
        url_listado_vinculacion,
        servicio_subida_documento,
        servicio_editar_documento,
        servicio_tipo_documento,
        servicio_listado_documentos,
    })

    const toggle = (_, title, compont, dataRequest) => {
        setModal(!modal)
        compont && setComponentModal(compont)
        title && setTitleModal(title)
        dataRequest && setDataRequest(dataRequest)
    }

    const { respuesta_credenciales, mensaje_carga, credenciales, carga, codigo_vinculacion } = documentacion
    console.log(documentacion)

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Adjuntar Documentos Obligatorios' parent='Servicios' />
            </Titulo>
            <p> {carga ? 'carga verdadero' : 'carga falso'}</p>
            <p> {respuesta_credenciales ? 'credenciales verdadero' : 'credenciales falso'}</p>

            <Modal
                toggle={toggle}
                modal={modal}
                dataRequest={dataRequest}
                title={titleModal}
                componentModal={componentModal}
                documentacion={documentacion}
                url_confirmacion={url_confirmacion}
                servicio_subida_documento={servicio_subida_documento}
                token_page={token_page}
                servicio_eliminar_documento={servicio_eliminar_documento}
                setLoad={setLoad}
                load={load}
                setMostrardocuv={setMostrardocuv}
                docclientes={docclientes}
                url_save_files={url_save_files}
                setShowtipodocsuv={setShowtipodocsuv}
            />

            <div className='container-fluid'>
                <div className='row mt-3'>
                    {carga ? (
                        <Loading />
                    ) : respuesta_credenciales ? (
                        credenciales ? (
                            <div>
                                <div className='col-sm-12'>
                                    <Targets />
                                </div>

                                <Table
                                    toggle={toggle}
                                    listdoc={listdoc}
                                    token_page={token_page}
                                    servicio_confirmacion={servicio_confirmacion}
                                    codigo_vinculacion={codigo_vinculacion}
                                    url_confirmacion={url_confirmacion}
                                />

                                <div className='col-sm-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <embed
                                                src={CartaParaVinculacionPDF}
                                                height='900px'
                                                width='100%'
                                                name='embed_content'
                                                type='application/pdf'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <CajaMensaje>{mensaje_carga}</CajaMensaje>
                        )
                    ) : (
                        <CajaMensaje>{mensaje_carga}</CajaMensaje>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default AdjuntarDocumentosComponent
