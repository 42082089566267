import React, { Fragment } from 'react'
import { Button } from 'reactstrap'

const AddGestor = ({
    toggle3,
    valor_x,
    v_habilita
    // token_user,
    // token_page,
    // url_valida_gestor
}) => {
    console.log(v_habilita)
    console.log(valor_x)
    const { nombre_gestor, cod_gestor } = valor_x

    return (
        <Fragment>
            <div className='col-sm-12'>
                <div className='card'>
                    {nombre_gestor !== '' && (
                        <div className='card-header b-b-warning  border-3'>
                            <div className='col-sm-12'>
                                <div className='card'>
                                    <div className='media p-20'>
                                        <div className='media-body'>
                                            <h6 className='mt-0 mega-title-badge'>
                                                Gestor agregado a la solicitud
                                                {cod_gestor !== '' ? (
                                                    <span className='badge badge-success pull-right digits'>
                                                        {'Válido '}
                                                        <i className='fa fa-check-square-o'></i>
                                                    </span>
                                                ) : (
                                                    <span
                                                        className='badge pull-right digits'
                                                        style={{ background: 'red', color: 'white' }}
                                                    >
                                                        {'No válido '}
                                                        <i className='fa fa-times-circle-o'></i>
                                                    </span>
                                                )}
                                            </h6>
                                            <p>{nombre_gestor}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {v_habilita && (
                        <div className='card-body col-sm-12 row '>
                            <div className='col-sm-12'>
                                <Button
                                    type='button'
                                    className='btn btn-warning pull-right w-100'
                                    onClick={() => {
                                        toggle3('', 'Agregar gestor', 'OPEN_GESTOR')
                                    }}
                                >
                                    <i className='fa fa-user'></i> &nbsp Agregar gestor
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default AddGestor
