import React, { useState, Fragment } from 'react'
import { InputsClientes } from '../tabs/inputsClientes'
import { TabsClientes } from '../tabs/tabsClientes'
import { useFetchValidacionNit } from '../../../../hooks/vinculaciones/useFetchValidacionNit'
import { MensajeProveedor } from './mensajeProveedor'
import Loading from '../loading'
const PasosVinculacionesCliente = ({
    useFetchInit,
    servicios,
    urlPeticiones
}) => {
    const { vinculacion } = useFetchInit
    const { nit_cliente_vinculacion, perfil_seleccionado } = vinculacion
    const [activeTab, setActiveTab] = useState('1')

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab)
    }

    const [validacionnit, cargaperfil] = useFetchValidacionNit(
        nit_cliente_vinculacion,
        perfil_seleccionado,
        servicios,
        urlPeticiones
    )

    const { v_informacion, v_mensaje, v_vincular } = validacionnit

    return (
        <Fragment>
            {cargaperfil ? <Loading /> : ''}
            {v_vincular && v_informacion === false ? (
                <>
                    <TabsClientes activeTab={activeTab} toggle={toggle} />
                    <InputsClientes
                        activeTab={activeTab}
                        useFetchInit={useFetchInit}
                    />
                </>
            ) : (
                <MensajeProveedor mensaje={v_mensaje} />
            )}
        </Fragment>
    )
}

export default PasosVinculacionesCliente
