import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
// import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
})

export const ConfirmandoVinculacion = ({ mensajevinculacion }) => {
    const { v_mensaje } = mensajevinculacion
    const classes = useStyles()
    // const bull = <span className={classes.bullet}>•</span>
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color='textSecondary' gutterBottom></Typography>
                <Typography variant='h5' component='h2'>
                    ---
                </Typography>
                <Typography className={classes.pos} color='textSecondary'></Typography>
                <Typography variant='body2' component='p'>
                    {v_mensaje}
                </Typography>
            </CardContent>
            <CardActions></CardActions>
        </Card>
    )

    /*
    console.log(vinculacion, estadovinculacion, mensajevinculacion)
    const { v_mensaje, v_resultado } = mensajevinculacion
    // {load ? <Loading /> : vinculacion.respuesta_api}
    // {error ? <span className='text-danger'>{error}</span> : null}

    return <div>{v_mensaje}</div>*/
}
