import React, { Fragment } from "react";

const ModalModule = ({ handleInputChange }) => {
    return (
        <Fragment>
            <div className="container-fluid bd-example-row">
                <div className="row">
                    <div className="col-md-12">
                        <label
                            className="col-form-label"
                            htmlFor="recipient-name"
                        >
                            Usuario:{" "}
                        </label>
                        <input
                            required
                            className="form-control"
                            type="text"
                            placeholder="Nombre de usuario"
                            name="usuario"
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label
                            className="col-form-label"
                            htmlFor="recipient-name"
                        >
                            Contraseña:{" "}
                        </label>
                        <input
                            required
                            className="form-control"
                            type="password"
                            placeholder="Contraseña de usuario"
                            name="password"
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ModalModule;
