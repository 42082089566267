import * as React from 'react'
import { Button, Table } from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    }
}))

export const InformacionSocios = ({
    useFetchInit
}) => {
    const {
        toggle3,
        dsocios,
        setDsocios,
        dinfobancaria,
        dreferencias,
        vinculacion,
        setBtndisableuv,
        dcontacto
    } = useFetchInit

    const {
        nombre_comercial,
        input_modalidad,
        razon_social,
        nombre_cliente,
        codigo_pais,
        codigo_ciudad,
        telefono_uno,
        correo_electronico,
        nit_cliente_vinculacion,
        tipo_persona,
        direccion,
        termino_pago,
        representante_legal,
        puesto_legal,
        tipo_documento,
        perfil_seleccionado
    } = vinculacion

    const classes = useStyles()

    const eliminar_socios = (id) => {
        const arrayFiltrado = dsocios.filter(item => {
            console.log(item)
            return item.socios_id !== id
        })

        setDsocios(arrayFiltrado)
        validar_campos()
    }

    const validar_campos = () => {
        if (perfil_seleccionado === '162') {
            // ------------------ validar proveedores ------------
            if (
                nombre_comercial.trim() !== '' &&
                input_modalidad.trim() !== '' &&
                razon_social.trim() !== '' &&
                nombre_cliente.trim() !== '' &&
                correo_electronico.trim() !== '' &&
                codigo_pais > 0 &&
                codigo_ciudad > 0 &&
                telefono_uno.trim() !== '' &&
                nit_cliente_vinculacion.trim() !== '' &&
                tipo_persona.trim() !== '' &&
                direccion.trim() !== '' &&
                termino_pago.trim() !== '' &&
                representante_legal.trim() !== '' &&
                puesto_legal.trim() !== '' &&
                tipo_documento.trim() !== '' &&
                dreferencias.length > 0 &&
                dinfobancaria.length > 0 &&
                dsocios.length > 0 &&
                dcontacto.length > 0
            ) {
                setBtndisableuv(false)
            } else {
                setBtndisableuv(true)
            }
        } else {
            // ------------------ validar clientes ------------
            if (
                nombre_comercial.trim() !== '' &&
                input_modalidad.trim() !== '' &&
                razon_social.trim() !== '' &&
                nombre_cliente.trim() !== '' &&
                correo_electronico.trim() !== '' &&
                codigo_pais > 0 &&
                codigo_ciudad > 0 &&
                telefono_uno.trim() !== '' &&
                nit_cliente_vinculacion.trim() !== '' &&
                tipo_persona.trim() !== '' &&
                direccion.trim() !== '' &&
                representante_legal.trim() !== '' &&
                puesto_legal.trim() !== '' &&
                tipo_documento.trim() !== '' &&
                dcontacto.length > 0
            ) {
                setBtndisableuv(false)
            } else {
                setBtndisableuv(true)
            }
        }
    }

    validar_campos()

    return (
        <div className={classes.root} noValidate autoComplete='off'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='nada'>
                        <Button
                            type='button'
                            className='btn btn-warning pull-right'
                            onClick={() => {
                                toggle3('', 'Agregar socios', 'OPEN_INFORMACION_SOCIOS')
                            }}
                        >
                            <i className='fa fa-address-book' aria-hidden='true'></i> &nbsp Agregar socios
                        </Button>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='card-header b-b-warning  border-3'>
                        <h5> Socios</h5>
                    </div>
                    {dsocios !== '' && (
                        <div className='card-body'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Accion</th>
                                        <th>Nombre socio</th>
                                        <th>Participacion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dsocios.map(listado_socios => {
                                        return (
                                            <tr key={listado_socios.socios_id}>
                                                <td>
                                                    <Button
                                                        className='btn btn-warning'
                                                        onClick={() => {
                                                            eliminar_socios(listado_socios.socios_id, 'REPORTE')
                                                        }}
                                                    >
                                                        <i className='fa fa-trash-o'></i>
                                                    </Button>
                                                </td>
                                                <td>{listado_socios.socios_nombre_socio}</td>
                                                <td>{listado_socios.socios_participacion}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
