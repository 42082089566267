/**
 * @module components/VinculacionUsuarios/cards
 * @description componente para manejar la vinculación de usuarios
 */
import React, { Fragment } from 'react'
// import Breadcrumb from '../common/breadcrumb';
/**
 * @async
 * @function
 * @name module:components/VinculacionUsuarios#useFetchInitVinculaciones
 * @description useFetchInitVinculaciones: Carga de hook
 * para utilizar dentro del componente
 * @param {string} url_listado_vinculacion - Url de la api
 * @param {string} servicio_perfiles_usuario - Servicio para cargar perfiles
 * @param {string} servicio_modalidad - Servicio para cargar modalidad
 * @param {string} servicio_tipo_persona - Servicio para carga listao de tipos de persona
 * @param {string} servicio_terminos - Servicio para cargar terminos de pago.
 * @param {string} token_page - Token a enviar para carga de página.
 * @see {@link module:hooks/vinculaciones}
 * @example
 * http://dominio/vinculacion-usuarios
 */
// import { useFetchInitVinculaciones } from '../../../hooks/vinculaciones'
import StepsTabsVinculacion from '../modal-components/stepsTabsVinculacion'
import ModalModule from '../modal-components/modal'
import DisclaimerAnuncio from '../../common/disclaimer-anuncios'
/************************ Cargando Hook principal ********************************/
/*********************************************************************************/
const VinculacionUsuarios = ({
    useFetchInit,
    servicios,
    perfilesContacto,
    urlPeticiones,
    ...props
}) => {

    const {capturandovalores} = useFetchInit

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            {/* <Breadcrumb title='Vinculación de Usuarios' parent='Servicios' /> */}
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <div className='col-sm-12'>
                        <ModalModule
                            useFetchInit={useFetchInit}
                            urlPeticiones={urlPeticiones}
                            servicios={servicios}
                            perfilesContacto={perfilesContacto}
                        />
                        <form onSubmit={capturandovalores}>
                            <StepsTabsVinculacion
                                useFetchInit={useFetchInit}
                                urlPeticiones={urlPeticiones}
                                servicios={servicios}
                                // perfilesContacto={perfilesContacto}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VinculacionUsuarios
