import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import styled from '@emotion/styled'

const TituloRed = styled.h5`
    margin-top: 20px
    border: 1px solid red
    padding: 20px
    box-sizing: border-box
    color: red
`

export const FacturarA = ({
    handleInputChange,
    token_user,
    token_page,
    p_valida_nit,
    // p_habilita,
    url_facturar_a
}) => {
    // console.log(p_valida_nit)
    // console.log(p_habilita)

    const [vnombre, setVnombre] = useState([]) // tipos

    useEffect(() => {
        const obtenerNombresDe = async () => {
            const { data } = await axios.get(url_facturar_a, {
                headers: {
                    'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            })
            setVnombre(data)
            //handleInputChange(data)
        }

        obtenerNombresDe()
        return () => {
            console.log('Desmontando')
        }
    }, [token_user, token_page, url_facturar_a])

    return (
        <Fragment>
            <div className='form-group row'>
                <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                    {'Facturar a nombre de'}
                </label>
                <div className='col-sm-9'>
                    <select className='form-control' name='facturar_a' onChange={handleInputChange}>
                        <option value=''>-- Facturar a nombre de --</option>
                        {vnombre.map(categoria => {
                            return (
                                <option
                                    key={categoria.COD_VINCULACION_USUARIO}
                                    value={categoria.COD_VINCULACION_USUARIO}
                                >
                                    {categoria.SOLICITADO_POR}
                                </option>
                            )
                        })}
                    </select>
                    {p_valida_nit.t_flag === true && p_valida_nit.t_respuesta !== null ? (
                        <TituloRed>
                            <p>{p_valida_nit.t_respuesta}</p>
                        </TituloRed>
                    ) : null}
                </div>
            </div>
        </Fragment>
    )
}
