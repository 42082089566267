import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import Breadcrumb from '../common/breadcrumb'
import TotalInventario from './cards/total-inventario'
import TotalContenedoresLlenosImportacion from './cards/total-contenedores-llenos-importacion.js'
import TotalContenedoresLlenosExportacion from './cards/total-contenedores-llenos-exportacion.js'
import Modal from './modal-components/modal'
import { headerSiSat } from './headers/header-si-sat.js'
import { headerNoSat } from './headers/header-no-sat.js'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DataGrid, GridToolbar, GridOverlay, GridPreferencePanelsValue } from '@material-ui/data-grid'
import { useFetchSolicitud } from './../../hooks/solicitudes/useFetchSolicitud'
import { MensajeCarga } from './../ingreso-solicitudes/cards/mensajeCarga'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'
const token_page = process.env.REACT_APP_TOKEN_PAGE
const url_solicitud = process.env.REACT_APP_URL_SOLICITUD

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}

const PreArribos = props => {
    const [load, setLoad] = useState(true)
    const [sizeTab, setSizeTab] = useState(200)
    const [modal, setModal] = useState()
    const [titleModal, setTitleModal] = useState('')
    const [data, setData] = useState([])
    const [dataTargets, setDataTargets] = useState({})
    const [sat, setSat] = useState('')
    const [msg, setMsg] = useState('')
    const [dataRequest, setDataRequest] = useState({})
    const token_user = props.match.params.tokenaut

    const [ctipos, setCtipos] = useFetchSolicitud(token_user, token_page, url_solicitud)

    const awaitDataTargets = async () => {
        const resp = await axios
            .get(process.env.REACT_APP_IAP + '/v1/apm-services/cabecera-tarjetas-iap', {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                },
            })
            .then(res => res)
        setDataTargets(resp.data)
    }

    const awaitData = async () => {
        const resp = await axios
            .get(process.env.REACT_APP_IAP + '/v1/apm-services/inteligencia-aduanera-portuaria', {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                },
            })
            .then(res => res)
        console.log(resp.data)
        setData(resp.data.data)
        setSat(resp.data.sat)
        setLoad(false)
        setSizeTab(1000)
    }

    const toggle3 = (_, title, dataRequest) => {
        setModal(!modal)
        modal && setMsg('')
        title && setTitleModal(title)
        dataRequest && setDataRequest(dataRequest)
    }

    useEffect(() => {
        awaitDataTargets()
        awaitData()
        return () => {
            console.log(`Limpieza`)
        }
    }, [])

    return ctipos.carga ? (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <div className='mt-3'>
                <Breadcrumb title='Consulta Inteligencia Aduanera Portuaria IAP' parent='Servicios' />
                <div className='container-fluid'>
                    <div className='row mt-2'>
                        <TotalInventario {...dataTargets} />
                        <TotalContenedoresLlenosImportacion {...dataTargets} />
                        <TotalContenedoresLlenosExportacion {...dataTargets} />
                    </div>

                    <Modal
                        msg={msg}
                        setMsg={setMsg}
                        props={props}
                        toggle={toggle3}
                        modal={modal}
                        title={titleModal}
                        dataRequest={dataRequest}
                    />

                    <div className='col-sm-12'>
                        <div className='card'>
                            <div style={{ height: sizeTab, width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={sat === 'SI' ? headerSiSat({ toggle3 }) : headerNoSat({ toggle3 })}
                                    pageSize={50}
                                    components={{
                                        LoadingOverlay: CustomLoadingOverlay,
                                        Toolbar: GridToolbar,
                                    }}
                                    loading={load}
                                    state={{
                                        preferencePanel: {
                                            open: false,
                                            openedPanelValue: GridPreferencePanelsValue.filters,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    ) : (
        <MensajeCarga ctipos={ctipos} />
    )
}

export default PreArribos
