import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import termsPDF from './Condiciones_27_12_2023.pdf';

const ModalModule = ({ modal, toggle }) => {
    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='lg'>
                <ModalHeader toggle={toggle}>{'Terms & Conditions'}</ModalHeader>
                <ModalBody>
                    <div>
                        <embed
                            src={termsPDF + '#scrollbar=0'}
                            height='600px'
                            width='100%'
                            name='embed_content'
                            type='application/pdf'
                        />
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default ModalModule;

// '#toolbar=0&navpanes=0&scrollbar=0'
