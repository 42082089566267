import { useState } from 'react'
import { postGenerarNuevasCredenciales } from '../../helpers/vinculaciones/postGenerarNuevasCredenciales'

export const useFetchIniRecuperacion = ({ url_confirmacion, servicion_nuevas_credenciales, token_page }) => {
    /******* boton de pasos *****/

    const [load, setLoad] = useState(false)
    const [error, setError] = useState(null)

    /***********************************************************/
    /***********************************************************************************************/
    const [vinculacion, setVinculacion] = useState({
        respuesta_api: false,
        mensaje_api: '',
        carga: false,
        perfil_carga: false,
        validacion_campos: false,
        usuario_recuperar: '',
        usuario_correo_electronico: ''
    })

    const capturandovalores = e => {
        setLoad(true)
        setVinculacion({ ...vinculacion, respuesta_api: '' })
        e.preventDefault()

        const { usuario_recuperar, usuario_correo_electronico } = vinculacion

        if (usuario_recuperar === '' || usuario_recuperar === undefined) {
            setError('Agregar nombre de usuario.')
            setLoad(false)
            setVinculacion({
                ...vinculacion,
                mensaje_api: '',
                respuesta_api: false,
                validacion_campos: false
            })
            return
        }

        if (usuario_correo_electronico === '' || usuario_correo_electronico === undefined) {
            setError('Agregar correo electrónico para recuperación.')
            setLoad(false)
            setVinculacion({
                ...vinculacion,
                mensaje_api: '',
                respuesta_api: false,
                validacion_campos: false
            })
            return
        }

        postGenerarNuevasCredenciales(url_confirmacion, servicion_nuevas_credenciales, token_page, vinculacion)
            .then(({ v_mensaje, v_resultado }) => {
                if (v_resultado) {
                    setError('')
                    setLoad(false)
                    setVinculacion({
                        ...vinculacion,
                        mensaje_api: v_mensaje,
                        respuesta_api: v_resultado,
                        usuario_recuperar: '',
                        usuario_correo_electronico: '',
                        validacion_campos: true
                    })
                } else {
                    setError('')
                    setLoad(false)
                    setVinculacion({
                        ...vinculacion,
                        mensaje_api: v_mensaje,
                        respuesta_api: false,
                        validacion_campos: true
                    })
                }
            })
            .catch(error => {
                console.log(`Existe un error `,error)
                setLoad(false)
            })
    }

    return [vinculacion, setVinculacion, capturandovalores, load, error]
}
