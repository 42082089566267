import React, { Fragment } from 'react';
import Loading from './loading';

const Form = ({ handleInputChange, loading }) => {
    return (
        <Fragment>
            <div className='col-sm-12'>
                <div className='card'>
                    <div className='card-header b-l-warning border-3'>
                        <h5>{'Completar información'}</h5>
                    </div>
                    <div className='card-body'>
                        <div className='form-group row'>
                            <label className='col-sm-3 col-form-label' htmlFor='inputEmail3'>
                                {'Nombres'}
                            </label>
                            <div className='col-sm-9'>
                                <input
                                    required
                                    className='form-control'
                                    id='nombres'
                                    type='text'
                                    placeholder='Nombres'
                                    name='nombres'
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                                {'Apellidos'}
                            </label>
                            <div className='col-sm-9'>
                                <input
                                    required
                                    className='form-control'
                                    id='apellidos'
                                    type='text'
                                    placeholder='Apellidos'
                                    name='apellidos'
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-sm-3 col-form-label' htmlFor='inputEmail3'>
                                {'Email'}
                            </label>
                            <div className='col-sm-4'>
                                <input
                                    required
                                    className='form-control'
                                    id='inputEmail3'
                                    type='email'
                                    placeholder='Email'
                                    name='email'
                                    onChange={handleInputChange}
                                />
                            </div>
                            <label className='col-sm-2 col-form-label' htmlFor='inputPassword3'>
                                {'Fecha de nacimiento'}
                            </label>
                            <div className='col-sm-3'>
                                <input
                                    required
                                    className='form-control'
                                    id='nacimiento'
                                    type='date'
                                    name='fecha_nacimiento'
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className='form-group row'>
                            <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                                {'Teléfono'}
                            </label>
                            <div className='col-sm-9'>
                                <input
                                    required
                                    className='form-control'
                                    id='telefono'
                                    type='number'
                                    placeholder='Número de teléfono'
                                    name='telefono'
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                                {'Dirección'}
                            </label>
                            <div className='col-sm-9'>
                                <input
                                    required
                                    className='form-control'
                                    id='direccion'
                                    type='text'
                                    placeholder='Dirección de su domicilio'
                                    name='direccion'
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer text-right'>
                        <div className='row'>
                            <div className='col-sm-10'>
                                {loading && (
                                    <div className={'text-right mt-2 mb-2'}>
                                        <Loading />
                                    </div>
                                )}
                            </div>
                            <div className='col-sm-2 mt-1'>
                                <button className='btn btn-outline-warning mr-1 col-sm-12 col-xl-auto' type='submit'>
                                    {'Enviar información'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Form;
