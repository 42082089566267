import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { DataGrid } from '@material-ui/data-grid'
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getTotalGestores } from '../../../helpers/solicitudes/getTotalgestores'
import { getValidaGestor } from '../../../helpers/solicitudes/getValidaGestor'
import Loading from './loading'

const ModalGestor = props => {
    const {
        token_user,
        token_page,
        toggle,
        title,
        url_gestores,
        ctipos,
        setCtipos,
        url_valida_gestor,
        setButtonEnable
    } = props
    const [responseData, setResposeData] = useState([])
    const [load, setLoad] = useState(true)
    const [paginas, setPaginas] = useState({
        p_pagina: 1,
        p_registros: 10,
        p_buscador: ''
    })
    const { p_pagina, p_registros, p_buscador } = paginas
    const [_buscar, setBuscar] = useState({
        busqueda: '',
        cambioEffect: true
    })
    const { busqueda, cambioEffect } = _buscar

    useEffect(() => {
        getTotalGestores(token_user, token_page, url_gestores, p_pagina, p_registros, p_buscador)
            .then(resultado => {
                console.log(resultado)
                setResposeData(resultado)
                setLoad(false)
                busqueda !== '' && setPaginas({ ...paginas, p_pagina: 1 })
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })
        return () => {
            console.log(`...`)
        }
    }, [p_pagina, p_buscador, p_registros, cambioEffect, token_user, token_page, url_gestores])

    const header = [
        {
            field: 'COD_VINCULACION_USUARIO',
            headerName: 'Codigo',
            width: 100,
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    <Button color='primary' onClick={() => agregarGestor(params)}>
                        <i className='fa fa-plus' />
                    </Button>
                </strong>
            )
        },
        {
            field: 'USUARIO',
            headerName: 'Usuario',
            width: 500
        }
    ]

    const handleInputChange = event => {
        setBuscar({
            ...setBuscar,
            [event.target.name]: event.target.value
        })
    }

    const buscar = event => {
        event.preventDefault()
        const { busqueda } = _buscar
        setLoad(true)
        setBuscar({ ..._buscar, cambioEffect: !cambioEffect })
        setPaginas({ ...paginas, p_buscador: busqueda })
        setResposeData([])
    }

    const validarGestor = v_cod_gestor => {
        return new Promise(resolve => {
            getValidaGestor(token_user, token_page, url_valida_gestor, v_cod_gestor)
                .then(resultado => {
                    if (resultado.v_estado === true && resultado.v_estado_gestor === 'ACTIVO') {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
                .catch(error => {
                    console.log(`Existe un error `,error)
                    resolve(false)
                })
        })
    }

    const agregarGestor = async gestor => {
        const respValidaGestor = await validarGestor(gestor.value).then(res => res)
        const { USUARIO } = gestor.row

        if (respValidaGestor) {
            setCtipos({ ...ctipos, cod_gestor: gestor.value, nombre_gestor: USUARIO })
            toast.success('Gestor agregado correctamente!', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            toggle()
            setButtonEnable(false)
        } else {
            setCtipos({ ...ctipos, cod_gestor: '', nombre_gestor: USUARIO })
            toast.error('Gestor no activo ante la SAT!', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            toggle()
            setButtonEnable(true)
        }
    }

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <form onSubmit={buscar} className='col-md-12 mb-4'>
                            <div className='input-group row'>
                                <div className='form-outline col-md-10'>
                                    <input
                                        onChange={handleInputChange}
                                        type='text'
                                        id='form1'
                                        className='form-control'
                                        name='busqueda'
                                        placeholder='Buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <button type='submit' className='btn btn-primary'>
                                        <i className='fa fa-search'></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        {load ? (
                            <Loading />
                        ) : (
                            <DataGrid
                                rows={responseData}
                                columns={header}
                                pageSize={10}
                                sortModel={[
                                    {
                                        field: 'USUARIO',
                                        sort: 'asc'
                                    }
                                ]}
                            />
                        )}
                    </div>
                    <nav aria-label='Page navigation example'>
                        <ul className='pagination justify-content-center'>
                            <li className={`page-item  ${p_pagina === 1 && 'disabled'}`}>
                                {/* <a
                                    className='page-link'
                                    type='button'
                                    onClick={() => {
                                        setPaginas({ ...paginas, p_pagina: p_pagina - 1 })
                                    }}
                                >
                                    Anterior
                                </a> */}
                                <button
                                    className='page-link'
                                    onClick={() => {
                                        setPaginas({ ...paginas, p_pagina: p_pagina - 1 })
                                    }}
                                >
                                    Anterior
                                </button>
                            </li>
                            <li className='page-item'>
                                {/* <a
                                    className='page-link'
                                    type='button'
                                    onClick={() => {
                                        setPaginas({ ...paginas, p_pagina: p_pagina + 1 })
                                    }}
                                >
                                    Siguiente
                                </a> */}
                                <button
                                    className='page-link'
                                    onClick={() => {
                                        setPaginas({ ...paginas, p_pagina: p_pagina + 1 })
                                    }}
                                >
                                    Siguiente
                                </button>

                            </li>
                        </ul>
                    </nav>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}

export default ModalGestor
