import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RetencionesSat from './retenciones-sat';
import Autoridades from './autoridades';
import Apmtq from './apmtq';
import Atc from './atc';
import LoaderRespUser from './loading-user';
import FormModal from './form';
import Rayosx from './rayosx';

const ModalModule = props => {
    const { modal, toggle, title, setDat, showData, componentModal, dataRequest, loginMsg, setLoginMsg } = props;
    const [responseData, setResponseData] = useState({});
    const [loginValid, setLoginValid] = useState(false);
    const [datos, setDatos] = useState({
        usuario: '',
        password: '',
    });

    const handleInputChange = event => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };

    const sendRequest = async (usuario, password) => {
        setLoginValid(true);
        setLoginMsg('');
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/auth/panel-liberaciones',
                {
                    credenciales: {
                        user: usuario,
                        pswd: password,
                    },
                    servicio: componentModal,
                    contenedor: dataRequest.CONTENEDOR,
                    buque: dataRequest.BUQUE,
                    viaje: dataRequest.VIAJE,
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => {
                setResponseData(res.data);
                setDat(true);
            })
            .catch(error => setLoginMsg(error.response.data.msg));
        setLoginValid(false);
    };

    const enviarDatos = event => {
        event.preventDefault();
        const { usuario, password } = !!datos && datos;
        sendRequest(usuario, password);
    };

    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size={showData && 'lg'}>
                {!showData ? (
                    <form onSubmit={enviarDatos}>
                        <ModalHeader toggle={toggle}>{title}</ModalHeader>
                        <ModalBody>
                            {loginValid ? <LoaderRespUser /> : <FormModal handleInputChange={handleInputChange} />}
                        </ModalBody>
                        <ModalFooter>
                            <p style={{ color: 'red' }}>{loginMsg}</p>
                            <Button color='gray' onClick={toggle}>
                                Cancelar
                            </Button>
                            <Button className='btn btn-warning' type='submit'>
                                Ingresar
                            </Button>
                        </ModalFooter>
                    </form>
                ) : componentModal === 'sat' ? (
                    <RetencionesSat {...props} responseData={responseData} />
                ) : componentModal === 'autoridades' ? (
                    <Autoridades {...props} responseData={responseData} />
                ) : componentModal === 'apmtq' ? (
                    <Apmtq {...props} responseData={responseData} />
                ) : componentModal === 'atc' ? (
                    <Atc {...props} responseData={responseData} />
                ) : componentModal === 'rayosx' ? (
                    <Rayosx {...props} responseData={responseData} />
                ) : (
                    <LoaderRespUser />
                )}
            </Modal>
        </Fragment>
    );
};

export default ModalModule;
