import { useState, useEffect } from 'react'
import { getTipoContacto } from '../../helpers/vinculaciones/getTipoContacto'

export const useFetchTipoContacto = (
    url_listado_vinculacion,
    servicio_tipo_contacto,
    tipo_contacto,
    perfil_seleccionado,
    token_page
) => {
    const [tipocontacto, setTipoContacto] = useState([]) // tipos

    useEffect(() => {
        getTipoContacto(url_listado_vinculacion, servicio_tipo_contacto, tipo_contacto, token_page)
            .then(resultado => {
                setTipoContacto(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error )
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [url_listado_vinculacion, servicio_tipo_contacto, perfil_seleccionado, token_page, tipo_contacto])
    // tipo_contacto revisar si lo dejamos en la dependencia.
    return [tipocontacto]
}
