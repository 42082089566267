import axios from 'axios'

export const GetSubTipos = async (token_user, token_page, url_subtipos, valor_tipo) => {
    const url_sub_tipos = `${url_subtipos}?v_tipo_solicitud=${encodeURI(valor_tipo)}`

    const retorno = await axios
        .get(url_sub_tipos, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
