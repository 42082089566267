import { postConfirmarSolicitudes } from './postConfirmarSolicitudes'

export const FetchConfirmacionSolicitud = async ({
    token_user,
    token_page,
    url_confirmar_solicitudes,
    solicitud_apex,
    en_nombre_de,
    tipo_solicitud,
    sub_tipo_solicitud,
    facturar_a,
    cod_gestor,
    observacion,
    fechaRetiro,
    setRespuestaSolicitud,
    respuestaSolicitud,
    documentacionCorrecta,
    validaFecha
}) => {
    if (documentacionCorrecta || validaFecha) {
        const { v_respuesta, v_mensaje, v_estado } = await postConfirmarSolicitudes(
            token_user,
            token_page,
            url_confirmar_solicitudes,
            solicitud_apex,
            en_nombre_de,
            tipo_solicitud,
            sub_tipo_solicitud,
            facturar_a,
            cod_gestor,
            observacion,
            fechaRetiro
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_respuesta: false
                }
            })
        console.log('CONFIRMACION SOLICITUD------------------>', v_respuesta, v_mensaje, v_estado)
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            // valida_documentos: sub_tipo_solicitud === 341 ? '' : 'Si tiene agregados los documentos',
            // estado_documentos: true,
            confirma_solicitud: v_mensaje,
            estado_solicitud: v_respuesta === 'true',
            mostrar_respuestas: true
        })
        const confirmaSolicitud = v_estado === true && v_respuesta === 'true'
        return { confirmaSolicitud }
    } else {
        return { confirmaSolicitud: false }
    }
}
