import axios from 'axios'

export const postGenerarNuevasCredenciales = async (
    url_confirmacion,
    servicion_nuevas_credenciales,
    token_page,
    vinculacion
) => {
    // se cambia a la variable porque lo resibe distinto
    let servicio = ''
    servicio = servicion_nuevas_credenciales
    const { usuario_recuperar, usuario_correo_electronico } = vinculacion

    const retorno = await axios
        .post(
            url_confirmacion,
            {
                servicio,
                usuario_recuperar,
                usuario_correo_electronico
            },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return {
                v_mensaje: data.v_mensaje,
                v_resultado: data.v_resultado
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_mensaje: '500',
                    v_resultado: ''
                }
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_mensaje: 'Error de autorización',
                    v_resultado: false
                }
            } else {
                console.log(e.response.data)
                return {
                    v_mensaje: 'ERROR',
                    v_resultado: ''
                }
            }
        })

    return retorno
}
