import React, { useState, Fragment } from 'react'
import logo from '../../../assets/images/endless-logo.png'
import home from '../../../assets/images/home.png'
// import whistle from '../../../assets/images/whistle.png';
import { Link } from 'react-router-dom'
import citas from '../../../assets/images/clock.png'
import logo_denuncia from '../../../assets/images/logo_denuncia.png'
import balanceScale from '../../../assets/images/balance-scale.png'
import priceTag from '../../../assets/images/price-tag.png'
// import cambioContrasena from '../../../assets/images/restablecer-la-contrasena.png'
// import SearchHeader from './searchHeader'
import { Maximize, MoreHorizontal } from 'react-feather'
import { UncontrolledTooltip } from 'reactstrap'

const Header = () => {
    // const [sidebar, setSidebar] = useState(false);
    const [headerbar, setHeaderbar] = useState(true)

    // const openCloseSidebar = () => {
    //     if (sidebar) {
    //         setSidebar(!sidebar);
    //         document.querySelector('.page-main-header').classList.remove('open');

    //     } else {
    //         setSidebar(!sidebar);
    //         document.querySelector('.page-main-header').classList.add('open');
    //     }
    // };

    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    let redirHome = () => {
        window.open('https://www.apmterminals.com/', '_blank')
    }
    let redirHomePuertoQuetzal = () => {
        window.open('https://www.apmterminals.com/en/puerto-quetzal', '_blank')
    }

    let redirDenuncias = () => {
        window.open('https://secure.ethicspoint.eu/domain/media/eseu/gui/102833/index.html', '_blank')
    }

    let redirCitas = () => {
        window.open('https://www.portal.apmterminalsquetzal.com/onlineservices/login', '_blank')
    }

    const redirectChangePassword = () => {
        window.open('https://www.services.apmterminalsquetzal.com/ords/f?p=102:25', '_blank')
    }

    return (
        <Fragment>
            <div className='page-main-header'>
                <div className='main-header-right row'>
                    <div className='logo-wrapper'>
                        <img className='img-fluid cursor w-logo' src={logo} alt='logo' onClick={redirHome} />
                    </div>

                    {/* <div className='mobile-sidebar d-block'>
                        <div className='media-body text-right switch-sm'>
                            <label className='switch'>
                                <a href='#javascript' onClick={() => openCloseSidebar()}></a>
                            </label>
                        </div>
                    </div> */}

                    <div className='nav-right col p-1 text-center'>
                        <ul className={`nav-menus ${headerbar ? '' : 'open overflow-auto'}`}>
                            <li>{/* <SearchHeader /> */}</li>
                            <li>
                                {/* <Clock className='cursor w-30 text-warning' onClick={redirCitas} /> */}
                                <img className='img-fluid cursor w-40' src={citas} alt='logo' onClick={redirCitas} />
                                <p className='mb-0'>Citas</p>
                            </li>
                            <li>
                                <Link to='/tarifas-apmtq'>
                                    <img id='tarifas' className='img-fluid cursor w-40' src={priceTag} alt='logo' />
                                </Link>
                                <p className='mb-0'>Tarifas</p>
                            </li>
                            <li>
                                <Link to='/codigo-etica'>
                                    <img id='etica' className='img-fluid cursor w-40' src={balanceScale} alt='logo' />
                                </Link>
                                <p className='mb-0'>Ética</p>
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                                <img id='denuncias' className='img-fluid cursor w-45 mt-2' src={logo_denuncia} alt='logo' onClick={redirDenuncias} />
                                <p className='mb-0'>Denuncias</p>
                            </li>
                            <li>
                                <img id='home' className='img-fluid cursor w-40' src={home} alt='logo' onClick={redirHomePuertoQuetzal} />
                                <p className='mb-0'>Principal</p>
                            </li>
                            <li className='row'>
                                <div className='cursor' onClick={redirectChangePassword} style={{ display: 'flex' }}>
                                    <div>
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' style={{ width: '42px', height: '42px', marginTop: '0px', fill: '#ff650c' }}>
                                            <path d='M10.125 8.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75zm2.5 2.813c1.726 0 3.125 1.482 3.125 3.31V17h-1.875v-2.253c0-.816-.585-1.435-1.25-1.435h-5c-.665 0-1.25.619-1.25 1.435V17H4.5v-2.253c0-1.828 1.4-3.31 3.125-3.31zM10.125 3a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5z'></path>
                                        </svg>
                                    </div>
                                    <div style={{ width: 'max-content', placeSelf: 'center' }}>
                                        <p className='mb-0' style={{ fontSize: '15px' }}>
                                            &nbsp;/&nbsp;Cambio de Contraseña
                                        </p>
                                    </div>
                                </div>
                                {/* <img id='cambiopass' className='img-fluid cursor w-44' src={cambioContrasena} alt='logo' onClick={redirectChangePassword} /> */}
                            </li>
                            <li>
                                {/* <a onClick={goFull} className='text-dark' href='#!'> */}
                                <Maximize onClick={goFull} className='text-warning cursor' id='max' />
                                {/* </a> */}
                            </li>
                        </ul>
                        <div className='d-lg-none mobile-toggle pull-right' onClick={() => setHeaderbar(!headerbar)}>
                            <MoreHorizontal />
                        </div>
                    </div>
                </div>
            </div>
            <UncontrolledTooltip placement='bottom' target='max'>
                Maximizar
            </UncontrolledTooltip>
            <UncontrolledTooltip placement='bottom' target='home'>
                Ir a la Página Constitucional de APM Terminals Quetzal
            </UncontrolledTooltip>
            <UncontrolledTooltip placement='bottom' target='denuncias'>
                Sistema de denuncias
            </UncontrolledTooltip>
            <UncontrolledTooltip placement='bottom' target='etica'>
                Código de Ética
            </UncontrolledTooltip>
            <UncontrolledTooltip placement='bottom' target='tarifas'>
                Tarifas
            </UncontrolledTooltip>
            {/* <UncontrolledTooltip placement='bottom' target='cambiopass'>
                Cambio de contraseña
            </UncontrolledTooltip> */}
        </Fragment>
    )
}
export default Header
