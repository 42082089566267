import { useState, useEffect } from 'react'
import { getModalidad } from '../../helpers/vinculaciones/getModalidad'

export const useFetchModalidad = (url_listado_vinculacion, servicio_modalidad, token_page) => {
    const [modalidad, setModalidad] = useState([]) // tipos

    useEffect(() => {
        getModalidad(url_listado_vinculacion, servicio_modalidad, token_page)
            .then(resultado => {
                setModalidad(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {
            console.log(`Limpieza en tipos de.`)
        }
    }, [url_listado_vinculacion, servicio_modalidad, token_page])

    return [modalidad]
}
