import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FacturasVencidas from './faturas-vencidas';
import Vgm from './vgm';
import LoaderRespUser from './loading-user';
import FormModal from './form';

const ModalModule = props => {
    const {
        modal,
        toggle,
        title,
        setDat,
        showData,
        componentModal,
        dataRequest,
        loginMsg,
        setLoginMsg,
        solicitud,
        setSolicitud,
    } = props;

    const [responseData, setResponseData] = useState([]);
    const [loginValid, setLoginValid] = useState(false);
    const [datos, setDatos] = useState({
        usuario: '',
        password: '',
    });

    const [vgmTipo, setVgmTipo] = useState([]);
    const [vgmSubipo, setVgmSubipo] = useState([]);
    const [vgmANombreDe, setVgmANombreDe] = useState([]);

    const handleInputChange = event => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };

    const getFactVenc = async (usuario, password) => {
        setLoginValid(true);
        setLoginMsg('');
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/auth/panel-liberaciones/export',
                {
                    credenciales: {
                        user: usuario,
                        pswd: password,
                    },
                    servicio: componentModal,
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => {
                setResponseData(res.data);
            })
            .catch(error => setLoginMsg(error.response.data.msg));
        setDat(true);
        setLoginValid(false);
    };

    const TipoSolicitudF = async (usuario, password) => {
        setLoginValid(true);
        setLoginMsg('');
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/auth/panel-liberaciones/export',
                {
                    credenciales: {
                        user: usuario,
                        pswd: password,
                    },
                    servicio: 'vgm-tipo',
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => {
                setVgmTipo(res.data);
                setDat(true);
            })
            .catch(error => setLoginMsg(error.response.data.msg));

        setLoginValid(false);
    };

    const SubtipoSolicitudF = async (usuario, password) => {
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/auth/panel-liberaciones/export',
                {
                    credenciales: {
                        user: usuario,
                        pswd: password,
                    },
                    servicio: 'vgm-subtipo',
                    cod_tipo: solicitud.cod_tipo,
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => setVgmSubipo(res.data));
    };

    const ANombreDeF = async (usuario, password) => {
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + '/v1/apm-services/auth/panel-liberaciones/export',
                {
                    credenciales: {
                        user: usuario,
                        pswd: password,
                    },
                    servicio: 'vgm-anombrede',
                },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => {
                setVgmANombreDe(res.data);
                setDat(true);
            });
    };

    const sendVgm = event => {
        event.preventDefault();
        const { usuario, password } = !!datos && datos;
        ANombreDeF(usuario, password);
        TipoSolicitudF(usuario, password);
    };

    const sendFactVenc = event => {
        event.preventDefault();
        const { usuario, password } = !!datos && datos;
        getFactVenc(usuario, password);
    };

    useEffect(() => {
        const { usuario, password } = !!datos && datos;
        componentModal && SubtipoSolicitudF(usuario, password);
        return () => {
            console.log(`Limpieza en Panel`);
        };
    }, [solicitud.cod_tipo]);

    return (
        <Fragment>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size={showData ? 'lg' : ''}>
                {!showData ? (
                    <form onSubmit={componentModal === 'facturas-vencidas' ? sendFactVenc : sendVgm}>
                        <ModalHeader toggle={toggle}>{title}</ModalHeader>
                        <ModalBody>
                            {loginValid ? <LoaderRespUser /> : <FormModal handleInputChange={handleInputChange} />}
                        </ModalBody>
                        <ModalFooter>
                            <p style={{ color: 'red' }}>{loginMsg}</p>
                            <Button color='gray' onClick={toggle}>
                                Cancelar
                            </Button>
                            <Button className='btn btn-warning' type='submit'>
                                Ingresar
                            </Button>
                        </ModalFooter>
                    </form>
                ) : componentModal === 'facturas-vencidas' ? (
                    <FacturasVencidas {...props} responseData={responseData} />
                ) : componentModal === 'vgm' ? (
                    <Vgm
                        {...props}
                        datos={datos}
                        responseData={responseData}
                        vgmTipo={vgmTipo}
                        vgmANombreDe={vgmANombreDe}
                        solicitud={solicitud}
                        setSolicitud={setSolicitud}
                        vgmSubipo={vgmSubipo}
                        setVgmSubipo={setVgmSubipo}
                    />
                ) : (
                    <LoaderRespUser />
                )}
            </Modal>
        </Fragment>
    );
};

export default ModalModule;
