import React, { Fragment, useState } from 'react'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
// import Header from '../common/external-portal/Header.js';
import Breadcrumb from '../common/external-portal/Breadcrumb.js'
import axios from 'axios'
import { TextField, Grid } from '@material-ui/core'

import ReactDataGrid from 'react-data-grid'

import 'react-dual-listbox/lib/react-dual-listbox.css'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

import gridCitas from './gridCitas.js'
import Swal from 'sweetalert2'
import catalog from './catalog.js'
import Csv from '../common/csv.js'

const CitasMasivas = props => {
    const token_user = props.match.params.tokenaut
    //console.log('token user: '+token_user);
    const [data, setData] = useState({ rows: {}, selectedIndexes: [] })
    const [getcolumns, setColumns] = useState([])
    const [getAllcolumns, setAllColumns] = useState([])
    const [load, setLoad] = useState(false)
    const [booking, setBooking] = useState({ booking: '' })
    const [validBooking, setValidBooking] = useState(true)
    const [bookingError, setBookingError] = useState('')
    const [generalData, setgeneralData] = useState([])
    const [transporteCedido, setTransporteCedido] = useState(false)

    const getCsv = () => {
        console.log('Getting Csv')
        document.getElementById('csvLink').click()
    }

    const onSaveGrid = () => {
        try {
            let editable = validarBooking()
            let flagValid = true
            console.log(`Dentro de onSaveGrid`)
            if (editable || generalData['editable'] === 'TRUE') {
                if (data.rows.length > 0) {
                    Swal.fire({
                        title: '¿Estás seguro de Guardar los registros?',
                        text: 'Este paso NO es Reversible!',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#FF6203',
                        cancelButtonColor: '#004165',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'Cancelar',
                    }).then(async result => {
                        if (result.isConfirmed) {
                            console.log('saving')

                            data.rows.forEach(row => {
                                if (row.piloto === null) {
                                    flagValid = false
                                } else if (row.piloto.toString().trim() === '') {
                                    flagValid = false
                                }

                                if (row.placa === null) {
                                    flagValid = false
                                } else if (row.placa.toString().trim() === '') {
                                    flagValid = false
                                }
                            })

                            if (flagValid) {
                                //console.log(`Rows: ${data.rows.length} - cantidad: ${generalData['cantidad_x_camion']} - citas: ${generalData['cantidad_citas']}`)
                                if (
                                    data.rows.length * generalData['cantidad_x_camion'] >
                                        generalData['cantidad_citas'] &&
                                    generalData['estado_booking'] !== 'FINAL'
                                ) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Citas Excedidas..',
                                        text: `Revise los datos nuevamente`,
                                        confirmButtonColor: '#FF6203',
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Espere un Momento...',
                                        allowEscapeKey: false,
                                        allowOutsideClick: false,
                                        onOpen: () => {
                                            Swal.showLoading()
                                        },
                                    }).then(
                                        () => {},
                                        dismiss => {}
                                    )

                                    await axios({
                                        method: 'POST',
                                        url:
                                            process.env.REACT_APP_EXTERNAL_PANEL +
                                            '/v1/apm-services/citas-masivas/schedule',
                                        headers: {
                                            authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                                            'x-auth-token': token_user,
                                        },
                                        data: {
                                            transaction: generalData,
                                            rows: data.rows,
                                        },
                                    })
                                        .then(async res => {
                                            Swal.close()
                                            if (res.data.data[0].response === 'SUCCESS') {
                                                console.log('Saving Exitoso')
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: 'Transacción Exitosa..',
                                                    text: 'Guardado Exitosamente!',
                                                    confirmButtonColor: '#FF6203',
                                                })
                                                await new Promise(r => setTimeout(r, 2000))
                                                window.location.reload()
                                            } else {
                                                console.log('Error saving PR')
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Ha ocurrido un Error..',
                                                    text: `Intente Nuevamente`,
                                                    confirmButtonColor: '#FF6203',
                                                })
                                            }
                                        })
                                        .catch(err => {
                                            console.log(`Error Saving Axios: ${err}`)
                                            Swal.close()
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Ha ocurrido un Error..',
                                                text: `Intente Nuevamente`,
                                                confirmButtonColor: '#FF6203',
                                            })
                                        })
                                }
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Datos Incompletos..',
                                    text: `Por favor ingrese todos los campos requeridos`,
                                    confirmButtonColor: '#FF6203',
                                })
                            }
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Ningún Registro Encontrado..',
                        text: 'Por favor agregue almenos un registro',
                        confirmButtonColor: '#FF6203',
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const onDeleteGrid = () => {
        try {
            let selected = data.selectedIndexes
            //console.log(`Data selected: ${JSON.stringify(selected)}`);
            let editable = validarBooking()
            if (editable) {
                if (selected.length > 0) {
                    Swal.fire({
                        title: 'Estás seguro de Eliminar los Registros?',
                        text: 'Este paso NO es Reversible!',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#FF6203',
                        cancelButtonColor: '#004165',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'Cancelar',
                    }).then(async result => {
                        if (result.isConfirmed) {
                            const rows = data['rows']
                            let newRows = []
                            //console.log(`Tam: ${rows.length}`);
                            for (let i = 0; i < rows.length; i++) {
                                if (!selected.includes(i)) {
                                    newRows.push(rows[i])
                                }
                            }
                            setData({ ...data, rows: newRows, selectedIndexes: [] })
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Ningún Registro Seleccionado..',
                        text: 'Por favor seleccione almenos un registro',
                        confirmButtonColor: '#FF6203',
                    })
                }
            }
        } catch (e) {
            console.log(`Error al eliminar: ${e}`)
        }
    }

    const onAddGrid = () => {
        console.log(`Dentro de On Add Grid`)
        let editable = validarBooking()
        if (editable) {
            const rows = data['rows']
            rows.push({ piloto: null, placa: null })
            setData({ ...data, rows: rows })
        }
    }

    const cederTransporte = async () => {
        console.log('Cediendo Transporte: ')
        let editable = validarBooking()
        if (editable || generalData['editable'] == 'TRUE') {
            if (!transporteCedido) {
                console.log('Cediendo transporte!!!!')
                Swal.fire({
                    title: 'Espere un Momento...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    onOpen: () => {
                        Swal.showLoading()
                    },
                }).then(
                    () => {},
                    dismiss => {}
                )

                let columns = await catalog(null, token_user, generalData['estado_booking'], generalData['editable'])
                if (columns.length > 0) {
                    setAllColumns(columns)
                    setTransporteCedido(true)
                }
                Swal.close()
            } else {
                Swal.fire({
                    icon: 'info',
                    title: 'Transporte Ya Cedido..',
                    text: 'Ya se ha Cedido transporte Previamente',
                    confirmButtonColor: '#FF6203',
                })
            }
        }
    }

    const getDataTransporte = async () => {
        try {
            let valid = true
            if (!booking['booking'] || booking['booking'].trim() == '') {
                setBookingError('Campo Requerido')
                setValidBooking(false)
                valid = false
            }

            if (valid) {
                setTransporteCedido(false)
                setLoad(false)
                console.log('Enviando: ', booking['booking'])
                let grid = await gridCitas(booking['booking'].trim(), token_user).then(res => res)
                if (grid.columns.length > 0 && grid.rows.length > 0) {
                    //console.log('Columns: '+JSON.stringify(grid.columns))
                    setColumns(grid.columns)
                    setData({ ...data, rows: grid.rows })
                    setgeneralData(grid.transaction)
                    //console.log('rows: ', JSON.stringify(grid.rows));
                    setLoad(true)
                } else {
                    setLoad(false)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Por Favor..',
                    text: 'Revisa los datos Ingresados',
                    confirmButtonColor: '#FF6203',
                })
            }
        } catch (e) {
            console.log(`Error No controlado: ${e}`)
            Swal.fire({
                icon: 'error',
                title: 'Por Favor..',
                text: 'Revisa los datos Ingresados',
                confirmButtonColor: '#FF6203',
            })
            setLoad(false)
        }
    }

    const validarBooking = () => {
        let flagBooking = true
        console.log('Validando booking')
        if (generalData['estado_booking'] !== 'PENDI') {
            Swal.fire({
                icon: 'error',
                title: 'Booking No Editable..',
                text: 'Las citas ya fueron confirmadas',
                confirmButtonColor: '#FF6203',
            })
            flagBooking = false
        }

        return flagBooking
    }

    /* 
    const onChangeSelect = (selected) => {
        setSelected({ selected });
    };
*/

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const rows = data['rows'].slice()
        let selected = data.selectedIndexes
        //console.log(`selected: ${selected} - from: ${fromRow} - to: ${toRow} - updated: ${JSON.stringify(updated)}`)
        for (let i = fromRow; i <= toRow; i++) {
            rows[i] = { ...rows[i], ...updated }
        }

        selected.forEach(row => {
            rows[row] = { ...rows[row], ...updated }
        })
        //console.log('rows: '+JSON.stringify(rows))
        setData({ ...data, rows: rows })
    }

    const onRowsSelected = rows => {
        let temp = data['selectedIndexes'].concat(rows.map(r => r.rowIdx))

        setData({ ...data, selectedIndexes: temp })
    }

    const onRowsDeselected = rows => {
        let rowIndexes = rows.map(r => r.rowIdx)
        let temp = data['selectedIndexes'].filter(i => rowIndexes.indexOf(i) === -1)
        setData({ ...data, selectedIndexes: temp })
    }

    const rowText = data['selectedIndexes'].length === 1 ? 'Row seleccionado' : 'Rows seleccionados'

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Breadcrumb title='Asignación de Pilotos' />
            <Container fluid>
                <Row className='mt-4'>
                    <Col md={{ span: 10, offset: 4 }}>
                        <Grid container direction='row' alignItems='center' spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    error={!validBooking}
                                    helperText={bookingError}
                                    fullWidth
                                    autoComplete='no'
                                    label='Booking:'
                                    value={booking['booking']}
                                    onChange={e => {
                                        setValidBooking(true)
                                        setBookingError('')
                                        setBooking({ booking: e.target.value })
                                    }}
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Button
                                    variant='contained'
                                    onClick={getDataTransporte}
                                    style={{ backgroundColor: '#FF6203', color: '#FFFFFF', fontWeight: 'bold' }}
                                >
                                    Siguiente
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                </Row>
                {load && (
                    <Row className='mt-4'>
                        <Col className='sm-3'>
                            <Card style={{ padding: '15px' }}>
                                <h6>
                                    <strong>Iso: </strong>
                                    {data['rows'].length > 0 ? ' ' + generalData['iso'] : ''}
                                </h6>
                                <h6>
                                    <strong>Cantidad de citas x camion:</strong>
                                    {data['rows'].length > 0 ? ' ' + generalData['cantidad_x_camion'] : ''}
                                </h6>
                                <h6>
                                    <strong>Citas x hora:</strong>
                                    {data['rows'].length > 0 ? ' ' + generalData['cantidad_citas'] : ''}
                                </h6>
                                <h6>
                                    <strong>Fecha Fin:</strong>
                                    {data['rows'].length > 0 ? ' ' + generalData['fecha_fin'] : ''}
                                </h6>
                                <div style={{ textAlign: 'right', marginRight: '75px' }}>
                                    {generalData['estado_booking'] === 'FINAL' ? (
                                        <div>
                                            <Csv
                                                id='csvLink'
                                                filename={`BOOKING-${booking['booking'].trim()}`}
                                                data={data['rows']}
                                                headers={getcolumns.map(column => {
                                                    return { label: column.name, key: column.key }
                                                })}
                                            />
                                            <Button
                                                variant='contained'
                                                onClick={getCsv}
                                                style={{
                                                    backgroundColor: '#004165',
                                                    color: '#FFFFFF',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Exportar
                                            </Button>{' '}
                                            <span></span>
                                            {generalData['editable'] === 'TRUE' ? (
                                                <Button
                                                    variant='contained'
                                                    onClick={onSaveGrid}
                                                    style={{
                                                        backgroundColor: '#004165',
                                                        color: '#FFFFFF',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Guardar
                                                </Button>
                                            ) : (
                                                ''
                                            )}{' '}
                                            <span></span>
                                            {generalData['editable'] === 'TRUE' ? (
                                                <Button
                                                    variant='contained'
                                                    onClick={cederTransporte}
                                                    style={{
                                                        backgroundColor: '#004165',
                                                        color: '#FFFFFF',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Ceder Transporte
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                variant='contained'
                                                onClick={onDeleteGrid}
                                                style={{
                                                    backgroundColor: '#004165',
                                                    color: '#FFFFFF',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Borrar
                                            </Button>{' '}
                                            <span></span>
                                            <Button
                                                variant='contained'
                                                onClick={onAddGrid}
                                                style={{
                                                    backgroundColor: '#004165',
                                                    color: '#FFFFFF',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Agregar
                                            </Button>{' '}
                                            <span></span>
                                            <Button
                                                variant='contained'
                                                onClick={cederTransporte}
                                                style={{
                                                    backgroundColor: '#004165',
                                                    color: '#FFFFFF',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Ceder Transporte
                                            </Button>{' '}
                                            <span></span>
                                            <Button
                                                variant='contained'
                                                onClick={onSaveGrid}
                                                style={{
                                                    backgroundColor: '#004165',
                                                    color: '#FFFFFF',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Guardar
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <span>
                                        {data['selectedIndexes'].length} {rowText}
                                    </span>
                                    {transporteCedido ? (
                                        <ReactDataGrid
                                            columns={getAllcolumns}
                                            rowGetter={i => data['rows'][i]}
                                            rowsCount={data['rows'].length}
                                            minHeight={500}
                                            onGridRowsUpdated={onGridRowsUpdated}
                                            enableCellSelect={true}
                                            rowSelection={{
                                                showCheckbox: true,
                                                enableShiftSelect: true,
                                                onRowsSelected: onRowsSelected,
                                                onRowsDeselected: onRowsDeselected,
                                                selectBy: {
                                                    indexes: data.selectedIndexes,
                                                },
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {transporteCedido == false ? (
                                        <ReactDataGrid
                                            columns={getcolumns}
                                            rowGetter={i => data['rows'][i]}
                                            rowsCount={data['rows'].length}
                                            minHeight={500}
                                            onGridRowsUpdated={onGridRowsUpdated}
                                            enableCellSelect={true}
                                            rowSelection={{
                                                showCheckbox: true,
                                                enableShiftSelect: true,
                                                onRowsSelected: onRowsSelected,
                                                onRowsDeselected: onRowsDeselected,
                                                selectBy: {
                                                    indexes: data.selectedIndexes,
                                                },
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default CitasMasivas
