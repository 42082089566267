import React from 'react'
import { useFetchInitDisclaimerAnuncios } from '../../../hooks/disclaimer-anuncios/useFetchInitDisclaimerAnuncios'
import Modal from './modal-components/modal'

const MaimModal = props => {
    const url = props.location.pathname.replace('/', '')
    const access = {
        url_disclaimer_anuncios: `${process.env.REACT_APP_EXTERNAL_PANEL_ES6_VERSION}/v1/apm-services/unaffectedly/disclaimer-anuncios`,
        url_page: url,
    }

    const useFetchInit = useFetchInitDisclaimerAnuncios({
        access,
    })

    return (
        <>
            <Modal useFetchInit={useFetchInit} access={access} />
        </>
    )
}

export default MaimModal
