import React from 'react'
import { Button } from 'reactstrap'
import TextField from '@material-ui/core/TextField'

export const Ciudades = ({ toggle3, vinculacion }) => {
    const { nombre_ciudad } = vinculacion

    return (
        <div className='row'>
            <div className='col-md-6'>
                {nombre_ciudad !== '' && (
                    <>
                        <TextField
                            id='filled-read-only-input'
                            label='Ciudad'
                            value={nombre_ciudad}
                            InputProps={{
                                readOnly: true
                            }}
                            variant='filled'
                        />
                    </>
                )}
            </div>
            <div className='col-md-6'>
                {' '}
                <Button
                    type='button'
                    className='btn btn-warning pull-right'
                    onClick={() => {
                        toggle3('', 'Agregar país', 'OPEN_CIUDADES')
                    }}
                >
                    <i className='fa fa-building-o' aria-hidden='true'></i> &nbsp Agregar ciudad
                </Button>
            </div>
        </div>
    )
}
