export class ConfigDB {
    static data = {
        settings: {
            layout_type: 'ltr',
            sidebar: {
                type: 'horizontal_sidebar',
                body_type: '',
            },
            sidebar_setting: 'horizontal-sidebar',
            sidebar_backround: 'dark-sidebar',
        },
        color: {
            layout_version: 'null',
            color: 'null',
            primary_color: 'null',
            secondary_color: 'null',
            mix_layout: 'default',
        },
        router_animation: 'fadeIn',
    };
}

export default ConfigDB;
