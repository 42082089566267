import React from 'react'
import {Card,CardGroup} from "react-bootstrap";
const CardsDashboard = (props) => {
    let cards = props.cardsData;
    let CardsShow=[];
    if(cards){
        cards.forEach((item,index)=>{
            CardsShow.push(
            <Card className="CustomCards" key={index}>
                <Card.Body>
                    <Card.Title className="EditFontTittle">{item.title?item.title:'Dato no encontrado'}</Card.Title>
                        <br/>
                    <Card.Title  className="EditFontCant">{item.cant?item.cant.toString().substring(0,4):0}</Card.Title>
                </Card.Body>
            </Card>
            )
        })
    }
    const dat = props.datos
    return(
        <div>
            <CardGroup className="contPadreCards">
                {CardsShow}
            </CardGroup>
        </div>
    )
}
export default CardsDashboard