import React, { Fragment } from 'react'
import FirstStep from '../cards/first-step'
import AddContainer from '../cards/addContainer'
import TerminosCondiciones from '../cards/terminos-condiciones'
import ListadoContenedores from '../cards/listadoContenedores'
import ResputestaSolicitud from '../cards/respuesta-solicitud'
import Loading from '../modal-components/loading'

export const Subtipo341 = ({
    index,
    mostrarcontenedor,
    setMostrarcontenedor,
    subtipos,
    validanit,
    habilitar,
    handleInputChange,
    ctipos,
    // setCtipos,
    toggle3,
    // buttonEnable,
    setButtonEnable,
    msgForm,
    url_eliminar_contenedor,
    token_user,
    token_page,
    // url_solicitud,
    url_get_contenedores,
    url_ennombre,
    url_tipos,
    url_facturar_a,
    respuestaSolicitud,
    load,
    // url_validar_documentos,
    url_valida_carga
}) => {
    switch (index) {
    case 0:
        return (
            <FirstStep
                handleInputChange={handleInputChange}
                valor_tipo={subtipos}
                token_user={token_user}
                token_page={token_page}
                validanit={validanit}
                // v_habilita={habilitar}
                url_ennombre={url_ennombre}
                url_tipos={url_tipos}
                url_facturar_a={url_facturar_a}
                msgForm={msgForm}
                // ctipos={ctipos}
                // setCtipos={setCtipos}
            />
        )
    case 1:
        return (
            <Fragment>
                <AddContainer
                    valor_boton={toggle3}
                    valor_x={ctipos}
                    v_habilita={habilitar}
                    // token_user={token_user}
                    // token_page={token_page}
                />
                <ListadoContenedores
                    flag_mostrar={mostrarcontenedor}
                    token_user={token_user}
                    token_page={token_page}
                    setMostrarcontenedor={setMostrarcontenedor}
                    url_get_contenedores={url_get_contenedores}
                    solicitud={ctipos.solicitud_apex}
                    url_eliminar_contenedor={url_eliminar_contenedor}
                    ctipos={ctipos}
                    toggle3={toggle3}
                    setButtonEnable={setButtonEnable}
                    // buttonEnable={buttonEnable}
                    url_valida_carga={url_valida_carga}
                />
            </Fragment>
        )
    case 2:
        return <TerminosCondiciones setButtonEnable={setButtonEnable} />
    default:
        return (
            <Fragment>
                {load && <Loading />}
                {respuestaSolicitud.mostrar_respuestas && (
                    <ResputestaSolicitud respuestaSolicitud={respuestaSolicitud} />
                )}
            </Fragment>
        )
    }
}
