import { SubtipoNo281 } from './sub-tipo-no-281'
import { SubTipo281 } from './sub-tipo-281'
import { Subtipo341 } from './sub-tipo-341'
import { SubtipoAOP } from './sub-tipo-aop'
import { Subtipo61 } from './sub-tipo-61'
import { Subtipo461y101 } from './sub-tipo-461y101'

export const getStepContent = ({
    index,
    mostrarcontenedor,
    setMostrarcontenedor,
    subtipos,
    // setSubTipos,
    validanit,
    // setValidanit,
    habilitar,
    // setHabilitar,
    handleInputChange,
    ctipos,
    setCtipos,
    // modal,
    // setModal,
    // titleModal,
    // setTitleModal,
    toggle3,
    // capturandovalores,
    // componentModal,
    // setComponentModal,
    // dataRequest,
    // setDataRequest,
    // buttonEnable,
    setButtonEnable,
    msgForm,
    // setMsgForm,
    mostrardocumentos,
    setMostrardocumentos,
    url_eliminar_contenedor,
    token_user,
    token_page,
    url_insertar_documentos,
    url_listado_documentos,
    url_eliminar_documentos,
    // url_solicitud,
    url_get_contenedores,
    // url_valida_gestor,
    url_ennombre,
    url_tipos,
    url_facturar_a,
    sub_tipo_solicitud,
    url_insert_contenedores_marchamo,
    respuestaSolicitud,
    load,
    url_validar_documentos,
    url_valida_carga
}) => {
    switch (sub_tipo_solicitud) {
    case '281':
        return SubTipo281({
            index,
            mostrarcontenedor,
            setMostrarcontenedor,
            subtipos,
            validanit,
            habilitar,
            handleInputChange,
            ctipos,
            // setCtipos,
            toggle3,
            // buttonEnable,
            setButtonEnable,
            msgForm,
            mostrardocumentos,
            setMostrardocumentos,
            url_eliminar_contenedor,
            token_user,
            token_page,
            url_insertar_documentos,
            url_listado_documentos,
            url_eliminar_documentos,
            // url_solicitud,
            url_get_contenedores,
            url_ennombre,
            url_tipos,
            url_facturar_a,
            url_insert_contenedores_marchamo,
            respuestaSolicitud,
            load,
            url_validar_documentos,
            url_valida_carga
        })
    case '341':
        return Subtipo341({
            index,
            mostrarcontenedor,
            setMostrarcontenedor,
            subtipos,
            validanit,
            habilitar,
            handleInputChange,
            ctipos,
            // setCtipos,
            toggle3,
            // buttonEnable,
            setButtonEnable,
            msgForm,
            url_eliminar_contenedor,
            token_user,
            token_page,
            // url_solicitud,
            url_get_contenedores,
            url_ennombre,
            url_tipos,
            url_facturar_a,
            respuestaSolicitud,
            load,
            // url_validar_documentos,
            url_valida_carga
        })
    // case '101':
    case '141':
    case '142':
        return SubtipoAOP({
            index,
            mostrarcontenedor,
            setMostrarcontenedor,
            subtipos,
            validanit,
            habilitar,
            handleInputChange,
            ctipos,
            // setCtipos,
            toggle3,
            // buttonEnable,
            setButtonEnable,
            msgForm,
            url_eliminar_contenedor,
            token_user,
            token_page,
            // url_solicitud,
            url_get_contenedores,
            mostrardocumentos,
            setMostrardocumentos,
            url_insertar_documentos,
            url_listado_documentos,
            url_eliminar_documentos,
            url_ennombre,
            url_tipos,
            url_facturar_a,
            respuestaSolicitud,
            load,
            url_validar_documentos,
            url_valida_carga
            // url_valida_gestor
        })
    case '61':
        return Subtipo61({
            index,
            mostrarcontenedor,
            setMostrarcontenedor,
            subtipos,
            validanit,
            habilitar,
            handleInputChange,
            ctipos,
            setCtipos,
            toggle3,
            // buttonEnable,
            setButtonEnable,
            msgForm,
            url_eliminar_contenedor,
            token_user,
            token_page,
            // url_solicitud,
            url_get_contenedores,
            // mostrardocumentos,
            // setMostrardocumentos,
            // url_insertar_documentos,
            // url_listado_documentos,
            // url_eliminar_documentos,
            url_ennombre,
            url_tipos,
            url_facturar_a,
            respuestaSolicitud,
            load,
            // url_validar_documentos,
            url_valida_carga
        })
    case '461':
    case '101':
        return Subtipo461y101({
            index,
            mostrarcontenedor,
            setMostrarcontenedor,
            subtipos,
            validanit,
            habilitar,
            handleInputChange,
            ctipos,
            // setCtipos,
            toggle3,
            // buttonEnable,
            setButtonEnable,
            msgForm,
            url_eliminar_contenedor,
            token_user,
            token_page,
            // url_solicitud,
            url_get_contenedores,
            mostrardocumentos,
            setMostrardocumentos,
            url_insertar_documentos,
            url_listado_documentos,
            url_eliminar_documentos,
            url_ennombre,
            url_tipos,
            url_facturar_a,
            respuestaSolicitud,
            load,
            url_validar_documentos,
            url_valida_carga
            // url_valida_gestor
        })
    default:
        return SubtipoNo281({
            index,
            mostrarcontenedor,
            setMostrarcontenedor,
            subtipos,
            validanit,
            habilitar,
            handleInputChange,
            ctipos,
            // setCtipos,
            toggle3,
            // buttonEnable,
            setButtonEnable,
            msgForm,
            mostrardocumentos,
            setMostrardocumentos,
            url_eliminar_contenedor,
            token_user,
            token_page,
            url_insertar_documentos,
            url_listado_documentos,
            url_eliminar_documentos,
            // url_solicitud,
            url_get_contenedores,
            // url_valida_gestor,
            url_ennombre,
            url_tipos,
            url_facturar_a,
            respuestaSolicitud,
            load,
            url_validar_documentos,
            url_valida_carga
        })
    }
}
