import { useState, useEffect } from 'react'
import { getMoneda } from '../../helpers/vinculaciones/getMoneda'

export const useFetchMoneda = (url_listado_vinculacion, servicio_moneda, token_page) => {
    const [moneda, setMoneda] = useState([]) // tipos

    useEffect(() => {
        getMoneda(url_listado_vinculacion, servicio_moneda, token_page)
            .then(resultado => {
                setMoneda(resultado)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {}
    }, [url_listado_vinculacion, servicio_moneda, token_page])

    return [moneda]
}
