import axios from 'axios'

export const useFetchInsertEstadosD = (
    token_user,
    token_page,
    setEstado,
    setRespuestad,
    v_show,
    url_estados_d
) => {
    const insertar_estados_d = campos => {
        console.log('inserttando estados D')

        console.log(campos)

        axios
            .post(url_estados_d, {
                v_tipos: campos.v_tipos,
                v_subtipos: campos.v_subtipos,
                v_solicitud: campos.v_solicitud,
                v_contenedor: campos.v_contenedor,
                v_usuario: campos.usuario_apex
            })
            .then(response => {
                setEstado(true)
                console.log(response.data)
                setRespuestad(response.data)
                v_show(true)
                //show_listado_contenedores()
                console.log('----------------------')
            })
            .catch(e => {
                console.log(e)
            })
    }

    return [insertar_estados_d]
}
