import React, { Fragment, useState } from 'react'
const FourthStep = ({ setButtonEnable }) => {
    const [check, setCheck] = useState(false)

    const checkF = () => {
        setCheck(!check)
        setButtonEnable(check)
    }
    return (
        <Fragment>
            <div className='col-sm-12 col-xl-12'>
                <div className='card'>
                    {/* <div className='card-header b-b-warning border-3'>
                        <h5>Presione "Enviar" para que el personal de APMTQ le de seguimiento</h5>
                    </div> */}
                    <div className='card-header b-b-warning border-3'>
                        <h5>Presione &quotEnviar&quot para que el personal de APMTQ le de seguimiento</h5>
                    </div>

                    <div className='card-body typography'>
                        <h5 className='f-w-700'>Términos y Condiciones</h5>
                        <h5 className='f-w-400'>
                            <small className='text-muted'>
                                APM Terminals Quetzal hará una verificación de su solicitud de servicios en
                                caso de que el servicio solicitado difiera de la operación a efectuar, se
                                harán las modificaciones pertinentes a la solicitud y le será informado
                                oportunamente.
                            </small>
                        </h5>
                        <br />
                        <h5 className='f-w-400'>
                            <small className='text-muted'>
                                La solicitud del servicio realizada por el cliente o su representante, por
                                este stio web o los demás medios establecidos, es considerada una aceptación
                                expresa de las condiciones económicas y operativas establecidas por APM
                                Terminals Quetzal, S. A. Una vez programado el servicio, APM Terminals
                                Quetzal, S.A. cobrará una tarifa administrativa, sin perjuicio que el servicio
                                no pueda concluirse por razones no atribuibles a APMTQ.
                            </small>
                        </h5>
                        <br />
                        <h5 className='f-w-400'>
                            <small className='text-muted'>
                                Página de inspecciones pendientes:
                                <a href='https://www.services.apmterminalsquetzal.com/ords/f?p=102:19'>
                                    https://www.services.apmterminalsquetzal.com/ords/f?p=102:19
                                </a>
                            </small>
                        </h5>
                        <h5 className='f-w-400'>
                            <small className='text-muted'>
                                Página de inspecciones Programadas:
                                <a href='https://www.services.apmterminalsquetzal.com/ords/f?p=102:18'>
                                    https://www.services.apmterminalsquetzal.com/ords/f?p=102:18
                                </a>
                            </small>
                        </h5>
                        <h5 className='f-w-400'>
                            <small className='text-muted'>
                                Tarifas y condiciones:
                                <a href='https://www.services.apmterminalsquetzal.com/ords/f?p=102:47'>
                                    https://www.services.apmterminalsquetzal.com/ords/f?p=102:47
                                </a>
                            </small>
                        </h5>
                        <br />
                        <div className='checkbox checkbox-solid-warning ml-3'>
                            <input id='solid1' type='checkbox' value={check} onClick={checkF} />
                            <label htmlFor='solid1'>Aceptar Términos y Condiciones</label>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FourthStep
