import React, { Fragment } from 'react';
import Vertical from '../../../assets/images/iap/vertical-line.png';
import Container from '../../../assets/images/iap/envio-al-mundo-entero.png';
import Cheque from '../../../assets/images/iap/cheque.png';
import Calendario from '../../../assets/images/iap/calendario.png';
import Internacional from '../../../assets/images/iap/internacional.png';

const FirstStep = props => {
    const {
        contenedorCitaImport,
        contenedorTokenImport,
        contenedoresLlenosImport,
        contenedoresTransitInternacional,
    } = !!props && props;

    return (
        <Fragment>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-info border-4'>
                        <h6>Total contenedores llenos de Importacion</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Container} alt='Smiley face' height='52' width='52'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedoresLlenosImport || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-info border-4'>
                        <h6>Contenedores con token</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Cheque} alt='Smiley face' height='42' width='42'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedorTokenImport || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-info border-4'>
                        <h6>Contenedores con cita</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Calendario} alt='Smiley face' height='42' width='42'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedorCitaImport || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-xl-3'>
                <div className='card '>
                    <div className='card-header b-t-info border-4'>
                        <h6>Total contenedores Tránsito Internacional</h6>
                    </div>
                    <div className='card-body row'>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <p>
                                <img src={Internacional} alt='Smiley face' height='52' width='52'></img>
                            </p>
                        </div>
                        <div className='col-xl-1'>
                            <img src={Vertical} alt='Smiley face' height='62' width='3'></img>
                        </div>
                        <div
                            className='col-xl-5'
                            style={{
                                textAlign: 'center',
                                placeSelf: 'flex-end',
                            }}
                        >
                            <h3>{contenedoresTransitInternacional || '0'}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FirstStep;
