import React, { Fragment, useEffect, useState } from 'react'
import Breadcrumb from '../common/breadcrumb'
import styled from '@emotion/styled'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import LinkedCameraRoundedIcon from '@material-ui/icons/LinkedCameraRounded'
import moment from 'moment-timezone'


const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const MainComponent = props => {

    const [dateState, setDateState] = useState()
    const [timeState, setTimeState] = useState()

    useEffect(() => {
        setInterval(()=>{
            const date = moment().tz("America/Guatemala").format("YYYY/MM/DD")
            const time = moment().tz("America/Guatemala").format("HH:mm:ss")
            setDateState(date)
            setTimeState(time)
        }, 1000)
    }, [])

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Entrada Principal APMTQ' parent='Servicios' />
            </Titulo>
            <div style={{ marginTop: '50px' }} className='container-fluid' >
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Col xs={12} md={12} lg={{ span:3, offset: 1 }}>
                        <Card  style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', height: '62vh' }}>
                            <Card.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ fontSize: '3rem', fontWeight: 'bold' }}>{dateState}</span>
                                <span style={{ fontSize: '2rem' }}>{timeState}</span>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={12} lg={{ span:6, offset: 1 }}>
                        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', height: '62vh' }}>
                            <Card.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Card.Title style={{ display: 'flex', alignItems: 'center' }}>
                                    <LinkedCameraRoundedIcon style={{ color: '#ffffff', fontSize: 35, background: '#FF6203', padding: '6px', borderRadius: '50%' }}/>
                                    <span className='ml-2'>Estado en tiempo real</span>
                                </Card.Title>
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item" src={`${process.env.REACT_APP_GATE_CAMERA_STREAM_URL}?autoplay=1&mute=1&loop=1"`} title="Gate Status APMTQ" frameborder="0" allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
                                </div>                            
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}

export default MainComponent
