import React from 'react';
import camionVerde from '../../../assets/images/estados_transportista/camion_verde.png';
import camionRojo from '../../../assets/images/estados_transportista/camion_rojo.png';
import camionAmarillo from '../../../assets/images/estados_transportista/camion_amarillo.png';
// import camionGris from '../../../assets/images/estados_transportista/camion_gris.png';

import contenedorVerde from '../../../assets/images/estados_transportista/contenedor_verde.png';
import contenedorRojo from '../../../assets/images/estados_transportista/contenedor_rojo.png';
import contenedorAmarillo from '../../../assets/images/estados_transportista/contenedor_amarillo.png';
import { UncontrolledTooltip } from 'reactstrap';

export function headerNoSat({ toggle3 }) {
    return [
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 200 },
        { field: 'BUQUE', headerName: 'Buque', width: 200 },
        { field: 'VIAJE', headerName: 'Viaje', width: 180 },
        {
            field: 'FECHA_CREO',
            headerName: 'Emision Token',
            width: 260,
        },
        {
            field: 'NAVIERA',
            headerName: 'Naviera',
            width: 220,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                className='ml-1'
                                src={camionVerde}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                {params.row.NAVIERA_TXT}
                                <br />
                                <b>Fecha Vencimiento Orden: </b>
                                {params.row.NAVIERA_FECHA}
                            </UncontrolledTooltip>
                        </>
                    )}
                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'a' + params.row.id}
                                className='ml-1'
                                src={camionRojo}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'a' + params.row.id}>
                                {params.row.NAVIERA_TXT}
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'APMTQ',
            headerName: 'Almacenaje APMTQ',
            width: 220,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='ml-1'
                                src={camionVerde}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                {params.row.APMTQ_TXT}
                                <br />
                                <b>Fecha Vencimiento Almacenaje: </b>
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    )}
                    {params.value === 'amarillo' && (
                        <>
                            <img
                                alt='img'
                                id={'b' + params.row.id}
                                className='ml-1'
                                src={camionAmarillo}
                                height='32'
                                width='32'
                                data-placement='top'
                            ></img>
                            <UncontrolledTooltip placement='top' target={'b' + params.row.id}>
                                {params.row.APMTQ_TXT}
                                <br />
                                <b>Fecha Vencimiento Almacenaje: </b>
                                {params.row.APMTQ_FECHA}
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
        {
            field: 'CITA',
            headerName: 'Cita Transporte',
            width: 220,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    {params.value === 'verde' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='cursor'
                                src={camionVerde}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Transportista', 'transportista', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.CITA_TXT}
                                <br />
                                <b>Fecha creación de cita: </b>
                                {params.row.CITA_FECHA_TXT}
                            </UncontrolledTooltip>
                        </>
                    )}
                    {params.value === 'rojo' && (
                        <>
                            <img
                                alt='img'
                                id={'c' + params.row.id}
                                className='cursor'
                                src={camionRojo}
                                height='32'
                                width='32'
                                data-placement='top'
                                onClick={() => toggle3('', 'Transportista', 'transportista', params.row)}
                            ></img>
                            <UncontrolledTooltip placement='top' target={'c' + params.row.id}>
                                {params.row.CITA_TXT}
                            </UncontrolledTooltip>
                        </>
                    )}
                </strong>
            ),
        },
    ];
}
