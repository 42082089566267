import { useState, useEffect } from 'react'
import { getAnuncios } from '../../helpers/disclaimer-anuncios/index'
import { toast } from 'react-toastify'

export const useFetchGetAnuncios = ({ access, dataModal }) => {
    const [anuncios, setAnuncios] = useState([])
    const { open } = dataModal

    useEffect(() => {
        getAnuncios({ access })
            .then(result => {
                setAnuncios(result)
            })
            .catch(error => {
                console.log('API ERROR: ', error)
                alertMessage()
            })
        return () => {}
    }, [open])

    return { anuncios }
}

const alertMessage = () => {
    toast.warning('Hay un error en los anuncios, por favor notificar a APM Terminals!.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
    })
}
