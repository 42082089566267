import React, { Fragment } from 'react'
import styled from '@emotion/styled'

const Titulo = styled.h5`
    padding: 24px 32px
    background-color: #ff6219
    font-size: 24px
    border-radius: 4px
    color: #ffffff
`

const TituloSolicitudes = () => {
    return (
        <Fragment>
            <Titulo>Ingreso de solicitudes:</Titulo>
        </Fragment>
    )
}

export default TituloSolicitudes
