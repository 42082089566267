import axios from 'axios'

export const postConfirmarVinculacionCliente = async (
    useFetchInit,
    urlPeticiones
    // vinculacion,
    // perfil_seleccionado,
    // dreferencias,
    // dinfobancaria,
    // dsocios,
    // dcontacto,
    // urlPeticiones
) => {
    // const token_user = 'nada'
    const { url_confirmacion }  = urlPeticiones
    const {
        vinculacion,
        dreferencias,
        dinfobancaria,
        dsocios,
        dcontacto
    } = useFetchInit

    const {
        perfil_seleccionado,
        nit_cliente_vinculacion,
        nombre_comercial,
        input_modalidad,
        razon_social,
        nombre_cliente,
        correo_electronico,
        codigo_pais,
        codigo_ciudad,
        telefono_uno,
        telefono_dos,
        sitio_web,
        tipo_persona,
        direccion,
        codigo_postal,
        representante_legal,
        puesto_legal,
        tipo_documento
    } = vinculacion

    // let retorno2 = ''
    let servicio = ''

    if (perfil_seleccionado === '162') {
        servicio = 'proveedor'
    } else {
        servicio = 'cliente'
    }

    const retorno = await axios
        .post(
            url_confirmacion,
            {
                servicio,
                perfil_seleccionado,
                nit_cliente_vinculacion,
                nombre_comercial,
                input_modalidad,
                razon_social,
                nombre_cliente,
                correo_electronico,
                codigo_pais,
                codigo_ciudad,
                telefono_uno,
                telefono_dos,
                sitio_web,
                tipo_persona,
                direccion,
                codigo_postal,
                representante_legal,
                puesto_legal,
                tipo_documento,
                dreferencias,
                dinfobancaria,
                dsocios,
                dcontacto
            },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${urlPeticiones.token_page}`
                }
            }
        )
        .then(({ data }) => {
            return data
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_nuevo: '',
                    v_mensaje: '500',
                    v_resultado: ''
                }
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_nuevo: '',
                    v_mensaje: '401',
                    v_resultado: ''
                }
            } else {
                console.log(e.response.data)
                return {
                    v_nuevo: '',
                    v_mensaje: 'ERROR',
                    v_resultado: ''
                }
            }
        })

    return retorno
}
