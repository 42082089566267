import React,{useState,useEffect} from 'react'
import {Form,Button, FormLabel,Row,Col} from "react-bootstrap";
import axios from 'axios';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
const FormularioDatos = (props) => {
    const token = props.token_user;
    const [startDate, setStartDate] = useState(new Date());
    const [endtDate, setEndtDate] = useState(new Date());
    const [buques,setOptionBuques] = useState([])
    const [buque, setData] = useState({});
    const verifyNull=()=>{
        if((typeof(buque.value)!='undefined')&&startDate!=null&&endtDate!=null){
            let day = startDate.getDate()
            let month = startDate.getMonth() + 1
            let year = startDate.getFullYear()
            let parseoFecha1 = day+'-'+month+'-'+year;
            day = endtDate.getDate()
            month = endtDate.getMonth() + 1
            year = endtDate.getFullYear()
            let parseoFecha2 = day+'-'+month+'-'+year;
            let nit = buque.value.replaceAll(' ', '%20');
            const{callData} = props
            callData(nit,parseoFecha1,parseoFecha2)
        }else{
            alert('Todos los campos son necesarios')
        }
    }
    const getBuques =async function(){
        const resp = await axios.get(process.env.REACT_APP_DASHBOARDSCMP_API+`/v1/cmp/users/list`,{
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                'x-auth-token': token,
            }
        }).then(res =>{
            console.log(res.data);
            setOptionBuques(res.data);
        });
    }
    useEffect(() => {
        getBuques();
    }, []);
    return(
        <div className="CustomFormularioDashboard">
            <Form>
                <Row  xs="12" sm="1" md="3" lg="4" className="AllInputs">
                    <Col xs="12" sm="12" md="3" lg="4">
                        <FormLabel className="textForm">
                            Usuarios
                        </FormLabel>
                        <Select
                            className="tamSelect"
                            name="buque"
                            value={buque}
                            options={buques}
                            onChange={setData}
                            required
                        />
                    </Col>
                    <Col xs="12" sm="12" md="3" lg="4">
                        <FormLabel className="textForm">
                            Fecha Inicio - Salida
                        </FormLabel>
                        <DatePicker
                            className="textodataPicker"
                            selected={endtDate}
                            onChange={(enddate) => setEndtDate(enddate)}
                            format="dd/mm/yyyy"
                            dateFormat = "dd/MM/yyyy"
                            required
                        />
                    </Col>
                    <Col xs="12" sm="12" md="3" lg="4">
                        <FormLabel className="textForm">
                            Fecha Fin - Salida
                        </FormLabel>
                        <DatePicker
                            className="textodataPicker"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            format="dd/mm/yyyy"
                            dateFormat = "dd/MM/yyyy"
                            required
                        />
                    </Col>
                </Row>
                <Row  xs="1" sm="1" md="3" lg="4" className="RowButtonSave">
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Button className="save" onClick={verifyNull}>
                            Buscar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default FormularioDatos