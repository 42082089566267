import React from 'react'
// import LoadingTable from './loading-line'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { Trash2 } from 'react-feather'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css'
const TableComponent = ({ useFetchInit }) => {
    // const { loadingOperation } = useFetchInit;
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='outer'>
                        <div className='ag-theme-alpine' style={{ height: 350, width: '100%' }}>
                            {/* {loadingOperation && <LoadingTable />} */}

                            <AgGridReact
                                paginationPageSize={5}
                                modules={AllModules}
                                defaultColDef={{
                                    editable: false,
                                    sortable: true,
                                    resizable: true,
                                }}
                                suppressRowClickSelection={true}
                                rowSelection={'multiple'}
                                enableRangeSelection={true}
                                pagination={true}
                                rowData={[
                                    {
                                        COD_MATRICULA: 'C032123DF',
                                    },
                                    {
                                        COD_MATRICULA: 'C0323453VF',
                                    },
                                    {
                                        COD_MATRICULA: 'C222s123KJ',
                                    },
                                ]}
                                frameworkComponents={{
                                    // Modificar: params => {
                                    //     return (
                                    //         <strong>
                                    //             <Edit3
                                    //                 className='cursor mt-2 text-warning'
                                    //                 // onClick={() => redirectCAE(params)}
                                    //             ></Edit3>
                                    //         </strong>
                                    //     )
                                    // },
                                    Borrar: params => {
                                        return (
                                            <strong>
                                                <Trash2
                                                    className='cursor mt-2 text-danger'
                                                    // onClick={() => redirectCAE(params)}
                                                ></Trash2>
                                            </strong>
                                        )
                                    },
                                }}
                                cacheBlockSize={100}
                                maxBlocksInCache={10}
                                blockLoadDebounceMillis={1000}
                                animateRows={true}
                            >
                                <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='Matrículas'
                                    field='COD_MATRICULA'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                                {/* <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='Dirección'
                                    field='DIRECCION'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                                <AgGridColumn
                                    flex={1}
                                    minWidth={100}
                                    headerName='Fecha'
                                    field='FECHA'
                                    filter='agTextColumnFilter'
                                ></AgGridColumn>
                                <AgGridColumn
                                    width={120}
                                    headerName='Modificar'
                                    field='MENU'
                                    filter='agTextColumnFilter'
                                    cellRendererSelector={params => {
                                        return { component: 'Modificar' }
                                    }}
                                ></AgGridColumn> */}
                                <AgGridColumn
                                    width={95}
                                    headerName='Borrar'
                                    field='MENU'
                                    filter='agTextColumnFilter'
                                    cellRendererSelector={params => {
                                        return { component: 'Borrar' }
                                    }}
                                ></AgGridColumn>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableComponent
