import React, { Fragment } from 'react'
const ResputestaSolicitud = ({ respuestaSolicitud }) => {
    console.log('DATA POR MOSTRAR', respuestaSolicitud)
    const {
        valida_documentos,
        estado_documentos,
        confirma_solicitud,
        estado_solicitud,
        envio_wo,
        estado_wo,
        envia_correo,
        estado_correo,
        boqueo_token,
        estado_bloqueo_token,
        envia_correo_almacen,
        estado_correo_almacen,
        envia_correo_naviera,
        estado_correo_naviera,
        valida_fecha,
        estado_valida_fecha,
        wo_almacenaje,
        valida_carga,
        estado_carga,
        estado_wo_almacenaje
    } = respuestaSolicitud

    return (
        <Fragment>
            <div className='col-xl-12'>
                <div className='card height-equal'>
                    <div className='card-header card-header-border'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h5>{'Validación de cada paso en la solicitud'}</h5>
                            </div>
                        </div>
                    </div>
                    <div className='card-body recent-notification'>
                        {estado_carga && valida_carga !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Validación de carga'}</span>
                                        </b>
                                        <p className='f-12'>{valida_carga}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : valida_carga !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Validación de carga'}</span>
                                        </b>
                                        <p className='f-12'>{valida_carga}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_documentos && valida_documentos !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Validacion de documentos adjuntos'}</span>
                                        </b>
                                        <p className='f-12'>{valida_documentos}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : valida_documentos !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Validacion de documentos adjuntos'}</span>
                                        </b>
                                        <p className='f-12'>{valida_documentos}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_solicitud && confirma_solicitud !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Confirmación de solicitudes'}</span>
                                        </b>
                                        <p className='f-12'>{confirma_solicitud}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : confirma_solicitud !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Confirmación de solicitudes'}</span>
                                        </b>
                                        <p className='f-12'>{confirma_solicitud}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_wo && envio_wo !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Confirmación de Work Order'}</span>
                                        </b>
                                        <p className='f-12'>{envio_wo}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : envio_wo !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Confirmación de Work order'}</span>
                                        </b>
                                        <p className='f-12'>{envio_wo}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_bloqueo_token && boqueo_token !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Bloqueo del token'}</span>
                                        </b>
                                        <p className='f-12'>{boqueo_token}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : boqueo_token !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Bloqueo del token'}</span>
                                        </b>
                                        <p className='f-12'>{boqueo_token}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_correo && envia_correo !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envío de correo para la solicitud'}</span>
                                        </b>
                                        <p className='f-12'>{envia_correo}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : envia_correo !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envío de correo para la solicitud'}</span>
                                        </b>
                                        <p className='f-12'>{envia_correo}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_correo_almacen && envia_correo_almacen !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envío de correo a Almacen'}</span>
                                        </b>
                                        <p className='f-12'>{envia_correo_almacen}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : envia_correo_almacen !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envío de correo a Almacen'}</span>
                                        </b>
                                        <p className='f-12'>{envia_correo_almacen}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_correo_naviera && envia_correo_naviera !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envío de correo a Naviera'}</span>
                                        </b>
                                        <p className='f-12'>{envia_correo_naviera}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : envia_correo_naviera !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envío de correo a Naviera'}</span>
                                        </b>
                                        <p className='f-12'>{envia_correo_naviera}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_valida_fecha && valida_fecha !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Validación fecha retiro'}</span>
                                        </b>
                                        <p className='f-12'>{valida_fecha}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : valida_fecha !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Validación fecha retiro'}</span>
                                        </b>
                                        <p className='f-12'>{valida_fecha}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}

                        {estado_wo_almacenaje && wo_almacenaje !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span className='badge badge-success pull-right digits'>
                                            {'Exitoso '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envio de Facturación'}</span>
                                        </b>
                                        <p className='f-12'>{wo_almacenaje}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : wo_almacenaje !== '' ? (
                            <Fragment>
                                <div className='media'>
                                    <h6>
                                        <span
                                            className='badge pull-right digits'
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                        >
                                            {'Error '}
                                            <i className='fa fa-check'></i>
                                        </span>
                                    </h6>
                                    <div className='media-body'>
                                        <b>
                                            <span>{'Envio de Facturación'}</span>
                                        </b>
                                        <p className='f-12'>{wo_almacenaje}</p>
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ResputestaSolicitud
