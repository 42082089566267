import React, { Fragment } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Loading from './loading';

export const RegistroAutoridades = ({ setVinculacion, vinculacion, perfiles, load, error }) => {
    const handleChange = event => {
        setVinculacion({
            ...vinculacion,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Fragment>
            {load ? <Loading /> : vinculacion.respuesta_api}
            {error ? <span className='text-danger'>{error}</span> : null}
            <Row form className={`contenido-tabs`}>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_nombre_completo'>Nombre Completo</Label>
                        <Input
                            type='text'
                            name='sat_nombre_completo'
                            id='sat_nombre_completo'
                            placeholder='Ingresar Nombre completo'
                            onChange={handleChange}
                            value={vinculacion.sat_nombre_completo}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_nit'>Nit (sin guiones)</Label>
                        <Input
                            type='text'
                            name='sat_nit'
                            id='sat_nit'
                            placeholder='Ingresar NIT'
                            onChange={handleChange}
                            value={vinculacion.sat_nit}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_telefono'>Teléfono</Label>
                        <Input
                            type='number'
                            name='sat_telefono'
                            id='sat_telefono'
                            placeholder='Teléfono'
                            onChange={handleChange}
                            value={vinculacion.sat_telefono}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_telefono2'>Teléfono 2</Label>
                        <Input
                            type='number'
                            name='sat_telefono2'
                            id='sat_telefono2'
                            placeholder='Teléfono 2'
                            onChange={handleChange}
                            value={vinculacion.sat_telefono2}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_correo_electronico'>
                            Email (Llegará confirmación de su usuario a esta dirección)
                        </Label>
                        <Input
                            type='email'
                            name='sat_correo_electronico'
                            id='sat_correo_electronico'
                            placeholder='Correo Electrónico'
                            onChange={handleChange}
                            value={vinculacion.sat_correo_electronico}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_perfil'>Perfil de Usuario</Label>
                        <Input
                            type='select'
                            name='sat_perfil'
                            id='sat_perfil'
                            onChange={handleChange}
                            value={vinculacion.sat_perfil}
                        >
                            <option value=''>-- Seleccionar perfil de usuario --</option>
                            {perfiles.map(valor => {
                                return (
                                    <option key={valor.COD_ACTIVIDAD} value={valor.COD_ACTIVIDAD}>
                                        {valor.NOMBRE_ACTIVIDAD}
                                    </option>
                                );
                            })}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_direccion'>Dirección (Ubicación de Trabajo o Institución)</Label>
                        <Input
                            type='text'
                            name='sat_direccion'
                            id='sat_direccion'
                            placeholder='Dirección'
                            onChange={handleChange}
                            value={vinculacion.sat_direccion}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='sat_puesto'>Puesto</Label>
                        <Input
                            type='text'
                            name='sat_puesto'
                            id='sat_puesto'
                            onChange={handleChange}
                            value={vinculacion.sat_puesto}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Button color='warning' type='submit'>
                <i class='fa fa-floppy-o' aria-hidden='true'></i> Confirrmar registro
            </Button>
            {/* <Button color='warning' onClick={() => eliminarCarga(params)}>
                        <i className='fa fa-trash-o'></i>
                    </Button> */}
        </Fragment>
    );
};
