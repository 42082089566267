import React, { Fragment } from 'react'
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'
import { PerfilCard } from './perfilCard'

export const IniciarVinculacion = ({
    useFetchInit
    // perfiles,
    // handleInputChange,
    // setVinculacion,
    // vinculacion,
    // setBtndisableuv
}) => {
    const {
        perfiles,
        handleInputChange,
        setVinculacion,
        vinculacion,
        setBtndisableuv
    } = useFetchInit
    console.log('INICIAR_VINCULACION ', vinculacion)
    const { perfil_seleccionado } = vinculacion
    const handleChange = event => {
        console.log('event.target.value', event.target.value)
        if (perfil_seleccionado !== '') {
            setBtndisableuv(false)
        }

        setVinculacion({
            ...vinculacion,
            [event.target.name]: event.target.value
        })
    }

    return (
        <Fragment>
            <Row form className={`contenido-tabs`}>
                <Col md={12}>
                    <FormGroup>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h5>Vinculación de Usuarios</h5>
                                    <span>Igresar número de NIT y perfil de usuario a Vincular</span>
                                </div>
                                <div className='card-body megaoptions-border-space-sm'>
                                    <form className='mega-inline'>
                                        <div className='row'>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for='nit_cliente_vinculacion'>Ingresar número de NIT</Label>
                                                    <Input
                                                        type='text'
                                                        name='nit_cliente_vinculacion'
                                                        id='nit_cliente_vinculacion'
                                                        placeholder='Ingresar número de NIT'
                                                        onChange={handleChange}
                                                        value={vinculacion.nit_cliente_vinculacion}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {perfiles.map((listado_perfiles, index) => {
                                                return (
                                                    <PerfilCard
                                                        key={index}
                                                        titulo={listado_perfiles.NOMBRE_ACTIVIDAD}
                                                        codigo={listado_perfiles.COD_ACTIVIDAD}
                                                        handleInputChange={handleInputChange}
                                                        perfil_seleccionado={perfil_seleccionado}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    )
}
