import React, { Fragment } from "react"
import Loader from "../loader"

const ModalModule = () => {
    return (
        <Fragment>
            <div className="container-fluid bd-example-row">
                <div className="row">
                    <div className="col-md-12" style={{ textAlign: 'center'}}>
                        <Loader/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ModalModule
