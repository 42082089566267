import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { DataGrid } from '@material-ui/data-grid';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalModule = props => {
    const { toggle, tittle, responseData, dataRequest } = props;
    const header = [
        { field: 'ATC_NUMBER', headerName: 'Número de ATC', width: 200 },
        { field: 'ATC_STATUS', headerName: 'Estado', width: 130 },
        {
            field: 'ATC_STATUS_DESCRIPTION',
            headerName: 'Descripción',
            width: 150,
        },
        {
            field: 'ATC_ADDITION_DATE',
            headerName: 'Feha de asignación',
            width: 200,
            valueGetter: params => moment(params.value).tz('America/Guatemala').format('DD/MM/YYYY hh:mm'),
        },
    ];

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{tittle}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <label className='col-form-label' htmlFor='recipient-name'>
                                <b>Contenedor:</b> {dataRequest.CONTENEDOR}
                            </label>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={responseData}
                            columns={header}
                            pageSize={10}
                            sortModel={[
                                {
                                    field: 'ATC_NUMBER',
                                    sort: 'asc',
                                },
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default ModalModule;
