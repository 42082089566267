import React from 'react'
import { Button } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
// import { makeStyles } from '@material-ui/core/styles'

// const useStyles = makeStyles(theme => ({
//     root: {
//         '& .MuiTextField-root': {
//             margin: theme.spacing(1),
//             width: '25ch'
//         }
//     }
// }))

export const Paises = ({ toggle3, vinculacion }) => {
    // const classes = useStyles()
    const { nombre_pais } = vinculacion

    return (
        <div className='row'>
            <div className='col-md-6'>
                {nombre_pais !== '' && (
                    <>
                        <TextField
                            id='filled-read-only-input'
                            label='País'
                            value={nombre_pais}
                            InputProps={{
                                readOnly: true
                            }}
                            variant='filled'
                        />
                    </>
                )}
            </div>
            <div className='col-md-6'>
                <Button
                    type='button'
                    className='btn btn-warning pull-right'
                    onClick={() => {
                        toggle3('', 'Agregar país', 'OPEN_PAISES')
                    }}
                >
                    <i className='fa fa-globe' aria-hidden='true'></i> &nbsp Agregar país
                </Button>
            </div>
        </div>
    )
}
