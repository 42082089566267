import React, { Fragment, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DataGrid, GridToolbar, GridOverlay, GridPreferencePanelsValue } from '@material-ui/data-grid';
import { toast } from 'react-toastify';
import { postConfirmaDocumentacion } from '../../helpers/vinculaciones/postConfirmaDocumentacion';
import Loading from './loading';

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

const TableComponent = ({
    toggle,
    listdoc,
    token_page,
    servicio_confirmacion,
    codigo_vinculacion,
    url_confirmacion,
}) => {
    const [load, setLoad] = useState(true); // false para dejar de cargar .. setLoad(false)

    const colums = [
        {
            field: 'Opciones',
            headerName: 'Opciones',
            width: 150,
            renderCell: params => (
                <strong style={{ fontWeight: 'normal' }}>
                    <button
                        className='btn btn-warning '
                        type='button'
                        onClick={() => toggle('', 'Editar Documentos', 'editar-documento', params)}
                    >
                        <i className='icofont icofont-ui-edit'></i>
                    </button>
                </strong>
            ),
        },
        { field: 'NOMBRE_DOCUMENTO', headerName: 'Tipo', width: 700, headerAlign: 'center' },
        { field: 'NOMBRE_ARCHIVO', headerName: 'Nombre del Archivo', width: 700, headerAlign: 'center' },
    ];

    const finalizarDocumentacion = () => {
        console.log(`Aquí en finalizar documentación...`);
        setLoad(false);

        //   code..

        postConfirmaDocumentacion({ token_page, servicio_confirmacion, codigo_vinculacion, url_confirmacion })
            .then(resultado => {
                console.log(resultado);

                const { v_mensaje, v_resultado } = resultado;

                if (v_resultado) {
                    setLoad(true);
                    toast.success(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    setLoad(true);
                    toast.warning(v_mensaje, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch(error => {
                setLoad(true);
                console.log(error);
                toast.warning('Existe un error, por favor comunirse con APM Terminals..', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            });
    };

    // DAJA DE EJEMPLO
    console.log(listdoc);

    return (
        <Fragment>
            <div className='col-sm-12'>
                <div className='card'>
                    {load ? '' : <Loading />}
                    <div className='form-group row mt-2 mb-2 mb-0 col-sm-12'>
                        <div className='col-sm-4 col-xs-12'></div>
                        <div className='col-sm-4 col-xs-12'>
                            <button
                                className='btn btn-outline-warning btn-block'
                                type='button'
                                onClick={() =>
                                    toggle('', 'Adjuntar Documentos', 'adjuntar-documento', { data: 'data' })
                                }
                            >
                                <i className='icofont icofont-upload-alt'></i>&nbsp; Agregar Documento
                            </button>
                        </div>
                        <div className='col-sm-4 col-xs-12'>
                            <button
                                className='btn btn-warning btn-block'
                                type='button'
                                onClick={finalizarDocumentacion}
                            >
                                <i className='icofont icofont-checked'></i>&nbsp; Finalizar Documentación
                            </button>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={listdoc}
                            columns={colums}
                            pageSize={50}
                            components={{
                                LoadingOverlay: CustomLoadingOverlay,
                                Toolbar: GridToolbar,
                            }}
                            loading={load}
                            state={{
                                preferencePanel: {
                                    open: false,
                                    openedPanelValue: GridPreferencePanelsValue.filters,
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TableComponent;
