import React, { Fragment, useState } from 'react'
import { Button, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { nanoid } from 'nanoid'
import { useFetchMoneda } from '../../../../hooks/vinculaciones/useFetchMoneda'

export const ModalInfoBancaria = ({
    toggle,
    title,
    url_listado_vinculacion,
    servicio_monedas,
    infobancaria,
    setInfobancaria,
    dinfobancaria,
    setDinfobancaria,
    token_page
}) => {
    const [moneda] = useFetchMoneda(url_listado_vinculacion, servicio_monedas, token_page)
    const [error, setError] = useState(null)

    const handleInputChange = event => {
        let valor_moneda = ''
        if (event.target.name === 'bancaria_moneda') {
            valor_moneda = event.target.options[event.target.selectedIndex].label

            setInfobancaria({
                ...infobancaria,
                [event.target.name]: event.target.value,
                bancaria_nombre_moneda: valor_moneda
            })
        } else {
            setInfobancaria({
                ...infobancaria,
                [event.target.name]: event.target.value
            })
        }
    }

    const insertar_informacion_bancaria = () => {
        const { bancaria_nombre_banco, bancaria_moneda, bancaria_nombre_cuenta, bancaria_cuenta } = infobancaria
        if (bancaria_nombre_banco === '' || bancaria_nombre_banco === undefined) {
            setError('Agregar nombre del Banco.')
            return
        }

        if (bancaria_moneda === '' || bancaria_moneda === undefined) {
            setError('Seleccionar una modena.')
            return
        }

        if (bancaria_nombre_cuenta === '' || bancaria_nombre_cuenta === undefined) {
            setError('Agregar nombre de cuenta.')
            return
        }

        if (bancaria_cuenta === '' || bancaria_cuenta === undefined) {
            setError('Agregar número de cuenta.')
            return
        }

        setDinfobancaria([
            ...dinfobancaria,
            {
                bancaria_id: nanoid(),
                bancaria_nombre_banco: infobancaria.bancaria_nombre_banco,
                bancaria_moneda: infobancaria.bancaria_moneda,
                bancaria_nombre_moneda: infobancaria.bancaria_nombre_moneda,
                bancaria_nombre_cuenta: infobancaria.bancaria_nombre_cuenta,
                bancaria_cuenta: infobancaria.bancaria_cuenta
            }
        ])

        setInfobancaria({
            bancaria_id: '',
            bancaria_nombre_banco: '',
            bancaria_moneda: '',
            bancaria_nombre_moneda: '',
            bancaria_nombre_cuenta: '',
            bancaria_cuenta: ''
        })
        setError(null)
    }

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    {error ? <span className='text-danger'>{error}</span> : null}
                    <Input
                        type='text'
                        name='bancaria_nombre_banco'
                        id='bancaria_nombre_banco'
                        placeholder='Nombre banco'
                        onChange={handleInputChange}
                        value={infobancaria.bancaria_nombre_banco}
                    />

                    <Input
                        type='select'
                        name='bancaria_moneda'
                        id='bancaria_moneda'
                        onChange={handleInputChange}
                        value={infobancaria.bancaria_moneda}
                    >
                        <option value=''>-- Seleccionar moneda --</option>
                        {moneda.map(listado_monedas => {
                            return (
                                <option key={listado_monedas.MONEDA} value={listado_monedas.MONEDA}>
                                    {listado_monedas.DESCRIPCION}
                                </option>
                            )
                        })}
                    </Input>

                    <Input
                        type='text'
                        className='form-control mb-2'
                        placeholder='Nombre de cuenta'
                        name='bancaria_nombre_cuenta'
                        id='bancaria_nombre_cuenta'
                        onChange={handleInputChange}
                        value={infobancaria.bancaria_nombre_cuenta}
                    />

                    <Input
                        type='text'
                        className='form-control mb-2'
                        placeholder='Número de cuenta'
                        name='bancaria_cuenta'
                        id='bancaria_cuenta'
                        onChange={handleInputChange}
                        value={infobancaria.bancaria_cuenta}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className='btn btn-primary pull-right'
                    onClick={() => {
                        insertar_informacion_bancaria()
                    }}
                >
                    Agregar cuentas
                </Button>
                <Button color='gray' onClick={toggle} className='btn btn-primary float-left'>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}
