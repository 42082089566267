import React, { Fragment } from 'react'
import { useFetchEnNombre } from '../../../hooks/solicitudes/useFetchEnNombre'

export const NombreDe = ({ handleInputChange, token_user, token_page, url_ennombre }) => {
    const [vnombre] = useFetchEnNombre(token_user, token_page, url_ennombre)

    return (
        <Fragment>
            <div className='form-group row'>
                <label className='col-sm-3 col-form-label' htmlFor='inputEmail3'>
                    {'Solicitar servicio en nombre de'}
                </label>
                <div className='col-sm-9'>
                    <select className='form-control' name='en_nombre_de' onChange={handleInputChange}>
                        <option value=''>-- Seleccione el nombre de la persona --</option>
                        {vnombre.map(categoria => {
                            return (
                                <option
                                    key={categoria.COD_VINCULACION_USUARIO}
                                    value={categoria.COD_VINCULACION_USUARIO}
                                >
                                    {categoria.SOLICITADO_POR}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </Fragment>
    )
}
