import React, { Fragment } from 'react';

const Breadcrumb = props => {
    const breadcrumb = props;
    console.log(breadcrumb.center);
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='page-header'>
                    <div className='row'>
                        <div className='col'>
                            <div className='page-header-left pc-center'>
                                <h3 className='text-warning'>{breadcrumb.title}</h3>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className='media'>
                                    <i className='flag-icon flag-icon-gt height-logo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Breadcrumb;
