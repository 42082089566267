import React from 'react'
import { Fragment } from 'react'
import StepContext from './steps/StepContext'
import logo from '../../assets/images/endless-logo.png'
// import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const BillingVisanet = props => {
    return (
        <Fragment>
            {/* <DisclaimerAnuncio {...props} /> */}
            <div className='page-main-header' style={{ margin: '0', padding: '0', width: '100%' }}>
                <div className='main-header-right row'>
                    <div className='logo-wrapper'>
                        <img
                            className='img-fluid'
                            src={logo}
                            alt='logo'
                            style={{ width: '200px', cursor: 'pointer' }}
                        />
                    </div>
                </div>
            </div>
            <br />
            <StepContext idInvoicing={props.match.params.id}></StepContext>
        </Fragment>
    )
}

export default BillingVisanet
