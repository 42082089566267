import moment from 'moment-timezone';

export function headerNoSat() {
    return [
        { field: 'COD_SOLICITUD_USUARIO', headerName: 'Solicitud', width: 320 },
        { field: 'COD_RAMPA', headerName: 'Rampa', width: 320, valueGetter: params => 'Rampa ' + params.value },
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 320 },
        { field: 'SUB_TIPO_SOLICITUD', headerName: 'Sub tipo solicitud', width: 320 },
        {
            field: 'FECHA_INICIO',
            headerName: 'Fecha programada',
            width: 320,
            valueGetter: params => {
                const fecha = moment(params.value).format('DD/MM/YYYY');
                const d = new Date(params.value);
                const minutos = d.getUTCMinutes() === 0 ? '00' : d.getUTCMinutes();
                const horas = d.getUTCHours().toString().length < 2 ? `0${d.getUTCHours()}` : d.getUTCHours();
                return `${fecha}  ${horas}:${minutos}`;
            },
        },
    ];
}
