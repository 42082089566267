import React from 'react'
import { ModalBody } from 'reactstrap'

const ModalModule = ({ useFetchInit }) => {
    const { FetchGetAnuncios } = useFetchInit
    const { anuncios } = FetchGetAnuncios

    return (
        <>
            <ModalBody>
                <div>
                    <embed
                        src={anuncios.length > 0 && anuncios[0].ARCHIVO + '#toolbar=0&navpanes=0&scrollbar=0'}
                        height='550px'
                        width='100%'
                        name='embed_content'
                        type='application/pdf'
                    />
                </div>
            </ModalBody>
        </>
    )
}

export default ModalModule
