import React, { Fragment } from 'react'
import { useFetchInitCambioCredenciales } from '../../hooks/vinculaciones/useFetchInitCambioCredenciales'
// import Breadcrumb from '../common/breadcrumb';
import FormularioCambios from './formularioCambios'
import { TituloCambio } from './tituloCambio'
import styled from '@emotion/styled'
import Loading from './loading'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const CajaMensaje = styled.div`
    padding: 20px 32px 20px 32px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    background-color: #ff6219;
    font-size: 15px;
    border-radius: 4px;
    color: #ffffff;
    width: 98%;
`

/************************ Cargando Hook principal ********************************/
/*********************************************************************************/
const CambioDeCredenciales = props => {
    const codigo_encritado = props.match.params.codigouser

    const url_confirmacion = process.env.REACT_APP_CUSTOMER + '/v1/apm-vinculacion/confirmacion'
    const servicio_confirma_credenciales = 'credenciales'
    const servicio_cambio = 'cambio-credenciales'
    const token_page = process.env.REACT_APP_TOKEN_PAGE

    const [cambio, capturandovalores, load, error, handleChange] = useFetchInitCambioCredenciales({
        url_confirmacion,
        servicio_confirma_credenciales,
        token_page,
        codigo_encritado,
        servicio_cambio,
    })

    const { respuesta_credenciales, mensaje_carga, credenciales, carga } = cambio

    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <TituloCambio />
                        {carga ? (
                            <Loading />
                        ) : respuesta_credenciales ? (
                            credenciales ? (
                                <form onSubmit={capturandovalores}>
                                    <FormularioCambios
                                        cambio={cambio}
                                        load={load}
                                        error={error}
                                        handleChange={handleChange}
                                    />
                                </form>
                            ) : (
                                <CajaMensaje>{mensaje_carga}</CajaMensaje>
                            )
                        ) : (
                            <CajaMensaje>{mensaje_carga}</CajaMensaje>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CambioDeCredenciales
