import axios from 'axios'

export const getValidaDocumentos = async (
    token_user,
    token_page,
    url_valida_documentos,
    sub_tipo,
    solicitud
) => {
    const retorno = await axios
        .get(`${url_valida_documentos}?v_sub_tipo=${sub_tipo}&v_solicitud=${solicitud}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            return {
                v_resultado: data.v_resultado,
                v_mensaje: data.v_mensaje,
                v_estado: true
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_resultado: '',
                    v_mensaje: '',
                    v_estado: false
                }
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_resultado: '',
                    v_mensaje: '',
                    v_estado: false
                }
            } else {
                console.log(e.response.data)
                return {
                    v_resultado: '',
                    v_mensaje: '',
                    v_estado: false
                }
            }
        })

    return retorno
}
