import React from 'react'

export const PerfilCard = ({ titulo, codigo, handleInputChange, perfil_seleccionado }) => {
    return (
        <div className='col-sm-6'>
            <div className='card'>
                <div className='media p-20'>
                    <div className='radio radio-primary mr-3 ml-2'>
                        {/* <input
                            id={codigo}
                            type='radio'
                            name='perfil_seleccionado'
                            value={perfil_seleccionado}
                            onChange={handleInputChange}
                            checked={codigo === perfil_seleccionado - ''}
                        /> */}
                        {codigo === perfil_seleccionado - '' ? (
                            <input
                                id={codigo}
                                type='radio'
                                name='perfil_seleccionado'
                                // value={perfil_seleccionado}
                                value={titulo}
                                onChange={handleInputChange}
                                checked
                            />
                        ) : (
                            <input
                                id={codigo}
                                type='radio'
                                name='perfil_seleccionado'
                                // value={perfil_seleccionado}
                                value={titulo}
                                onChange={handleInputChange}
                            />
                        )}

                        <label htmlFor={codigo}>-+-</label>
                    </div>
                    <div className='media-body'>
                        <h6 className='mt-0 mega-title-badge'>
                            {titulo}
                            {/* <span className='badge badge-primary pull-right digits'>{sello}</span> */}
                        </h6>
                        <p>{'-----'}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
