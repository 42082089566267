import React, { Fragment } from 'react'
import styled from '@emotion/styled'

const Brief = styled.h5`
    padding: 12px 0px 0px 0px
    font-size: 22px
    border-radius: 4px
    color: black
`

const Texto = styled.p`
    padding: 12px 0px 0px 0px
    font-size: 1.2em
    border-radius: 4px
    color: black
`

const Targets = () => {
    return (
        <Fragment>
            <Brief>Instrucciones:</Brief>
            <Texto>
                Para que APMTQ pueda darle seguimiento a su solicitud, debe completar los requerimientos que se tienen a
                continuación.
            </Texto>
        </Fragment>
    )
}

export default Targets
