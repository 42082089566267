import axios from 'axios'

export const postConfirmaUsuarioEncriptado = async ({
    url_confirmacion,
    servicio_confirma_credenciales,
    token_page,
    codigo_encritado
}) => {
    // se cambia a la variable porque lo resibe distinto
    let servicio = ''
    let codigo_vinculacion = ''
    servicio = servicio_confirma_credenciales
    codigo_vinculacion = codigo_encritado

    console.log(servicio)
    console.log(codigo_vinculacion)

    const retorno = await axios
        .post(
            url_confirmacion,
            {
                servicio,
                codigo_vinculacion
            },
            {
                headers: {
                    // 'x-auth-token': token_user,
                    Authorization: `Basic ${token_page}`
                }
            }
        )
        .then(({ data }) => {
            return {
                v_cod_vinculacion: data.v_cod_vinculacion,
                v_mensaje: data.v_mensaje,
                v_resultado: data.v_resultado,
                v_error: true
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response?.status === 500) {
                return {
                    v_mensaje: 'ERROR 500, comunicar a APM Terminals',
                    v_resultado: false,
                    v_error: false
                }
            } else if (e.response?.status === 401) {
                return {
                    v_mensaje: 'Error de autorización, comunicar a APM Terminals',
                    v_resultado: false,
                    v_error: false
                }
            } else {
                console.log(e.response?.data)
                return {
                    v_mensaje: 'ERROR en la comunicación, comunicar a APM Terminals',
                    v_resultado: false,
                    v_error: false
                }
            }
        })

    return retorno
}
