import axios from 'axios'

export const getAnuncios = async ({ access }) => {
    const { url_disclaimer_anuncios, url_page } = access
    return await axios
        .get(url_disclaimer_anuncios, {
            headers: {
                url_page: url_page,
                Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
            },
        })
        .then(({ data }) => {
            return data
        })
        .catch(({ response }) => {
            throw response
        })
}
