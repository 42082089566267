import React, { useState, Fragment } from 'react'
import logo from '../../assets/images/endless-logo.png'
import IconLink from '../servicios-en-linea/cards/icon-link'
import LogoLiquidacion from '../../assets/images/LiquidacionAlmacenaje.png'
import { Form, Row, Col, FormLabel, Button, FormControl } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { DataGrid } from '@material-ui/data-grid'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'
// import { stubFalse } from 'lodash';

const ConsultaAlmacenaje = props => {
    const [startDate, setStartDate] = useState(new Date())
    const [contenedor, setcontenedor] = useState()
    const [data, setDataTable] = useState([])
    const [msgError, setMsgError] = useState({})
    const [showTable, setShowTable] = useState(true)

    const colums = [
        { field: 'TIPO', headerName: 'Tipo', width: 200 },
        { field: 'CONTENEDOR', headerName: 'Contenedor', width: 200 },
        { field: 'PIES', headerName: 'Pies', width: 200 },
        { field: 'FECHA_INGRESO', headerName: 'Fecha ingreso', width: 200 },
        { field: 'FECHA_EXCENTO_ESTADIA', headerName: 'Días libres APMTQ', width: 250 },
        { field: 'FECHA_APROXIMADA_RETIRO', headerName: 'Fecha retiro', width: 200 },
        { field: 'COSTE_APROXIMADO_ESTADIA', headerName: 'Monto a cancelar', width: 200 },
    ]

    const verifyNull = () => {
        if (typeof contenedor != 'undefined' || contenedor === '') {
            let day = startDate.getDate()
            let month = startDate.getMonth() + 1
            let year = startDate.getFullYear()
            let parseoFecha = day + '/' + month + '/' + year
            callApi(contenedor, parseoFecha)
        } else {
            alert('El campo contenedor es requerido')
        }
    }
    const callApi = async function (contenedor, fechaEstimada) {
        let datos = {
            contenedor: contenedor,
            fechaEstimada: fechaEstimada,
        }
        await axios
            .post(
                process.env.REACT_APP_EXTERNAL_PANEL + `/v1/apm-services/unaffectedly/calculadora-almacenaje`,
                datos,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                    },
                }
            )
            .then(res => {
                if (res.data.msg ? false : true) {
                    setDataTable(res.data)
                    setShowTable(true)
                } else {
                    setMsgError(res.data)
                    setShowTable(false)
                }
            })
            .catch(err => {
                console.log('Error: ' + err)
            })
    }
    const monto = data[0] ? data[0].COSTE_APROXIMADO_ESTADIA : 'x'
    return (
        <div>
            <DisclaimerAnuncio {...props} />
            <div className='page-main-header' style={{ margin: '0', padding: '0', width: '100%' }}>
                <div className='main-header-right row'>
                    <div className='logo-wrapper'>
                        <img
                            className='img-fluid'
                            src={logo}
                            alt='logo'
                            style={{ width: '200px', cursor: 'pointer' }}
                        />
                    </div>
                </div>
            </div>
            <div className='CustomFormularioConsultaContenedores'>
                <Form>
                    <Row xs='12' sm='12' md='12' lg='12' className='TittleConsulta'>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <FormLabel>Consulta de almacenajes para contenedores</FormLabel>
                        </Col>
                    </Row>
                    <Row xs='12' sm='1' md='3' lg='4' className='AllInputs'>
                        <Col xs='12' sm='12' md='6' lg='3' className='marCol'>
                            <FormLabel className='textForm'>Contenedor</FormLabel>
                            <FormControl
                                className='textoCont'
                                type='text'
                                name='contenedor'
                                id='numContenedor'
                                placeholder='ingrese el contenedor a consultar'
                                onChange={e => setcontenedor(e.target.value)}
                            />
                        </Col>
                        <Col xs='12' sm='12' md='6' lg='3' className='marCol'>
                            <FormLabel className='textForm'>Fecha de retiro</FormLabel>
                            <DatePicker
                                className='textodataPicker'
                                selected={startDate}
                                onChange={startDate => setStartDate(startDate)}
                                format='dd/mm/yyyy'
                                dateFormat='dd/MM/yyyy'
                                required
                            />
                        </Col>
                        <Col xs='12' sm='12' md='12' lg='3' className='marCol'>
                            <Button className='save' onClick={verifyNull}>
                                Consultar
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {showTable ? (
                    <>
                        <Fragment>
                            <Row className='TablaConstulta'>
                                <Col className='card'>
                                    <div style={{ height: 200, width: '100%' }}>
                                        <DataGrid rows={data} columns={colums} pageSize={2} loading={false} />
                                    </div>
                                </Col>
                            </Row>
                        </Fragment>
                        {monto === '$0' ? (
                            <>
                                <p className='nota'>
                                    NOTA: Favor verificar sus días libres podria estar en el rango de cobertura o bien
                                    su almacenaje debe cancelarlo directamente con su naviera
                                </p>
                            </>
                        ) : null}
                        <Row>
                            <Col>
                                <p className='nota'>
                                    Para solicitar su factura de almacenaje haga click en el siguiendo icono:{' '}
                                </p>
                            </Col>
                            <Col>
                                <IconLink
                                    key={1}
                                    id={15}
                                    img={LogoLiquidacion}
                                    link={
                                        'https://www.services.apmterminalsquetzal.com/ords/f?p=103:101::::101:P101_LINK:39'
                                    }
                                    tittle={'Liquidación Almacenaje'}
                                    tooltipTittle={
                                        'Permite solicitar la liquidación de almacenaje de Importación en base a una fecha estimada de retiro del contenedor de la terminal'
                                    }
                                    tooltipDescription={'Solicitud de Liquidación de almacenaje'}
                                    text='apm'
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row xs='12' sm='12' md='12' lg='12' className='MessageConsulta'>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <FormLabel>{msgError.msg}</FormLabel>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}
export default ConsultaAlmacenaje
