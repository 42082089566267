import axios from 'axios'

export const getValidaGestor = async (token_user, token_page, url_valida_gestor, v_cod_gestor) => {
    console.log('RECIBIENDO GESTOR', v_cod_gestor)
    const retorno = await axios
        .get(`${url_valida_gestor}?v_cod_gestor=${v_cod_gestor}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            console.log('RESPUTA VALIDA GESTOR', data)
            return {
                v_resultado: data.v_resultado,
                v_estado_gestor: data.v_estado_gestor,
                v_mensaje: 'success',
                v_estado: true
            }
        })
        .catch(e => {
            console.log(e)
            if (e.response.status === 500) {
                console.log('There was a problem with the server')
                return {
                    v_resultado: '',
                    v_estado_gestor: '',
                    v_mensaje: '500',
                    v_estado: false
                }
            } else if (e.response.status === 401) {
                console.log(`No hay autorización`)
                return {
                    v_resultado: '',
                    v_estado_gestor: '',
                    v_mensaje: '401',
                    v_estado: false
                }
            } else {
                console.log(e.response.data)
                return {
                    v_resultado: '',
                    v_estado_gestor: '',
                    v_mensaje: 'error',
                    v_estado: false
                }
            }
        })

    //console.log(retorno)
    return retorno
}
