import axios from 'axios'

export const getTotalGestores = async (
    token_user,
    token_page,
    url_gestor,
    p_pagina,
    p_registros,
    p_buscador
) => {
    console.log('qu epasaaaaaa', p_buscador)
    //const url_sub_tipos = `${url_s/ubtipos}?v_tipo_solicitud=${encodeURI(valor_tipo)}`

    console.log(p_pagina)
    console.log(p_registros)
    console.log(p_buscador)
    console.log('-----')

    const retorno = await axios
        .get(`${url_gestor}?v_pagina=${p_pagina}&v_registros=${p_registros}&v_buscador=${p_buscador}`, {
            headers: {
                'x-auth-token': token_user,
                Authorization: `Basic ${token_page}`
            }
        })
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            console.log(error)
            return []
        })

    return retorno
}
