import React, {useContext} from 'react';
import {Container} from 'react-bootstrap';
import {TableContainer, TableHead, TableBody, TableRow, Table, TableCell} from '@material-ui/core';
import logo from '../../assets/images/endless-logo.png';

const  InvoiceToPrint = (props)=> {
    let transdata= props.trasData;
    return (
      <Container>
        <TableContainer >
            <Table border="2" size="small">
                <TableHead>  
                    <TableRow style={{color: '#FFFFFF', fontWeight: 'bold'}}>
                        <TableCell colSpan={2} style={{ textAlign:'center', color: '#FFFFFF', fontWeight: 'bold'}}>
                            <img
                                className='img-fluid'
                                src={logo}
                                alt='logo'
                                style={{ width: '200px', cursor: 'pointer' }}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>     
                <TableBody>
                    <TableRow key={'1'}>
                        <TableCell style={{fontWeight: 'bold'}}>Método de Pago</TableCell>
                        <TableCell>VisaNet Guatemala (Cybersource)</TableCell>
                    </TableRow>
                    <TableRow key={'2'}>
                        <TableCell style={{fontWeight: 'bold'}}>Fecha y Hora</TableCell>
                        <TableCell>{transdata.date}</TableCell>
                    </TableRow>
                    <TableRow key={'3'}>
                        <TableCell style={{fontWeight: 'bold'}}>Monto</TableCell>
                        <TableCell>{transdata.amount}</TableCell>
                    </TableRow>
                    <TableRow key={'4'}>
                        <TableCell style={{fontWeight: 'bold'}}>Nombre del Cliente</TableCell>
                        <TableCell>{transdata.client}</TableCell>
                    </TableRow>
                    <TableRow key={'5'}>
                        <TableCell style={{fontWeight: 'bold'}}>Número de Tarjeta</TableCell>
                        <TableCell>{transdata.card}</TableCell>
                    </TableRow>
                    <TableRow key={'6'}>
                        <TableCell style={{fontWeight: 'bold'}}>Numero de Referencia</TableCell>
                        <TableCell>{transdata.referenceApm}</TableCell>
                    </TableRow>
                    <TableRow key={'7'}>
                        <TableCell style={{fontWeight: 'bold'}}>Numero de Autorización</TableCell>
                        <TableCell>{transdata.authorization}</TableCell>
                    </TableRow>
                    <TableRow key={'8'}>
                        <TableCell style={{fontWeight: 'bold'}}>Numero de Auditoría</TableCell>
                        <TableCell>{transdata.audit}</TableCell>
                    </TableRow>
                    <TableRow key={'9'}>
                        <TableCell style={{fontWeight: 'bold'}}>Afiliación</TableCell>
                        <TableCell>{transdata.membership}</TableCell>
                    </TableRow>
                    <TableRow key={'10'}>
                      <TableCell style={{ textAlign:'center', fontWeight: 'bold'}} colSpan={2}>------------------------  Pago Electrónico  ------------------------</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
      </Container>
    );
}

export default InvoiceToPrint;