import { postWoMarchamo } from './postWoMarchamo'

export const FetchWoMarchamo = async ({
    token_user,
    token_page,
    url_enviar_wo,
    solicitud_apex,
    facturar_a,
    // sub_tipo_solicitud,
    setRespuestaSolicitud,
    respuestaSolicitud,
    confirmaSolicitud
}) => {
    if (confirmaSolicitud) {
        const { v_mensaje, v_resultado, v_estado } = await postWoMarchamo(
            token_user,
            token_page,
            url_enviar_wo,
            solicitud_apex,
            facturar_a
        )
            .then(res => res)
            .catch(err => {
                console.log(err)
                return {
                    v_estado: false,
                    v_mensaje: 'Error interno del servidor',
                    v_resultado: false
                }
            })

        console.log('666666666666666666666 WO-----------------------> ', v_mensaje, v_resultado, v_estado)
        setRespuestaSolicitud({
            ...respuestaSolicitud,
            // valida_documentos: sub_tipo_solicitud === 341 ? '' : 'Si tiene agregados los documentos',
            // estado_documentos: true,
            // confirma_solicitud: 'Se confirmó en la solicitud',
            // estado_solicitud: true,
            envio_wo: v_mensaje,
            estado_wo: v_resultado === 'true',
            mostrar_respuestas: true
        })
        const woMarchamo = v_estado === true && v_resultado === 'true'
        return { woMarchamo }
    } else {
        return { woMarchamo: false }
    }
}
