import React,{useState,useEffect} from 'react'
import {Form,Button, FormLabel,Row,Col} from "react-bootstrap";
import axios from 'axios';
import Select from 'react-select';

const DataForm = (props) => {
    const { pad } = props
    const token = props.token_user;
    const [years, setOptionYears] = useState([])
    const [weeks, setOptionWeeks] = useState([])

    const [year, setDataYear] = useState("");
    const [weekFrom, setDataWeekFrom] = useState("");
    const [weekTo, setDataWeekTo] = useState("");
    
    const verifyNull=()=>{
        if((typeof(year.value)!='undefined')&&(typeof(weekFrom.value)!='undefined')&&(typeof(weekTo.value)!='undefined')){
            const{callData} = props
            callData(year.value, weekFrom.value, weekTo.value)
        }else{
            alert('Todos los campos son necesarios')
        }
    }

    const loadData =async function(){
        const resp = await axios.get(process.env.REACT_APP_DASHBOARDSCMP_API+`/v1/yard-density/years`,{
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                'x-auth-token': token,
            }
        }).then(res =>{
            setOptionYears(res.data.data.map((r) => ({
                value: r.year,
                label: r.year
            })));
        });
        console.log(resp)
    }

    const getWeeks = async function(year){
        const resp = await axios.get(process.env.REACT_APP_DASHBOARDSCMP_API+`/v1/yard-density/weeks/${year}`,{
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_TOKEN_PAGE}`,
                'x-auth-token': token,
            }
        }).then(res =>{
            setOptionWeeks(res.data.data.map((r) => ({
                value: r.week,
                label: pad(r.week, 2)
            })));
        });
        console.log(resp)
    }

    const yearChanged = async function (event) {
        const{resetGraphic} = props
        setDataYear(event)
        await getWeeks(event.value)
        setDataWeekFrom("")
        setDataWeekTo("")
        resetGraphic()
    }

    useEffect(() => {
        loadData();
    }, []);

    return(
        <div className="CustomFormularioDashboard">
            <Form>
                <Row  xs="12" sm="1" md="3" lg="4" className="AllInputs">
                    <Col xs="12" sm="12" md="3" lg="4">
                        <FormLabel className="textForm">
                            Year
                        </FormLabel>
                        <Select
                            className="tamSelect"
                            name="year"
                            value={year || ''}
                            options={years}
                            onChange={e => yearChanged(e)}
                            required
                        />
                    </Col>
                    <Col xs="12" sm="12" md="3" lg="4">
                        <FormLabel className="textForm">
                            Start Week
                        </FormLabel>
                        <Select
                            className="tamSelect"
                            name="weekFrom"
                            value={weekFrom || ''}
                            options={weeks}
                            onChange={setDataWeekFrom}
                            required
                        />
                    </Col>
                    <Col xs="12" sm="12" md="3" lg="4">
                        <FormLabel className="textForm">
                            End Week
                        </FormLabel>
                        <Select
                            className="tamSelect"
                            name="weekTo"
                            value={weekTo || ''}
                            options={weeks}
                            onChange={setDataWeekTo}
                            required
                        />
                    </Col>
                </Row>
                <Row  xs="1" sm="1" md="3" lg="4" className="RowButtonSave">
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Button className="save" onClick={verifyNull}>
                            Accept
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default DataForm
