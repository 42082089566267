import React, { useContext, useState } from 'react';
import {
    Button,
    TextField,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormControl,
    Grid,
    FormHelperText,
} from '@material-ui/core';
import { multiStepContext } from './StepContext';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Col, Container, Image } from 'react-bootstrap';
import logoVisa from '../../../assets/images/logo-visa.png';
import logoMasterCard from '../../../assets/images/logo-mastercard.png';
import Swal from 'sweetalert2';

const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
const onlyNumbers_regex = new RegExp('^[0-9]+$');
const email_regex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$');

export default function SecondStep() {
    const { setStep, userData, setUserData } = useContext(multiStepContext);

    const [selectedDate, handleDateChange] = useState(new Date());

    async function getCardType(card) {
        //console.log('Analizando tarjeta: '+card);

        if (card.match(visa_regex)) {
            setUserData({ ...userData, cardType: 'visa', accountNumber: card });
            //console.log('Es visa');
        } else if (card.match(mastercard_regex)) {
            setUserData({ ...userData, cardType: 'mastercard', accountNumber: card });
            //console.log('Es Mastercard');
        } else {
            setUserData({ ...userData, cardType: '', accountNumber: card });
            //console.log('No se que tipo es');
        }
    }

    const getDate = date => {
        handleDateChange(date);
        //console.log('exp:'+date);
        //console.log('test......'+date.getFullYear());
        //console.log('test2......'+(date.getMonth()+1));
        setDateValid(true);
        setDateError('');
        setUserData({ ...userData, expirationYear: date.getFullYear(), expirationMonth: date.getMonth() + 1 });
    };

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showAccount: false,
        showCod: false,
    });

    const handleClickShowCod = () => {
        setValues({ ...values, showCod: !values.showCod });
    };

    const handleMouseDownCod = event => {
        event.preventDefault();
    };

    const [nameValid, setNameValid] = useState(true);
    const [nameError, setNameError] = useState('');
    const [lastNameValid, setLastNameValid] = useState(true);
    const [lastNameError, setLastNameError] = useState('');
    const [addressValid, setaddressValid] = useState(true);
    const [addressError, setaddressError] = useState('');
    const [cardValid, setCardValid] = useState(true);
    const [cardError, setCardError] = useState('');
    const [cvvValid, setCvvValid] = useState(true);
    const [cvvError, setCvvError] = useState('');
    const [dateValid, setDateValid] = useState(true);
    const [dateError, setDateError] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [emailError, setEmailError] = useState('');

    async function validForm() {
        let valid = true;
        //console.log('Validando Form');

        if (!userData['firstName'] || userData['firstName'].trim() === '') {
            setNameError('Campo Requerido');
            setNameValid(false);
            valid = false;
        }

        if (!userData['lastName'] || userData['lastName'].trim() === '') {
            setLastNameError('Campo Requerido');
            setLastNameValid(false);
            valid = false;
        }

        if (!userData['adress'] || userData['adress'].trim() === '') {
            setaddressError('Campo Requerido');
            setaddressValid(false);
            valid = false;
        }

        if (!userData['accountNumber'] || userData['accountNumber'].trim() === '') {
            setCardError('Campo Requerido');
            setCardValid(false);
            valid = false;
        }

        if (!userData['cvNumber'] || userData['cvNumber'].trim() === '') {
            setCvvError('Campo Requerido');
            setCvvValid(false);
            valid = false;
        } else if (!userData['cvNumber'].match(onlyNumbers_regex)) {
            setCvvError('Solo se permiten Números');
            setCvvValid(false);
            valid = false;
        }

        if (!userData['expirationYear'] || String(userData['expirationYear']).trim() === '') {
            setDateError('Campo Requerido');
            setDateValid(false);
            valid = false;
        }

        if (!userData['expirationMonth'] || String(userData['expirationMonth']).trim() === '') {
            setDateError('Campo Requerido');
            setDateValid(false);
            valid = false;
        }

        if (!userData['email'] || userData['email'].trim() === '') {
            setEmailError('Campo Requerido');
            setEmailValid(false);
            valid = false;
        } else if (!userData['email'].match(email_regex)) {
            setEmailError('Email No Válido');
            setEmailValid(false);
            valid = false;
        }

        if (valid) {
            // console.log('Form Valido');
            setStep(3);
        } else {
            //console.log('Form InValido');

            Swal.fire({
                icon: 'error',
                title: 'Por Favor..',
                text: 'Revisa los datos Ingresados',
                confirmButtonColor: '#FF6203',
            });
        }
    }

    return (
        <Container className='mt-5'>
            <Col md={{ span: 6, offset: 3 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: 20,
                            boxShadow: '0 2px 15px 0 rgba(197,197,197,.5)',
                            borderRadius: '10px',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    error={!nameValid}
                                    helperText={nameError}
                                    fullWidth
                                    autoComplete='no'
                                    label='Nombre'
                                    value={userData['firstName']}
                                    onChange={e => {
                                        setNameValid(true);
                                        setNameError('');
                                        setUserData({ ...userData, firstName: e.target.value });
                                    }}
                                    margin='dense'
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    error={!lastNameValid}
                                    helperText={lastNameError}
                                    fullWidth
                                    autoComplete='no'
                                    label='Apellido'
                                    value={userData['lastName']}
                                    onChange={e => {
                                        setLastNameValid(true);
                                        setLastNameError('');
                                        setUserData({ ...userData, lastName: e.target.value });
                                    }}
                                    margin='dense'
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    error={!addressValid}
                                    helperText={addressError}
                                    fullWidth
                                    autoComplete='no'
                                    label='Dirección'
                                    value={userData['adress']}
                                    onChange={e => {
                                        setaddressValid(true);
                                        setaddressError('');
                                        setUserData({ ...userData, adress: e.target.value });
                                    }}
                                    margin='dense'
                                    variant='outlined'
                                    color='secondary'
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    required
                                    error={!cardValid}
                                    helperText={cardError}
                                    inputProps={{ inputMode: 'numeric', maxLength: 16 }}
                                    fullWidth
                                    autoComplete='no'
                                    label='No Tarjeta'
                                    type='number'
                                    value={userData['accountNumber']}
                                    onChange={e => {
                                        setCardValid(true);
                                        setCardError('');
                                        getCardType(e.target.value);
                                    }}
                                    margin='dense'
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {userData['cardType'] === 'visa' ? (
                                    <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                        <span
                                            style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}
                                        ></span>
                                        <Image
                                            src={logoVisa}
                                            style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '50%' }}
                                        />
                                    </div>
                                ) : userData['cardType'] === 'mastercard' ? (
                                    <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                        <span
                                            style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}
                                        ></span>
                                        <Image
                                            src={logoMasterCard}
                                            style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '50%' }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    required
                                    error={!cvvValid}
                                    fullWidth
                                    autoComplete='no'
                                    variant='outlined'
                                    margin='dense'
                                >
                                    <InputLabel htmlFor='outlined-adornment-password'>CVV</InputLabel>
                                    <OutlinedInput
                                        id='outlined-adornment-password2'
                                        inputProps={{ inputMode: 'numeric', maxLength: 4 }}
                                        type={values.showCod ? 'text' : 'password'}
                                        value={userData['cvNumber']}
                                        onChange={e => {
                                            setCvvValid(true);
                                            setCvvError('');
                                            setUserData({ ...userData, cvNumber: e.target.value });
                                        }}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowCod}
                                                    onMouseDown={handleMouseDownCod}
                                                >
                                                    {values.showCod ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={45}
                                    />
                                    {!cvvValid ? <FormHelperText id='helpTextCvv'>{cvvError}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    required
                                    error={!dateValid}
                                    helperText={dateError}
                                    fullWidth
                                    autoComplete='no'
                                    margin='dense'
                                    variant='outlined'
                                    size='small'
                                    inputVariant='outlined'
                                    label='With keyboard'
                                    openTo='year'
                                    views={['year', 'month']}
                                    label='Año y Mes'
                                    format='yyyy/MM'
                                    value={selectedDate}
                                    onChange={getDate}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    error={!emailValid}
                                    helperText={emailError}
                                    fullWidth
                                    autoComplete='no'
                                    label='Email'
                                    value={userData['email']}
                                    onChange={e => {
                                        setEmailValid(true);
                                        setEmailError('');
                                        setUserData({ ...userData, email: e.target.value });
                                    }}
                                    margin='dense'
                                    variant='outlined'
                                    color='secondary'
                                    size='small'
                                />
                            </Grid>
                        </Grid>

                        <Grid container className='mt-4' spacing={2} style={{ textAlign: 'center' }}>
                            <Grid item xs={6}>
                                <Button
                                    variant='contained'
                                    onClick={() => setStep(1)}
                                    style={{ backgroundColor: '#004165', color: '#FFFFFF', fontWeight: 'bold' }}
                                >
                                    Atrás
                                </Button>{' '}
                                <span></span>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant='contained'
                                    onClick={() => validForm()}
                                    style={{ backgroundColor: '#FF6203', color: '#FFFFFF', fontWeight: 'bold' }}
                                >
                                    Siguiente
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </MuiPickersUtilsProvider>
            </Col>
        </Container>
    );
}
