import {makeStyles} from '@material-ui/core/styles';
export const confTableRowsStatus ={
    height: 300,
    width: 1, 
    '& .super-app.low': {
      backgroundColor: 'rgba(255, 0, 0, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.medium': {
      backgroundColor:  'rgba(255, 165, 0, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.high': {
        backgroundColor: 'rgba(50, 205, 50,0.49)',
        color: '#1a3e72',
        fontWeight: '600',
      },
  }

  export const listadoEstado = [
    {
      value: 'HH',
      label: 'Llenos',
    },
    {
      value: 'EMT',
      label: 'Vacíos',
    }, 
  ];
  export const listadoImportExport = [
    {
      value: 'E',
      label: 'Importacion',
    },
    {
      value: 'A',
      label: 'Exportacion',
    }, 
  ];
  export const initialState = {
    statusContainers: '',
    importExport: '', 
    fecha: '', 
  };

  export const useStyles = makeStyles((theme) => ({ 
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '90%',
    }, 
  }));