import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { DataGrid } from '@material-ui/data-grid'
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Loading from './loading'
import { GetTipoCarga } from './../../../helpers/solicitudes/GetTipoCarga'
import { postInsertarCarga } from './../../../helpers/solicitudes/postInsertarCarga'
import { getCargas } from './../../../helpers/solicitudes/getCargas'
import { postEliminarCarga } from './../../../helpers/solicitudes/postEliminarCarga'

const ModalCarga = props => {
    const {
        token_user,
        token_page,
        toggle,
        // modal,
        title,
        dataRequest,
        // url_gestores,
        ctipos,
        // setCtipos,
        // url_valida_gestor,
        // setButtonEnable,
        url_tipo_carga,
        url_insertar_carga,
        url_eliminar_carga,
        url_listar_carga,
        v_show
    } = props

    console.log('SETO SE MUESTRA', dataRequest)
    const { solicitud_apex } = ctipos

    const [responseData, setResposeData] = useState([])
    const [listadoCargas, setListadoCargas] = useState([])
    const [load, setLoad] = useState(true)
    const [enviarData, setEnviardata] = useState({
        tipo_carga: '',
        identificador: '',
        cambioEffect: true
    })
    const { cambioEffect } = enviarData

    useEffect(() => {
        GetTipoCarga(token_user, token_page, url_tipo_carga)
            .then(resultado => {
                console.log('RESULTADO', resultado)
                setResposeData(resultado)
                setLoad(false)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        getCargas(token_user, token_page, solicitud_apex, url_listar_carga)
            .then(resultado => {
                v_show(false)
                //let total_cargas = Object.keys(resultado).length
                let total_cargas = resultado.length

                if (total_cargas === 0) {
                    console.log(`no tiene carga`)
                    v_show(true)
                } else {
                    console.log(`si tiene cargas`)
                    v_show(true)
                }
                console.log('RESULTADO CARGAS', resultado)
                setListadoCargas(resultado)
                // validarle cargas
                //v_show
                //
                setLoad(false)
            })
            .catch(error => {
                console.log(`Existe un error `,error)
            })

        return () => {
            console.log(`...`)
        }
    }, [cambioEffect, solicitud_apex, token_user, token_page, url_tipo_carga, url_listar_carga])

    const header = [
        {
            field: 'COD_VINCULACION_USUARIO',
            headerName: 'Codigo',
            width: 100,
            renderCell: params => (
                <strong style={{ minWidth: '-webkit-fill-available' }}>
                    <Button color='warning' onClick={() => eliminarCarga(params)}>
                        <i className='fa fa-trash-o'></i>
                    </Button>
                </strong>
            )
        },
        {
            field: 'CONTENEDOR',
            headerName: 'Contenedor',
            width: 200
        },
        {
            field: 'IDENTIFICADOR',
            headerName: 'Identificador',
            width: 200
        },
        {
            field: 'TIPO',
            headerName: 'Tipo',
            width: 200
        }
    ]

    const handleInputChange = event => {
        setEnviardata({
            ...enviarData,
            [event.target.name]: event.target.value
        })
    }

    const AgregarCarga = async event => {
        event.preventDefault()
        // console.log('ENVIANDO INFOR', enviarData, dataRequest, ctipos)
        const { tipo_carga, identificador } = enviarData
        setLoad(true)

        const { v_resultado, v_mensaje } = await postInsertarCarga(
            token_user,
            token_page,
            url_insertar_carga,
            solicitud_apex,
            dataRequest,
            tipo_carga,
            identificador
        )
            .then(res => res)
            .catch(err => err)

        console.log('RESPUESTA', v_resultado, v_mensaje)

        if (v_resultado === 'true') {
            toast.success(v_mensaje, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setEnviardata({ ...enviarData, cambioEffect: !cambioEffect })
        } else {
            toast.warning(v_mensaje, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        setLoad(false)
    }

    const eliminarCarga = async params => {
        setLoad(true)
        const { COD_VACIADO_ALMACENAJE } = params.row
        const { v_resultado, v_mensaje } = await postEliminarCarga(
            token_user,
            token_page,
            url_eliminar_carga,
            COD_VACIADO_ALMACENAJE
        )
            .then(res => res)
            .catch(err => err)
        if (v_resultado === 'true') {
            toast.success(v_mensaje, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setEnviardata({ ...enviarData, cambioEffect: !cambioEffect })
        } else {
            toast.warning(v_mensaje, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        setLoad(false)
    }

    return (
        <Fragment>
            <ModalHeader toggle={toggle}>
                {title} &nbsp &nbsp- &nbsp &nbsp Contenedor: {dataRequest}
            </ModalHeader>
            <ModalBody>
                <div className='container-fluid bd-example-row'>
                    <div className='row'>
                        <form onSubmit={AgregarCarga} className='col-md-12 mb-4'>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                                    {'Tipo Carga Suelta *'}
                                </label>
                                <div className='col-sm-9'>
                                    <select
                                        required
                                        className='form-control'
                                        name='tipo_carga'
                                        onChange={handleInputChange}
                                    >
                                        <option value=''>-- Seleccione el tipo de carga --</option>
                                        {responseData.map(item => {
                                            return (
                                                <option key={item.COD_TIPO_CARGA} value={item.COD_TIPO_CARGA}>
                                                    {item.NOMBRE}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label' htmlFor='inputPassword3'>
                                    {'Identificador de Carga *'}
                                </label>
                                <div className='col-sm-9'>
                                    <input
                                        required
                                        onChange={handleInputChange}
                                        type='text'
                                        id='form1'
                                        className='form-control'
                                        name='identificador'
                                    />
                                </div>
                            </div>

                            <div className='col-md-12 text-right'>
                                <button type='submit' className='btn btn-primary'>
                                    Agregar
                                </button>
                            </div>
                        </form>
                    </div>

                    <div style={{ height: 300, width: '100%' }}>
                        {load ? (
                            <Loading />
                        ) : (
                            <DataGrid
                                rows={listadoCargas}
                                columns={header}
                                pageSize={10}
                                sortModel={[
                                    {
                                        field: 'CONTENEDOR',
                                        sort: 'asc'
                                    }
                                ]}
                            />
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color='gray' onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Fragment>
    )
}

export default ModalCarga
