import React, { Fragment } from 'react';
import logo from '../../assets/images/endless-logo.png';

export const TituloCambio = () => {
    return (
        <Fragment>
            <div className='text-center'>
                <div className='text-center'>
                    <img src={logo} alt='Logo' style={{ width: '270px' }} />
                </div>
                <h5 className='pt-3'>{'Cambio de contraseñas'}</h5>
            </div>
        </Fragment>
    );
};
